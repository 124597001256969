import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AutoFocusIonSearchbarDirective } from "./autofocus/autofocus-ionsearchbar.directive";
import { LazyBackgroundImageDirective } from "./lazy-background-image/lazy-background-image.directive";
import { MoveFocusDirective } from "./move-focus/move-focus.directive";
import { PageTitleDirective } from "./page-title/page-title.directive";
import { ScrollWhenKeyboardDidShowDirective } from "./scroll-when-keyboard-did-show/scroll-when-keyboard-did-show.directive";
import { ShareIsAvailableDirective } from "./share-is-available/share-is-available.directive";
import { ShareDirective } from "./share/share.directive";
import { LazyForDirective } from "./lazyFor.directive";

const directives = [
  AutoFocusIonSearchbarDirective,
  LazyBackgroundImageDirective,
  MoveFocusDirective,
  PageTitleDirective,
  ScrollWhenKeyboardDidShowDirective,
  ShareDirective,
  ShareIsAvailableDirective,
  LazyForDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
  imports: [CommonModule],
})
export class DirectivesModule {}
