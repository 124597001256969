import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import {
  AlertController,
  IonicModule,
  ToastController,
  Platform,
} from "@ionic/angular";
import { SocialLinkPipeModule } from "src/app/pipes/social-link/social-link.pipe";
import { DqTreatMeDetails } from "src/app/services/treat-me-data/treat-me-data.service";
import { NgNavigatorShareService } from "ng-navigator-share";
import { Subject, timer } from "rxjs";
import {
  exhaustMap,
  filter,
  map,
  retry,
  share,
  switchMap,
  take,
  takeUntil,
} from "rxjs/operators";
import { RandomiseUrlPipeModule } from "src/app/pipes/randomise-url/randomise-url.pipe";
import { environment } from "src/environments/environment";
import { OrderService } from "src/app/services/order/order.service";
import { selectTreatInfluencerTable } from "src/app/reducers";
import { UserTypePipe } from "../../../pipes/user-type.pipe";
import { AuthService } from "src/app/services/auth/auth.service";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { CognitoUser } from "@aws-amplify/auth";
import { NativeStorage } from "@awesome-cordova-plugins/native-storage/ngx";
import { RegionsService } from "src/app/services/regions/regions.service";
import { StorageAdapter } from "src/app/services/storage-adapter/storage-adapter.service";
import { VenueService } from "src/app/services/venue/venue.service";

@Component({
  selector: "dq-prom-bar",
  templateUrl: "prombar.component.html",
  styleUrls: ["prombar.component.scss"],
})
export class prombarComponent implements OnInit {
  isTablet: boolean = false;
  private stopPolling = new Subject();

  constructor(
    public orderSvc: OrderService,
    public platform: Platform,
    private auth: AuthClass,
    private storage: StorageAdapter,
    private nativeStorage: NativeStorage,
    private regions: RegionsService,
    private venueService: VenueService
  ) {
    if (this.platform.is("tablet")) this.isTablet = true;
  }
  ngOnDestroy() {
    this.stopPolling.next("");
  }

  async ngOnInit() {}
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RandomiseUrlPipeModule,
    SocialLinkPipeModule,
  ],
  exports: [prombarComponent],
  declarations: [prombarComponent],
  providers: [],
})
export class PromFooterModule {}
