import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, take } from "rxjs/operators";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { paramsToFormData } from "src/app/utils/object-to-form-data";
import { RegionsService } from "../regions/regions.service";
import {
  ActionSheetController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastButton,
  ToastController,
} from "@ionic/angular";
import { SyncService } from "../sync-engine/sync/sync.service";
import { SettingsState } from "../sync-engine/settings-state/settings-state.service";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
export interface DqCurrencyDetails {
  lc_currency_code_iso_4217: string; // "GBP",
  lc_country_code_iso_3166_three_char: string; // "GBR",
  lc_language_iso_639_1: string; // "en",
  lc_tax_text: string; // "VAT",
  lc_tax_rate: string; // "0.2"
}

export interface DqStaffDetails {
  venue_user_type: string; // "1",
  staff_mem_id: string; // "161",
  staff_venue_id: string; // "1",
  pin_number: string; // "1111",
  status: string; // "1",
  staff_username: string; // "testmanager",
  staff_password: string; // "5f4dcc3b5aa765d61d8327deb882cf99",
  notification_count: number; // 0;
  username: string; // "Testmanager"
}

export interface DqVenueDetails {
  v_id: string; // "1",
  v_name: string; // "Test Venue",
  v_merchant_uuid: string; // "54a624c325151",
  v_api_key: string; // "opt6ufl8",
  v_api_passphrase: string; // "czo4lkoe"
  v_stripe_physical_location?: string;
}

export interface DqVenuePayPalDetails {
  v_id: string; // "1",
  v_name: string; // "Test Venue",
  v_merchant_uuid: string; // "54a624c325151",
  v_api_key: string; // "opt6ufl8",
  v_api_passphrase: string; // "czo4lkoe"
}

export interface DqUpdateUser {
  imageUrl?: string;
  email?: string;
  nickname?: string;
  name?: string;
  picture?: string;
}

/**
 * [
  {
    "status": "success",
    "message": "Successfully logged in",
    "user_type": "1",
    "venue_id": "1",
    "staff_pin": "1111",
    "staffdetails": [
      {
        "venue_user_type": "1",
        "staff_mem_id": "161",
        "staff_venue_id": "1",
        "pin_number": "1111",
        "status": "1",
        "staff_username": "testmanager",
        "staff_password": "5f4dcc3b5aa765d61d8327deb882cf99",
        "notification_count": 0,
        "username": "Testmanager"
      }
    ],
    "marchantdetails": [
      {
        "v_id": "1",
        "v_name": "Test Venue",
        "v_merchant_uuid": "54a624c325151",
        "v_api_key": "opt6ufl8",
        "v_api_passphrase": "czo4lkoe"
      }
    ],
    "paypaldetails": [
      {
        "v_id": "1",
        "v_name": "Test Venue",
        "v_merchant_uuid": "54a624c325151",
        "v_api_key": "opt6ufl8",
        "v_api_passphrase": "czo4lkoe"
      }
    ],
    "currency_locale_details": [
      {
        "lc_currency_code_iso_4217": "GBP",
        "lc_country_code_iso_3166_three_char": "GBR",
        "lc_language_iso_639_1": "en",
        "lc_tax_text": "VAT",
        "lc_tax_rate": "0.2"
      }
    ]
  }
]

 */
import { Haptics } from "@capacitor/haptics";
import { VenueService } from "../venue/venue.service";

export interface DqLoginResponseSuccess extends DqApiResponse {
  status: string;
  message: string; // "Successfully logged in"
  user_type: string; // "1",
  venue_id: string; // "1",
  staff_pin: string; // "1111",
  staffdetails: DqStaffDetails[];

  marchantdetails: DqVenueDetails[];
  paypaldetails: DqVenuePayPalDetails[];
  currency_locale_details: DqCurrencyDetails[];
  square_location_id: string;
}
export interface DqLoginResponseError extends DqApiResponse {
  status: "failed";
  message: "Enter a valid username and password"; // "Successfully logged in"
}
export type DqLoginResponse = DqLoginResponseSuccess | DqLoginResponseError;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private loadingCtrl: LoadingController,
    private modal: ModalController,
    private navCtrl: NavController,
    private sync: SyncService,
    private settingSt: SettingsState,
    private platform: Platform,
    private auth: AuthClass,
    private venueSvc: VenueService,
    private actionSheet: ActionSheetController
  ) {}

  async switchVenue() {
    //get all venues for this user from the auth object
    //then show a action sheet with each of the venues
    let user = await this.settingSt.currentUser$.pipe(take(1)).toPromise();
    /*let venues = user['signInUserSession']['accessToken']['payload']['cognito:groups'].filter((x) => {
      return x.startsWith("venue-");
    })*/

    let venu = await this.venueSvc.getStaffVenues().toPromise();
    if (venu.venues.length == 0) {
      //navigate to the no venues page
      this.navCtrl.navigateRoot([`${this.reg.getRegion()}/no-venues`], {});
      return;
    }

    if (venu.venues.length == 1) {
      this.settingSt.state.set({ currentVenue: venu.venues[0].v_id });
      console.warn('stepped in before switching venue as there is only 1 available')
      return 
    }
    //get
    let act = await this.actionSheet.create({
      header: "Select Venue",
      buttons: venu.venues.map((x) => {
        return {
          text: x.v_name,
          data: x,

          handler: () => {
            //switch
          },
        };
      }),
    });

    await act.present();

    let ondismiss = await act.onDidDismiss();
    let initalval = this.settingSt.state.get("currentVenue");
    if (ondismiss.data !== undefined) {
      if (initalval != ondismiss.data.v_id) {
        //create laoder
        let load = await this.loadingCtrl.create({
          message: "Switching Venue",
        });
        await load.present();
        console.log(ondismiss.data.v_id);
        this.settingSt.state.set({ currentVenue: ondismiss.data.v_id });
        if (initalval != undefined) {
          this.sync.storage.clear();
          let deviceToken = await this.settingSt.state.get("deviceToken");
          await this.logoutV2({ deviceToken }).toPromise();
          //wait 100ms
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(true);
            }, 100);
          });
          window.location.reload();
        } else {
          await load.dismiss();
        }
      } else {
      }
    } else {
      //if there is already a venue selected, then just go to the dashboard
      if (initalval == undefined) {
        //retriugger
        await this.switchVenue();
      } else {
        console.log("abort changing venue");
      }
    }
  }

  async logout() {
    Haptics.impact();
    const loader = await this.loadingCtrl.create();
    await loader.present();
    /**
     * Note that the menu is manually closed here, rather than using the
     * ion-menu-toggle component. When using the ion-menu-toggle component and
     * logging out, in some cases, this left the login screen unresponsive as
     * the menu close animation is interrupted, leaving html classes that locked
     * out interaction with the login page.
     */
    if ((await this.modal.getTop()) != undefined) {
      await this.modal.dismiss();
    }
    this.sync.storage.clear();
    let deviceToken = await this.settingSt.state.get("deviceToken");
    await this.logoutV2({ deviceToken }).toPromise();
    await this.auth.signOut();
    await loader.dismiss();
    if (this.platform.is("capacitor")) {
      // In the ios & android FOH apps, we need to manually goto login after
      // logout. As the default route redirects to /dashboard, and if we are
      // already on /dashboard the navigation does nothing.
      var region = this.reg.getRegion();
      await this.navCtrl.navigateRoot([`${region}/login`], {});
    } else {
      var region = this.reg.getRegion();
      await this.navCtrl.navigateRoot([`${region}/login`], {});
    }
    Haptics.vibrate();

    window.location.reload();
  }

  logoutV2({ deviceToken }: undefined | { deviceToken: string }) {
    return this.http.delete<DqApiResponse>(
      `${this.reg.ServerURL()}/v3/check_in`,
      {
        ...(deviceToken && {
          params: {
            fcm_id: deviceToken,
          },
        }),
      }
    );
  }
}
