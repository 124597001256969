import { Component, OnInit, ViewChild } from "@angular/core";
import {  ModalController, ToastController } from "@ionic/angular";
import {
  CMSPOSImport,
  CMSv2Menu,
  CMSv2MenuCategory,
} from "src/app/models/cms_db_equiv";
import { CmsMenuService } from "src/app/services/cms/menu/cms-menu.service";
import { CmsSettingsService } from "src/app/services/cms/settings/cms-settings.service";

@Component({
  selector: "app-menu-modal",
  templateUrl: "./menu-modal.component.html",
  styleUrls: ["./menu-modal.component.scss"],
})
export class MenuModalComponent implements OnInit {
  constructor(
    public modalcrt: ModalController,
    private menuSvc: CmsMenuService,
    private toast: ToastController
  ) {}
  options = {
    allowTouchMove: false,
  };
  data: CMSPOSImport;
  CategoryData: CMSv2MenuCategory[];
  async ngOnInit() {
    await this.menuSvc.getCategories().subscribe(async (points) => {
      //get all of the cats out of the super cats
      let menucats = [];
      menucats.push({ menu_cat_id: 0, name: "Don't Import" });
      for (let i = 0; i < points.CMSv2MenuSuperCategory.length; i++) {
        for (
          let j = 0;
          j < points.CMSv2MenuSuperCategory[i].menu_cats.length;
          j++
        ) {
          menucats.push(points.CMSv2MenuSuperCategory[i].menu_cats[j]);
        }
      }
      //loop through the menucats and add the pos_cat_id to the mappable_categories
      this.CategoryData = menucats;
    });

    //wait for slides to not be null
 
    this.data = await this.menuSvc.StartMenuImport().toPromise();
    this.updateNumber();
    this.checkCatsReady();
  }
  slide;
  updateNumber() {
    
  }
  CatsReady = false;
  CatsDontUser = {};
  checkCatsReady() {
    //loop through mappable_categories and check that no pos_cat_id are null
    this.CatsReady = true;
    for (let i = 0; i < this.data.mappable_categories.length; i++) {
      this.CatsDontUser[this.data.mappable_categories[i].pos_cat_id] = false;
      if (this.data.mappable_categories[i].menu_cat_id == 0) {
        this.CatsDontUser[this.data.mappable_categories[i].pos_cat_id] = true;
      }

      console.log(`checking`, this.data.mappable_categories[i]);
      if (this.data.mappable_categories[i].menu_cat_id == undefined) {
        console.log(`not ready`, this.data.mappable_categories[i]);

        this.CatsReady = false;
        return;
      }
    }
    console.log(`ready`, this.CatsDontUser);
  }

  async finishup() {
    let finaldata = this.data;
    //loop through new_items
    for (let i = 0; i < finaldata.new_items.length; i++) {
      //remove any items from final data that are in the dont import list
      if (this.CatsDontUser[finaldata.new_items[i].pos_cat_id] == true) {
        finaldata.new_items.splice(i, 1);
        i--;
      }
    }

    this.updateNumber();
    let result = await this.menuSvc.EndMenuImport(finaldata).toPromise();
    this.toast
      .create({
        message: "Refresh Completed, Changes should be reflected shortly",
        header: "Success",
        duration: 2000,
        position: "top",
        swipeGesture: "vertical",

      })
      .then((toast) => {
        toast.present();
      });
    this.modalcrt.dismiss();
  }
}
