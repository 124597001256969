import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { concatMap, filter, take } from "rxjs/operators";
import { RegionsService } from "../services/regions/regions.service";
import Tracker from "@openreplay/tracker";
import { CognitoUser } from "@aws-amplify/auth";
import { environment } from "src/environments/environment";
import { Platform } from "@ionic/angular";
import { SettingsState } from "../services/sync-engine/settings-state/settings-state.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInCMSGuard implements CanActivate {
  constructor(
    private settingSt: SettingsState,
    private auth: AuthClass,
    private router: Router,
    private regionService: RegionsService,
    private platform: Platform
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let user = await this.settingSt.state.get("cognitoUser");
    

    if (user != null && user != undefined) {
      let management = await this.settingSt.hasClaim$('management').pipe(take(1)).toPromise() 
      if (this.platform.is("capacitor") && management == false) {
        const reg = await this.regionService.getRegion();
        await this.router.navigate([`${reg}/login`]);
        return false;
      } else {
        return true;
      }
    } else {
      const reg = await this.regionService.getRegion();
      await this.router.navigate([`${reg}/login`], {
        queryParams: {
          redirect: encodeURIComponent(state.url),
        },
      });
      return false;
    }
  }
}
