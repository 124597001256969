import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";

@Component({
  selector: "app-notifstype",
  templateUrl: "./notifstype.component.html",
  styleUrls: ["./notifstype.component.scss"],
})
export class NotifstypeComponent implements OnInit {
  @Input() notificationOpt: { type: string; opt: string };
  @Output() options = new EventEmitter();
  constructor(private modal: ModalController) {}

  ngOnInit() {
    //log output of tableDetail
  }

  set(notif, event) {
    this.notificationOpt = notif;
    this.options.emit(notif);
    event.stopPropagation();
  }

  isus(type, opt) {
    return (
      this.notificationOpt.type === type && this.notificationOpt.opt === opt
    );
  }
}
