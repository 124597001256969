import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { concatMap, switchMap, take } from "rxjs/operators";
import { notificationsSelectDeviceToken, State } from "src/app/reducers";
import { VenueService } from "src/app/services/venue/venue.service";
import { Device } from "@capacitor/device";
import { Platform, ToastButton, ToastController } from "@ionic/angular";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  pushNotificationReceived,
  pushNotificationRegistrationError,
  pushNotificationRegistrationSuccess,
} from "src/app/actions/notification.actions";
import { PushNotificationSchema } from "@capacitor/push-notifications";
import { Router } from "@angular/router";
import { NotificationsEffects } from "src/app/effects/notifications/notification.effects";
import { environment } from "src/environments/environment";
import { NotificationService } from "src/app/services/notification/notification.service";
import { RegionsService } from "src/app/services/regions/regions.service";
@Injectable({
  providedIn: "root",
})
export class VenuesFacadeService {
  //for reference, the two lines below are currently found in the auth facade
  // currentVenue$ = this.store$.pipe(select(venuesSelectCurrentVenue));
  // currentVenueId$ = this.store$.pipe(select(venuesSelectCurrentVenueId));
  constructor(
    private store$: Store<State>,
    private venueSvc: VenueService,
    private platform: Platform,
    private toast: ToastController,
    private router: Router,
    private notificationSvc: NotificationService,
    private regionService: RegionsService
  ) {
    if (
      this.platform.is("desktop") &&
      !window.location.href.includes("local.getdqd.com")
    ) {
      const app = initializeApp({
        apiKey: "AIzaSyAsJrudep2BnYThbWuJ4T36a1pdZpVR8HA",
        authDomain: "dq-admin.firebaseapp.com",
        databaseURL: "https://dq-admin.firebaseio.com",
        projectId: "dq-admin",
        storageBucket: "dq-admin.appspot.com",
        messagingSenderId: "423361901692",
        appId: "1:423361901692:web:fa8eba05eb994ee7c105f9",
        measurementId: "G-WJKVHSNN0H",
      });
      console.log("On web");
    }
  }

  checkIn(v_id: string) {}
}
