import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";

import { MenuState } from "src/app/services/sync-engine/menu-state/menu.service";
import { PackageState } from "src/app/services/sync-engine/package-state/package.service";
import { CmsSettingsService } from "src/app/services/cms/settings/cms-settings.service";
import { BookingService } from "src/app/services/booking/booking.service";
import { environment } from "src/environments/environment";
import { RegionsService } from "src/app/services/regions/regions.service";
import { Clipboard } from "@capacitor/clipboard";

@Component({
  selector: "app-tab-report",
  templateUrl: "./tab-report.component.html",
  styleUrls: ["./tab-report.component.scss"],
})
export class TabReportComponent implements OnInit {
  @Input() seating;

  constructor(
    public modalController: ModalController,
    public alertController: AlertController,
    private region: RegionsService,
    private toaster: ToastController
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }
  async copyBookingLink(id) {
    let url = environment.production
      ? "https://book.getdqd.com/"
      : "http://book.dev.getdqd.com/";
    url += this.region.getRegion() + "/book/" + id + "/view";

    await Clipboard.write({
      string: url,
    });
    let to = await this.toaster.create({
      message:
        "Link Copied, this can be shared with customers to see their booking details.",
      duration: 2000,
      position: "top",
      swipeGesture: "vertical",

    });
    await to.present();
  }

  async copyRecieptLink(id) {
    let url = environment.production
      ? "https://order.getdqd.com/"
      : "http://dev.getdqd.com/";
    url += this.region.getRegion() + "/csreciept/" + id;

    await Clipboard.write({
      string: url,
    });
    let to = await this.toaster.create({
      message:
        "Link Copied, Share to allow guests to allow them to see a reciept.",
      duration: 2000,
      position: "top",
      swipeGesture: "vertical",

    });
    await to.present();
  }
}
