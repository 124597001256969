<ion-item lines="full" (click)="clicked.emit()" [disabled]="!can_book" [detail]="clickable" button>
  <ion-thumbnail slot="start">
    <ion-img
      [src]="
        (event?.e_image | imagePipe: 'thumbnail')
          ?   (event?.e_image | imagePipe: 'thumbnail')
          : 'https://dq-static-media.s3.amazonaws.com/6949d989-fbeb-4c75-b939-b291cde199ca'
      "
    ></ion-img>
  </ion-thumbnail>
  <ion-label [class]="event.parent_event_id != undefined ? '' : 'extramargin'">
    <ion-label>{{ event.name }}</ion-label>
    <div>
      <ion-chip *ngIf="event.parent_event_id != undefined" color="primary"
        >Recurring</ion-chip
      >
    </div>
  </ion-label>
  <ion-note> {{ event.e_date_local | date }} </ion-note>
  <ng-content></ng-content>
</ion-item>
