import { Pipe, PipeTransform } from "@angular/core";
import { DqMixtureBasic } from "src/app/services/mixture/mixture.service";

@Pipe({
  name: "matchMixtures",
})
export class MatchMixturesPipe implements PipeTransform {
  transform(ids: string, mixtures: DqMixtureBasic[]): DqMixtureBasic[] {
    if (!ids || !mixtures) {
      return;
    }
    return ids
      .split(",")
      .filter(id => !!id)
      .map(id => mixtures.find(mix => mix.mix_id === id));
  }
}
