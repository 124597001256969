import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-horizontal-scroller",
  templateUrl: "./horizontal-scroller.component.html",
  styleUrls: ["./horizontal-scroller.component.scss"],
})
export class HorizontalScrollerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
