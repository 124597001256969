
  <td  class="nopadd " (click)="booking.status == 'new'? false : open.emit()"><ion-icon [color]="booking.guest_id == '' || booking.table_id == '' || booking.package_id == ''? 'primary' : 'light'"  name="apps-outline">
    
  </ion-icon></td>
  <td  (click)="booking.status == 'new' || booking.booking_id == 'new'? false : open.emit()" class="ion-text-capitalize">{{booking.status == 'new'? '': booking.status}}   <ion-button *ngIf="booking.status =='new'" [disabled]="booking.guest_id == '' || booking.table_id == '' || booking.package_id == ''"  expand="block" size="small" (click)="saveBooking(); $event.stopPropagation();">Save</ion-button> </td>
  <td (click)="booking.guest_id == '' || booking.guest_id == undefined? guestedit.present($event): viewEditGuest()"  [class.unset]="booking.guest_id == '' || booking.guest_id == undefined"><div *ngIf="booking.group_name != undefined && booking.group_name != ''">{{booking.group_name}}</div>   <div [class.note]="booking.group_name != undefined && booking.group_name != ''"> {{(guest$(booking.guest_id) | async)?.name | default: 'Select Guest'}}</div> </td>
  <td (click)="tableedit.present($event)" [class.unset]="booking.table_id == '' || booking.table_id == undefined">{{(table$(booking.table_id) | async)?.name | default: 'Select Table'}} {{booking.linked_table_ids != undefined && booking.linked_table_ids.length > 0? ' + ' + booking.linked_table_ids.length :''}}</td>
  <td [class.unset]="booking.package_id == '' || booking.package_id == undefined" (click)="packageEdit.present($event)">{{(package$(booking.package_id) | async)?.name | default: 'No Package'}}</td>
  
  <td (click)="booking.receipt_id != ''? paymentOpen(): false">
    
    <app-reciept-chip *ngIf="booking.receipt_id != ''"  [receipt_id]="booking.receipt_id"></app-reciept-chip> 
    <ion-chip *ngIf="booking.receipt_id == ''" color="light" [disabled]="true" outline="true">
      - - - - - -
    </ion-chip>

    
  
  
  </td>
    <td (click)="booking.status == 'new'? false : open.emit()"><app-list-chip [booking]="booking" [interactable]="false">
    </app-list-chip>
</td>
<td [style.max-width.px]="175" [style.padding-top.px]="4" [style.padding-bottom.px]="4"><ion-textarea rows="2" autoGrow="false" [(ngModel)]="booking.notes"  [debounce]="500" (ngModelChange)="updateBooking()">

</ion-textarea></td>

<td><ion-select
  slot="end"
  placeholder="Any"
  [(ngModel)]="booking.arrive_utc"
  (ngModelChange)="updateBooking()"
  interface="popover"
>
  <ion-select-option [value]="">No Arrival</ion-select-option>
  <ng-container *ngrxLet=" event$(booking.event_id) | async as event">

    <ng-container
    *ngIf="event != undefined && event.arrival_time_options != undefined"
    >
    <ion-select-option
    *ngFor="let arrivalo of event.arrival_time_options"
    [value]="arrivalo.arrive_utc"
    >{{arrivalo.arrive_text_primary +
      arrivalo.arrive_text_trailing}}</ion-select-option
    >
  </ng-container>
</ng-container>
</ion-select></td>
<td ><ion-select
  slot="end"
  placeholder="None"
  [(ngModel)]="booking.promoter_id"
  (ngModelChange)="updateBooking()"
  interface="popover"
  toggleIcon="nope"
  
>
  <ion-select-option [value]="">No Promoter</ion-select-option>

    
    <ion-select-option
    *ngFor="let user of users$ | async"
    [value]="user.cog_user_name"
    >{{user.full_name }}</ion-select-option
    >

</ion-select></td>
<td> <app-tag-item [wrap]="false" (tagsUpdated)="booking.tags = $event; updateBooking()" color="transparent" [tiny]="true" [tags]="booking.tags" [title]="" [edit]="true" [reduced]="true"></app-tag-item></td>
<td *ngIf="booking.status == 'new'"><div>

<ion-button (click)="discardBooking()" color="secondary"  expand="block" size="small">Discard</ion-button>
</div>
</td>

<div *ngIf="loading" class="abs"> <ion-spinner>  </ion-spinner></div>
<ion-popover   size="auto" side="right" alignment="start"  arrow="true" [trigger]="booking.booking_id" triggerAction="click" [style.--min-width.px]="400"  [style.transform]="'translateY(-'+getXOffset(guestedit)+')'"  [style.--max-height.px]="getX(guestedit)" #guestedit dismissOnSelect="true">
  <ng-template>
    <ion-content  >
<app-search-guest (selectedGuest)="booking.guest_id = $event.guest_id; updateBooking()" (createGuest)="log('create guest'); createguest()"></app-search-guest>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-popover size="auto" side="right" [dismissOnSelect]="true" [style.--max-height.px]="getX(packageEdit)" [style.transform]="'translateY(-'+getXOffset(packageEdit)+')'"   alignment="start" arrow="true" [style.--min-width.px]="400" #packageEdit>
  <ng-template>
    <ion-content class="" [style.min-width.px]="300">
      <app-select-package (selectedPackage)="log($event); booking.package_id = $event.package_id; updateBooking()"></app-select-package>

    </ion-content>
  </ng-template>
</ion-popover>

<ion-popover   
size="auto" side="right" [dismissOnSelect]="true" alignment="start" arrow="true" [style.--min-width.px]="300" [style.transform]="'translateY(-'+getXOffset(tableedit)+')'" [style.--max-height.px]="getX(tableedit)"  #tableedit>
  <ng-template>
    <ion-content class="" [style.min-width.px]="300">
      <ion-list>

      <ng-container *ngFor="let table of (avaibility_tables$ | async)">
        <ion-item
          (click)="booking.table_id = table.table_id; booking.linked_table_ids = undefined; updateBooking()"
        >
          <ion-label>{{ table.name }}</ion-label>
        
         
            <ion-chip
              *ngIf="table.available"
              color="success"
              >Available</ion-chip
            >
            <ion-chip
            *ngIf="!table.available"
            color="danger"
              >Booked</ion-chip
            >
        
            
        
      
          <div
            slot="end"
            [class]="(table_included$(table.table_id) | async)? 'fillc' : 'outc'"
            (click)="$event.stopPropagation(); SelectBooking(table.table_id); updateBooking() "
          >
            <ion-icon name="checkmark-outline"></ion-icon>
          </div>
        </ion-item>
      </ng-container> 
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

