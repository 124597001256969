import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import "@aws-amplify/api-graphql";
import "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { Capacitor } from "@capacitor/core";
// initialize stripe https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
import "@stripe/stripe-js";
import { REDIRECTS } from "./app/app-routing.module";
import { AppModule } from "./app/app.module";
import { CapacitorAmplifyStorage } from "./app/services/capacitor-amplify-storage/capacitor-amplify-storage.service";
import awsConfig from "./aws-exports";
import { environment } from "./environments/environment";
import { defineCustomElements } from "@teamhive/lottie-player/loader";

defineCustomElements(window);
// window["LOG_LEVEL"] = "DEBUG";

if (environment.production) {
  enableProdMode();
}

(async function () {
  try {
    if (Capacitor.isNativePlatform()) {
      const storage = new CapacitorAmplifyStorage();
      awsConfig.storage = storage;
      storage.sync();
    }

    awsConfig.aws_user_pools_id = environment.cognitoConfig.userPoolId;
    awsConfig.aws_user_pools_web_client_id =
      environment.cognitoConfig.userPoolWebClientId;

    Amplify.configure(awsConfig);

    await platformBrowserDynamic([
      { provide: REDIRECTS, useValue: {} },
    ]).bootstrapModule(AppModule);
  } catch (err) {
    console.error(err);
  }
})();
