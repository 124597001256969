<ion-row color="none">
  <ion-button
    size="default"
    color="primary"
    class="small"
    [class.smallr]="
      !(type === 'percentage' && (value == 0 || value == undefined))
    "
    [fill]="
      type === 'percentage' && (value == 0 || value == undefined)
        ? 'solid'
        : 'outline'
    "
    (click)="setGratuityType('percentage'); gratuityDidChange(0)"
    ><ion-col
      ><ion-label>0%</ion-label>
      <ion-note>{{ 0 | currency: currencySymbol }}</ion-note></ion-col
    ></ion-button
  >
  <ion-button
    size="default"
    [class.smallr]="!(type === 'percentage' && value == 10)"
    class="small"
    color="primary"
    [fill]="type === 'percentage' && value == 10 ? 'solid' : 'outline'"
    (click)="setGratuityType('percentage'); gratuityDidChange(10)"
  >
    <ion-col
      ><ion-label>10%</ion-label>
      <ion-note>{{ total * 0.1 | currency: currencySymbol }}</ion-note></ion-col
    ></ion-button
  >
  <ion-button
    size="default"
    [class.smallr]="!(type === 'percentage' && value == 15)"
    class="small"
    color="primary"
    [fill]="type === 'percentage' && value == 15 ? 'solid' : 'outline'"
    (click)="setGratuityType('percentage'); gratuityDidChange(15)"
    ><ion-col
      ><ion-label>15%</ion-label>
      <ion-note>{{
        total * 0.15 | currency: currencySymbol
      }}</ion-note></ion-col
    ></ion-button
  >

  <ion-button
    size="default"
    color="primary"
    [class.smallr]="!(type === 'percentage' && value == 20)"
    class="small"
    [fill]="type === 'percentage' && value == 20 ? 'solid' : 'outline'"
    (click)="setGratuityType('percentage'); gratuityDidChange(20)"
    ><ion-col
      ><ion-label>20%</ion-label>
      <ion-note>{{ total * 0.2 | currency: currencySymbol }}</ion-note></ion-col
    ></ion-button
  >
  <ion-button
    size="default"
    color="primary"
    [fill]="type === 'amount' ? 'solid' : 'outline'"
    (click)="setGratuityType('amount'); alertamount()"
  >
    <ion-icon
      *ngIf="type != 'amount'"
      slot="icon-only"
      name="create-outline"
    ></ion-icon>
    <ion-col *ngIf="type === 'amount'"
      ><ion-icon class="icon-only" name="create-outline"></ion-icon>
      <ion-note>{{ value | currency: currencySymbol }}</ion-note></ion-col
    >
  </ion-button>
</ion-row>
