<ion-list color="secondary">
  <ion-item color="secondary" button="true" (click)="showQRCode()">
    <ion-label>Share Treat</ion-label>
  </ion-item>
  <ion-item
    color="secondary"
    button="true"
    *ngIf="table_info.tabs_public_uuid != undefined"
    (click)="showRecieptCode()"
  >
    <ion-label>View Tab</ion-label>
  </ion-item>
  <ion-item
    *ngIf="table_info.b_id == undefined"
    color="secondary"
    button="true"
    (click)="adjustSpend()"
  >
    <ion-label>Adjust Min Spend</ion-label>
  </ion-item>
  <ion-item
    *ngIf="table_info.b_id == undefined"
    color="secondary"
    button="true"
    (click)="moveTable()"
  >
    <ion-label>Move Table</ion-label>
  </ion-item>
  <ion-item
    *ngIf="table_info.upgrade_options.guest_needs_assignment"
    color="secondary"
    button="true"
    (click)="geust()"
  >
    <ion-label>Complete Guest Profile</ion-label>
  </ion-item>
  <ion-item
    *ngIf="table_info.upgrade_options.package_assignment_options"
    color="secondary"
    button="true"
    (click)="package()"
  >
    <ion-label>Select A Package</ion-label>
  </ion-item>
</ion-list>
