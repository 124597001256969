
    <ion-col class="fullhi ion-justify-content-between">
      <h1>Import Wizard</h1>
      <h5>Fetching updated menu</h5>
      <ion-spinner class="bigspinner" color="light"></ion-spinner>
    </ion-col>



    <ion-col
      class="fullhi ion-justify-content-between"
      *ngIf="data != undefined && CategoryData != undefined"
    >
      <h1>Import Wizard</h1>
      <h5>Map POS categories to DQ Categories</h5>
      <ion-list lines="none">
        <ion-item *ngFor="let category of data.mappable_categories">
          <ion-label>{{ category.pos_name }}</ion-label>
          <ion-select
            placeholder="Select relevant category"
            slot="end"
            [(ngModel)]="category.menu_cat_id"
            (ngModelChange)="checkCatsReady()"
          >
            <ion-select-option
              *ngFor="let mapped_category of CategoryData"
              [value]="mapped_category.menu_cat_id"
            >
              {{ mapped_category.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </ion-col>

    <ion-col
      class="fullhi ion-justify-content-between"
      *ngIf="data != undefined && CategoryData != undefined"
    >
      <h1>Import Wizard</h1>
      <h5>Approve new items</h5>
      <ion-list lines="none">
        <ng-container *ngFor="let item of data.new_items">
          <ion-item
            *ngIf="
              CatsDontUser[item.pos_cat_id] == undefined ||
              CatsDontUser[item.pos_cat_id] == false
            "
          >
            <ion-checkbox
              [(ngModel)]="item.to_import"
              slot="start"
            ></ion-checkbox>
            <ion-label>{{ item.pos_name }}</ion-label>
            <ion-label slot="end">{{ item.pos_cat_id }}</ion-label>
            <ion-label slot="end">{{ item.pos_sku }}</ion-label>
            <ion-note slot="end">{{ item.pos_price }}</ion-note>
          </ion-item>
        </ng-container>
      </ion-list>
    </ion-col>
 
    <ion-col
      class="fullhi ion-justify-content-between"
      *ngIf="data != undefined && CategoryData != undefined"
    >
      <h1>Import Wizard</h1>
      <h5>Approve price updates</h5>
      <ion-list lines="none">
        <ion-item *ngFor="let item of data.updated_items">
          <ion-checkbox
            [(ngModel)]="item.to_import"
            slot="start"
          ></ion-checkbox>
          <ion-label>{{ item.our_name }}</ion-label>
          <ion-note slot="end">{{ item.our_price }}</ion-note>
          <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
          <ion-note slot="end">{{ item.pos_price }}</ion-note>
        </ion-item>
      </ion-list>
    </ion-col>

    <ion-col
      class="fullhi ion-justify-content-between"
      *ngIf="data != undefined && CategoryData != undefined"
    >
      <h1>Import Wizard</h1>
      <h5>Approve deactivations no longer on the POS</h5>
      <ion-list lines="none">
        <ion-item *ngFor="let item of data.removed_items">
          <ion-checkbox
            [(ngModel)]="item.to_delete"
            [disabled]="true"
            slot="start"
          ></ion-checkbox>
          <ion-label>{{ item.our_name }}</ion-label>
          <ion-label slot="end">{{ item.pos_sku }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-col>



    <ion-col class="fullhi ion-justify-content-between">
      <h1>Import Wizard</h1>
      <h5>Updating and importing menu changes</h5>
      <ion-spinner class="bigspinner" color="light"></ion-spinner>
    </ion-col>




<ion-row
  class="ion-justify-content-between"
  *ngIf="data != undefined && CategoryData != undefined && slide != 5"
>
  <ion-button (click)="updateNumber()" *ngIf="slide != 1"
    >Back</ion-button
  >
  <h6 *ngIf="slide == 1">Map all relevant categories to continue</h6>
  <ion-button
    (click)=" updateNumber()"
    [disabled]="!CatsReady"
    *ngIf="slide != 4"
    >Next</ion-button
  >
  <ion-button (click)="finishup()" *ngIf="slide == 4">Complete</ion-button>
</ion-row>

<ion-footer> </ion-footer>
