import { CommonModule } from "@angular/common";
import { Component, ViewChild,Input, NgModule, OnInit, ElementRef } from "@angular/core";
import { IonicModule, ModalController, NavController } from "@ionic/angular";
import { float, int } from "@zxing/library/esm/customTypings";
import { RandomiseUrlPipeModule } from "src/app/pipes/randomise-url/randomise-url.pipe";
import { SocialLinkPipeModule } from "src/app/pipes/social-link/social-link.pipe";
import { DqConsumerVenueInfo } from "src/app/services/consumer-table/consumer-table.service";
import { DqTreatMeDetails } from "src/app/services/treat-me-data/treat-me-data.service";
import { TreatMeHowWorksPage } from "src/app/treat-me-how-works/treat-me-how-works.page";
@Component({
  selector: "dq-treat-me-influencer-header",
  templateUrl: "treat-me-influencer-header.component.html",
  styleUrls: ["treat-me-influencer-header.component.scss"],
})
export class TreatMeInfluencerHeaderComponent implements OnInit {
  @Input() details: DqTreatMeDetails;
  @Input() venue: DqConsumerVenueInfo;
  @Input() isStaff: boolean;
  @Input() previewMode = false;
  @Input() step?: number;
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) {}

  ngOnInit() {}
  


  async howWorks() {
    const modal = await this.modalCtrl.create({
      component: TreatMeHowWorksPage,
      swipeToClose: true,
    });
    await modal.present();
  }
}

