import { EventEmitter, Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import {
  distinctUntilChanged,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { Platform } from "@ionic/angular";
import { SyncSEvent } from "../sync/sync.service";
import { SyncAPIService } from "../sync-api/syncapi.service";

@Injectable({
  providedIn: "root",
})
export class BaseSynced {
  state: RxState<any> = new RxState<{}>();
  constructor(private syncapi: SyncAPIService) {
    this.serviceState.set({
      restored: false,
      initiliased: false,
      refreshing: false,
      last_synced: null,
      last_updated: null,
      in_error: false,
    });
  }

  serviceState = new RxState<{
    restored: boolean;
    initiliased: boolean;
    refreshing: boolean;
    last_synced: Date;
    last_updated: Date;
    in_error: boolean;
  }>(); //state of the service

  service_id: string | string[]; //id of the service
  service_title: string; //icon to display in the data tab
  service_url: string;

  service_icon: string; //title to display in the data tab
  data_id: string; //title to display in the data tab
  data_detail: string; //title to display in the data tab
  data_expiry: number = 120; //in minutes, the amount of time before the data is considered unusable
  permissions_required: string[] = [];


  loaded$ = this.serviceState.select().pipe(
    map((x) => x.restored || x.initiliased)
    //only run change if the value has changed
  );

  refreshing$ = this.serviceState.select().pipe(
    map(
      (x) =>
        (x.refreshing && x.initiliased == true) ||
        (x.refreshing && x.initiliased == false && x.restored == true)
    )
    //only run change if the value has changed
  );

  last_synced$ = this.serviceState.select("last_synced");
  in_error$ = this.serviceState.select("in_error");
  getItems$: Observable<any[]> = this.state.select();

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased || stats.restored) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  get loaded() {
    return (
      this.serviceState.get("restored") || this.serviceState.get("initiliased")
    );
  }

 

  async InitStore() {
    let loaded = this.serviceState.get("initiliased");
    if (loaded === false) {
      //log init with service name
      console.log(
        " %c Init " + this.service_title,
        "background: #00000; color: #FF0000"
      );
      await this.SyncData();
    }
  }

  get storageId(): string {
    return this.service_id as string;
  }

  async refresh() {
    if (this.serviceState.get("refreshing")) {
      return;
    }
    this.serviceState.set({
      refreshing: true,
    });

    //log init with service name
    console.log(
      " %c Refresh " + this.service_title,
      "background: #00000; color: #FF0000"
    );
    await this.SyncData();
    this.serviceState.set({
      refreshing: false,
      last_synced: new Date(),
    });
  }

  retryTime = 1;
  //syncs data from the server
  async SyncData() {
    try {
      let res = await this.Sync();
      if (res != "uness" && res != "success") {
        throw "error";
      }

      if (res == "uness") {
        return;
      }
      this.retryTime = 1;
    } catch (error) {
      console.log(error);
      console.log(
        " %c Server Sync Error " + this.service_title,
        "background: #00000; color: #FF0000"
      );
      //retry in 1 second
      this.serviceState.set({
        in_error: true,
      });

      return;
    }

    this.serviceState.set({
      initiliased: true,
      in_error: false,
      last_synced: new Date(),
    });
    console.log(
      " %c Data Synced " + this.service_title,
      "background: #00000; color: #4BB37F"
    );
  }

  //syncs data from the server
  async Sync() {
    if (this.serviceState.get("refreshing")) {
      return "uness";
    }
    this.serviceState.set({
      refreshing: true,
    });
    ///throw error

    let data: any = await this.syncapi
      .getCompleteSync(this.service_url)
      .toPromise();

    let map = this.GetState();
    if (map == undefined) {
      map = {};
    }
    let keys = Object.keys(map);
    if (keys.length > 0) {
      keys.forEach(key => {
        delete map[key];
      });
    }
    data.items.map((item) => {
      item.sort_key = data.sort_order;
      map[item[this.data_id]] = item;
    });

    this.state.set(map);

    this.serviceState.set({
      refreshing: false,
    });

    return "success";
  }

  RelevantState(event: SyncSEvent) {
    return this.state;
  }

  UpdateState(newState) {
    if (newState == undefined) {
      this.state.set({});
      return;
    }

    this.state.set(newState);
  }
  GetState() {
    return this.state.get();
  }

  ClearState() {}

  //handle websocket events
  HandleEvent(event: SyncSEvent) {
    ///throw error
    let stater = this.RelevantState(event);
    let ev = stater.get(event.sync_id);
    if (event.event_type == "DELETE") {
      //stater.set({ [event.sync_id]: undefined });
      //remove the item from the state setting it to undefined does not work
      let state = stater.get();

      delete state[event.sync_id];

      let oldstate=  Object.keys(stater.get()).length
      stater.set(state);
      //compare the length of the orignal state to the new one
      console.log("New " + Object.keys(state).length + " Old "+ oldstate);
      console.log("updated delted state", stater.get());
    } else {
      if (
        ev == undefined ||
        ev.sort_key == undefined ||
        ev.sort_key < event.sort_key
      ) {
        stater.set({ [event.sync_id]: event.payload });
      }
    }
  }
}
