import { getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController, ToastController, ViewDidEnter } from "@ionic/angular";
import { async, BehaviorSubject, combineLatest } from "rxjs";
import {
  distinctUntilChanged,
  finalize,
  map,
  pluck,
  shareReplay,
  switchMap,
  take,
} from "rxjs/operators";
import { CartFacadeService } from "src/app/facades/cart-facade/cart-facade.service";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { MixtureService } from "src/app/services/mixture/mixture.service";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import {
  DqGratuityType,
  DqOrderDetailsItem,
  DqPaymentMethod,
  OrderService,
} from "src/app/services/order/order.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { OrderState } from "src/app/services/sync-engine/order-state/order.service";
import { SyncService } from "src/app/services/sync-engine/sync/sync.service";
import { TableService } from "src/app/services/table/table.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-data-store",
  templateUrl: "./data-store.page.html",
  styleUrls: ["./data-store.page.scss"],
})
export class DataStorePage implements OnInit, ViewDidEnter {
  constructor(public cartFacade: CartFacadeService, public sync: SyncService, private orderState: OrderState) {}
  presenting = document.querySelector("app-data-store");
  selected;

  display = {};
  async ngOnInit() {}

  async RefreshOrder(ev: CustomEvent) {}

  async ionViewDidEnter() {}

  view;

  async addToOrderStore() {
    this.orderState.state.set({
      'o_testing': {"order_id":"o_testing","title":"78177","o_uuid":"a82b7b16-57c8-4ad3-a77e-dadfeae1bba5","guest_id":"guest_115934","table_name":"Club A1","placed_by":"testmanager (testmanager)","created_at_utc":"2025-03-06T05:51:48Z","created_at_local":"2025-03-06T16:51:48+11:00","total_price_text":"$105.00","pay_icon":"wallet-outline","receipt_id":"re_fSP3XyAtSPqDRjGt6keyRR","current_column":"Pending","card_lines":[{"quantity":1,"item_name":"Belvedere","line_item_price":"$105.00","tags":[],"comment":null,"voided":false,"void_url":"/v4/items/276161/void"}],"staff_expected_incentive":0,"staff_expected_incentive_text":null,"package_id":null,"loading":false,"is_treat":false,"treat_note":null,"service_charge":null,"tax":"$9.55","subtotal":"$105.00","table_id":"242","cp_id":"99","segment_ids":["seg_-1000000001","seg_-2000000001"],"sibling_order_ids":[],"last_touched_user_id":null,"last_touched_user_name":null}
    })
  }

  async clearAll() {
    await this.sync.storage.clear();
  }
}
