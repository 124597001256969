<div class="event-selector">
  <ion-icon
    name="chevron-back-outline"
    (click)="slider.slidePrev(); checkMonth()"
    color="light"
  ></ion-icon>

  <ion-slides #slider pager="false"
    ><ion-slide *ngFor="let month of Months">
      <h3>{{ month }}</h3>
    </ion-slide></ion-slides
  >

  <ion-icon
    name="chevron-forward-outline"
    (click)="slider.slideNext(); checkMonth()"
    color="light"
  ></ion-icon>
</div>
