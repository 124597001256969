import {
  animate,
  animation,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const fadeOut = (timings: string = "0.3s ease-out") =>
  trigger("fadeOut", [
    transition(":leave", [
      style({
        opacity: 1,
      }),
      animate(
        timings,
        style({
          opacity: 0,
        })
      ),
    ]),
  ]);

export const fadeIn = (timings: string = "0.3s ease-in") =>
  trigger("fadeIn", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate(timings, style({ opacity: 1 })),
    ]),
  ]);

export const fadeInOut = (duration: string = "0.3s") =>
  trigger("fadeInOut", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate(`${duration} ease-in`, style({ opacity: 1 })),
    ]),
    transition(":leave", [
      style({ opacity: 1 }),
      animate(`${duration} ease-out`, style({ opacity: 0 })),
    ]),
  ]);

export const slideInOut = trigger("slideInOut", [
  state("hidden", style({ display: "none" })),
  state("shown", style({ display: "block" })),

  transition("hidden => shown", [
    style({ transform: "translateY(-20px)", opacity: 0, "z-index": -10 }),
    animate(
      "0.3s ease-in",
      style({ transform: "translateY(0px)", opacity: 1, "z-index": -10 })
    ),
  ]),
  transition("shown => hidden", [
    style({ transform: "translateY(0px)", opacity: 1, "z-index": -10 }),
    animate(
      "0.3s ease-out",
      style({ transform: "translateY(-20px)", opacity: 0, "z-index": -10 })
    ),
  ]),

  transition(":enter", [
    style({ transform: "translateY(-20px)", opacity: 0, "z-index": -10 }),
    animate(
      "0.3s ease-in",
      style({ transform: "translateY(0px)", opacity: 1, "z-index": -10 })
    ),
  ]),
  transition(":leave", [
    style({ transform: "translateY(0px)", opacity: 1, "z-index": -10 }),
    animate(
      "0.3s ease-out",
      style({ transform: "translateY(-20px)", opacity: 0, "z-index": -10 })
    ),
  ]),
]);

export const staggerIn = trigger("staggerIn", [
  transition("* => *", [
    query(
      ":enter",
      style({ opacity: 0, transform: `translate3d(-10px,0,0)` }),
      { optional: true }
    ),
    query(
      ":enter",
      stagger("100ms", [
        animate(
          "300ms",
          style({ opacity: 1, transform: `translate3d(0,0,0)` })
        ),
      ]),
      { optional: true }
    ),
  ]),
]);

/**
 * From https://github.com/jiayihu/ng-animate
 */
export const tada = animation(
  animate(
    "{{ timing }}s {{ delay }}s",
    keyframes([
      style({ transform: "scale3d(1, 1, 1)", offset: 0 }),
      style({
        transform: "scale3d(.9, .9, .9) rotate3d(0, 0, 1, -20deg)",
        offset: 0.25,
      }),
      style({
        transform: "scale3d(.9, .9, .9) rotate3d(0, 0, 1, 20deg)",
        offset: 0.5,
      }),
      style({
        transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -20deg)",
        offset: 0.75,
      }),
      style({
        transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 20deg)",
        offset: 1,
      }),
      style({ transform: "scale3d(1, 1, 1)", offset: 1 }),
    ])
  ),
  { params: { timing: 1, delay: 0 } }
);
