import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { DqMenuItemDetails } from "src/app/services/menu/menu.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

@Component({
  selector: "app-add-item-row",
  templateUrl: "./add-item-row.component.html",
  styleUrls: ["./add-item-row.component.scss"],
})
export class AddItemRowComponent implements OnInit {
  @Input() item: DqMenuItemDetails;
  @Output() addItem = new EventEmitter<DqMenuItemDetails>();

  constructor(private settingSt: SettingsState) {}

  currency$ = this.settingSt.currencyCode$;

  ngOnInit() {}

  addItemToCart(event: CustomEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.addItem.emit(this.item);
  }
}
