import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  EventList,
  Event,
  AvailabilityList,
  BookingCheckout,
  BookingList,
  BookingManagment,
  GuestList,
  OrderRequest,
  GuestStats,
  BookingGuest,
  GuestListObject,
} from "src/app/models/bookings";
import { CardList } from "src/app/models/bump_screen_models";
import { environment } from "src/environments/environment";
import { RegionsService } from "../regions/regions.service";
import { SyncV1Guest } from "src/app/models/sync_models";
import { GuestFilterResponses, TagInstances } from "src/app/models/live_tags";
import { GuestHistory } from "src/app/models/guest_history";
import { BookingState } from "../sync-engine/booking-state/booking.service";
import { EventState } from "../sync-engine/event-state/event.service";
import { map, share } from "rxjs";
import { Cipher } from "crypto";

@Injectable({ providedIn: "root" })
export class BookingService {
  constructor(private http: HttpClient, private reg: RegionsService, private bookingSt: BookingState, private eventSt: EventState) {}


  upcoming_booking_map$ = this.bookingSt.state.select(
    map((bookings) => {
      let obj = Object.values(bookings);
      let tablemap = {};
      //dont show things more than 14hs in the future

      obj.forEach((x) => {
        if (x.status == "confirmed" && x.event_id != undefined && this.eventSt.state.get(x.event_id) != undefined) {
          if (x.linked_table_ids != undefined) {
            x.linked_table_ids.forEach((y) => {
              if (tablemap[y] == undefined) {
                tablemap[y] = [];
              }
              tablemap[y].push(x);
            });
          }
        }
      });

      obj.forEach((x) => {
        if (x.status == "confirmed") {
          if (x.table_id != undefined) {
            if (tablemap[x.table_id] == undefined) {
              tablemap[x.table_id] = [];
            }
            tablemap[x.table_id].push(x);
          }
        }
      });
      return tablemap;
    }),
  );


  upcoming_map$ = this.upcoming_booking_map$.pipe(
    map((incomingMap) => {
      //go through the tablemap and pick remove all but the soonest booking for each table (use sort_order)
      //then create a piece of text for them
      let outputMap = {};
      for (let key in incomingMap) {
        //remove any bookings that are more than 14hs in the future
        //check if the
          
        outputMap[key] = incomingMap[key].sort((a, b) => {
          return a.sort_order - b.sort_order;
        });
        let soonest = outputMap[key][0];
        outputMap[key] = soonest;

        if (outputMap[key].arrival != undefined) {
          let arrival = outputMap[key].arrival.arrive_text_primary;
          outputMap[key] = "Arriving at " + arrival;
        } else {
          outputMap[key] = "Upcoming Booking";
        }
      
    }

      return outputMap;
    }),
  );

  upcomingForTable$ = (id) =>
    this.upcoming_booking_map$.pipe(
      map((x) => {
        return x[id];
      })
    );  

  is_upcoming$ = (id) =>
    this.upcoming_map$.pipe(
      map((x) => {
        return x[id];
      })
    );


  getActiveEventId() {
    return this.http.get<{ e_id: string | null; r_id: number | null }>(
      `${this.reg.ServerURL()}/event/current_id`
    );
  }

  CreateNewBooking(booking) {
    return this.http.post(`${this.reg.ServerURL()}/bookings/new`, booking);
  }

  GetWalletKit(booking) {
    return this.http.get(`${this.reg.ServerURL()}/walletkit/` + booking);
  }

  GetSyncGuest(guest_id) {
    return this.http.get(`${this.reg.ServerURL()}/sync/v1/guest/` + guest_id);
  }

  getGuestList(guest_id: string) {
    return this.http.get<GuestListObject>(
      `${this.reg.ServerURL()}/guest_list/${guest_id}`
    );
  }
  getGuestListEntry(guest_id: string) {
    return this.http.get<GuestListObject>(
      `${this.reg.ServerURL()}/guest_entry/${guest_id}`
    );
  }

  getGuestListEntryAuth(guest_id: string) {
    return this.http.get<GuestListObject>(
      `${this.reg.ServerURL()}/bookings/guest_entry/${guest_id}`
    );
  }

  addToGuestList(guest, booking_public_id, private_id = "") {
    return this.http.post(
      `${this.reg.ServerURL()}/guest_list/${booking_public_id}`,
      guest,
      { params: { private_id: private_id } }
    );
  }

  deleteGuestList(guest, booking_id) {
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking_id}/delete`,
      guest
    );
  }

  postItemRequest(booking, items) {
    let body: OrderRequest = {
      orders: items,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking}/request`,
      body
    );
  }

  //get venues events
  getVenuesEvents(venue_id: string, operational = false) {
    return this.http.get<EventList>(
      `${this.reg.ServerURL()}/bookings/${venue_id}/events`,
      {
        params: {
          operational: operational.toString(),
        },
      }
    );
  }

  getBookingOrders(booking_id: string) {
    return this.http.get<CardList>(
      `${this.reg.ServerURL()}/bookings/${booking_id}/orders`
    );
  }

  getGuestTags(guest_id: string) {
    return this.http.get<TagInstances>(
      `${this.reg.ServerURL()}/live_tags/guest/${guest_id}/tags`
    );
  }
  getGuestMetrics(guest_id: string) {
    return this.http.get<GuestFilterResponses>(
      `${this.reg.ServerURL()}/live_tags/guest/${guest_id}/metrics`
    );
  }
  getGuestHistory(guest_id: string) {
    return this.http.get<GuestHistory>(
      `${this.reg.ServerURL()}/live_tags/guest/${guest_id}/history`
    );
  }
  getPromoterEvents() {
    return this.http.get<EventList>(
      `${this.reg.ServerURL()}/bookings/promoter`
    );
  }

  getPromoterCode() {
    return this.http.get<any>(`${this.reg.ServerURL()}/bookings/refcode`);
  }

  updatePromoterCode(body) {
    return this.http.post<any>(
      `${this.reg.ServerURL()}/bookings/refcode`,
      body
    );
  }

  getEvent(e_id: string) {
    return this.http.get<Event>(
      `${this.reg.ServerURL()}/bookings/events/${e_id}`
    );
  }

  getEventAvailability(e_id: string) {
    return this.http.get<AvailabilityList>(
      `${this.reg.ServerURL()}/bookings/events/${e_id}/availability`
    );
  }

  createBooking(booking) {
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/events/${booking.event_id}`,
      booking
    );
  }

  createTag(booking) {
    return this.http.post(`${this.reg.ServerURL()}/live_tags`, booking);
  }

  deleteTag(id) {
    return this.http.delete(`${this.reg.ServerURL()}/live_tags/${id}`);
  }

  AddListGuest(booking, guest_list) {
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/${booking}/guests`,
      guest_list
    );
  }

  RequestGuestList(booking) {
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/${booking}/request_list`,
      {}
    );
  }

  getDuplicateGuests(guest_id) {
    return this.http.get(
      `${this.reg.ServerURL()}/cms_v2/guest/${guest_id}/duplicates`
    );
  }

  MergeGuests(old_guest_id, new_guest_id) {
    return this.http.post<{ old_guest_id: string; new_guest_id: string }>(
      `${this.reg.ServerURL()}/bookings/guests/merge`,
      {
        old_guest_id: old_guest_id,
        new_guest_id: new_guest_id,
      }
    );
  }

  RemoveListGuest(booking, guest_id) {
    return this.http.delete(
      `${this.reg.ServerURL()}/bookings/${booking}/entrys/${guest_id}`
    );
  }
  UpdateListGuest(booking, guest_list) {
    console.log("UpdateListGuest", booking, guest_list);
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/${booking}/entrys/${
        guest_list.gle_id
      }`,
      guest_list
    );
  }

  readyCheckout(data, booking_id) {
    return this.http.post<BookingCheckout>(
      `${this.reg.ServerURL()}/bookings/${booking_id}/checkout`,
      data
    );
  }

  getBookings(operational = false, e_id?, status?, guest_lists = false) {
    let params = {};
    if (e_id) {
      params["e_id"] = e_id;
    }
    if (status) {
      params["status"] = status;
    }

    return this.http.get<any>(
      `${this.reg.ServerURL()}/sync/v1/event_extended`,
      {
        params: params,
      }
    );
  }

  getBooking(booking_id, checkin = false) {
    let params = {};
    if (checkin) {
      params = {
        checkin: checkin.toString(),
      };
    }

    return this.http.get<BookingManagment>(
      `${this.reg.ServerURL()}/bookings/${booking_id}`,
      {
        params: params,
      }
    );
  }

  searchGuests(search_term = "", order_by = "", filter = "") {
    return this.http.get<any>(`${this.reg.ServerURL()}/bookings/guests_sync`, {
      params: {
        search_term: search_term,
        order_by: order_by,
        filter: filter,
      },
    });
  }

  changeBookingStatus(booking_id, status) {
    let body = {
      status: status,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking_id}/status_transition`,
      body
    );
  }
  updateBooking(booking) {
    let body = {
      status: status,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking.booking_id}`,
      booking
    );
  }

  updateGuest(guest) {
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/guest/${guest.guest_id}`,
      guest
    );
  }
  createGuest(guest) {
    return this.http.post<SyncV1Guest>(
      `${this.reg.ServerURL()}/bookings/v2/guest`,
      guest
    );
  }

  checkin(items, booking_id) {
    let body = {
      status: status,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking_id}/checkin`,
      items
    );
  }

  getBookingCheckout(booking_id) {
    return this.http.get<BookingCheckout>(
      `${this.reg.ServerURL()}/bookings/${booking_id}/checkout`
    );
  }
}
