
<ion-item-group  class="rounded-group ion-margin-top ion-margin-bottom"  color="secondary">
  <ion-item color="secondary" lines="inset" button [detail]="false" (click)="notes_modal.present(); impact()">
    Add notes on the group
    <ion-note slot="end">
      Add Notes
    </ion-note>
    <ion-modal #notes_modal [presentingElement]="presentingElement">
      <ng-template>
        <ion-header>
          <ion-toolbar>
            <ion-title>
              Add Notes
            </ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="notes_modal.dismiss()">
                <ion-icon name="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item-group class="rounded-group">
            <ion-item color="secondary" lines="full">
              <ion-label>
                Notes
              </ion-label>
            </ion-item>
            <ion-item color="secondary">
            
              <ion-textarea [(ngModel)]="notes" placeholder="Extra details about the group, their experience, preferences and more." rows="5" autoGrow="true"></ion-textarea>
            </ion-item>
          </ion-item-group>
        </ion-content>
        <ion-footer>
          <ion-toolbar>
            <ion-button expand="block" (click)="addNotes(); notes_modal.dismiss()">
              <ion-label color="light">
                Save
              </ion-label>
            </ion-button>
          </ion-toolbar>
        </ion-footer>
      </ng-template>
    </ion-modal>
  </ion-item>
 

    <ion-item  color="secondary" lines="none" button [detail]="false" (click)="addTag()">
      Tag occasion or preferences
      <ion-note slot="end">
          Add Tags
      </ion-note>
    </ion-item>
   
</ion-item-group>
