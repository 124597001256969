/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export type SaveType = "merge" | "duplicate" | "required";
/**
 * Say someone was a regular booker, then they stopped coming for a year,
 * but a couple of months ago they attended on someone's guestlist. What are
 * they? A churned booker? A returning guestlist? The order decides that
 * when more than one status could apply. First is highest priority.
 */
export type GuestStatus =
    | "regular_booker"
    | "regular_guestlist"
    | "returning_booker"
    | "returning_guestlist"
    | "churned_booker"
    | "churned_guestlist"
    | "first_time_guest"
    | "first_time_booker";
export type BookingStatus = "new" | "pending" | "confirmed" | "denied" | "seated" | "finished" | "cancelled" | "draft";
/**
 * This is just an enum for the convenience of validation.
 */
export type PackageType = "advanced" | "upfront" | "minimum" | "minimumexc" | "package";
/**
 * New Payment Service options.
 */
export type PaymentState =
    | "initial"
    | "pending"
    | "paid"
    | "failed"
    | "pending_refund"
    | "refunded"
    | "requires_capture"
    | "cancelled";
export type ReceiptState = "unpaid" | "partial" | "paid" | "overpaid" | "refunded";
export type NotificationClientAction = "no_action" | "accept" | "approve";
export type SenderUserType = "foh" | "manager" | "table_update" | "treat" | "other";
export type TagType = "taste" | "music" | "brand" | "general" | "highlight" | "system";

export interface EventTimeslot {
    ts_id: string;
    arrive_utc: string;
    arrive_local: string;
    max_people?: number;
}
export interface MergeList {
    guests_phone: SyncV1Guest[];
    guests_email: SyncV1Guest[];
}
export interface SyncV1Guest {
    guest_id: string;
    name: string;
    email?: string;
    mobile_num?: string;
    mobile_code?: string;
    identity?: string;
    notes?: string;
    walk_in_unassigned: boolean;
    save_type?: SaveType;
    tags: string[];
    status: GuestStatus;
    status_text: string;
}
export interface SurchargeBasic {
    fixed: number;
    ratio: number;
}
export interface SyncPackageInclusion {
    pinc_id: string;
    name?: string;
    quantity: number;
    mi_id?: string;
    avail: number;
    item_options?: string[];
}
export interface SyncV1Assignment {
    uta_id: string;
    table_id: string;
    user_id: string;
    created_at: Date;
}
export interface SyncV1Booking {
    booking_id: string;
    public_id: string;
    number: number;
    status: BookingStatus;
    package_id: string;
    guest_id: string;
    table_id: string;
    linked_table_ids?: string[];
    receipt_id?: string;
    unapproved_treats: number;
    unapproved_treat_value: string;
    chips: Chip[];
    event_id?: string;
    gle_ids: string[];
    notes?: string;
    adjustment: number;
    promoter_id?: string;
    guest_list_locked: boolean;
    tags: string[];
    sort_order?: number;
    arrive_utc?: string;
    arrival?: ArrivalTime;
    v_id: number;
    group_name?: string;
    
}

export interface Chip {
    label: string;
    icon: string;
    color: string;
}
export interface ArrivalTime {
    ts_id: string;
    arrive_utc: string;
    arrive_text_primary: string;
    arrive_text_trailing: string;
    is_available_for_package: boolean;
    max_people?: number;
}
export interface SyncV1Checkin {
    venue: SyncV1Venue;
    user: SyncV1User;
}
export interface SyncV1Venue {
    stripe_location_id?: string;
    v_id: number;
    name: string;
    tip_1: number;
    tip_2: number;
    tip_3: number;
    currency_code: string;
    target_turnaround_time: number;
    feature_colour: string;
    logo_url: string;
    collection_points: SyncV1CollectionPoint[];
    segments: SyncV1Segment[];
    surcharge: SurchargeBasic;
    tax_rate: number;
    tax_additive: boolean;
    default_prefix: string;
    requests_list: string[];
}
export interface SyncV1CollectionPoint {
    cp_id: string;
    name: string;
    sort_order: number;
}
export interface SyncV1Segment {
    seg_id: string;
    name: string;
}
export interface SyncV1User {
    user_id?: string;
    user_name: string;
    role_id?: string;
    last_active_at?: Date;
}
export interface SyncV1Event {
    event_id: string;
    parent_event_id?: string;
    is_recurring_base: boolean;
    name: string;
    e_date: string;
    e_date_local: string;
    e_desc: string;
    e_deposit: string;
    e_deposit_calculated: boolean;
    e_image?: string;
    package_ids: string[];
    tags: string[];
    arrival_time_options?: ArrivalTime[];
    timeslots?: EventTimeslot[];
}
export interface SyncV1EventAssociated {
    sort_order: number;
    packages: SyncV1Package[];
    bookings: SyncV1Booking[];
    receipts: SyncV1Reciept[];
    guests: SyncV1Guest[];
    guestlist_entries: SyncV1GuestListEntry[];
}
export interface SyncV1Package {
    package_id: string;
    name: string;
    package_type: PackageType;
    inclusions: SyncPackageInclusion[];
    table_ids: string[];
    deposit: number;
    included_guests: number;
    min_customer_liability: number;
    price: number;
    min_spend: number;
    included_spend: number;
    parent_id?: string;
    auto_approve?: boolean;
    available?: boolean;
}
export interface SyncV1Reciept {
    receipt_id: string;
    total: number;
    tip_paid: number;
    outstanding: number;
    payments: PayItemResponse[];
    receipt_state: ReceiptState;
    supports_partial: boolean;
    seat_id_managing?: string;
}

export type RefundType = "none" | "manual" | "automatic";
export interface PayItemResponse {
    name: string;
    icon: string;
    status: PaymentState;
    time: string;
    person: string;
    amount: number;
    tip_paid: number;
    refunded: boolean;
    refundable?: PaymentUserActionResponse;
    refund_type: RefundType;
    pi_uuid: string;
    view_process?: boolean;
}
export interface PaymentUserActionResponse {
    old_state: PaymentState;
    new_state: PaymentState;
    text: string;
    url: string;
}
export interface SyncV1GuestListEntry {
    gle_id: string;
    guest_id: string;
    booking_id: string;
    arrived: boolean;
    notes: string;
}
export interface SyncV1Generic {
    sort_order: number;
    items: unknown[];
}
export interface SyncV1IncentiveCampaign {
    ic_id: string;
    name: string;
    description: string;
    mi_ids: string[];
    unit_price: number;
    active: boolean;
    start_utc: string;
    end_utc: string;
    is_enabled: boolean;
    total_uses: number;
    uses_so_far: number;
}
export interface SyncV1MenuCategory {
    mc_id: string;
    mi_ids: string[];
    msc_id: string;
    name: string;
    sort_order: number;
    contains_active_items: boolean;
}
export interface SyncV1MenuComplete {
    sort_order: number;
    menu_super_cats: SyncV1MenuSuperCategory[];
    menu_cats: SyncV1MenuCategory[];
    menu_items: SyncV1MenuItem[];
    modifier_groups: SyncV1ModifierGroup[];
    modifiers: SyncV1Modifier[];
}
export interface SyncV1MenuSuperCategory {
    msc_id: string;
    name: string;
    sort_order: number;
}
export interface SyncV1MenuItem {
    mi_id: string;
    mc_id: string;
    image?: string;
    desc?: string;
    name: string;
    price: string;
    base_price: string;
    featured: boolean;
    mmg_ids: string[];
    pos_id?: string;
    sort_order?: number;
    is_mixer: boolean;
    is_active: boolean;
    is_treatable: boolean;
    is_staff: boolean;
    is_consumer: boolean;
    price_overrides: CMSv2PriceOverride[];
    is_available_staff_now?: boolean;
    tags: string[];
    static_commission?: number;
}
export interface CMSv2PriceOverride {
    id: number;
    avail_id: number;
    price_override: string;
}
export interface SyncV1ModifierGroup {
    mmg_id: string;
    name: string;
    desc?: string;
    min_selections?: number;
    max_selections?: number;
    sort_order: number;
}
export interface SyncV1Modifier {
    mm_id: string;
    mmg_id: string;
    name: string;
    price?: number;
    sort_order: number;
}
export interface SyncV1MultiEvent {
    events: SyncV1SentEvent[];
}
export interface SyncV1SentEvent {
    sync_ver: number;
    v_id: number;
    obj_type: string;
    sync_id: string;
    event_type: "CREATE" | "UPDATE" | "DELETE";
    sort_key: number;
    payload: BaseModel;
}
export interface BaseModel { }
export interface SyncV1Notification {
    note_id: string;
    title: string;
    message: string;
    client_action: NotificationClientAction;
    accept_link?: string;
    deep_link?: string;
    created_utc: string;
    created_local: string;
    sticky: boolean;
    accepted_by_user_names: string[];
    sender: string;
    sender_user_type: SenderUserType;
    notification_class: string;
    can_be_accepted: boolean;
    for_user_types: string[];
    for_user_claims: string[];
    target_table_id?: string;
    target_table_cp_id?: string;
    re_id?: string;
}
export interface SyncV1Order {
    order_id: string;
    title: string;
    o_uuid?: string;
    guest_id: string;
    table_name: string;
    placed_by: string;
    created_at_utc: string;
    created_at_local: string;
    total_price_text: string;
    pay_icon: string;
    receipt_id?: string;
    current_column?: string;
    card_lines: CardLine[];
    staff_expected_incentive: number;
    staff_expected_incentive_text?: string;
    package_id?: string;
    loading: boolean;
    is_treat: boolean;
    treat_note?: string;
    service_charge?: string;
    tax: string;
    subtotal: string;
    table_id?: string;
    cp_id: string;
    segment_ids: string[];
    sibling_order_ids: string[];
    last_touched_user_id?: string;
    last_touched_user_name?: string;
}
export interface CardLine {
    quantity: number;
    item_name: string;
    line_item_price: string;
    tags: string[];
    comment?: string;
    voided: boolean;
    void_url?: string;
}
export interface SyncV1Pull {
    items?: unknown;
}
export interface SyncV1Seating {
    seat_id: string;
    guest_id: string;
    booking_id?: string;
    table_id: string;
    seated_local: string;
    trailing: string;
    trailing_subtitle: string;
    requires_payment: boolean;
    met_goals: boolean;
    percentage?: number;
    spent_and_unpaid: string;
    remaining_on_minimum?: number;
    used_included_spend?: number;
    unpaid_total?: number;  
}
export interface SyncV1Table {
    table_id: string;
    name: string;
    cp_id: string;
    sort_order: number;
    seat_id?: string;
    is_active: boolean;
    table_capacity: string;
    table_entry: string;
}
export interface SyncV1TagDefinition {
    tag_id: string;
    name: string;
    description: string;
    tag_type: TagType;
}
