<ion-grid class="ion-text-center transparent">
  <ion-row class="rowgrow">
    <ion-col>
      <ion-icon
        *ngIf="details?.social_account_consumer.sa_image_url == 'null'"
        size="large"
        name="person-circle"
        class="placeholerAvatar"
      ></ion-icon>
      <div
        class="avatarwrapper"
        [ngClass]="{ avatarborder: step == undefined }"
      >
        <circle-progress
          class="progress"
          [percent]="step * 25 + 25"
          [radius]="110"
          [outerStrokeWidth]="5"
          [outerStrokeColor]="'#000000'"
          [innerStrokeWidth]="1"
          [innerStrokeColor]="'#000000'"
          [animation]="true"
          [animationDuration]="300"
          [showTitle]="false"
          [showSubtitle]="false"
          [space]="-5"
          [showUnits]="false"
          [animationDuration]="1000"
          [showInnerStroke]="true"
          *ngIf="step != undefined"
        ></circle-progress>
        <ion-icon
          [name]="step >= -1 ? 'ellipse' : 'ellipse-outline'"
          class="topicon"
          *ngIf="step != undefined && !isStaff"
        ></ion-icon>
        <ion-icon
          [name]="step >= 0 ? 'ellipse' : 'ellipse-outline'"
          class="ricon"
          *ngIf="step != undefined && !isStaff"
        ></ion-icon>
        <ion-icon
          [name]="step >= 1 ? 'ellipse' : 'ellipse-outline'"
          class="bicon"
          *ngIf="step != undefined && !isStaff"
        ></ion-icon>
        <ion-icon
          [name]="step >= 2 ? 'ellipse' : 'ellipse-outline'"
          class="licon"
          *ngIf="step != undefined && !isStaff"
        ></ion-icon>
        <ion-avatar
          *ngIf="details?.social_account_consumer.sa_image_url != 'null'"
          class="placeholerAvatar"
        >
          <img
            [src]="details?.social_account_consumer.sa_image_url | randomiseUrl"
          />
        </ion-avatar>
      </div>
    </ion-col>
  </ion-row>
  <br *ngIf="step != undefined" />
  <ion-row>
    <ion-col class="name">{{
      details?.social_account_consumer.sa_handle
    }}</ion-col>
  </ion-row>

  <ng-container> </ng-container>
  <ion-row *ngIf="step == undefined"
    ><ion-col>
      <ion-label class="treatSpecialText"
        >{{ details?.gift_description }}
      </ion-label></ion-col
    ></ion-row
  >
</ion-grid>
