<ng-container
*ngrxLet="reciept$(receipt_id) | async as receipt"
>
<ion-chip
  *ngIf="receipt != undefined"
  [color]="receipt.outstanding == 0 ? 'success' : 'primary'"
  [class]="
    receipt.outstanding == 0 ? 'chip-success' : 'chip-primary'
  "
  [outline]="receipt.outstanding == 0 ? false : true"
  >{{ receipt.total | currency: (currency$ | async) }}
</ion-chip>
</ng-container>