import { createAction, props } from "@ngrx/store";
import {
  DqTableDetails,
  DqCollectionPointTableDetails,
} from "../services/table/table.service";
import { OpenTableDidCloseData } from "../pages/open-table-modal/open-table-modal.page";

const ACTION_PREFIX = "[Collection Points]";

export const loadCollectionPoints = createAction(
  `${ACTION_PREFIX} Fetch Collection Points`
);
export const loadCollectionPointsSuccess = createAction(
  `${ACTION_PREFIX} Fetch Collection Points Success`,
  props<{ collectionPoints: DqCollectionPointTableDetails[] }>()
);

export const checkIn = createAction(`${ACTION_PREFIX} Checkin Required`);
export const assignmentLoaded = createAction(
  `${ACTION_PREFIX} Assignment Loaded`,
  props<{ tables: any }>()
);
export const assign = createAction(
  `${ACTION_PREFIX} table assigned`,
  props<{ table_id: string }>()
);
export const unAssign = createAction(
  `${ACTION_PREFIX} table unassigned`,
  props<{ table_id: string }>()
);

export const assignmentUpdated = createAction(
  `${ACTION_PREFIX} Assignment Updated`
);
export const assignmentViewed = createAction(
  `${ACTION_PREFIX} Assignment Viewed`
);

export const loadCollectionPointsError = createAction(
  `${ACTION_PREFIX} Fetch Collection Points Error`,
  props<{ error: any }>()
);
export const clearCollectionPoints = createAction(
  `${ACTION_PREFIX} Clear Collection Points`
);

export const selectTable = createAction(
  `${ACTION_PREFIX} Select Table`,
  props<{ selectedTableId: string }>()
);

export const openTable = createAction(
  `${ACTION_PREFIX} Open Table`,
  props<{
    details: OpenTableDidCloseData;
    tableId: string;
    collectionPointId: string;
  }>()
);

export const updateTable = createAction(
  `${ACTION_PREFIX} Update Table`,
  props<{
    table: DqTableDetails;
  }>()
);

export const favTable = createAction(
  `${ACTION_PREFIX} Fav Table`,
  props<{
    table: DqTableDetails;
  }>()
);
export const unFavTable = createAction(
  `${ACTION_PREFIX} UnFav Table`,
  props<{
    table: DqTableDetails;
  }>()
);

export const openTableSuccess = createAction(
  `${ACTION_PREFIX} Open Table Success`
);

export const openTableError = createAction(
  `${ACTION_PREFIX} Open Table Error`,
  props<{ error: any }>()
);

export const closeTable = createAction(
  `${ACTION_PREFIX} Close Table`,
  props<{ table: DqTableDetails; onComplete: () => void }>()
);

export const closeTableSuccess = createAction(
  `${ACTION_PREFIX} Close Table Success`,
  props<{ table: DqTableDetails }>()
);

export const closeTableError = createAction(
  `${ACTION_PREFIX} Close Table Error`,
  props<{ error: any; table: DqTableDetails }>()
);

export const influencerTableSelected = createAction(
  `${ACTION_PREFIX} Influencer At Valid Table`,
  props<{ tableId: number }>()
);
