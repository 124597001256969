import { DqCartItem } from "src/app/models/cart.model";
import { DqVenueSurcharge } from "../services/consumer-table/consumer-table.service";
import { DateTime } from "luxon";
import { SurchargeBasic } from "../models/sync_models";

export function getCartTotalPrice(items: DqCartItem[]) {
  return items.reduce<number>((total, item) => {
    const itemTotal = item.itemDetails
      ? getLegacyItemTotal(item)
      : getConsumerItemTotal(item);
    return total + itemTotal * item.quantity;
  }, 0);
}

export function getConsumerTotalPrice(
  items: DqCartItem[],
  surcharge: SurchargeBasic,
  taxRate: number = 0.1
) {
  const subtotal = getCartTotalPrice(items);
  const surcharges = getSurcharge(subtotal, surcharge);
  const tax = getTaxFromTotal(subtotal + surcharges, taxRate);
  return subtotal + surcharges + tax;
}

export function getSurcharge(total: number, surcharge: SurchargeBasic) {
  if (total === 0) {
    return 0;
  }

  if (surcharge) {
    const fixedSurcharge = +surcharge.fixed || 0;
    const variableSurcharge = +surcharge.ratio || 0;
    return fixedSurcharge + variableSurcharge * total;
  } else {
    return 0;
  }
}

export function getTaxFromTotal(total: number, taxRate: number = 0.1) {
  return total - total / (1 + taxRate);
}

export function getConsumerItemTotal(item: DqCartItem) {
  const modifierTotal = Array.isArray(item.consumerModifiers)
    ? item.consumerModifiers
        .flatMap((mg) => mg.modifiers)
        .reduce<number>((total, modifier) => total + +modifier.price, 0)
    : 0;

  return (
    +(item.user_input_price || item.consumerItemDetails.price) + modifierTotal
  );
}

export function getLegacyItemTotal(item: DqCartItem) {
  const modifierTotal = Array.isArray(item.consumerModifiers)
    ? item.consumerModifiers
        .flatMap((mg) => mg.modifiers)
        .reduce<number>((total, modifier) => total + +modifier.price, 0)
    : 0;

  return +item.itemDetails.i_price + modifierTotal;
}

function findApplicableSurchage(surcharges: DqVenueSurcharge[], total: number) {
  const now = DateTime.local();
  return surcharges.find((sur) => {
    // v_sc_total_lte <= order_total < v_sc_total_gt and  v_sc_active_utc_lte <= now_utc < v_sc_active_utc_gt
    const surchargeStartTime = DateTime.fromISO(sur.v_sc_active_utc_lte);
    const surchargeEndTime = DateTime.fromISO(sur.v_sc_active_utc_gt);
    const surchargeMinValue = +sur.v_sc_total_lte;
    const surchargeMaxValue = +sur.v_sc_total_gt;
    return (
      surchargeMinValue <= total &&
      total < surchargeMaxValue &&
      surchargeStartTime <= now &&
      now < surchargeEndTime
    );
  });
}
