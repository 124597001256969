import { createAction, props } from "@ngrx/store";
import { DqGratuityType } from "src/app/services/order/order.service";
import { DqConsumerTabCredit } from "../../consumer-event/services/consumer-event/consumer-event.service";

const ACTION_PREFIX = "[Consumer Cart Page]";

export const didEnter = createAction(
  `${ACTION_PREFIX} Did Enter`,
  props<{ tableId: string }>()
);

export const updateTip = createAction(
  `${ACTION_PREFIX} Update Tip`,
  props<{
    value: number;
    gratuityType: DqGratuityType;
    couponCodes: DqConsumerTabCredit[];
  }>()
);
