import { Injectable } from "@angular/core";
import Cache from "@aws-amplify/cache";
import { Observable } from "rxjs";
import { pluck, tap } from "rxjs/operators";
import { Params } from "@angular/router";
import { RegionsService } from "../regions/regions.service";
const MAX_CACHE_HOURS = 12;
const KEY = "table-customer-id";

@Injectable({ providedIn: "root" })
export class CustomerIdService {
  private cache = Cache.createInstance({
    defaultTTL: MAX_CACHE_HOURS * 60 * 60 * 1000,
    keyPrefix: "dq:",
  });

  constructor() {}

  setCustomerId(customerId: string) {
    // if this eventId already exists, then delete it first and then set it to
    // reset the cache time
    if (this.cache.getItem(KEY)) {
      this.cache.removeItem(KEY);
    }
    this.cache.setItem(KEY, customerId);
  }

  getCustomerId() {
    return this.cache.getItem(KEY) as string | null;
  }

  cacheCustomerId<T extends Observable<Params>>() {
    return (source: T) => {
      return source.pipe(
        pluck("cid"),
        tap((customerId) => {
          if (customerId) {
            this.setCustomerId(customerId);
          }
        })
      );
    };
  }
}
