import { Pipe, PipeTransform } from "@angular/core";

// this pipe adds a random 'get' param to image urls to help prevent browser caching.
@Pipe({
  name: "randomiseUrl",
})
export class RandomiseUrlPipe implements PipeTransform {
  transform(imageUrl: string): any {
    return `${imageUrl}?=${Math.random().toString()}`;
  }
}

import { NgModule } from "@angular/core";

@NgModule({
  imports: [],
  exports: [RandomiseUrlPipe],
  declarations: [RandomiseUrlPipe],
  providers: [],
})
export class RandomiseUrlPipeModule {}
