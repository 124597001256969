import { Actor } from "./mapactor.graphic";
import { ToolBar } from "./toolbar.graphic";
import {
  Application,
  Container,
  Sprite,
  Texture,
  Text as PText,
} from "pixi.js";
import { MapObject, Scale } from "../../../models/map_editor_models";
import { DisplayObject, ObservablePoint, DisplayObjectEvents } from "pixi.js";
import { AlertController } from "@ionic/angular";
import { Subject } from "rxjs";
import { GlowFilter } from "@pixi/filter-glow";

export class Text extends Actor {
  //inputs include the x and y coordinates of the table (defaults to middle of screen)
  //the table type,
  //the number of seats,
  //and the table ID
  data: any | null;
  public mapObject: MapObject;
  public HoverBar: Container;
  public Editing: boolean;
  textobject;
  public CurrentlySelected: boolean = false;
  private SelectedObject: Subject<any>;
  constructor(
    InputObject: MapObject,
    Editing: boolean,
    Selected: Subject<any>,
    alertCtrl: AlertController
  ) {
    super();
    this.mapObject = InputObject;
    this.x = InputObject.x;
    this.y = InputObject.y;
    this.angle = InputObject.rotation;
    this.interactive = true;
    this.Editing = Editing;
    this.SelectedObject = Selected;


    Selected.subscribe((next) => {
      if (this.CurrentlySelected == true && next != this) {
        this.DeSelect();
      }
      if (next == this && this.CurrentlySelected == false) {
        this.onSelect();
      }
    });
    this.onpointertap = function (e) {
      this.onSelect();
    };
    this.onpointerdown = function (e) {
      this.onSelect();
    };

   
    this.onpointerdown = function (e) {
      console.log("start dragging");
      const sprite = e.currentTarget;
      const viewport = sprite.parent as any;
      this.alpha = 0.5;
      this.dragging = true;
      this.data = e.data;

      if (this.Editing) {
        viewport.drag({ pressDrag: false });
      }
      this.Selected = this;
    };
    this.onpointerup = function (e) {
      console.log("stop dragging");
      const sprite = e.currentTarget;
      const viewport = sprite.parent as any;
      this.data = e.data;
      this.alpha = 1;
      this.dragging = false;
      viewport.drag();
      this.Selected = this;
    };
    this.onpointerupoutside = function (e) {
      const sprite = e.currentTarget;
      const viewport = sprite.parent as any;
      this.data = e.data;
      this.alpha = 1;
      this.dragging = false;
      viewport.drag();
      this.Selected = this;
    };

    this.onglobalpointermove = function (e) {
      if (this.dragging) {
        if (this.Editing) {
          const newPosition = this.data!.getLocalPosition(this.parent);
          this.x = newPosition.x;
          this.y = newPosition.y;
          this.Selected = this;
        }
      }
    };

    this.onrightclick = function (e) {
      console.log(" editing");

      if (this.Editing) {
        //create an alert box asking for a new text input
        let al = alertCtrl
          .create({
            header: "Edit Text",
            message: "Enter the new text",
            inputs: [
              {
                name: "Text",
                placeholder: "Text",
              },
            ],
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                handler: () => {
                  console.log("Cancel clicked");
                },
              },
              {
                text: "Save",
                handler: (alertData) => {
                  console.log(alertData.Text);
                  this.mapObject.Data = alertData.Text;
                  this.textobject.text = alertData.Text;
                },
              },
            ],
          })
          .then((prompt) => {
            prompt.present();
          });
      }
    };

    if (this.mapObject.mapItem.toolbar != undefined) {
      //var tooly = new ToolBar(this.mapObject, this);
      //this.addChild(tooly);
      //this.HoverBar = tooly;
      //tooly.alpha = 0;
    }

    var text = new PText(this.mapObject.Data as any, {
      fontFamily: "Arial",
      fontSize: 78,
      fill: 0x191919,
      align: "center",
    });

    this.textobject = this.addChild(text as any);
  }
  public destroyobject() {
    this.destroy();
  }
  getMapObject(): MapObject {
    this.mapObject.x = this.x;
    this.mapObject.y = this.y;
    this.mapObject.scale = { x: this.scale.x, y: this.scale.y };
    this.mapObject.rotation = this.angle;
    return this.mapObject;
  }

  public refreshContent() {
    this.textobject.text = this.mapObject.Data;
  }


  onSelect() {
    if (this.CurrentlySelected == true && this.Editing == true) {
      this.DeSelect();
    } else {
      if (this.CurrentlySelected == false) {
        this.CurrentlySelected = true;

        this.filters = [
          new GlowFilter({
            color: 0x494949,
            distance: 12,
            outerStrength: 2,
            quality: 1,
          }) as any,
        ];
        //get current value of selected table, if selected table is this objec
        //this.viewport.ZoomToObject(this as unknown as DisplayObject);
        this.SelectedObject.next(this);
      }
    }
  }

  DeSelect() {
    this.CurrentlySelected = false;
    this.filters = [];
  }
}
