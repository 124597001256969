<ng-container *ngrxLet="reciept$(recieptId) as reciept">
  <ion-header>
    <ion-toolbar color="medium" class="mobilewidth">
      <ion-buttons slot="start">
        <ion-button *ngIf="!backbutton" (click)="closeMenu()">
          <ion-icon name="close" color="primary"></ion-icon>
        </ion-button>
        <ion-back-button
          color="primary"
          [defaultHref]="route.snapshot.queryParamMap.get('return_url')"
          *ngIf="backbutton"
        ></ion-back-button>
      </ion-buttons>
      <ng-container>
        <ng-container *ngIf="reciept != undefined && reciept.supports_partial">
          <ion-buttons
            *ngIf="splitpayment == false"
            slot="end"
            (click)="splitpayment = true"
          >
            <ion-button
              *ngIf="reciept.outstanding > 0"
              fill="clear"
              color="primary"
            >
              Split Payment
            </ion-button>
          </ion-buttons>
          <ion-buttons
            *ngIf="splitpayment == true"
            slot="end"
            (click)="splitpayment = false"
          >
            <ion-button
              *ngIf="reciept.outstanding > 0"
              fill="clear"
              color="primary"
            >
              Full Payment
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ng-container>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ng-container *ngIf="reciept.outstanding > 0">
      <ion-list lines="none" class="header">
        <ng-container>
          <ion-item>
            <ion-col [style.min-height.px]="77">
              <ion-row class="ion-justify-content-between">
                <ion-label position="stacked">OUTSTANDING</ion-label>
                <ion-label
                  *ngIf="reciept != undefined"
                  position="stacked"
                  class="ion-text-end"
                  >TOTAL
                  {{ reciept.total | currency: (currency$ | async) }}</ion-label
                >
                <ion-label *ngIf="reciept == undefined"
                  ><ion-skeleton-text
                    [animated]="true"
                    [style.height]="'25px'"
                    [style.width]="'120px'"
                  ></ion-skeleton-text
                ></ion-label>
              </ion-row>
              <ion-label
                *ngIf="!splitpayment && reciept != undefined"
                class="title"
              >
                {{ reciept.outstanding | currency: (currency$ | async) }}
              </ion-label>
              <ion-label class="" *ngIf="reciept == undefined"
                ><ion-skeleton-text
                  [animated]="true"
                  [style.width]="'160px'"
                  [style.height]="'60px'"
                  [style.margin-left]="'0px'"
                  [style.margin-top]="'auto'"
                ></ion-skeleton-text
              ></ion-label>

              <ion-item *ngIf="splitpayment" class="optiunput">
                <ion-input
                  [(ngModel)]="outstanding"
                  type="decimal"
                  placeholder="0.0"
                  placeholder="Enter Partial Amount"
                  class="inputi"
                >
                </ion-input>
              </ion-item>

              <ion-label *ngIf="splitpayment"
                >REMAINING
                {{
                  reciept.outstanding - outstanding
                    | currency: (currency$ | async)
                }}</ion-label
              >
            </ion-col>
          </ion-item>
        </ng-container>
      </ion-list>
      <ion-list class="methods">
        <ion-item-divider color="dark-grey"> PAYMENT METHODS </ion-item-divider>
        <ng-container *ngIf="groupedMethods.length > 0">
          <ion-card
            color="secondary"
            class="methodcard"
            *ngFor="let group of groupedMethods"
            (click)="SelectedMethod = group.selected; methodSelected()"
            [class]="
              SelectedMethod == group.selected
                ? 'selected ' + group.internal_key
                : group.internal_key
            "
          >
            <ion-item lines="none" color="secondary">
              <ion-icon
                [name]="
                  SelectedMethod == group.selected
                    ? 'ellipse'
                    : 'ellipse-outline'
                "
                slot="start"
                color="primary"
              ></ion-icon>
              <ion-col>
                <ion-label>{{ group.name }}</ion-label>
                <ion-note color="">{{ group.description }}</ion-note>
              </ion-col>

              <ion-icon [name]="group.icon" slot="end"></ion-icon>
            </ion-item>
            <ion-segment
              #segv
              [(ngModel)]="group.selected"
              (ngModelChange)="SelectedMethod = group.selected"
            >
              <ion-segment-button
                *ngFor="let option of group.options"
                [value]="option"
              >
                {{ option.name }}
              </ion-segment-button>
            </ion-segment>
          </ion-card>
        </ng-container>
        <ng-container *ngIf="paymentMethods != undefined; else methodslaoding">
          <ng-container *ngFor="let method of paymentMethods">
            <ion-card
              color="secondary"
              class="methodcard"
              (click)="SelectedMethod = method; methodSelected()"
              [class]="
                SelectedMethod == method
                  ? 'selected ' + method.internal_key
                  : method.internal_key
              "
            >
              <ion-item class="internalitem" lines="none" color="secondary">
                <ion-icon
                  [name]="
                    SelectedMethod == method ? 'ellipse' : 'ellipse-outline'
                  "
                  slot="start"
                  color="primary"
                ></ion-icon>
                <ion-col class="nowrap-col">
                  <ion-label>{{ method.name }}</ion-label>
                  <ion-note color="">{{ method.description }}</ion-note>
                  <span *ngIf="method.internal_key == 'stripe_tap_to_pay'">
                    <ng-container
                      *ngrxLet="mobilepay.status_text$ | async as mobile_stat"
                    >
                      <ion-spinner
                        *ngIf="mobile_stat == 'preparing'"
                        color="primary"
                        class="inline-marker"
                      >
                      </ion-spinner>

                      <ion-note
                        color="primary"
                        *ngIf="mobile_stat == 'preparing'"
                        class="tinytext"
                        >Configuring</ion-note
                      >

                      <ion-icon
                        *ngIf="mobile_stat == 'ready'"
                        name="checkmark-circle-outline"
                        color="primary"
                        class="inline-marker right-margin-small"
                      ></ion-icon>

                      <ion-note
                        *ngIf="mobile_stat == 'ready'"
                        color="primary"
                        class="tinytext"
                        >Ready</ion-note
                      >

                      <ion-note
                        *ngIf="
                          mobile_stat != 'preparing' && mobile_stat != 'ready'
                        "
                        color="danger"
                        class="tinytext"
                        >Failed to Setup</ion-note
                      >
                    </ng-container>
                  </span>
                </ion-col>

                <ion-icon [name]="method.icon" slot="end"></ion-icon>
              </ion-item>
            </ion-card>
          </ng-container>
        </ng-container>
      </ion-list>
    </ng-container>
    <div
      @fadeSlideInOut
      *ngIf="
        reciept != undefined &&
        reciept.outstanding <= 0 &&
        !(this.route_to != undefined && this.route_to.state == 'pending_refund')
      "
    >
      <ion-icon
        name="checkmark-circle"
        color="success"
        class="bigcheck"
      ></ion-icon>

      <div class="titlepa">
        Paid {{ reciept.total | currency: (currency$ | async) }}
      </div>
      <div class="recipewr">
        <app-reciept [recieptId]="recieptId" [Actionable]="true"></app-reciept>
      </div>
    </div>
  </ion-content>
  <ion-footer>
    <ion-toolbar
      *ngIf="
        reciept.outstanding > 0 &&
        SelectedMethod != undefined &&
        SelectedMethod.supports_tips
      "
      color="medium"
      [class]="isTablet ? 'footer' : ''"
    >
      <app-gratuity
        [total]="
          this.splitpayment ? this.outstanding : this.reciept.outstanding
        "
        (valueChange)="tip = $event; log($event)"
        [options]="[
          SelectedMethod.tip_1,
          SelectedMethod.tip_2,
          SelectedMethod.tip_3
        ]"
      ></app-gratuity>
    </ion-toolbar>
    <ion-toolbar
      *ngIf="reciept.outstanding > 0"
      color="medium"
      [class]="isTablet ? 'footer' : ''"
    >
      <ion-button
        size="large"
        (click)="CollectPayment()"
        [class]="isTablet ? 'mainbutton' : ''"
        expand="block"
        [disabled]="SelectedMethod == undefined || reciept == undefined"
      >
        <ion-label *ngIf="!loading" color="light">
          {{
            SelectedMethod == undefined
              ? "Select Method"
              : SelectedMethod.complete_button != undefined
              ? SelectedMethod.complete_button
              : "Complete"
          }}
        </ion-label>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-toolbar>
  </ion-footer>
  <ng-template #methodslaoding>
    <ion-card color="secondary" class="methodcard">
      <ion-item lines="none" color="secondary">
        <ion-icon
          [name]="'ellipse-outline'"
          slot="start"
          color="primary"
        ></ion-icon>
        <ion-col>
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'100px'"
              [style.height]="'24px'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note color=""
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'220px'"
              [style.height]="'19px'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-col>

        <ion-skeleton-text
          [animated]="true"
          [style.width]="'30px'"
          [style.height]="'30px'"
        ></ion-skeleton-text>
      </ion-item>
    </ion-card>
    <ion-card color="secondary" class="methodcard">
      <ion-item lines="none" color="secondary">
        <ion-icon
          [name]="'ellipse-outline'"
          slot="start"
          color="primary"
        ></ion-icon>
        <ion-col>
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'100px'"
              [style.height]="'24px'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note color=""
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'220px'"
              [style.height]="'19px'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-col>

        <ion-skeleton-text
          [animated]="true"
          [style.width]="'30px'"
          [style.height]="'30px'"
        ></ion-skeleton-text>
      </ion-item>
    </ion-card>
    <ion-card color="secondary" class="methodcard">
      <ion-item lines="none" color="secondary">
        <ion-icon
          [name]="'ellipse-outline'"
          slot="start"
          color="primary"
        ></ion-icon>
        <ion-col>
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'100px'"
              [style.height]="'24px'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note color=""
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'220px'"
              [style.height]="'19px'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-col>

        <ion-skeleton-text
          [animated]="true"
          [style.width]="'30px'"
          [style.height]="'30px'"
        ></ion-skeleton-text>
      </ion-item>
    </ion-card>
    <ion-card color="secondary" class="methodcard">
      <ion-item lines="none" color="secondary">
        <ion-icon
          [name]="'ellipse-outline'"
          slot="start"
          color="primary"
        ></ion-icon>
        <ion-col>
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'100px'"
              [style.height]="'24px'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note color=""
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'220px'"
              [style.height]="'19px'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-col>

        <ion-skeleton-text
          [animated]="true"
          [style.width]="'30px'"
          [style.height]="'30px'"
        ></ion-skeleton-text>
      </ion-item>
    </ion-card>
    <ion-card color="secondary" class="methodcard">
      <ion-item lines="none" color="secondary">
        <ion-icon
          [name]="'ellipse-outline'"
          slot="start"
          color="primary"
        ></ion-icon>
        <ion-col>
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'100px'"
              [style.height]="'24px'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note color=""
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'220px'"
              [style.height]="'19px'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-col>

        <ion-skeleton-text
          [animated]="true"
          [style.width]="'30px'"
          [style.height]="'30px'"
        ></ion-skeleton-text>
      </ion-item>
    </ion-card>
    <ion-card color="secondary" class="methodcard">
      <ion-item lines="none" color="secondary">
        <ion-icon
          [name]="'ellipse-outline'"
          slot="start"
          color="primary"
        ></ion-icon>
        <ion-col>
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'100px'"
              [style.height]="'24px'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note color=""
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'220px'"
              [style.height]="'19px'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-col>

        <ion-skeleton-text
          [animated]="true"
          [style.width]="'30px'"
          [style.height]="'30px'"
        ></ion-skeleton-text>
      </ion-item>
    </ion-card>
  </ng-template>

  <div @fadeSlideInOut class="foregroundcover" *ngIf="route_to != undefined">
    <div class="holder">
      <h1 class="maxwidth" *ngIf="route_to.title != undefined">
        {{ route_to.title }}
      </h1>
      <ion-label class="maxwidth" *ngIf="route_to.desc != undefined">{{
        route_to.desc
      }}</ion-label>
      <ion-spinner color="light" *ngIf="route_to.loading" name="crescent">
      </ion-spinner>
      <qrcode
        *ngIf="!route_to.loading"
        class="qrcode padd"
        [qrdata]="route_to.qr"
        [width]="256"
        [errorCorrectionLevel]="'M'"
      ></qrcode>
      <ion-note class="maxwidth" *ngIf="route_to.note != undefined">
        {{ route_to.note }}
      </ion-note>
      <ion-button (click)="CancelPayment()" size="small" class="offset"
        >Cancel</ion-button
      >
    </div>
  </div>

  <div @fadeSlideInOut class="foregroundcover ontop" *ngIf="success">
    <div class="holder fulldark">
      <ion-icon
        @rotateIn
        color="success"
        [style.width]="'50vh'"
        [style.height]="'50vh'"
        name="checkmark-circle-outline"
      ></ion-icon>
      <h1
        class="maxwidth"
        color="light"
        *ngIf="temp_route_to.success_title != undefined"
      >
        {{ temp_route_to.success_title }}
      </h1>
    </div>
  </div>

  <div @fadeSlideInOut class="foregroundcover ontop" *ngIf="failure">
    <div class="holder fulldark">
      <ion-icon
        @rotateIn
        color="danger"
        [style.width]="'50vh'"
        [style.height]="'50vh'"
        name="close-circle-outline"
      ></ion-icon>
      <h1
        class="maxwidth"
        color="light"
        *ngIf="temp_route_to.failure_title != undefined"
      >
        {{ temp_route_to.failure_title }}
      </h1>
      <ion-note
        class="maxwidth"
        *ngIf="temp_route_to.failure_note != undefined"
      >
        {{ temp_route_to.failure_note }}
      </ion-note>
    </div>
  </div>
</ng-container>
