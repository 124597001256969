import { AfterViewInit, Directive, ElementRef } from "@angular/core";
import { IonSearchbar } from "@ionic/angular";

@Directive({
  selector: "ion-searchbar[appAutofocus]",
})
export class AutoFocusIonSearchbarDirective implements AfterViewInit {
  constructor(private host: ElementRef<IonSearchbar>) {}

  ngAfterViewInit() {
    if (this.host?.nativeElement?.setFocus) {
      setTimeout(() => this.host.nativeElement.setFocus(), 50);
    }
  }
}
