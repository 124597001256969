<div class="modal">
  <qrcode
    *ngIf="!paid"
    class="qrcode"
    [qrdata]="qrCode"
    [width]="256"
    [errorCorrectionLevel]="'M'"
  ></qrcode>
  <ion-icon
    *ngIf="paid"
    name="checkmark-circle-outline"
    color="success"
    class="success"
  ></ion-icon>
  <div *ngIf="payments && !paid">
    <div class="paymentvert">
      <ion-label class="padd" color="light">
        <h2>Waiting for customer payment</h2>
      </ion-label>

      <ion-spinner class="padd" name="crescent" color="light"></ion-spinner>
    </div>
  </div>
  <div *ngIf="payments && paid">
    <div class="paymentvert">
      <ion-label class="padd" color="light"
        ><h2>Payment Completed!</h2>
      </ion-label>
    </div>
  </div>
  <div *ngIf="!payments">
    <ion-label class="ion-text-center" color="light">
      <h2
        class="ion-margin-left ion-margin-right ion-padding-start ion-padding-end ion-padding-bottom"
      >
        {{ title }}
      </h2>
    </ion-label>
  </div>
  <ion-row>
    <ion-button
      class=""
      *ngIf="!paid"
      (click)="close()"
      size="small"
      expand="block"
    >
      Close
    </ion-button>
    <ion-button
      class=""
      *ngIf="!paid"
      (click)="copy()"
      color="light"
      size="small"
      expand="block"
    >
      Copy Link
    </ion-button></ion-row
  >
</div>
