import { Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import Pusher from "pusher-js";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { RegionsService } from "../regions/regions.service";
import { SettingsState } from "../sync-engine/settings-state/settings-state.service";
import {
  combineLatestWith,
  filter,
  map,
  take,
  tap,
  withLatestFrom,
} from "rxjs";
import { RxState } from "@rx-angular/state";

@Injectable({
  providedIn: "root",
})
export class PusherService {
  pusher$ = new RxState<{ pusher: any; in_error: boolean }>();
  in_error$ = this.pusher$.select("in_error");
  venue;
  constructor(
    private auth: AuthClass,
    private reg: RegionsService,
    private settingsSt: SettingsState
  ) {
    this.pusher$.set({ in_error: false });
    this.settingsSt.currentUser$
      .pipe(combineLatestWith(this.settingsSt.venueId$), filter((x) => {
        return x[1] != null
      }))
      .subscribe((x) => {
        //wait for venue_id to not be null
        console.log("setting up pusher", x[1])
        if (x[0] != null) {
          this.setupPusher(x[0], x[1]);
        }
      });
  }

  async setupPusher(user, venue_id) {
    let pusher = this.pusher$.get("pusher");

    let operations = await this.settingsSt.hasClaim$(
      "operations"
    ).pipe(
      take(1)
    ).toPromise()
    let management = await this.settingsSt.hasClaim$(
      "management"
    ).pipe(
      take(1)
    ).toPromise()
    let promoter = await this.settingsSt.hasClaim$(
      "promoter"
    ).pipe(
      take(1)
    ).toPromise()

    console.log("setting up pusher", venue_id)
    //wait for venue to be checked

    

      console.log()
    if (
      (pusher == "" || pusher == undefined) &&
(operations || management || promoter)  ) {
      let token = (user as any).signInUserSession.idToken.jwtToken;

      if (pusher != undefined) {
        await pusher.disconnect();
      }
      let env = this.reg.getCurrentEnvironment();
      console.log("setting up pusher", env)
      pusher = new Pusher(env.pusher.key, {
        cluster: env.pusher.cluster,
        auth: {
          headers: {
            Venue_id: venue_id,
            Authorization: `Bearer ${token}`,
            
          },
        },
        channelAuthorization: {
          transport: "ajax",
          endpoint: `${this.reg.ServerURL()}/pusher/auth-regional`,
          headers: {
            Authorization: `Bearer ${token}`,
            Venue_id: venue_id,

          },
        },
      });
      this.pusher$.set({ pusher: pusher });

      pusher.connection.bind("disconnected", (x) => {
        console.log("Pusher disconnected");
        this.pusher$.set({ in_error: true });
      });

      pusher.connection.bind("connected", (x) => {
        this.pusher$.set({ in_error: false });
      });
    }
  }

  async getObjectChannel(object_type: string, object_id) {
    //get venue_id
    //wait for venue_id
    let venue = await this.settingsSt.state
      .select("currentVenue")
      .pipe(
        filter((venue) => venue != null),
        take(1)
      )
      .toPromise();
    let pusher = await this.pusher$.select("pusher").pipe(take(1)).toPromise();

    let subscription = pusher.subscribe(
      `private-${venue}-${object_type}-${object_id}`
    );
    return subscription;
  }

  async unsubscribeChannel(object_type: string, object_id) {
    //get venue_id
    //wait for venue_id

    let pusher = await this.pusher$.select("pusher").pipe(take(1)).toPromise();

    let unsub = pusher.unsubscribe(
      `private-${this.venue}-${object_type}-${object_id}`
    );
    return unsub;
    //return observable using channel.bind
  }
}
