/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export type BookingStatus = "new" | "pending" | "confirmed" | "denied" | "seated" | "finished" | "cancelled";
/**
 * This is just an enum for the convenience of validation.
 */
export type PackageType = "advanced" | "upfront" | "minimum" | "minimumexc" | "package";
export type CollectionRequirement = "not_shown" | "optional" | "required";
export type ServiceMethod = "delivery" | "pickup" | "delivery_agent";
export type TableEntryAction = "menu_only" | "checkin_only" | "menu_and_checkin" | "treat_only" | "menu_and_treat";

export interface ArrivalTime {
  ts_id: string;
  arrive_utc: string;
  arrive_text_primary: string;
  arrive_text_trailing: string;
  is_available_for_package: boolean;
  max_people?: number;
}
export interface ArrivalTimes {
  arrival_times: ArrivalTime[];
}
export interface Availability {
  /**
   * ID of the availability for should be prefced with a string tag eg TBL-124
   */
  id?: string;
  /**
   * Whether it is available
   */
  available?: boolean;
  /**
   * Number of availability
   */
  availability_count?: number;
}
export interface AvailabilityList {
  availabilities: Availability[];
}
export interface BookingCard {
  id: string;
  name: string;
  contact: string;
  payment_method_icon: string;
  payment_completed: boolean;
  currency_code: string;
  total_price: string;
  number: string;
  status: BookingStatus;
  table: string;
  table_id: number;
  event: string;
  event_date?: string;
  guest_count: string;
  guests?: GuestListGuest[];
  guest_status: string;
  venue?: string;
  notes?: string;
  treat_summary: TreatSummary;
}
export interface GuestListGuest {
  id: string;
  guest_name: string;
  contact: string;
  status: string;
  seated: boolean;
}
export interface TreatSummary {
  unapproved_treats: number;
  unapproved_value: string;
}
export interface BookingCheckout {
  /**
   * ID of the booking
   */
  booking_id: string;
  /**
   * Package of the booking
   */
  package: Package;
  /**
   * Event of the booking
   */
  event: Event;
  /**
   * Client secret of the booking
   */
  client_secret: string;
  /**
   * Payment ID of the booking
   */
  payment_id: string;
  stripe_account_id?: string;
  /**
   * Deposit of the booking
   */
  deposit: string;
  status?: string;
  guests?: unknown;
  table?: string;
  currency_code: string;
  tab_public_id?: string;
  guest_list_locked: boolean;
  public_uuid?: string;
  treat_enabled?: boolean;
  default_prefix: string;
  arrival_time_options?: ArrivalTime[];
}
export interface Package {
  /**
   * ID of the package
   */
  id: number;
  /**
   * Name of the package
   */
  name?: string;
  /**
   * Number of guests
   */
  guests?: number;
  /**
   * Price of the package
   */
  price?: string;
  /**
   * Whether the package is available
   */
  available?: boolean;
  /**
   * List of inclusions
   */
  inclusions?: PackageInclusion[];
  /**
   * List of tables
   */
  tables?: PackageTable[];
  currency_code: string;
  /**
   * Minimum spend of the package
   */
  minimum_spend?: string;
  /**
   * Deposit of the package
   */
  deposit?: string;
  /**
   * Deposit of the package
   */
  payLater?: string;
  included_spend?: string;
  chip?: string;
  subtitle?: string;
  type: PackageType;
  public_arrival_select: boolean;
  arrival_time_options: ArrivalTime[];
}
export interface PackageInclusion {
  /**
   * Name of the inclusion
   */
  name?: string;
  /**
   * Quantity of the inclusion
   */
  quantity?: number;
}
export interface PackageTable {
  /**
   * ID of the package
   */
  id: number;
  /**
   * Name of the table
   */
  name?: string;
  /**
   * Whether the table is available
   */
  available?: boolean;
  /**
   * Capacity of the table
   */
  capacity?: number & string;
}
export interface Event {
  /**
   * ID of the event
   */
  id?: number;
  name: string;
  description: string;
  image: string;
  date: string;
  packages?: Package[];
  /**
   * ID of the venue
   */
  v_id: string;
  /**
   * Whether the event can be booked
   */
  can_book?: boolean;
  availability?: string;
  recurring?: boolean;
  sub_events?: Event[];
  reccuring_parent_id?: string;
  tables?: PackageTable[];
  public_arrival_select: boolean;
  arrival_time_options: ArrivalTime[];
  allow_table_preference: boolean;
  require_email: boolean;
  birthday: CollectionRequirement;
}
export interface BookingGiftObject {
  public_uuid?: string;
  description?: string;
  image?: string;
  public_link?: boolean;
  venue_logo?: string;
  link_url?: string;
}
export interface BookingGuest {
  id: string;
  guest_name: string;
  guest_mobile_num: string;
  guest_mobile_code: string;
  guest_email: string;
  guest_identity?: string;
  tags?: string[];
  spend: string;
  visits: string;
  orders: string;
  bookings?: BookingCard[];
  guest_notes?: string;
  relations?: GuestListGuest[];
  guest_walk_in_unassigned?: boolean;
}
export interface BookingList {
  bookings: BookingCard[];
}
export interface BookingManagment {
  id: string;
  status: BookingStatus;
  notes: string;
  number: string;
  base_package?: CMS_Package;
  package: CMS_Package;
  event: Event;
  guest?: BookingGuest;
  reciept_id: string;
  table_id: string;
  table_number: string;
  promoter?: string;
  total: string;
  total_number: number;
  currency_code: string;
  adjustment: number;
  warning?: string;
  guests?: GuestListGuest[];
  tab_public_id?: string;
  card?: BookingCard;
  guest_list_locked: boolean;
  public_uuid?: string;
  remaining_included_spend?: number;
  treat_summary: TreatSummary;
  linked_table_ids?: string[];
}
export interface CMS_Package {
  /**
   * ID of the package
   */
  id: number;
  /**
   * Name of the package
   */
  name?: string;
  /**
   * Number of guests
   */
  guests?: number;
  /**
   * Currency code of the package
   */
  currency_code?: string;
  /**
   * Price of the package
   */
  price?: number;
  /**
   * List of inclusions
   */
  inclusions?: CMS_PackageInclusion[];
  /**
   * List of tables
   */
  tables?: CMSv2Table[];
  /**
   * Deposit of the package
   */
  deposit?: number;
  /**
   * Minimum spend of the package
   */
  minimum_spend?: number;
  /**
   * Minimum spend of the package
   */
  included_spend?: number;
  chip?: string;
  subtitle?: string;
  type: PackageType;
}
export interface CMS_PackageInclusion {
  /**
   * ID of the inclusion
   */
  id: number;
  /**
   * Name of the inclusion
   */
  name?: string;
  /**
   * Quantity of the inclusion
   */
  quantity?: number;
  /**
   * ID of the menu item
   */
  menu_item_id: number;
  avail?: number;
  modifiers?: ConsumerModifierGroup[];
}
/**
 * "mg_id": self.mg_id,
 * "mg_name": self.mg_name,
 * "mg_description": self.mg_description,
 * "mg_min_selections": self.mg_min_selections,
 * "mg_max_selections": self.mg_max_selections,
 * "modifiers": mod_contents,
 */
export interface ConsumerModifierGroup {
  mg_id: number;
  mg_name?: string;
  /**
   * Description of the modifier group
   */
  mg_description?: string;
  /**
   * Minimum number of selections
   */
  mg_min_selections?: number;
  /**
   * Maximum number of selections
   */
  mg_max_selections?: number;
  modifiers?: ConsumerModifier[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface ConsumerModifier {
  mod_id: number;
  mod_name?: string;
  mod_price?: string;
}
export interface CMSv2Table {
  table_id?: number;
  table_name: string;
  vc_id: number;
  venue_id: number;
  table_status: number;
  doshii_table_id?: string;
  table_service: ServiceMethod;
  table_sort_order: number;
  table_entry_action: TableEntryAction;
  doshii_override_name?: string;
  table_capacity?: string;
}
export interface BookingManagmentCheap {
  id?: string;
  status: BookingStatus;
  notes: string;
  number?: string;
  package: CMS_Package;
  event: Event;
  guest?: BookingGuest;
  reciept_id?: string;
  table_id: string;
  table_number?: string;
  promoter?: string;
  total: string;
  adjustment: number;
}
export interface BookingReported {
  id: string;
  name: string;
  package: string;
  table: string;
  bookingcost: string;
  turnaround: string;
  upsellcost: string;
  totalcost: string;
  promoter?: string;
  package_id: string;
  upsell_value: number;
}
export interface Chip {
  label: string;
  icon: string;
  color: string;
}
export interface EventList {
  events: Event[];
}
export interface EventReport {
  Bookings: BookingReported[];
  NoShows: BookingReported[];
  Promoters: PromoterReported[];
  PackagePop: PieChart[];
  UpsellPromoters: PieChart[];
  UpsellPackages: PieChart[];
  Available: string;
  BookRev: string;
  Total: string;
  Tags: string[];
}
export interface PromoterReported {
  id: string;
  name: string;
  bookings: number;
  show: number;
}
export interface PieChart {
  name: string;
  value: number;
}
export interface GuestList {
  guests: BookingGuest[];
}
export interface GuestListObject {
  event?: Event;
  list_name?: string;
  status: string;
  booking?: BookingCard;
  guest?: GuestListGuest;
  treat_link?: string;
  default_prefix: string;
}
export interface GuestStats {
  tags?: string[];
  spend: string;
  visits: string;
}
/**
 * one of these will exist for every time that includes modifiers
 * for an item with a quantity of 3, there will be 3 of these in the list
 * each one will specify the modifiers that are applied to that item
 *
 * (i assume well create different lines for each modifier) unless the modifiers for each
 * of the items is the same (or some of the items?)
 */
export interface ItemModifiers {
  modifiers: string[];
  mg_id: string;
}
export interface OrderRequest {
  orders: RequestOrder[];
}
export interface RequestOrder {
  id: string;
  quantity: number;
  options?: string[];
  item_modifiers?: ItemModifiers[];
}
export interface PaceLimit {
  v_id: number;
  pl_id: number;
  start_minute: number;
  max_people?: number;
}
export interface PaceLimits {
  pace_limits: PaceLimit[];
}
export interface TimeslotAvail {
  event_id: number;
  arrival_time: ArrivalTime;
  people_booked: number;
  people_remaining?: number;
}
export interface TimeslotAvails {
  timeslots: TimeslotAvail[];
}
