import { Component, OnInit, Input } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import {
  NotificationService,
  DqNotificationSendResponse,
} from "src/app/services/notification/notification.service";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { environment } from "src/environments/environment";
import { ActionSheetController, AlertController, ToastController } from "@ionic/angular";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { SessionState } from "src/app/services/sync-engine/sessions-state/session.service";

@Component({
  selector: "app-fab-actions",
  templateUrl: "./fab-actions.component.html",
  styleUrls: ["./fab-actions.component.scss"],
})
export class FabActionsComponent implements OnInit {
  @Input() tableId: string;

  constructor(
    private notificationSvc: NotificationService,
    private tableFacade: TablesFacadeService,
    private toastCtrl: ToastController,
    private analy: AnalyticsService,
    private tableSt: TableState,
    private settingSt: SettingsState,
    private actionCtrl: ActionSheetController,
    private sessionSt: SettingsState,
    private alertCtrl: AlertController
  ) {}

  RequestDQ = this.analy.getFeatureFlag("request_support", false);

  ngOnInit() {}

  callManager() {
    Haptics.impact();

    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallManager({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request Manager", result);
          this.showToastForResponse(result);
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }
  callDQ() {
    Haptics.impact();

    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallDQ({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request DQ", result);
          this.showToastForResponse(result);
          Haptics.selectionEnd();
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }

  callSecurity() {
    Haptics.impact();

    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallSecurity({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request Security", result);
          this.showToastForResponse(result);
          Haptics.selectionEnd();
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }

  async callRunner() {
    Haptics.impact();
    let requests = this.sessionSt.state.get('venue').requests_list


      
    const actionSheet = await this.actionCtrl.create({
      header: 'Request Runner',
      
      buttons: [
        ...requests.map(request => ({
        text: request,
        handler: () => this.callRunnerWithRequest(request)
      })),
      {
        text: "Custom",
        handler: async () => {
          //show alert for custom request
          let alert = await this.alertCtrl.create({
            header: 'Custom Request',
            inputs: [
              {
                name: 'request',
                type: 'text',
                placeholder: 'Enter your custom request'
              }
            ],
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'Submit',
                handler: (data) => this.callRunnerWithRequest(data.request)
              }
            ]
          })
          await alert.present()
          let result = await alert.onDidDismiss()
          
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        icon: 'close-circle-outline',
        cssClass: 'toast-close-button',
      }
    ]
        
    })

    await actionSheet.present()

  }

  callRunnerWithRequest(request: string) {
    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallRunner({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
            request: request
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request Runner", result);
          this.showToastForResponse(result);
          Haptics.selectionEnd();
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }

  getUserAndTableDetails() {
    return combineLatest([
      this.settingSt.state.select(),
      this.tableSt.table$(this.tableId),
    ]);
  }

  showToastForResponse(result: DqNotificationSendResponse) {
    if (result.status.toLowerCase() === "success") {
    } else {
      console.error(FabActionsComponent.name, result);
      this.showToast("Your request was not sent. Please try again");
    }
  }

  async showToast(message) {
    const toast = await this.toastCtrl.create({
      header: message,
      duration: environment.defaultToastDuration,
      position: "top",
      buttons: [
        {
          role: "cancel",
          icon: "close-circle-outline",
          cssClass: "toast-close-button",
        },
      ],
    });
    await toast.present();
  }
}
