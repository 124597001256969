<ion-item-group
  class="orderDetailsItemSummaryOrderPlaced"
  [class.first]="first"
  [class.last]="last"
  [class.shaddow]="shadow"
>
  <ion-item-sliding #slider (ionDrag)="dragging($event, item)" #item>
    <ion-item
      [attr.button]="canEdit"
      detail="false"
      lines="none"
      (click)="toggleEditButtons()"
      [class.cancelled]="status === 'Cancelled'"
      [class.cancelled]="voided"
      color="secondary"
      [class.firstitem]="first"
      [class.lastitem]="last"
    >
      <ion-badge
        *ngIf="quantity != undefined"
        slot="start"
        class="qty"
        [class.voidedbadge]="voided"
        >{{ quantity }}</ion-badge
      >
      <ion-label class="ion-text-wrap">
        <div class="item-name">
          {{ name }}
        </div>
        <div class="cancelled" *ngIf="status === 'Cancelled'">Cancelled</div>
        <div class="cancelled" *ngIf="voided && status !== 'Cancelled'">
          Voided
        </div>
        <ng-container
          *ngIf="
            garnishes?.length > 0 ||
            mixtures?.length > 0 ||
            modifiers?.length > 0
          "
        >
          <p class="mixed_label">with</p>
          <div class="options-list">
            <ion-badge *ngFor="let garnish of garnishes">
              {{ garnish.gar_name }}
            </ion-badge>
            <ion-badge *ngFor="let mixture of mixtures">
              {{ mixture.cfm_name || mixture.mix_name }}
            </ion-badge>
            <ion-badge *ngFor="let modifier of modifiers">
              {{ modifier.name }}
            </ion-badge>
          </div>
          <p class="item_comment" *ngIf="!canEdit && this.showCommentRow()">
            {{ comment }}
          </p>
        </ng-container>
      </ion-label>

      <ion-note
        *ngIf="price != undefined && currencyCode != undefined"
        slot="end"
        class="line-price"
        [color]="price == 'Package' ? 'primary' : ''"
        >{{ price | currency: currencyCode }}</ion-note
      >
      <ion-note
        *ngIf="price != undefined && currencyCode == undefined"
        slot="end"
        class="line-price"
        [color]="price == 'Package' ? 'primary' : ''"
        >{{ price }}</ion-note
      >

      <ion-button
        slot="end"
        fill="outline"
        *ngIf="showCancelButton && status !== 'Cancelled'"
        (click)="slider.closeOpened(); cancelItem.emit(itemId)"
      >
        <ion-icon slot="icon-only" name="trash"></ion-icon>
      </ion-button>
    </ion-item>
    <ng-container *ngIf="voidable">
      <ion-item-options side="end">
        <ion-item-option
          (click)="voidItem.emit(itemId)"
          *ngIf="!voided"
          color="danger"
        >
          <ion-icon slot="icon-only" name="alert-circle-outline"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ng-container>
    <ion-item-options *ngIf="canEdit" side="start">
      <ion-item-option (click)="addCommentToCartItem()">
        <ion-icon slot="icon-only" name="chatbox-ellipses"></ion-icon>
      </ion-item-option>
      <ion-item-option (click)="incrementCartItem()">
        <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-item-option>
    </ion-item-options>
    <ion-item-options side="end" *ngIf="canEdit">
      <ion-item-option (click)="decrementCartItem()">
        <ion-icon slot="icon-only" name="remove-outline"></ion-icon>
      </ion-item-option>
      <ion-item-option (click)="removeCartItem()" color="danger">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>

  <ion-item *ngIf="showCommentRow() && canEdit" lines="none">
    <ion-input
      type="text"
      placeholder="Enter a comment"
      debounce="300"
      clearInput="true"
      [value]="comment"
      (ionChange)="updateComment($event)"
    ></ion-input>
    <ion-button (click)="showComment = false">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-button>
  </ion-item>
</ion-item-group>
