<ion-slides pager="true" #slides>
  <ion-slide>
    <ion-col class="fullhi ion-justify-content-between">
      <h1></h1>
      <h5>Tap OPEN to open a table and add the guest’s name</h5>
      <ion-img
        src="../../../assets/major-assets/opentable.png"
        class="opentb"
      ></ion-img>
      <ion-img
        src="../../../assets/major-assets/tablelist.png"
        class="bigscr"
      ></ion-img>
    </ion-col>
  </ion-slide>
  <ion-slide>
    <ion-col class="fullhi ion-justify-content-between">
      <h1></h1>
      <h5>
        Tap on the table to view packages, service items and the menu. Request
        support as you need it with alerts
      </h5>
      <ion-img
        src="../../../assets/major-assets/menuscreen.png"
        class="menuscreen"
      ></ion-img>
    </ion-col>
  </ion-slide>
  <ion-slide>
    <ion-col class="fullhi ion-justify-content-between">
      <h1></h1>
      <h6>
        Indicate your guest’s payment method and submit. Your order will be
        brought to you.
      </h6>
      <ion-img
        src="../../../assets/major-assets/payments.png"
        class=""
      ></ion-img>
    </ion-col>
  </ion-slide>
</ion-slides>

<ion-icon
  name="arrow-forward-outline"
  class="ticker"
  color="primary"
  (click)="next()"
></ion-icon>

<ion-footer>
  <ion-button (click)="next()" class="padd" expand="block">
    Continue
  </ion-button>
</ion-footer>
