import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { SyncV1Table } from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class TableState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Table }>();

  //fields specific to the service
  service_icon: string = "tablet-landscape-outline";
  service_title: string = "Table Store";
  service_id: string = "Table"; //id of the service
  service_url: string = "tables";

  //fields specific to the data
  data_id: string = "table_id";
  data_detail: string = "name";
  data_expiry: number = 1440; //extended because its a menu

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }
  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  //get the menu items as an array
  allMenuItems$ = this.state.select(
    map((menuItems) => {
      return Object.values(menuItems).filter((item) => item.table_id != "0");
    })
  );

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased || stats.restored) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  tables$ = this.state.select(
    map((menuItems) => {
      return Object.values(menuItems).filter((item) => item.table_id != "0");
    })
  );

  tables_ordered$ = this.state.select(
    map((menuItems) => {
      console.log("Table Info", menuItems);
      delete menuItems.new;

      let tables = Object.values(menuItems).filter(
        (item) => item.table_id != "0"
      );

      return tables.sort((a, b) => {
        //remove _ a.cp_id
        let a1 = Number(a.cp_id.replace("cp_", ""));
        let b1 = Number(b.cp_id.replace("cp_", ""));
        return a.sort_order + a1 - (b.sort_order + b1);
      });
    })
  );

  //get table using id
  table$ = (id) => this.state.select(id);

  menuItemsInCategory$ = (id) =>
    this.state.select(
      map((menuItems) => Object.values(menuItems).filter((item) => item === id))
    );
}
