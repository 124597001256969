import { Injectable } from "@angular/core";
import { registerPlugin } from "@capacitor/core";

export interface ActivityPlugin {
  echo(options: { value: string }): Promise<{ value: string }>;
  createActivity(
    options: orderActivityModel
  ): Promise<{ value: string; token?: string }>;
  //add a listner for PUSH_NOTIFICATION_TOKEN_UPDATE

  addListener(
    eventName: "PUSH_NOTIFICATION_TOKEN_UPDATE",
    listenerFunc: (token: string) => void
  ): Promise<{ value: string }>;

  //remove a listner for PUSH_NOTIFICATION_TOKEN_UPDATE
}

const Activity = registerPlugin<ActivityPlugin>("Activity");

//create OrderActivityModel
interface orderActivityModel {
  orderColumn: string;
  orderPaid?: boolean;
  order_placed?: Date;
  tableName?: string;
  orderNumber?: string;
  totalAmount?: string;
}

export default Activity;
