import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1Guest, SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
@Component({
  selector: "app-guest-status",
  templateUrl: "./guest-status.component.html",
  styleUrls: ["./guest-status.component.scss"],
})
export class GuestStatusComponent implements OnInit {
  @Input() guest: SyncV1Guest | any;
  @Input() transparent: boolean = false;
  @Input() shrink: boolean = false;

  statusIcon = {
    regular_booker: {
      icon: "person-outline",
      color: "success",
      text: "Regular Booker",
    },
    first_time_booker: {
      icon: "person-add-outline",
      color: "primary",
      text: "First Booking",
    },
    churned_booker: {
      icon: "alert-outline",
      color: "danger",
      text: "Churned Booker",
    },
    returning_booker: {
      icon: "return-up-back-outline",
      color: "tertiary",
      text: "Returning Booker",
    },
    regular_guestlist: {
      icon: "person-outline",
      color: "success",
      text: "Regular Guest",
    },
    first_time_guest: {
      icon: "person-add-outline",
      color: "primary",
      text: "New Guest",
    },
    churned_guestlist: {
      icon: "alert-outline",
      color: "danger",
      text: "Churned Guest",
    },
    returning_guestlist: {
      icon: "return-up-back-outline",
      color: "tertiary",
      text: "Returning Guest",
    },
  };

  constructor() {}

  ngOnInit() {
    //log output of tableDetail
  }

  statIcon(status) {
    if (this.statusIcon[status] == undefined) {
      return "person-add-outline";
    }

    return this.statusIcon[status].icon;
  }
  statColor(status) {
    if (this.statusIcon[status] == undefined) {
      return "primary";
    }
    return this.statusIcon[status].color;
  }
  statText(status) {
    if (this.statusIcon[status] == undefined) {
      return "Unknown";
    }
    return this.statusIcon[status].text;
  }
}
