import {
  HttpClient,
  HttpParams,
  HttpUrlEncodingCodec,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { environment } from "src/environments/environment";
import { DqPaymentMethod } from "../order/order.service";
import { map } from "rxjs/operators";
import { RegionsService } from "../regions/regions.service";
import { BookingList } from "src/app/models/bookings";
import { TableSeatingUpgradeOptions } from "src/app/models/table_models";

/*
{
  "status": "success",
  "getTable": [
    {
      "vc_id": "99",
      "vc_name": "Rooftop",
      "venue_id": "1",
      "number_of_tables": 4,
      "tables": [
        {
          "table_id": "181",
          "table_number": "Rooftop C1",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "1",
          "table_status": "1",
          "guest": [
            {
              "guest_id": "11176",
              "guest_type_id": "0",
              "guest_name": "TESTING CLIENT PYTHON",
              "guest_mobile_num": "",
              "guest_email": "",
              "venue_id": "1",
              "collection_id": "99",
              "guest_table_id": "181",
              "guest_user_id": "0",
              "order_id": "0",
              "status": "1",
              "group_user_id": null,
              "datetime": "26/07/2019 09:55:53 AM"
            }
          ]
        },
        {
          "table_id": "182",
          "table_number": "Rooftop C2",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "183",
          "table_number": "Rooftop C3",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "184",
          "table_number": "Rooftop C4",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        }
      ]
    },
    {
      "vc_id": "154",
      "vc_name": "VIP",
      "venue_id": "1",
      "number_of_tables": 2,
      "tables": [
        {
          "table_id": "240",
          "table_number": "VIP B1",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "154",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "241",
          "table_number": "VIP B2",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "154",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        }
      ]
    },
    {
      "vc_id": "155",
      "vc_name": "Club",
      "venue_id": "1",
      "number_of_tables": 2,
      "tables": [
        {
          "table_id": "242",
          "table_number": "Club A1",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "155",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "243",
          "table_number": "Club A2",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "155",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        }
      ]
    }
  ]
}
*/

export interface DqGuest {
  guest_id: string; // "11176";
  guest_type_id: "0";
  guest_name: string; // "TESTING CLIENT PYTHON";
  guest_mobile_num: string; // "";
  guest_email: string; // "";
  venue_id: string; // "1";
  collection_id: string; // "99";
  guest_table_id: string; // "181";
  guest_user_id: string; // "0";
  order_id: string; // "0";
  status: "0" | "1";
  group_user_id: null;
  /**
   * "26/07/2019 09:55:53 AM";
   */
  datetime: string;
}

export interface DqTableDetails {
  table_id: string; // "181",
  table_number: string; // "Rooftop C1",
  table_capacity: string; // "",
  area_id: string; // "0",
  collection_id: string; // "99",
  venue_id: string; //  "1",
  description?: string;
  total: string;
  met_goals: boolean;
  is_assigned: boolean;
  booking_id: string;
  /**
   * if table_flag === "0" the table is closed
   * if table_flag === "1" the table is open
   */
  table_flag: "0" | "1";
  table_status: "1";
  guest?: DqGuest[];
  must_close_session: boolean;
}

export interface DqCollectionPointTableDetails {
  vc_id: string; // "99",
  vc_name: string; // "Rooftop",
  venue_id: string; // "1",
  number_of_tables: number; // 4;
  tables: DqTableDetails[];
}
export interface DqTableGetResponseSuccess extends DqApiResponse {
  status: "success";
  getTable: DqCollectionPointTableDetails[];
  assignment_viewed: boolean;
}

export type DqTableGetResponse = DqTableGetResponseSuccess | null;

export interface DqTableCloseResponse {
  status: "success" | "failed";
  message: string;
}

export interface DqOpenTableParams {
  guest_name: string;
  guest_mobile_number?: string;
  guest_email?: string;
  venue_id: string;
  collection_point_id: string;
  table_id: string;
}

export interface DqOpenTableResponseSuccess {
  status: "success";
  message: string;
  guest_id: number;
  guest_details: DqGuest[];
}

export interface DqOpenTableResponseError {
  status: "failed";
  message: string;
}

export type DqOpenTableResponse =
  | DqOpenTableResponseSuccess
  | DqOpenTableResponseError;

@Injectable({
  providedIn: "root",
})
export class TableService {
  constructor(private http: HttpClient, private reg: RegionsService) {}

  getUpgradeOptions(table_id) {
    const path = `/v3/table/${table_id}/upgrade_options`;
    return this.http.get<TableSeatingUpgradeOptions>(
      `${this.reg.ServerURL()}${path}`
    );
  }

  assignPackage(table_id, body) {
    const path = `/v3/table/${table_id}/assign_package`;
    return this.http.post(`${this.reg.ServerURL()}${path}`, body);
  }

  AssignGuest(table_id, body) {
    const path = `/v3/table/${table_id}/assign_guest`;
    return this.http.post(`${this.reg.ServerURL()}${path}`, body);
  }

  getClosable(table_id) {
    const path = `/v3/table/${table_id}/close`;
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }

  closetable(table_id) {
    const path = `/v3/table/${table_id}`;
    return this.http.delete<any>(`${this.reg.ServerURL()}${path}`);
  }
  sendReciept(table_id, body) {
    const path = `/v3/table/${table_id}/send_tab_reciept`;
    return this.http.post<any>(`${this.reg.ServerURL()}${path}`, body);
  }
  sendRecieptPublic(table_id, body) {
    const path = `/v3/tab/${table_id}/send_tab_reciept`;
    return this.http.post<any>(`${this.reg.ServerURL()}${path}`, body);
  }
  getOutstanding(table_id) {
    const path = `/v3/table/${table_id}/outstanding`;
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }
  getTab(table_id) {
    const path = `/v3/table/${table_id}/finalise_tab`;
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }
  getPublicTab(tab_id) {
    const path = `/tab/${tab_id}`;
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }

  getPublicOrder(tab_id) {
    const path = `/order/${tab_id}/live_receipt`;
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }

  getPublicPayment(tab_id) {
    const path = `/payment/${tab_id}/live_receipt`;
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }

  updateMinSpend(table_id, body) {
    const path = `/v3/table/${table_id}/update_minimum_spend`;
    return this.http.post<any>(`${this.reg.ServerURL()}${path}`, body);
  }

  moveTable(table_id, body) {
    const path = `/v3/table/${table_id}/move`;
    return this.http.post<any>(`${this.reg.ServerURL()}${path}`, body);
  }

  /*
   {
  "status": "success",
  "getTable": [
    {
      "vc_id": "99",
      "vc_name": "Rooftop",
      "venue_id": "1",
      "number_of_tables": 4,
      "tables": [
        {
          "table_id": "181",
          "table_number": "Rooftop C1",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "1",
          "table_status": "1",
          "guest": [
            {
              "guest_id": "11176",
              "guest_type_id": "0",
              "guest_name": "TESTING CLIENT PYTHON",
              "guest_mobile_num": "",
              "guest_email": "",
              "venue_id": "1",
              "collection_id": "99",
              "guest_table_id": "181",
              "guest_user_id": "0",
              "order_id": "0",
              "status": "1",
              "group_user_id": null,
              "datetime": "26/07/2019 09:55:53 AM"
            }
          ]
        },
        {
          "table_id": "182",
          "table_number": "Rooftop C2",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "183",
          "table_number": "Rooftop C3",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "184",
          "table_number": "Rooftop C4",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "99",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        }
      ]
    },
    {
      "vc_id": "154",
      "vc_name": "VIP",
      "venue_id": "1",
      "number_of_tables": 2,
      "tables": [
        {
          "table_id": "240",
          "table_number": "VIP B1",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "154",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "241",
          "table_number": "VIP B2",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "154",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        }
      ]
    },
    {
      "vc_id": "155",
      "vc_name": "Club",
      "venue_id": "1",
      "number_of_tables": 2,
      "tables": [
        {
          "table_id": "242",
          "table_number": "Club A1",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "155",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        },
        {
          "table_id": "243",
          "table_number": "Club A2",
          "table_capacity": "",
          "area_id": "0",
          "collection_id": "155",
          "venue_id": "1",
          "table_flag": "0",
          "table_status": "1"
        }
      ]
    }
  ]
}

   */
  getAllTables({ venue_id }: { venue_id: string }) {
    const path = "/v3/tables";
    return this.http.get<DqTableGetResponse>(`${this.reg.ServerURL()}${path}`, {
      params: { venue_id },
    });
  }

  getAssignedTables() {
    const path = "/v3/assignments";
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`);
  }

  clearAssignments() {
    const path = "/v3/table/assignments";
    return this.http.delete<any>(`${this.reg.ServerURL()}${path}`);
  }

  openTable({
    guest_name,
    guest_email,
    guest_mobile_number,
    venue_id,
    table_id,
    collection_point_id,
  }: DqOpenTableParams) {
    const path = "/v4/table/" + table_id;
    /**
     * params = ?guest_name=Guest&guest_mobile_number=0411111111&guest_email=a@b.com&venue_id=1&collection_id=99&guest_table_id=184&date=31/07/2019%2003:51%20PM
     */
    const date = DateTime.local().toFormat("dd/LL/yyyy hh:mm a");
    return this.http.post(
      `${this.reg.ServerURL()}${path}`,
      {
        venue_id,
          table_id: table_id,
   
        
      }
    );
  }


  seatTable({
    venue_id,
    table_id,
  }) {
    const path = "/v1/seat";
    /**
     * params = ?guest_name=Guest&guest_mobile_number=0411111111&guest_email=a@b.com&venue_id=1&collection_id=99&guest_table_id=184&date=31/07/2019%2003:51%20PM
     */
    return this.http.get<DqOpenTableResponse>(
      `${this.reg.ServerURL()}${path}`,
      {
        params: {
          venue_id,
          table_id,
        },
        
      }
    );
  }

  closeTableAndPay({
    venue_id,
    collection_point_id,
    table_number,
    guest_id,
    order_id,
    payment_method,
    guest_table_id,
    tax,
    gratuity,
    total,
    square_transaction_id,
    square_client_transaction_id,
  }: {
    venue_id: string;
    order_id: string;
    payment_method: DqPaymentMethod;
    gratuity: number;
    tax: number;
    total: number;
    guest_id: string;
    table_number: string;
    collection_point_id: string;
    guest_table_id: string;
    square_transaction_id?: string;
    square_client_transaction_id?: string;
  }) {
    const params = {
      o_payment_method: payment_method, // Cash,
      // PayPalTransactionId:,
      o_gratuity: gratuity.toFixed(2),
      o_tax: tax.toFixed(2),
      // o_discount:0.0,
      o_total: total.toFixed(2),
      // promo_id:,
      // o_promo_code:,
      // o_discount_type:,
      // o_authorization_id:,
      // o_refresh_token:,
      // o_CredCard_trans_id:,
      o_id: order_id, // 32445,
      guest_id, // :11443,
      table_number, // :Rooftop%20C2,
      collection_id: collection_point_id, // 99,
      venue_id, // 1,
      guest_table_id, //182,
      square_client_transaction_id,
      square_transaction_id,
    };
    const httpParams = Object.keys(params).reduce<HttpParams>(
      (acc, key) => acc.set(key, params[key]),
      new HttpParams()
    );
    // o_id=32445&guest_id=11443&table_number=Rooftop%20C2&collection_id=99&venue_id=1&guest_table_id=182
    const path = "/admin/master/WS/hotel_ws/close_table_v1.php";
    return this.http
      .get<DqTableCloseResponse>(`${this.reg.ServerURL()}${path}`, {
        params: httpParams,
      })
      .pipe(
        map(
          ({ status, message }) =>
            ({
              status: status.toLowerCase(),
              message,
            } as DqTableCloseResponse)
        )
      );
  }
}
