import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  name: "secondsmin",
})
export class SecondsMinutesPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    if (minutes === 0) {
      return value.toString().padStart(2, '') + 's';
    
    }
    return minutes.toString().padStart(2, '') + 'm ' + 
        (value - minutes * 60).toString().padStart(2, '') + 's';
 }
}
