import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { RegionsService } from "src/app/services/regions/regions.service";

@Component({
  selector: "app-table-card",
  templateUrl: "./table-card.component.html",
  styleUrls: ["./table-card.component.scss"],
})
export class TableCardComponent implements OnInit {
  @Input() tableDetail: DqTableDetails;
  @Input() subscriptions: DqNotificationSubscription[];

  showNotificationButtons = true; //this.platform.is("capacitor"); TODO: undo this

  public NonFavTables: DqTableDetails[];
  public FavTables: DqTableDetails[] = [];

  @Output() addSubscription = new EventEmitter<DqTableDetails>();
  @Output() removeSubscription = new EventEmitter<DqTableDetails>();
  isTablet: boolean = this.platform.is("tablet");

  constructor(
    private loaderCtrl: LoadingController,
    private router: Router,
    public modalController: ModalController,
    private tableFacade: TablesFacadeService,
    private activatedRoute: ActivatedRoute,
    public alertController: AlertController,
    private ordersV2Svc: OrderV2Service,
    private store$: Store<State>,
    private changeDetector: ChangeDetectorRef,
    private analy: AnalyticsService,
    private platform: Platform,
    private region: RegionsService
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  async presentOpenTableModal(table: DqTableDetails, $event: CustomEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    const modal = await this.modalController.create({
      component: OpenTableModalPage,
      cssClass: "open-table-modal",
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      await this.tableFacade.openTable(data, table);
      if (!this.hasActiveSubscription(table.table_id)) {
        this.addSubscriptionClicked(table, $event);
      }
    }
  }

  async viewMenu(table: DqTableDetails) {
    if (
      table.table_flag === "1" &&
      this.tableFacade.tableNeedsToBeClosed(table)
    ) {
      await this.promptToCloseTable(table);
    } else if (table.table_flag === "1") {
      this.analy.addEvent("View Table", table);
      if (this.isTablet) {
        this.router.navigate([
          this.region.getRegion(),
          "tab",
          "dashboard",
          "t",
          table.table_id,
        ]);
      } else {
        await this.router.navigate([
          this.region.getRegion(),
          "table",
          table.table_id,
        ]);
      }
    }
  }

  async promptToCloseTable(table: DqTableDetails) {
    const alert = await this.alertController.create({
      header: `${table.table_number} must be closed`,
      subHeader: `This table needs to be closed. Do you want to close it?`,
      buttons: [
        { text: "No", role: "cancel", cssClass: "secondary" },
        {
          text: "Yes",
          handler: () => {
            this.closeTable(table);
          },
        },
      ],
    });

    await alert.present();
  }

  async presentCloseTableConfirm(table: DqTableDetails, $event: CustomEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    const alert = await this.alertController.create({
      header: `Closing table ${table.table_number}`,
      message: "Do you want to close this table?",
      buttons: [
        {
          text: "No",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Yes",
          handler: () => {
            this.closeTable(table);
          },
        },
      ],
    });

    await alert.present();
  }

  hasActiveSubscription(tableId: string) {
    return (
      this.subscriptions &&
      this.subscriptions.some((sub) => sub.tableId === tableId)
    );
  }

  addSubscriptionClicked(table: DqTableDetails, ev: CustomEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    this.addSubscription.emit(table);
  }

  removeSubscriptionClicked(table: DqTableDetails, ev: CustomEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    this.removeSubscription.emit(table);
  }

  async closeTable(table: DqTableDetails) {
    const loader = await this.loaderCtrl.create();
    await loader.present();
    this.ordersV2Svc
      .getTableUnpaidOrders({ tableId: table.table_id })
      .subscribe(async (response) => {
        if (+response.unpaid_total === 0) {
          return this.store$.dispatch(
            closeTable({
              table,
              onComplete: () => {
                loader.dismiss();
              },
            })
          );
        } else {
          console.log("Redirect Closing");
          await loader.dismiss();
          this.router.navigate(["../../table", table.table_id, "close"], {
            relativeTo: this.activatedRoute,
          });
        }
      });
  }
}
