import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { subscribeToTableNotificationsError } from "src/app/actions/fcm-api.actions";
import {
  clearSubscriptionsAfterLogout,
  loadSavedSubscriptions,
  pushNotificationRegistrationError,
  pushNotificationRegistrationSuccess,
  subscribeToTableNotifications,
  unsubscribeToTableNotifications,
} from "src/app/actions/notification.actions";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";

export interface State extends EntityState<DqNotificationSubscription> {
  // additional entities state properties
  deviceToken: string;
  registrationComplete: boolean;
}

export const adapter = createEntityAdapter<DqNotificationSubscription>({
  selectId: ({ venueId, tableId }) => getSubscriptionId(venueId, tableId),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  deviceToken: null,
  registrationComplete: false,
});

const notificationReducer = createReducer(
  initialState,
  on(
    loadSavedSubscriptions,
    (state, { subscriptions }): State =>
      adapter.addMany(subscriptions || [], state)
  ),
  on(subscribeToTableNotifications, (state, { venueId, tableId }) =>
    adapter.upsertOne({ venueId, tableId }, state)
  ),
  on(subscribeToTableNotificationsError, (state, { venueId, tableId }) =>
    adapter.removeOne(getSubscriptionId(venueId, tableId), state)
  ),
  on(unsubscribeToTableNotifications, (state, { venueId, tableId }) =>
    adapter.removeOne(getSubscriptionId(venueId, tableId), state)
  ),
  on(pushNotificationRegistrationSuccess, (state, { registrationId }) => ({
    ...state,
    deviceToken: registrationId,
    registrationComplete: true,
  })),
  on(pushNotificationRegistrationError, (state) => ({
    ...state,
    registrationComplete: true,
  })),
  on(clearSubscriptionsAfterLogout, (state) => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return notificationReducer(state, action);
}

function getSubscriptionId(venueId: string, tableId: string) {
  return `${venueId}-${tableId}`;
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectDeviceToken = (state: State) => state.deviceToken;
export const selectRegistrationComplete = (state: State) =>
  state.registrationComplete;
