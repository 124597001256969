import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1Booking, SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { EntryState } from "src/app/services/sync-engine/guest-entry/entry.service";
@Component({
  selector: "app-list-chip",
  templateUrl: "./list-chip.component.html",
  styleUrls: ["./list-chip.component.scss"],
})
export class ListChipComponent implements OnInit {
  @Input() booking: SyncV1Booking;
  @Input() interactable = true;
  @Output() clicked = new EventEmitter();
  
  entrys_seated$ = (id) =>
  this.entrySt.entrys_for_booking$(id).pipe(
    map((x) => {
      //check all the entries are seated
      let seated = 0;
      x.map((y) => {
        if (y.arrived == true) {
          seated += 1;
        }
      });
      return seated;
    })
  );

  constructor(
    private region: RegionsService,
    private entrySt: EntryState
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  

  
}
