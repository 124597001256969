import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { TagSearchComponent } from "../tag-search/tag-search.component";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

@Component({
  selector: "app-tag-item",
  templateUrl: "./tag-item.component.html",
  styleUrls: ["./tag-item.component.scss"],
})
export class TagItemComponent implements OnInit, OnChanges {
  @Input() title = "";
  @Input() tags = [];
  showableTags = [];
  @Input() subtitle;
  @Input() wrap = true;
  @Input() reduced = false;
  @Input() tiny = false;
  @Input() editios = false;

  @Input() edit = false;
  @Input() outsource = false;
  @Input() color = "medium";

  @Output() tagsUpdated = new EventEmitter<string[]>();

  @Output() tagClicked = new EventEmitter<SyncV1TagInstance>();

  constructor(
    private region: RegionsService,
    private tagSt: TagState,
    private modal: ModalController
  ) {}

  ngOnInit() {
    //log output of tableDetail
    //  <ion-note slot="end" *ngIf="subtitle != undefined">{{ subtitle }}</ion-note>
  }

  ngOnChanges() {
    //if tags is an array of strings
    if (this.tags != undefined && typeof this.tags[0] == "string") {
      this.showableTags = this.tags.map((tag) => {
        return this.tagSt.state.get(tag);
      });
      return;
    }

    this.showableTags = this.tags;
    //order by strength value if it exists (values without strength last)
    this.showableTags = this.showableTags.sort((a, b) => {
      if (a.strength != undefined && b.strength != undefined) {
        return b.strength - a.strength;
      } else if (a.strength != undefined) {
        return -1;
      } else if (b.strength != undefined) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  async editTags() {
    if (this.outsource) {
      this.tagClicked.emit();
      return;
    }

    Haptics.impact({
      'style': ImpactStyle.Light
    })

    let mod = await this.modal.create({
      component: TagSearchComponent,
      componentProps: {
        tags: this.showableTags.map((x) => {
          return x.tag_id;
        }),
      },
      presentingElement: await this.modal.getTop(),
    });

    await mod.present();

    let res = await mod.onDidDismiss();
    // check result is of array type
    if (res.data != undefined && Array.isArray(res.data)) {
      this.tagsUpdated.emit(res.data);
      console.log("res.data", res.data);
    }
  }

  removeTag(tag) {
    console.log("remove tag", tag, this.tags);
    let t = this.showableTags.filter((x) => x.tag_id != tag.tag_id);
    console.log("Reduced tag list", t);
    t = t.map((x) => {
      return x.tag_id;
    });
    this.tagsUpdated.emit(t);
  }

  trackByTag(index, item) {
    return item.tag_id;
  }
}
