<app-wrapper>
  <div #width class="ion-margin-vertical">
    <ion-row class="ion-margin-horizontal">
      <ion-col
        (click)="metric.description != undefined ? pop.present($event) : ''"
        [id]="metric.title"
        [size]="12 / metrics.length"
        *ngFor="let metric of metrics; let i = index"
        [class]="
          i == 0
            ? 'ion-text-start'
            : i == metrics.length - 1
            ? 'ion-text-end'
            : 'ion-text-center'
        "
      >
        <ion-label> {{ metric.title }} </ion-label>
        <h2 *ngIf="metric.value != undefined; else big_loading">
          {{ metric.value }}
          <div
            class="change"
            [class.angry]="
              metric.inverted ? metric.change > 0 : metric.change <= 0
            "
            [class.happy]="
              metric.inverted ? metric.change <= 0 : metric.change > 0
            "
            *ngIf="metric.change != undefined"
          >
            {{
              (metric.change >= 0 ? "+" : "") + metric.change | number: ".0-0"
            }}%
          </div>
        </h2>
        <ion-note *ngIf="metric.value != undefined; else little_loading">{{
          metric.subtitle
        }}</ion-note>
        <ion-col
          *ngIf="metric.gender_male != undefined"
          class="bbh"
          [class]="
            i == 0 ? '' : i == metrics.length - 1 ? 'col-end' : 'col-cent'
          "
          size="10"
        >
          <div
            [style.left.px]="0"
            [style.width.%]="metric.gender_male"
            class="mal"
          ></div>
          <div
            class="fem"
            [style.left.%]="metric.gender_female"
            [style.width.%]="metric.gender_female"
          ></div>
        </ion-col>
        <ion-popover
          #pop
          [trigger]="metric.title"
          triggerAction="context-menu"
          [backdropDismiss]="true"
        >
          <ng-template>
            <ion-list class="ion-padding-vertical">
              <ion-item lines="none">
                {{ metric.description }}
              </ion-item>
            </ion-list>
          </ng-template>
        </ion-popover>
      </ion-col>
    </ion-row>
    <br
      *ngIf="chart_title != undefined && metrics.length > 0"
      class="ion-margin-top"
    />
    <ion-row
      *ngIf="chart_title != undefined"
      class="padding-h ion-justify-content-between ion-margin-horizontal"
    >
      <h2 [style.margin-top.px]="0">{{ chart_title }}</h2>
      <h2 [style.margin-top.px]="0">{{ chart_value }}</h2>
    </ion-row>

    <ion-skeleton-text
      *ngIf="
        chart_comiing &&
        date_line_chart == undefined &&
        line_chart == undefined &&
        bar_chart == undefined
      "
      [animated]="true"
      [style.margin-top.px]="10"
      class="widthc"
      [style.margin-right.px]="15"
      [style.margin-left.px]="15"
      [style.height.px]="170"
    ></ion-skeleton-text>

    <ngx-charts-bar-vertical
      class="ion-margin-top"
      *ngIf="bar_chart != undefined"
      [view]="[width.offsetWidth, 150]"
      [scheme]="colorScheme"
      [legend]="false"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxis]="true"
      [yAxis]="true"
      xAxisLabel="Last Month"
      [yAxisLabel]="''"
      [results]="bar_chart"
      [gradient]="true"
      [showGridLines]="false"
      [xAxisTickFormatting]="dateTickFormatting"
      [yAxisTickFormatting]=""
    ></ngx-charts-bar-vertical>
    <ngx-charts-line-chart
      class="ion-margin-top"
      *ngIf="line_chart != undefined"
      [view]="[width.offsetWidth, 200]"
      [scheme]="colorScheme"
      [legend]="false"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxis]="true"
      [yAxis]="true"
      xAxisLabel="Last Month"
      [yAxisLabel]="''"
      [showRefLines]="true"
      [showRefLabels]="true"
      [trimXAxisTicks]="true"
      yScaleMin="0"
      [yScaleMax]="max_value"
      [referenceLines]="
        target != undefined
          ? [
              {
                name: 'target',
                value: target
              }
            ]
          : []
      "
      [results]="[
        {
          name: 'Over Time',
          series: line_chart
        }
      ]"
      [gradient]="true"
      [showGridLines]="false"
      style="fill: white"
      [xAxisTickFormatting]="dateTickFormatting"
    ></ngx-charts-line-chart>
    <ngx-charts-line-chart
      class="ion-margin-top"
      *ngIf="date_line_chart != undefined"
      [view]="[width.offsetWidth, 200]"
      [scheme]="colorScheme"
      [legend]="false"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxis]="true"
      [yAxis]="true"
      xAxisLabel="Last Month"
      [yAxisLabel]="''"
      [showRefLines]="true"
      [showRefLabels]="true"
      [trimXAxisTicks]="true"
      yScaleMin="0"
      [yScaleMax]="max_value"
      [activeEntries]=""
      [referenceLines]="
        target != undefined
          ? [
              {
                name: 'target',
                value: target
              }
            ]
          : []
      "
      [results]="date_line_chart == undefined ? [] : date_line_chart"
      [gradient]="true"
      [showGridLines]="false"
      style="fill: white"
      [xAxisTickFormatting]="bigdateTickFormatting"
    ></ngx-charts-line-chart>
  </div>
</app-wrapper>

<ng-template #big_loading>
  <ion-skeleton-text
    [animated]="true"
    [style.margin-top.px]="10"
    [style.width.px]="120"
    [style.height.px]="30"
  ></ion-skeleton-text>
</ng-template>
<ng-template #little_loading>
  <ion-skeleton-text
    [animated]="true"
    [style.margin-top.px]="10"
    [style.width.px]="60"
    [style.height.px]="23"
  ></ion-skeleton-text>
</ng-template>
