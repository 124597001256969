import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActionSheetController,
  AlertController,
  IonRefresher,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
  ViewDidLeave,
} from "@ionic/angular";
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  interval,
  of,
  Subject,
  timer,
} from "rxjs";
import {
  catchError,
  concatMap,
  concatMapTo,
  filter,
  finalize,
  map,
  pluck,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { ConsumerCartFacadeService } from "src/app/facades/consumer-cart-facade/consumer-cart-facade.service";
import { EventService } from "src/app/services/event/event.service";
import {
  DqOrderStateChange,
  DqOrderStatus,
  OrderService,
} from "src/app/services/order/order.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { TreatMeDataService } from "src/app/services/treat-me-data/treat-me-data.service";
import { environment } from "src/environments/environment";
import {
  ConsumerOrderService,
  DqConsumerOrderItem,
  DqConsumerOrderSummary,
  DqTrascationDetailsResponse,
} from "../../pages/consumer-order-history/services/consumer-order.service";
import * as confetti from "canvas-confetti";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { PaymentService } from "src/app/services/payment/payment.service";
import { OrderState } from "src/app/services/sync-engine/order-state/order.service";
import { Haptics } from "@capacitor/haptics";

const COMPLETE_ORDER: DqOrderStatus[] = ["Cancelled", "Delivered"];
import mixpanel from "mixpanel-browser";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { PackageState } from "src/app/services/sync-engine/package-state/package.service";
import { SessionState } from "src/app/services/sync-engine/sessions-state/session.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { EditGuestComponent } from "../edit-guest/edit-guest.component";
import { BookingState } from "src/app/services/sync-engine/booking-state/booking.service";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent
  implements OnInit, AfterViewInit, OnDestroy, ViewDidLeave
{
  @Input() orderId$ = "";

  isVenueUser = !(window.location.href.includes('treat') || window.location.href.includes('book'))

  showHomeButton = this.activatedRoute.snapshot.data.showHomeButton ?? true;
  defaultHref = "/" + this.region.getRegion() + "/orders";
  refresh$ = new BehaviorSubject<IonRefresher>(undefined);
  ionViewDidLeave$ = new Subject<void>();
  giftStatusInt: number = 0;
  @ViewChild("confettiboy", { read: ElementRef }) confettiRef: ElementRef;
  order$ = this.orderSt.order$(this.orderId$);
  orderActiveStep;
  guest$ = this.guestSt.guest$;
  pageTitle$ = this.order$.pipe(map((order) => `Order #${order.cp_id}`));
  canCancelOrder$ = this.order$.pipe(map((transaction) => true));
  package$ = this.packageSt.package$;

  homeButtonLink$ = this.order$.pipe(
    concatMap((transaction) => {
      return of(["/location", transaction.table_id]);
    })
  );

  isTreatMeOrder = false;

  showLightTheme$ = this.isTreatMeOrder;

  treatMeDetails;
  isTablet = this.platform.is("tablet") || this.platform.is("desktop");



  treatVenue;

  @Output() paymentOpen = new EventEmitter<string>();
  currency$ = this.settingSt.currencyCode$;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private actionSheetCtrl: ActionSheetController,
    private activatedRoute: ActivatedRoute,
    private alertCtrl: AlertController,
    private cartFacade: ConsumerCartFacadeService,
    private consumerOrderSvc: ConsumerOrderService,
    private eventSvc: EventService,
    private loadingCtrl: LoadingController,
    private orderSvc: OrderService,
    private router: Router,
    private region: RegionsService,
    private toastCtrl: ToastController,
    private treatMeDataSvc: TreatMeDataService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    public menu: MenuController,
    public platform: Platform,
    private analy: AnalyticsService,
    private paymentSvc: PaymentService,
    private orderSt: OrderState,
    private settingSt: SettingsState,
    private guestSt: GuestState,
    private recieptSt: RecieptState,
    private packageSt: PackageState,
    private tabSt: SessionState,
    private tableSt: TableState,
    private modalCtrl: ModalController,
    private bookingSt: BookingState
  ) {
    console.log("Test Order Detail", this.orderId$);

    this.order$.subscribe((X) => {
      console.log("Test Order Detail", X);
    });
  }

  OpenPayments(event) {
    //emit event ouit
    this.analy.addEvent("Open Reciept From Order", { info: event });
    this.paymentOpen.emit(event);
  }

  async openPackage() {
    let currentTab = await this.getTabSession();
    if (currentTab == undefined) {
      return;
    }
    if (!this.isTablet) {
      this.router.navigate([
        this.region.getRegion(),
        "bookings",
        currentTab.booking_id,
      ]);
    } else {
      this.router.navigate([
        this.region.getRegion(),
        "bookings",
        "b",
        currentTab.booking_id,
      ]);
    }
  }

  async getTabSession() {
    //get the order
    let order = await this.order$.pipe(take(1)).toPromise();
    console.log("order", order);

    //get the receipt
    let receipt = await this.recieptSt
      .receipt$(order.receipt_id)
      .pipe(take(1))
      .toPromise();
    console.log("receipt", receipt);
    if (receipt == undefined) {
      return;
    }
    await this.bookingSt.InitStore();
    if (receipt.seat_id_managing == undefined) {
      //is there a booking that uses this receipt
      let bookings = await this.bookingSt.bookings$.pipe(take(1)).toPromise();
      let booking = bookings.find((x) => x.receipt_id == receipt.receipt_id);
      if (booking == undefined) {
        return;
      }
      return booking;
    } else {
      //get the tab session
      let tab_session = await this.tabSt
        .session$(receipt?.seat_id_managing)
        .pipe(take(1))
        .toPromise();
      return tab_session;
    }
  }

  async openTable() {
    let currentTab = await this.getTabSession();
    if (currentTab == undefined) {
      return;
    }
    if (!this.isTablet) {
      this.router.navigate([
        this.region.getRegion(),
        "table",
        currentTab.table_id,
      ]);
    } else {
      this.router.navigate([
        this.region.getRegion(),
        "table",
        "t",
        currentTab.table_id,
      ]);
    }
  }

  async openGuest() {
    let currentTab = await this.getTabSession();
    if (currentTab == undefined) {
      return;
    }
    let md = await this.modalCtrl.create({
      component: EditGuestComponent,
      presentingElement: this.elementRef.nativeElement.parentElement,
      componentProps: {
        guest_id: currentTab.guest_id,
      },
    });
    await md.present();
  }

  refreshing;
  ngOnInit() {
    this.order$ = this.orderSt.order$(this.orderId$);
    this.guestSt.InitStore();
    this.orderSt.InitStore();
    this.recieptSt.InitStore();

    const { stripeResult } = this.activatedRoute.snapshot.data;

    if (stripeResult === "success") {
      this.cartFacade.clearCartForTable();
      this.router.navigate(
        [
          this.region.getRegion(),
          "orders",
          this.activatedRoute.snapshot.params.orderId,
        ],
        { replaceUrl: true, queryParamsHandling: "merge" }
      );
    }
    let firsttime = true;
    this.refresh$.subscribe(async (refresher) => {
      let f = this.getData().then(() => {
        try {
          refresher?.complete();
        } catch (e) {}
      });
      if (firsttime) {
        await f;
        await this.getData();
      }
    });

    //every 5 seconds send a request to get the latest order status
    this.refreshing = interval(environment.orderAutoRefreshInterval).subscribe(
      async () => {
        if (firsttime) {
          firsttime = false;
          return;
        }

        console.log("refreshing");
        await this.getData();
      }
    );

    this.settingSt.hasClaim$('operations').subscribe((user) => {
      if (user) {
        this.isVenueUser = true;
      }

      console.log("this.isVenueUser", this.isVenueUser);
    });

    this.packageSt.InitStore();
    this.tabSt.InitStore();
    this.tableSt.InitStore();
  }

  async getData() {
    if (
      !window.location.href.includes(this.orderId$) &&
      this.paymentSvc.selected_order != this.orderId$
    ) {
      console.log("Leaving");
      this.refreshing.unsubscribe();
      return;
    }
  }

  async ngAfterViewInit() {
    await new Promise((f) => setTimeout(f, 1000));
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.gift == "true") {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
        });

        const canvas = this.renderer2.createElement("canvas");

        this.renderer2.appendChild(this.confettiRef.nativeElement, canvas);
      }
    });
  }

  async voidItem(item) {
    //show a alert to confirm the void
    //if quantity is greater than 0 add a slider to select the quantity to void
    Haptics.vibrate();
    let al = {
      header: "Confirm Void",
      message: "Are you sure you want to void this item?",

      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {},
        },
        {
          text: "Void",
          role: "destructive",
          cssClass: "dangerbutton",
          handler: async (result) => {
            Haptics.impact();
            console.log("result", result, item);
            if (!result) result = item.quantity;
            await this.orderSvc
              .voidItem(item.void_url, result, item.quantity)
              .toPromise();
            this.analy.addEvent("Item.Voided", { info: item });
            this.refresh$.next(null);
          },
        },
      ],
      inputs: [],
    };

    if (item.quantity > 1) {
      al.message = "Select the quantity to void";
      console.log("item", item);
      //create radio buttons for each quantity
      for (let i = 1; i <= item.quantity; i++) {
        console.log("i", i);
        al.inputs.push({
          name: "quantity",
          type: "radio",
          label: i.toString(),
          value: i,
        });
      }
    }
    const alert = await this.alertCtrl.create(al);

    await alert.present();
  }

  ngOnDestroy() {
    this.ionViewDidLeave$.complete();
  }

  ionViewDidLeave() {
    this.ionViewDidLeave$.next();
  }

  disableSubscibe() {
    this.ionViewDidLeave$.next();
  }

  getItemId(_: number, item: DqConsumerOrderItem) {
    return item.item_id;
  }

  getOrderId(_: number, order: DqConsumerOrderSummary) {
    return order.o_id;
  }

  async cancelOrder() {
    const loader = await this.loadingCtrl.create();
    await loader.present();
    this.analy.addEvent("Cancel Order", { info: "Cancel Order" });
    try {
      await this.consumerOrderSvc.cancelOrder(this.orderId$).toPromise();
      loader.dismiss();

    
      this.refresh$.next(null);

      let order = await this.order$.pipe(take(1)).toPromise();

      let receipt = await this.recieptSt
      .receipt$(order.receipt_id)
      .pipe(take(1))
      .toPromise();
      if (receipt.outstanding < receipt.total) {
        const alert = await this.alertCtrl.create({
          header: 'Payments Present',
          message: 'Payments have been taken on this order. You may need to refund these.',
          buttons: [{
            text: 'Acknowledged',
            role: 'destructive',
            handler: () => {
              alert.dismiss();
            }
          }]
        });
        await alert.present();
      }
    } catch (error) {
      this.showCancelErrorToast(error.error);
    }
  }

  async showOptionsActionSheet() {
    const buttons = [
      {
        text: "Email Receipt",
        handler: () => {
          this.showEmailReceiptAlert();
        },
      },
      {
        text: "Close",
        role: "cancel",
        handler: () => {
          sheet.dismiss();
        },
      },
    ];

      buttons.splice(0, 0, {
        text: "Cancel your order?",
        role: "destructive",
        handler: async () => {
          await sheet.dismiss();
          this.confirmCancelOrder();
        },
      });
    
    const sheet = await this.actionSheetCtrl.create({
      buttons,
    });

    sheet.present();
  }

  private async confirmCancelOrder() {
    const as = await this.actionSheetCtrl.create({
      header: "Are you sure you want to cancel your order?",
      buttons: [
        {
          text: "Yes, cancel the order",
          role: "destructive",
          handler: () => this.cancelOrder(),
        },
        { text: "No, don't cancel the order", role: "cancel" },
      ],
    });
    await as.present();
  }

  private async showCancelErrorToast(errorMessage: string) {
    const toast = await this.toastCtrl.create({
      message:
        errorMessage ||
        "Unable to cancel order, please try again or contact the venue",
      duration: environment.defaultToastDuration,
      position: "top",
      buttons: [
        {
          role: "cancel",
          icon: "close-circle-outline",
          cssClass: "toast-close-button",
        },
      ],
    });
    await toast.present();
  }

  private async showEmailReceiptAlert() {
    const alert = await this.alertCtrl.create({
      header: "Email Receipt",
      inputs: [
        {
          name: "email",
          type: "email",
          placeholder: "Enter your email address",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Send Receipt",
          handler: async (data) => {
            data?.email && (await this.sendEmailReceipt(data?.email));
          },
        },
      ],
    });

    await alert.present();
  }

  private async sendEmailReceipt(email: string) {
    try {
      await this.consumerOrderSvc
        .emailReceipt({
          transactionUuid: this.orderId$,
          email,
        })
        .toPromise();
      this.showEmailReceiptSuccessToast();
    } catch (error) {
      this.showEmailReceiptErrorToast();
    }
  }

  private async showEmailReceiptSuccessToast() {
    const toast = await this.toastCtrl.create({
      message: "The receipt was emailed to you.",
      duration: environment.defaultToastDuration,
      position: "top",
      buttons: [
        {
          role: "cancel",
          icon: "close-circle-outline",
          cssClass: "toast-close-button",
        },
      ],
    });

    await toast.present();
  }

  private async showEmailReceiptErrorToast() {
    const toast = await this.toastCtrl.create({
      message:
        "Sorry, we couldn't email the receipt to you. Please check your email address and try again.",
      duration: environment.defaultToastDuration,
      position: "top",
      buttons: [
        {
          role: "cancel",
          icon: "close-circle-outline",
          cssClass: "toast-close-button",
        },
      ],
    });
    await toast.present();
  }

  async orderStateButtonClicked() {
    this.analy.addEvent("Bump Order Manually", { info: "Change Status" });
    //create order
    let gift_Status;
    let options = {
      "For Approval": ["Accept", "Reject"],
      Pending: ["Preparing", "Ready", "Delivered", "Cancelled"],
      Preparing: ["Pending", "Ready", "Delivered", "Cancelled"],
      Ready: ["Delivered", "Cancelled"],
      Delivered: ["Cancelled"],
    };

    //get current order status
    let order = await this.order$.pipe(take(1)).toPromise();

    const actionUrl = await this.orderSvc.orderStateActionSheet(
      options[order.current_column + ""]
    );

    Haptics.selectionStart();

    if (actionUrl && actionUrl != "cancelSelectedChoice") {
      const spinner = document.getElementById("statusSpinner");
      spinner.classList.remove("hide");
      this.orderSvc
        .changeOrderStatus(order.o_uuid, {
          old_state: (order.current_column as any).toLowerCase(),
          new_state: (actionUrl as any).toLowerCase(),
          o_ids: [order.order_id],
        })
        .pipe(take(1))
        .subscribe(async (response) => {
          Haptics.impact();

          this.refresh$.next(null);
          spinner.classList.add("hide");
          console.log("actionUrl", actionUrl);
          if (actionUrl == 'Cancelled') {

            let receipt = await this.recieptSt
            .receipt$(order.receipt_id)
            .pipe(take(1))
            .toPromise();

            const alert = await this.alertCtrl.create({
              header: 'Payments Present',
              message: 'Payments have been taken on this order. You may need to refund these.',
              buttons: [{
                text: 'Acknowledged',
                role: 'destructive',
                handler: () => {
                  alert.dismiss();
                }
              }]
            });
            await alert.present();

          }

        });
    }
  }
}
