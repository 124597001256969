<ng-container *ngrxLet="guest$ | async as guest">
  <ion-header *ngIf="header">
    <ion-toolbar *ngIf="guest != undefined">
      <ion-title *ngIf="guest.guest_id == '0'">Create Guest</ion-title>
      <ion-title *ngIf="guest.guest_id != '0'">Edit Guest</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismiss()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content
    class="thinmodal transparentmodal"
    color=""
    class=""
    *ngIf="guest != undefined"
  >
    <ion-list class="tertlist" lines="none">
      <ion-item lines="none" class="guestform" (click)="namesel.setFocus()">
        <ion-label>
          <ion-input
          #namesel
            [style.min-width.px]="200"
            [style.pointer-events]="'all'"
            class="large ion-text-capitalize"
            placeholder="Guest Name"
            [(ngModel)]="guest.name"
            inputmode="text"
            autocapitalize="words"
            enterkeyhint="done"

            (ngModelChange)="debugt()"
          ></ion-input>

          <app-guest-status
            class="ion-padding-start padding-top-neg"
            [guest]="guest"
            [transparent]="true"
            >{{ guest.status }}</app-guest-status
          >
        </ion-label>

        <div slot="end" class="tagHolder">
          <app-tag
            *ngFor="let tag of shortened_tags$ | async; trackBy: trackByTagID"
            [tag]="tag"
          >
          </app-tag>
        </div>
      </ion-item>

      <div class="ion-margin-top"></div>
      <div class="ion-padding-start ion-padding-top ion-padding-end">
        <ion-segment
          class=""
          [(ngModel)]="page"
          (ngModelChange)="viewing(page)"
        >
          <ion-segment-button value="info"> Information </ion-segment-button>
          <ion-segment-button
            value="history"
            [disabled]="guest.guest_id == '0'"
            (click)="timeoutexpand()"
          >
            History
          </ion-segment-button>
          <ion-segment-button
            value="metrics"
            [disabled]="guest.guest_id == '0'"
          >
            Metrics
          </ion-segment-button>
        </ion-segment>
      </div>
      <ng-container>
        <ion-col *ngIf="page == 'metrics'" class="ion-padding">
          <ng-template #loading>
            <ion-skeleton-text
              [style.width]="'auto'"
              [style.height.px]="105"
              [animated]="true"
              class="ion-margin-bottom"
            ></ion-skeleton-text>
            <ion-skeleton-text
              [style.width]="'auto'"
              [style.height.px]="300"
              [animated]="true"
              class="ion-margin-bottom"
            ></ion-skeleton-text>
          </ng-template>
          <ng-template #loadingtags>
            <ion-skeleton-text
              [style.width]="'auto'"
              [style.height.px]="350"
              [animated]="true"
              class="ion-margin-bottom"
            ></ion-skeleton-text>
          </ng-template>
          <div
            class="ion-margin-horizontal gap"
            *ngrxLet="guest_metrics$ | async as metrics"
          >
            <app-wrapper
              *ngIf="(guest_tags$ | async) != undefined; else loadingtags"
            >
              <div class="ion-margin-vertical">
                <app-tag-item
                  title="Manual Tags"
                  [edit]="true"
                  [tags]="guest.tags"
                  (tagsUpdated)="guest.tags = $event; EditedTags = true"
                ></app-tag-item>
                <app-tag-groups [tags$]="guest_tags$" [show_manual]="true">
                </app-tag-groups>
              </div>
            </app-wrapper>

            <dq-metric
              [metrics]="[
                {
                  title: 'Total Spend',
                  value:
                    metrics?.total_spend_currency == undefined
                      ? undefined
                      : (metrics?.total_spend_currency
                        | currency: (currency$ | async):'symbol':'1.0-0'),
                  subtitle:
                    metrics?.total_tips_currency == undefined
                      ? undefined
                      : (metrics?.total_tips_currency
                          | currency: (currency$ | async):'symbol':'1.0-0') +
                        ' Tips'
                },
                {
                  title: 'Avrg Spend',
                  value:
                    metrics?.ave_spend_currency == undefined
                      ? undefined
                      : (metrics?.ave_spend_currency
                        | currency: (currency$ | async):'symbol':'1.0-0'),
                  subtitle:
                    metrics?.ave_spend_currency == undefined
                      ? undefined
                      : 'Commits ' +
                        (metrics.upfront_commitment_min_currency +
                          metrics.upfront_commitment_max_currency) /
                          2
                },
                {
                  title: 'Percentile',
                  value:
                    metrics?.spend_rank_strength == undefined
                      ? undefined
                      : (metrics.spend_rank_strength * 100) | number,
                  subtitle:
                    metrics?.spend_rank_raw_count == undefined
                      ? undefined
                      : 'Spender #' + metrics.spend_rank_raw_count
                }
              ]"
            >
            </dq-metric>

            <dq-metric
              [metrics]="[
                {
                  title: 'Visits',
                  value:
                    metrics?.visits_raw_count == undefined
                      ? undefined
                      : metrics.visits_raw_count,
                  subtitle:
                    metrics?.num_packages_raw_count == undefined
                      ? undefined
                      : metrics.num_packages_raw_count + ' Bookings'
                },
                {
                  title: 'Frequency',
                  value:
                    metrics?.visit_ave_days_raw_count == undefined
                      ? undefined
                      : (metrics.visit_ave_days_raw_count | lengthDate),
                  subtitle:
                    metrics?.visit_rank_strength == undefined
                      ? undefined
                      : 'Top ' + metrics.visit_rank_strength * 100 + '%'
                },
                {
                  title: 'Lifetime',
                  value:
                    metrics?.lifetime_days_raw_count == undefined
                      ? undefined
                      : (metrics.lifetime_days_raw_count | lengthDate),
                  subtitle:
                    metrics?.last_visit_days_raw_count == undefined
                      ? undefined
                      : (metrics.last_visit_days_raw_count | lengthDate) +
                        ' Ago'
                }
              ]"
            >
            </dq-metric>

            <dq-metric
              [metrics]="[
                {
                  title: 'Invited',
                  value:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : metrics.invited_guests_raw_count,
                  subtitle:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : (metrics.invited_female_strength * 100 | number) +
                        ' Female',
                  gender_male:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : metrics.invited_male_strength * 100,
                  gender_female:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : metrics.invited_female_strength * 100
                },
                {
                  title: 'Friends',
                  value:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : metrics.friends_total_raw_count,
                  subtitle:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : (metrics.friends_female_strength * 100 | number) +
                        ' Female',
                  gender_male:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : metrics.friends_male_strength * 100,
                  gender_female:
                    metrics?.invited_guests_raw_count == undefined
                      ? undefined
                      : metrics.friends_female_strength * 100
                }
              ]"
            >
            </dq-metric>
          </div>

          <div class="center-empty ion-margin-top ion-margin-horizontal">
            <ion-label class="empty-text"
              >What do you want to know about guests? Let us know!</ion-label
            >
          </div>
        </ion-col>
        <ion-list
          [style.--padding-start.px]="0"
          class=""
          *ngrxLet="guest_history$ | async as historyData"
        >
          <ion-spinner
            color="light"
            class="ion-margin-top ion-padding-top center-spinner"
            *ngIf="page == 'history' && historyData.length == 0"
          ></ion-spinner>
          <ng-container *ngIf="page == 'history' && historyData.length > 0">
            <ion-list lines="inset" class="recieptlist" >
              <div [style.margin-top.px]="8"></div>

              <ng-container  *ngFor="
              let history_event of historyData ;
              let indexOfelement = index;
              trackBy: trackByevent
            ">
  
              <ion-item-sliding
               
              >
                <ion-item
                [detail]="true"
                class="activity-item"
                [lines]="history_event.event_note ? 'none' : 'inset'"
                (click)="history_event.tableData != undefined ? modal.present() : null"
                [style.--padding-bottom.px]="history_event.event_note ? 0 : 10"
                >
                  <div
                    class="vl"
                    [class.firstn]="indexOfelement == 0"
                    [class.lastn]="indexOfelement == historyData.length - 1"
                    slot="start"
                  ></div>
                  <div
                    [style.margin-right.px]="isCMS ? 20 : 0"
                    slot="start"
                  >
                    <div class="iconholder intent">
                      <ion-icon size="large" [name]="history_event.event_icon"></ion-icon>
                    </div>
                    <div class="iconholder behind">
                    </div>
                  </div>
                  <ion-col>
                    <ion-row class="ion-justify-content-between">

                      <ion-label>
                        <b>

                          {{ history_event.event_title }}
                          - {{ history_event.event_venue }}
                        </b>
                      </ion-label>
                     
                      
                    </ion-row>
                    <ion-row>
                      <ion-note>
                        <ion-note>{{ history_event.event_body }}</ion-note>
                      </ion-note>
                     
                    </ion-row>
                  </ion-col>
                 
                  <ion-note slot="end" >
                    {{ history_event.event_date | date: 'shortDate' }}
                  </ion-note>

                 
                  

                  <ion-modal #modal [presentingElement]="presentingElement">
                    <ng-template>
                      <ion-header>
                        <ion-toolbar> 
                          <ion-title>
                            {{ history_event.event_title }}
                          </ion-title>
                          <ion-buttons slot="end">
                            <ion-button (click)="modal.dismiss()">
                              <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                            </ion-button>
                          </ion-buttons>
                        </ion-toolbar>
                        
                      </ion-header>
                      <ion-content>
                        <ion-list class="ion-margin">

                          <app-tab-report [seating]="history_event.tableData"></app-tab-report>
                        </ion-list>
                        </ion-content>
                    </ng-template>
                  </ion-modal>
                </ion-item>
                <ion-item-options side="end" *ngIf="history_event.refundable">
                </ion-item-options>
              </ion-item-sliding>
              <ion-item [style.--inner-padding-top.px]="0" [style.--inner-padding-bottom.px]="16" [style.margin-top.px]="-8" *ngIf="history_event.event_note"    [style.--padding-start.px]="87"            class="activity-item"
              >
              <div

              [style.top]="'0px !important'"
              class="vl"
              [class.firstn]="indexOfelement == 0"
              [class.lastn]="indexOfelement == historyData.length - 1"
              slot="start"
            ></div>
                <div  class="note-s "               [style.margin-left.px]="isCMS ? 20 : 0"
                >
                  <ion-note [style.font-size.px]="12">
                    Booking Note
                  </ion-note>
                  <ion-label>
                    {{ history_event.event_note }}
                  </ion-label>
                  </div>

              </ion-item>
            </ng-container>
            </ion-list>
           
            <ion-button expand="block" class="ion-margin" fill="outline">
              View Audit Log
              <ion-icon slot="end" name="newspaper-outline"></ion-icon>
            </ion-button>
          </ng-container>
          <ion-item
            *ngIf="
              ((duplicates$ | async)?.guests_phone?.length > 0 ||
                (duplicates$ | async)?.guests_email?.length > 0) &&
              page == 'info'
            "
            (click)="modal.present()"
            class="guestform glowing border-item ion-margin-horizontal ion-margin-top"
          >
            <ion-label
              >{{
                (duplicates$ | async)?.guests_phone?.length +
                  (duplicates$ | async)?.guests_email?.length
              }}
              duplicate guest profiles found</ion-label
            >
            <ion-button color="primary" slot="end">Merge Guests</ion-button>
          </ion-item>
          <div *ngIf="page == 'info'" class="ion-padding">
            <app-phoneInput [(code)]="guest.mobile_code" [(phone)]="guest.mobile_num" >

              <ion-row class="row obscured" *ngIf="!(seeGuestDetails$ | async)">
                 
                <div class="blurred">
                  <ion-label>
                    12315124213
                  </ion-label>
                </div>
                
              </ion-row>
              <ion-icon *ngIf="!(seeGuestDetails$ | async)" name="lock-closed-outline" slot="end">

              </ion-icon>  
               <ion-button
                color="secondary"
              *ngIf="seeGuestDetails$ | async"
                class="interact-button"
                slot="end"
                fill="none"

                [href]="'tel:' + guest.mobile_num"
                ><ion-icon
                  color="primary"
                  slot="icon-only"
                  name="call"
                ></ion-icon
              ></ion-button>
            </app-phoneInput>


            <ion-item color="secondary"  class="guestform border-item ion-margin-top">
              <ion-label position="stacked">Guest Email (Opt)</ion-label>
              <ng-container *ngIf="(seeGuestDetails$ | async)"
              >

                <ion-input
                [(ngModel)]="guest.email"
                placeholder="john@example.com"
                type="email"
                ></ion-input>
                <ion-button
                color="secondary"
                class="interact-button"
                slot="end"
                fill="none"
                [href]="'mailto:' + guest.email"
                [style.padding-right.px]="16"
                ><ion-icon
                color="primary"
                slot="icon-only"
                name="mail"
                ></ion-icon
                ></ion-button>
                
              </ng-container>

              <ng-container class="row obscured" *ngIf="!(seeGuestDetails$ | async)">
                <ion-icon [style.height.%]="80" slot="end" name="lock-closed-outline">
                </ion-icon>
                <div class="blurred">
                  <ion-input
                value="test@example.com"
                placeholder="john@example.com"
                type="email"
                ></ion-input>
                </div>
                
              </ng-container>

            </ion-item> 
            <ion-item color="secondary"  class="guestform gender-inset border-item ion-margin-top">
              <ion-segment [(ngModel)]="guest.identity" class="segment-simple"
                ><ion-segment-button value="female" class="segment-padding"
                  ><ion-label
                    [style.font-size.px]="20"
                    [style.margin-top.px]="3"
                    >F</ion-label
                  ></ion-segment-button
                >
                <ion-segment-button value="male" class="segment-padding"
                  ><ion-label
                    [style.font-size.px]="20"
                    [style.margin-top.px]="3"
                    >M</ion-label
                  ></ion-segment-button
                >
                <ion-segment-button value="nothanks" class="segment-padding"
                  ><ion-icon
                    name="close-outline"
                  ></ion-icon></ion-segment-button
              ></ion-segment>
            </ion-item>
            <ion-item color="secondary"  [style.margin-bottom.px]="8" class="guestform border-item guest-note ion-margin-top">
              <ion-label position="stacked">Guest Notes</ion-label>
              <ion-textarea
                [(ngModel)]="guest.notes"
                type="email"
                class="talltext"
                [autoGrow]="true"
                placeholder="Guest notes go here"
                autocapitalize="sentences"
                enterkeyhint="done"
                autocorrect="on"
              ></ion-textarea>
            </ion-item>

            <ion-note class="ion-padding-top">
              #{{ guest.guest_id == "0" ? "new" : guest.guest_id }}
            </ion-note>
          </div>
        </ion-list>
      </ng-container>
    </ion-list>
  </ion-content>
  <ion-footer
    class="offset"
    *ngIf="(page == 'info' || EditedTags == true) && guest != undefined"
  >
    <ion-toolbar>
      <ion-button
        color="primary"
        expand="block"
        fill="outline"
        *ngIf="guest.guest_id != '0' && change"
        (click)="changeGuest()"
      >
        <ion-label *ngIf="!saving">Change Guest</ion-label>

        <ion-spinner class="" *ngIf="saving"></ion-spinner>
      </ion-button>
      <ion-button
        color="primary"
        expand="block"
        *ngIf="guest.guest_id == '0'"
        (click)="CreateGuest(guest)"
        [disabled]="
          !(
            guest.name != '' &&
            guest.mobile_num != '' &&
            guest.mobile_code != ''
          )
        "
      >
        <ion-label
          *ngIf="
            !saving &&
              !(
                guest.name == '' ||
                (guest.mobile_code == '' && guest.mobile_num == '')
              );
            else notcont
          "
          >Create Guest</ion-label
        >
        <ng-template #notcont>
          <ion-label
            *ngIf="
              guest.mobile_code == '' || guest.mobile_num == '';
              else smaller
            "
            >Add valid phone number</ion-label
          >
        </ng-template>
        <ng-template #smaller>
          <ion-label *ngIf="!saving">Add guest name</ion-label>
        </ng-template>

        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
      <ion-button
        (click)="updateGuest(guest)"
        color="primary"
        expand="block"
        *ngIf="guest.guest_id != '0'"
        [disabled]="
          !(
            guest.name != '' &&
            guest.mobile_num != '' &&
            guest.mobile_code != ''
          )
        "
      >
        <ion-label *ngIf="!saving">Save Guest</ion-label>

        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-toolbar>
  </ion-footer>
</ng-container>

<ion-modal #modal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Duplicate Guests</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="modal.dismiss()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item-divider
          color="medium"
          class="ion-margin-top"
          *ngIf="(duplicates$ | async)?.guests_phone?.length > 0"
        >
          Duplicate phone numbers
        </ion-item-divider>
        <ion-item
          *ngFor="
            let item of (duplicates$ | async)?.guests_phone;
            trackBy: trackByid
          "
        >
          <ion-label>{{ item.name }}</ion-label>
          <ion-note slot="end">{{ item.mobile_num }}</ion-note>
          <ion-button
            slot="end"
            *ngIf="item.loading != true"
            (click)="mergeGuests(item.guest_id); item.loading = true"
            >Merge</ion-button
          >
          <ion-spinner slot="end" *ngIf="item.loading == true"> </ion-spinner>
        </ion-item>
        <ion-item-divider
          color="medium"
          class="ion-margin-top"
          *ngIf="(duplicates$ | async)?.guests_email?.length > 0"
        >
          Duplicate emails
        </ion-item-divider>
        <ion-item
          *ngFor="
            let item of (duplicates$ | async)?.guests_email;
            trackBy: trackByid
          "
        >
          <ion-label>{{ item.name }}</ion-label>
          <ion-note slot="end">{{ item.email }}</ion-note>
          <ion-button
            *ngIf="item.loading != true"
            slot="end"
            (click)="mergeGuests(item.guest_id); item.loading = true"
            >Merge</ion-button
          >
          <ion-spinner slot="end" *ngIf="item.loading == true"> </ion-spinner>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>
