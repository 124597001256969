import { Directive, HostListener, Input } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Share, ShareOptions } from "@capacitor/share";

@Directive({
  selector: "[appShare]",
})
export class ShareDirective {
  @Input("appShare") opts: ShareOptions;

  constructor(private titleSvc: Title) {}

  @HostListener("click")
  async share() {
    // silently ignore erros
    Share.share({ ...this.getDefaultShareOpts(), ...this.opts }).catch(
      (error) => {
        console.warn(error);
      }
    );
  }

  private getDefaultShareOpts() {
    const opts: ShareOptions = {
      title: this.titleSvc.getTitle(),
      url: window.location.toString(),
    };
    return opts;
  }
}
