<div class="wrapper" [class.shrunk]="shinkable && !open" [class.nob]="!border">
  <ng-content></ng-content>
  <div *ngIf="shinkable" class="closed" [class.hidden]="open">
    <ion-button
      size="defualt"
      *ngIf="!open"
      (click)="open = true"
      color="medium"
      >Expand</ion-button
    >
    <ion-button
      size="defualt"
      *ngIf="open"
      (click)="open = false"
      color="secondary"
      >Shrink</ion-button
    >
  </div>
</div>
