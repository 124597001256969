import { Injectable, Input } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
} from "@angular/router";
import { CognitoUser } from "@aws-amplify/auth";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { concatMap, map, take, withLatestFrom } from "rxjs/operators";
import { RegionsService } from "src/app/services/regions/regions.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RegionAuthGuard implements CanActivate {
  constructor(
    private settingSt: SettingsState,
    private regions: RegionsService,
    private auth: AuthClass,
    private navCtrl: NavController,
    
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //return promise

    const initi = route.url[0].path;
    const fullurl = state.url;
    var region = this.regions.getRegion();

    //if the regoin fetched isnt first inline,
    if (region !== initi) {
      console.warn("Region finder has a difference with found region, redirecting");
      const regionIndex = fullurl.indexOf(initi);
      if (regionIndex !== -1) {
        const beforeRegion = fullurl.substring(0, regionIndex);
        const afterRegion = fullurl.substring(regionIndex + initi.length);
        window.location.href = `${beforeRegion}${region}${afterRegion}`;
      } else {
        // If the region is not in the URL, simply prepend the correct region
        window.location.href = `/${region}${fullurl}`;
      }
      return false;
    }

    console.log("checking user egion auth gaurd")

    //if authorisation is setup ensure where in the right region
      let auth;
      try {
         auth = await this.auth.currentAuthenticatedUser();
      } catch (e) {
        console.warn("the user has not got auth info so we have not compared it to their region", e);
      }
        console.log(auth);
        if (auth != undefined) {
          console.log("Auth Info Present", auth.attributes);
          var region_ath = auth.attributes["custom:user_region"]; // TODO: auth.attributes.region
         console.log(region_ath)
         //throw big warning if region is not set
         if (region_ath == undefined) {
            console.error("Region is not set in user attributes");

            console.error("we are refreshing the token with the hope of getting the region back in state")
            this.settingSt.currentUser$.pipe().subscribe(async (user) => {
              try {
                user.getSession((err, session) => {});
              } catch (error) {
                console.log(error);
                 user = await this.auth.currentAuthenticatedUser();
              }
              user.getSession((err, session) => {
                console.error("Token Refreshed")

                user.refreshSession(session.refreshToken, (err, session) => {
                  this.settingSt.state.set({
                    cognitoUser: user
                  })
              })
              })
              
            })
            return 
        }
          //somehting
          //if venue_id is between 0 and 10 million set region to au,
          //if venue_id is between 10 million and 20 million set region to us
          //if venue_id is between 20 million and 30 million set region to eu
          
          if (region !== region_ath) {
            const updatedUrl = fullurl.replace(`/${region}/`, `/${region_ath}/`);
            console.error("Region did not match auth region, redirecting", region_ath, region);
            window.location.href = updatedUrl;
            return false;
          }
        }
      
   
    
    return true;
  }
}
