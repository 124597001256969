import { Injectable } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { notificationsSelectAll, State } from "src/app/reducers";

import { of } from "rxjs";
import {
  catchError,
  concatMap,
  finalize,
  map,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { authCheckInSuccess } from "../actions/auth.actions";
import { orderPlacedSuccessfully } from "../actions/cart.actions";
import {
  closeTable,
  closeTableError,
  closeTableSuccess,
  favTable,
  loadCollectionPointsError,
  loadCollectionPointsSuccess,
  assignmentUpdated,
  openTable,
  openTableError,
  openTableSuccess,
  assignmentLoaded,
} from "../actions/table.actions";
import { VenuesFacadeService } from "../facades/venues-facade/venues-facade.service";
import {
  dashboardDidEnter,
  dashboardRefreshTables,
} from "../pages/dashboard/actions/dashboard.actions";
import { OrderV2Service } from "../services/order-v2/order-v2.service";
import { DqTableDetails, TableService } from "../services/table/table.service";

export function showLoader<T>(loadingController: LoadingController) {
  return concatMap(async (params: T): Promise<[T, HTMLIonLoadingElement]> => {
    const loader = await loadingController.create();
    await loader.present();
    return [params, loader];
  });
}
@Injectable()
export class TablesEffects {
  openTableError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openTableError),
        concatMap(async ({ error }) => {
          const toast = await this.toastCtrl.create({
            header: "Unable to open table",
            position: "top",
            duration: environment.defaultToastDuration,
            buttons: [
              {
                role: "cancel",
                icon: "close-circle-outline",
                cssClass: "toast-close-button",
              },
            ],
            ...(typeof error === "string" && { message: error }),
          });
          await toast.present();
        })
      ),
    { dispatch: false }
  );

  /**
   * A table can be closed immediately if there are no "paylater" orders open
   */
  closeTableImmediately$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeTable),
      concatMap(({ table, onComplete }) => {
        return this.orderV2Svc
          .closeTable({
            tableId: table.table_id,
          })
          .pipe(
            map(() => {
              return closeTableSuccess({ table });
            }),
            catchError((error) => of(closeTableError({ error, table }))),
            finalize(() => onComplete && onComplete())
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private loadingCtrl: LoadingController,
    private orderV2Svc: OrderV2Service,
    private tableSvc: TableService,
    private toastCtrl: ToastController,
    private venuesFacade: VenuesFacadeService,
    private store: Store<State>
  ) {}

  async showLoader() {
    const loader = await this.loadingCtrl.create();
    await loader.present();
    return loader;
  }

  private getOrderId(table: DqTableDetails) {
    const orderId = table.guest && table.guest[0] && table.guest[0].order_id;
    return !!orderId && orderId !== "0" && orderId;
  }

  private refreshTableData(venue_id: string) {
    return this.tableSvc.getAllTables({ venue_id }).pipe(
      map((response) => {
        if (response.status === "success") {
          return loadCollectionPointsSuccess({
            collectionPoints: response.getTable,
          });
        } else {
          return loadCollectionPointsError({
            error: "error fetching tables from server",
          });
        }
      }),
      catchError((error) => {
        console.log(error);
        return of(loadCollectionPointsError({ error: error.description }));
      })
    );
  }
}
