import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { select, Store } from "@ngrx/store";
import { RxState } from "@rx-angular/state";
import { combineLatest, EMPTY, Observable, throwError } from "rxjs";
import { concatMap, map, take, withLatestFrom } from "rxjs/operators";
import {
  addToCart,
  changeCartToTable,
  clearCart,
  decrementItemQuantity,
  incrementItemQuantity,
  removeCartItem,
  updateItemComment,
} from "src/app/actions/cart.actions";
import { DqModifierGroup } from "src/app/models/cart.model";
import { SyncV1IncentiveCampaign, SyncV1MenuItem, SyncV1Venue } from "src/app/models/sync_models";
import {
  cartSelectAllForSelectedTable,
  cartSelectBindingQuoteId,
  cartSelectCount,
  cartSelectCouponCodeIds,
  cartSelectCouponCodes,
  cartSelectEntities,
  cartSelectGratuity,
  cartSelectTotalPrice,
  State,
} from "src/app/reducers";
import {
  ConsumerTableService,
  DqConsumerMenuItem,
  DqConsumerVenueInfo,
} from "src/app/services/consumer-table/consumer-table.service";
import { DqMenuItemDetails } from "src/app/services/menu/menu.service";
import { OrderService } from "src/app/services/order/order.service";
import { getSurcharge } from "src/app/utils/calculate-cart-total";

export const EMPTY_CART = "EMPTY CART";
@Injectable({
  providedIn: "root",
})
export class ConsumerCartFacadeService {
  state$ = new RxState<{
    [key: string]: {
      id: string;
      table: string;
      quantity: number;
      comment: string;
      itemDetails: SyncV1MenuItem
      modifiers: any[]
    };
  }>

 

  cartAll$ = (id) => this.state$.select(map((menuItems) => Object.values(menuItems).filter((x)=> x.table == id)));
  cartCouponCodeIds$ = this.store$.pipe(select(cartSelectCouponCodeIds));
  cartCouponCodes$ = this.store$.pipe(select(cartSelectCouponCodes));
  cartEntities$ = this.store$.pipe(select(cartSelectEntities));
  cartGratuity$ = this.store$.pipe(select(cartSelectGratuity));
  cartTotal$ = (id) =>  this.state$.select(map((menuItems) => 
  {
    let total = 0
    Object.values(menuItems).forEach(item => {


      if (id == item.table){
      total += item.quantity
      }
    })
    return total
  }));
  cartTotalPrice$ = (id: string) =>  this.state$.select(map((menuItems) => 
  {
    console.log(menuItems, id)
    let total = 0
    Object.values(menuItems).forEach(item => {
      if (item.table == id){

        let baseitemprice = Number.parseFloat(item.itemDetails.price)
        console.log(item)
        item.modifiers.forEach(modifier => {

          modifier.modifiers.forEach(mod => {

          baseitemprice += mod.price
          })
        })


      total += baseitemprice * item.quantity
      console.log(item.itemDetails.price)
      }
    })
    return total
  }));


  cartTotalCommission$ = (id: string, camps: {
    [key: string]: SyncV1IncentiveCampaign[]
  }) =>  this.state$.select(map((menuItems) => 
  {
    console.log(menuItems, id)
    let camp_totals = {}
    let camp_defs: {[key: string]: SyncV1IncentiveCampaign} = {}
    let totalbase = 0
    Object.values(menuItems).forEach(item => {
      if (item.table == id){

        //get base commission amount
        
        let basecommission = item.itemDetails.static_commission
        if (basecommission == undefined){
          basecommission = 0
        }

      //find relevant camp totals
      let foundcamps = camps[item.itemDetails.mi_id]
        if (foundcamps != undefined){
        //for each of these
        foundcamps.forEach(camp => {
          //if the total is not set, set it to 0
          if (camp_totals[camp.ic_id] == undefined){
            camp_totals[camp.ic_id] = 0
            camp_defs[camp.ic_id] = camp
          }
          //add the base commission to the total
          camp_totals[camp.ic_id] += item.quantity
        })
      }
      
      totalbase += basecommission * item.quantity
      console.log(item.itemDetails.price)
      }
    })
    var total = totalbase
    //now go through all the camp totals, max them out at thier max usable availablke
    Object.keys(camp_totals).forEach(key => {
      let total_left = camp_defs[key].total_uses - camp_defs[key].uses_so_far
      let total_camp = camp_totals[key]
      var maxed = false
      if (total_camp > total_left){
        total_camp = total_left
        maxed = true
      }
      let total_price = total_camp * camp_defs[key].unit_price
      let camp_editable = structuredClone(camp_defs[key] as any);
      camp_editable.total = total_price
      if (maxed) {
        camp_editable.maxed = true
      }
      camp_totals[key] = camp_editable
    })
    console.log("camp_totals", camp_totals, total, totalbase)
    //add them all together
    Object.values(camp_totals).forEach(camp_total => {
      total += (camp_total as any).total 
    })
    return {
      base: totalbase,
      camps: Object.values(camp_totals),
      total: total
    }
  }));

  cartBindingQuoteId$ = this.store$.pipe(select(cartSelectBindingQuoteId));

  constructor(
    private auth: AuthClass,
    private consumerTableSvc: ConsumerTableService,
    private currencyPipe: CurrencyPipe,
    private orderSvc: OrderService,
    private store$: Store<State>
  ) {}

  consumerGetCartSubtotal() {
    return this.cartTotalPrice$;
  }


  addItemToCart(item: DqMenuItemDetails, tableId: string) {
    this.store$.dispatch(
      addToCart({
        item: {
          id: item.i_id,
          tableId,
          quantity: 1,
          itemDetails: item,
        },
      })
    );
  }

  addConsumerItemToCart(
    item,
    tableId: string,
    quantity = 1,
    user_input_price?: string
  ) {

    let existing = this.state$.get(item.mi_id.toString())
    if (existing && existing.table == tableId) {
      this.incrementItemQuantity(item.mi_id.toString(), tableId, quantity)
      return
    }



    this.state$.set({
     
      [item.mi_id.toString()]: {
        id: item.mi_id.toString(),
        quantity,
        comment: "",
        table: tableId,
        itemDetails: item,
        modifiers: []
      }
    })
    this.store$.dispatch(
      addToCart({
        item: {
          id: item.mi_id.toString(),
          tableId,
          quantity,
          consumerItemDetails: item,
          ...(user_input_price && { user_input_price }),
        },
      })
    );
  }

  addConsumerMixtureToCart(
    item,
    mixtureGroups: DqModifierGroup[],
    quantity: number,
    tableId: string
  ) {
    let id = (Math.random() * Math.pow(10, 6)).toFixed(0) + ''
    console.log("addConsumerMixtureToCart", item, mixtureGroups, quantity, tableId)
    this.state$.set({
      
      [id]: {
        id: id,
        quantity,
        comment: "",
        table: tableId,
        itemDetails: item,
        modifiers: mixtureGroups
      }
    })

    console.log("addConsumerMixtureToCart",this.state$.get())


    this.store$.dispatch(
      addToCart({
        item: {
          id: (Math.random() * Math.pow(10, 6)).toFixed(0),
          tableId,
          quantity,
          consumerItemDetails: item,
          consumerModifiers: mixtureGroups,
        },
      })
    );
  }

  incrementItemQuantity(itemId: string, tableId: string, quantity = 1) {
    this.state$.set({
      [itemId]: {
        ...this.state$.get(itemId),
        quantity: this.state$.get(itemId).quantity + quantity,
      },
    });

    this.store$.dispatch(incrementItemQuantity({ itemId, tableId }));
  }

  decrementItemQuantity(itemId: string, tableId: string) {

    if (this.state$.get(itemId).quantity == 1) {
      this.removeItem(itemId, tableId)
      return
    } else {
      this.state$.set({
        [itemId]: {
          ...this.state$.get(itemId),
          quantity: this.state$.get(itemId).quantity - 1,
        },
      });
    }

    this.store$.dispatch(decrementItemQuantity({ itemId, tableId }));
  }

  removeItem(itemId: string, tableId: string) {
    console.log("Removeitem" ,itemId, tableId)
    let st = this.state$.get()
    delete st[itemId]
    this.state$.set(st)


    this.store$.dispatch(removeCartItem({ itemId, tableId }));
  }

  updateItemComment(itemId: string, comment: string, tableId: string) {
    let item = this.state$.get(itemId)

    this.state$.set({
      [itemId]: {
        ...item,
        comment
      },
    });
    
    this.store$.dispatch(updateItemComment({ itemId, comment, tableId }));
  }

  clearCartForTable() {

    //clear state
    this.state$ =  new RxState<{
      [key: string]: {
        id: string;
        table: string;
        quantity: number;
        comment: string;
        itemDetails: SyncV1MenuItem
        modifiers: any[]
      };
    }>()

    this.store$.dispatch(clearCart());
  }

  changeCartTable(newTableId: string, itemIdsNotAvailable: string[]) {
    this.store$.dispatch(
      changeCartToTable({ newTableId, itemIdsNotAvailable })
    );
  }

  consumerCartGetBindingQuote({
    table_or_gift_id,
    venueInfo$,
  }: {
    table_or_gift_id: string;
    venueInfo$: Observable<DqConsumerVenueInfo>;
  }) {
    return this.cartTotal$(table_or_gift_id).pipe(
      take(1),
      withLatestFrom(this.cartAll$(table_or_gift_id)),
      concatMap(async ([gratuity, cart]) => {
        const { identityId } = await this.auth.currentCredentials();
        return [gratuity, cart, identityId] as const;
      }),
      withLatestFrom(this.cartCouponCodeIds$),
      concatMap(([[gratuity, cart, aws_identity_id], tab_credit_codes]) => {
        if (cart.length === 0) {
          return throwError(new Error(EMPTY_CART));
        }
        const order_details = cart.map(
          ({
            itemDetails,
            quantity,
            comment,
            modifiers,
            id
          }) => ({
            item_id: +id,
            quantity,
            comment,
            modifier_groups: (modifiers ?? []).map(
              ({ mg_id, modifiers }) => ({
                mg_id,
                mod_ids: modifiers.map((m) => m.mod_id),
              })
            ),
          })
        );
        return this.consumerTableSvc.getBindingQuote({
          table_or_gift_id,
          order_details,
          gratuity: 0,
          aws_identity_id,
          tab_credit_codes,
        });
      })
    );
  }

  consumerCartGetBindingQuoteV2({
    table_or_gift_id,
    venueInfo$,
  }: {
    table_or_gift_id: string;
    venueInfo$: Observable<SyncV1Venue>;
  }) {
    return this.cartTotal$(table_or_gift_id).pipe(
      take(1),
      withLatestFrom(this.cartAll$(table_or_gift_id)),
      concatMap(async ([gratuity, cart]) => {
        const { identityId } = await this.auth.currentCredentials();
        return [gratuity, cart, identityId] as const;
      }),
      withLatestFrom(this.cartCouponCodeIds$),
      concatMap(([[gratuity, cart, aws_identity_id], tab_credit_codes]) => {
        if (cart.length === 0) {
          return throwError(new Error(EMPTY_CART));
        }
        const order_details: any = cart.map((x) => {
          return {
            item_id: x.itemDetails.mi_id,
            quantity: x.quantity,
            comment: x.comment,
            modifier_groups: x.modifiers.map((mg) => {
              return {
                mg_id: mg.mg_id,
                modifier_ids: mg.modifiers.map((m) => m.mm_id),
              }})
          }
          
        })
        return this.consumerTableSvc.getBindingQuoteV2({
          table_or_gift_id,
          order_details,
          gratuity,
          aws_identity_id,
          tab_credit_codes,
        });
      })
    );
  }

  consumerCartCancelCurrentQuote() {
    return this.cartBindingQuoteId$.pipe(
      take(1),
      concatMap((bindingQuoteId) => {
        if (bindingQuoteId) {
          return this.consumerTableSvc.deleteBindingQuote(bindingQuoteId);
        } else {
          return EMPTY;
        }
      })
    );
  }

  placeOrder(params: {
    orderUuid: string;
    customer_name?: string;
    customer_email?: string;
    customer_phone?: string;
    customer_push_url?: string;
    [key: string]: string;
  }) {
    const { email, name, phone, notificationUrl } = params;
    return this.consumerTableSvc.initOrder(params);
  }
}
function calculateTax(totalPrice: number, taxRate: number): number {
  return totalPrice - totalPrice / (1 + taxRate);
}
