<div *ngIf="HasMap; else EmptyContainer">
  <div #pixiContainer class="Container"></div>
  <div *ngIf="Editing"></div>
</div>

<ng-template #EmptyContainer>
  <div class="Container">
    <h1>No Map in the venue yet! Check back soon.</h1>
  </div>
</ng-template>
