import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, shareReplay, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Event,
  SyncV1Guest,
  SyncV1IncentiveCampaign,
  SyncV1Table,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class CampaignState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1IncentiveCampaign }>();

  //fields specific to the service
  service_icon: string = "pricetags-outline";
  service_title: string = "Campaign Store";
  service_id: string = "IncentiveCampaign"; //id of the service
  service_url: string = "incentive_campaigns";

  //fields specific to the data
  data_id: string = "ic_id";
  data_detail: string = "ic_name";

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  //get the menu items as an array

  //get table using id
  campaign$ = (id) => this.state.select(id);
  campaigns$ = this.state.select(map((menuItems) => Object.values(menuItems)));

  campItemMap$ = this.state.select(
    map((camps) => {
      let arr = Object.values(camps);
      let address = {};
      arr.forEach((camp) => {
        if (camp.active) {
          camp.mi_ids.forEach((item) => {
            if (address[item]) {
              address[item].push(camp);
            } else {
              address[item] = [camp];
            }
          });
        }
      });
      console.log("array ready", address);
      return address;
    }),
    shareReplay()
  );

  campItemMapPrice$ = this.campItemMap$.pipe(
    map((camps) => {
      let address = {};

      Object.keys(camps).forEach((key) => {
        let camp = camps[key];
        let price = 0;
        camp.forEach((item) => {
          price += item.unit_price;
        });
        address[key] = price;
      });
      console.log("array ready", address);
      return address;
    })
  );

  camps$ = (id) =>
    this.campItemMap$.pipe(
      map((camp) => {
        return camp[id];
      })
    );
}
