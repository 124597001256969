import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import {
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { Platform, ToastController } from "@ionic/angular";
import { ToastButton } from "@ionic/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { defer, EMPTY, Observable } from "rxjs";
import { concatMap, map, switchMap, take } from "rxjs/operators";
import {
  pushNotificationReceived,
  pushNotificationRegistrationError,
  pushNotificationRegistrationSuccess,
} from "src/app/actions/notification.actions";
import { NotificationBroadcast } from "src/app/models/notification_models";
import { State } from "src/app/reducers";
import { NotificationService } from "src/app/services/notification/notification.service";
import { wrapInZone } from "src/app/utils/wrap-in-zone";
import { environment } from "src/environments/environment";
import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";
@Injectable()
export class NotificationsEffects {
  onAppReady$ = defer(() => {
    return this.platform.is("capacitor") ? this.platform.ready() : EMPTY;
  });

  notificationReceived$ = createEffect(() =>
    this.onAppReady$.pipe(
      switchMap(() => {
        const onNotification$ = new Observable<PushNotificationSchema>(
          (observer) => {
            const receivedListener = PushNotifications.addListener(
              "pushNotificationReceived",
              (notification) => {
                if (
                  notification.click_action &&
                  notification.click_action != ""
                ) {
                  //stop auto routing
                  //  this.router.navigate([notification.click_action]);
                }
                return observer.next(notification);
              }
            );
            const actionPerformedListener = PushNotifications.addListener(
              "pushNotificationActionPerformed",
              (action) => {
                console.log("pushNotificationActionPerformed", action);
                if (
                  action.notification.data &&
                  action.notification.data.deep_link &&
                  action.notification.data.deep_link != ""
                ) {
                  console.log("pushNotificationActionPerformed", action);
                  this.router.navigate([action.notification.data.deep_link]);
                }
                return observer.next(action.notification);
              }
            );
            return () => {
              receivedListener.remove();
              actionPerformedListener.remove();
            };
          }
        );
        return onNotification$;
      }),
      wrapInZone(this.zone),
      map((notification) => pushNotificationReceived({ notification }))
    )
  );

  showNotificationToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pushNotificationReceived),
        concatMap(async ({ notification }) => {})
      ),
    { dispatch: false }
  );

  pushNotificationDidRegister$ = new Observable<string>((observer) => {
    const listener = PushNotifications.addListener("registration", (token) =>
      observer.next(token.value)
    );
    return () => listener.remove();
  }).pipe(wrapInZone(this.zone));

  pushNotificationRegistrationError$ = new Observable<any>((observer) => {
    const listener = PushNotifications.addListener(
      "registrationError",
      (error) => {
        observer.next(error);
      }
    );
    return () => listener.remove();
  }).pipe(wrapInZone(this.zone));

  constructor(
    private actions$: Actions,
    private notificationSvc: NotificationService,
    private platform: Platform,
    private store$: Store<State>,
    private router: Router,
    private toastCtrl: ToastController,
    private zone: NgZone
  ) {
    if (platform.is("capacitor")) {
      platform
        .ready()
        .then(() => {
          // Setup listeners
          this.pushNotificationDidRegister$.subscribe((value) => {
            console.log("registered", value);
            this.store$.dispatch(
              pushNotificationRegistrationSuccess({
                registrationId: value,
              })
            );
          });
          this.pushNotificationRegistrationError$.subscribe({
            next: (error) =>
              this.store$.dispatch(pushNotificationRegistrationError(error)),
          });
          // Now register
          return PushNotifications.requestPermissions();
        })
        .then((result) => {
          if (result.receive == "granted") {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
            this.store$.dispatch(
              pushNotificationRegistrationError({
                error: "push notification permission not granted",
              })
            );
          }
        });
    } else {
      this.store$.dispatch(
        pushNotificationRegistrationError({ error: "no push token on web" })
      );
    }
  }

  async acceptNotification(url: string) {
    try {
      let data = await this.notificationSvc.acceptNotification(url).toPromise();
      const message =
        (data as any).status == "OK"
          ? (data as any).message
          : "There was an error accepting the notification";

      let toastSettings: any = {
        message: message,
        buttons: [
          {
            text: "Close",
            role: "cancel",
            cssClass: "toast-close-button",
          },
        ],
        duration: environment.defaultToastDuration,
      };

      const toast = await this.toastCtrl.create(toastSettings);
      await toast.present();
    } catch (e) {
      console.log(e);
      let toastSettings: any = {
        message: "There was an error accepting the notification",
        buttons: [
          {
            text: "Close",
            role: "cancel",
            cssClass: "toast-close-button",
          },
        ],
        duration: environment.defaultToastDuration,
      };

      const toast = await this.toastCtrl.create(toastSettings);
      await toast.present();
    }
  }

  acceptNotificationOld(notificationId: string) {}
}
