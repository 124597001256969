import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { ViewController } from "@ionic/core";
import { QRCodeComponent } from "angularx-qrcode";
import { combineLatest, Observable, Subject, timer } from "rxjs";
import {
  catchError,
  concatMapTo,
  filter,
  mapTo,
  retry,
  share,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import {
  DqOrderPaymentResponse,
  DqOrderPaymentResponseSuccess,
  OrderService,
} from "src/app/services/order/order.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { NgNavigatorShareService } from "ng-navigator-share";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-qrpayment-modal",
  templateUrl: "./qrpayment-modal.component.html",
  styleUrls: ["./qrpayment-modal.component.scss"],
})
export class QRPaymentModalComponent implements OnInit, OnDestroy {
  @Input() qrCode: string;
  @Input() payments: boolean;
  @Input() order_id: string;
  @Input() title = "Scan to create a TREAT link";

  private status$: Observable<DqOrderPaymentResponse>;
  paid: boolean = false;
  constructor(
    public modalCtrl: ModalController,
    public orderSvc: OrderService,
    public platform: Platform,
    public ngNavigatorShareService: NgNavigatorShareService,
    public toastCtrl: ToastController
  ) {
    if (this.payments == true) {
    }
  }
  private stopPolling = new Subject();

  ngOnDestroy() {
    this.stopPolling.next("");
  }
  ngOnInit() {
    if (this.payments == true) {
      this.status$ = timer(0, 3000).pipe(
        switchMap(() => this.orderSvc.checkPaymentStatus(this.order_id)),
        retry(),
        share(),
        takeUntil(this.stopPolling)
      );
      this.status$.subscribe((X) => {
        if (
          X != undefined &&
          (X as DqOrderPaymentResponseSuccess).payment_status == "paid"
        ) {
          if (this.paid === true) {
            this.modalCtrl.dismiss(true);
          }
          if (this.platform.is("capacitor")) {
            Haptics.impact({ style: ImpactStyle.Medium });
          } else if ("vibrate" in navigator) {
            // Success = tap, tap, tap
            navigator.vibrate([20, 20, 20, 20]);
          }

          this.paid = true;
        }
      });
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async copy() {
    navigator.clipboard.writeText(this.qrCode);

    const toast = await this.toastCtrl.create({
      header: "Link copied to clipboard",
      message: this.qrCode,
      position: "top",
      duration: environment.defaultToastDuration,
      buttons: [
        {
          role: "cancel",
          icon: "close-circle-outline",
          cssClass: "toast-close-button",
        },
      ],
    });
    await toast.present();
  }
}
