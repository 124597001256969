import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
  ViewDidLeave,
} from "@ionic/angular";
import { OrderService } from "src/app/services/order/order.service";
import { NgNavigatorShareService } from "ng-navigator-share";
import { PaymentService } from "src/app/services/payment/payment.service";

import {
  PaymentMethod,
  PaymentUserActionRequest,
  Receipt,
  ReceiptPaymentPost,
} from "src/app/models/payment_models";
import { QRPaymentModalComponent } from "../non-standard/qrpayment-modal/qrpayment-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  query,
  stagger,
  keyframes,
} from "@angular/animations";
import { RegionsService } from "src/app/services/regions/regions.service";
import Activity, {
  ActivityPlugin,
} from "src/app/services/activity/activities.service";
import { Capacitor } from "@capacitor/core";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { PaymentTypeKey } from "src/app/models/payment_models";
import { MobilePayService } from "src/app/services/mobile-pay/mobile-pay.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { take } from "rxjs";
import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";

@Component({
  selector: "app-payment-page",
  templateUrl: "./payment-page.component.html",
  styleUrls: ["./payment-page.component.scss"],
  animations: [
    trigger("rotateIn", [
      transition(":enter", [
        style({ opacity: 0.5, transform: "rotate(90deg);" }),
        animate("200ms", style({ opacity: 1, transform: "rotate(0deg);" })),
      ]),
      transition(":leave", [
        animate("200ms", style({ opacity: 0.5, transform: "rotate(90deg);" })),
      ]),
    ]),
    trigger("fadeSlideInOut", [
      transition(":enter", [
        style({ opacity: 0.5, transform: "translateY(600px)" }),
        animate("200ms", style({ opacity: 1, transform: "translateY(0)" })),
      ]),
      transition(":leave", [
        animate(
          "200ms",
          style({ opacity: 0.5, transform: "translateY(600px)" })
        ),
      ]),
    ]),
  ],
})
export class PaymentPageComponent implements OnInit {
  @Input() recieptId: string;
  @Input() set hiddennow(value: any) {
    if (value == undefined) {
      console.log("Cancelling Payment");
      //cancel any pending things
      this.CancelPayment();
    }
  }

  paymentMethods: PaymentMethod[];
  groupedMethods = [];
  reciept$ = this.recieptSt.receipt$;
  currency$ = this.settingSt.currencyCode$;
  constructor(
    public modalCtrl: ModalController,
    public orderSvc: OrderService,
    public platform: Platform,
    public ngNavigatorShareService: NgNavigatorShareService,
    public toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private paymentSvc: PaymentService,
    private loadingCtrl: LoadingController,
    private router: Router,
    public route: ActivatedRoute,
    public menu: MenuController,
    private reg: RegionsService,
    private analy: AnalyticsService,
    private ref: ChangeDetectorRef,
    private mobilePayments: MobilePayService,
    private recieptSt: RecieptState,
    private settingSt: SettingsState
  ) {}
  mobilepay = this.mobilePayments;
  SelectedMethod;
  splitpayment = false;

  outstanding = 0;
  loading = false;
  backbutton = true;
  success = false;
  failure = false;
  isTablet = this.platform.is("tablet") || this.platform.is("desktop");
  route_to;
  tip = 0;
  //feature flag

  async ngOnInit() {

    Haptics.selectionStart()
    console.log("Initing stores");
    this.recieptSt.InitStore();
    this.settingSt.InitStore();
    if (this.recieptId == undefined) {
      this.recieptId = this.route.snapshot.params["locationId"];
    }

    this.GetData();
    //wait 1 sec

    setTimeout(() => {
      this.menu.isOpen().then((isOpen) => {
        this.backbutton = !isOpen;
      });
    }, 400);
    console.log("Initing stores 2");

    if (this.route.snapshot.queryParams["default_amount"] != undefined) {
      this.splitpayment = true;
      this.outstanding = this.route.snapshot.queryParams["default_amount"];
      //get current navigation path as a url (not including query params and domain ect of course)
      let url = this.router.url.split("?")[0];
      console.log("defualt amount being triggered");
      console.log("Initing stores 3");

      //remove query params (by setting to null)
      this.router.navigate([url], {
        queryParams: { default_amount: null },
        queryParamsHandling: "merge", // merge with the current query params
      });
    }
    //check if queryparams include payment
    if (
      this.route.snapshot.queryParams["default_amount"] != undefined &&
      this.router.url.includes("/pay/")
    ) {
      this.splitpayment = true;
      this.outstanding = this.route.snapshot.queryParams["default_amount"];
      //get current navigation path as a url (not including query params and domain ect of course)
      let url = this.router.url.split("?")[0];
      console.log("defualt amount being triggered");
      console.log("Initing stores 4");

      //remove query params (by setting to null)

      this.router.navigate([url], {
        queryParams: { default_amount: null },
        queryParamsHandling: "merge", // merge with the current query params
      });
    }
    //subscibre to queryparams
    this.route.queryParams.subscribe(async (params) => {
      if (params["payment"] != undefined && this.route_to == undefined) {
        let paymentresponse = await this.paymentSvc
          .getRecieptPayment(params["payment"])
          .toPromise();
        this.actionData(paymentresponse, 1);
        console.log("Initing stores 5");

        //remove query params
        this.router.navigate([this.reg.getRegion(), "pay", this.recieptId], {
          queryParams: { payment: null },
          queryParamsHandling: "merge",
        });
      }
    });

    //set back button to menu is open
  }

  /* async checkUpdatedReciept(reciept) {
    /*
    if (
      this.route.snapshot.queryParams["default_amount"] != undefined &&
      !this.router.url.includes("/pay/")
    ) {
      console.log("defualt amount being triggered");
      this.splitpayment = true;
      this.outstanding = this.route.snapshot.queryParams["default_amount"];
      //get current navigation path as a url (not including query params and domain ect of course)
      let url = this.router.url.split("?")[0];

      //remove query params (by setting to null)
      console.log("Initing stores 6");

      this.router.navigate([url], {
        queryParams: { default_amount: null },
        queryParamsHandling: "merge", // merge with the current query params
      });
    }

    return "True";
  }*/

  DeviceDependantMethods() {
    //return if any device dependant methods are true, if any are return a map of the keys and a boolean value of true
    let mobilactive = this.mobilePayments.state$.get("available");
    if (mobilactive) {
      return {
        tap_to_pay: mobilactive,
      };
    } else {
      return {};
    }
  }

  async GetData() {
    let returned = 0;

    let dependants = this.DeviceDependantMethods();

    this.paymentSvc
      .getRecieptMethods(this.recieptId, dependants)
      .subscribe((data) => {
        this.groupedMethods = [];

        //loop through methods
        data.methods.forEach((method) => {
          //if method is grouped
          if (method.group_key != undefined && method.group_key != "") {
            //check if group exists
            let group = this.groupedMethods.find(
              (x) => x.internal_key == method.group_key
            );

            //check theres at least 2 methods in group
            let groupcount = data.methods.filter(
              (x) => x.group_key == method.group_key
            ).length;
            //if theres only one method in group, skip over the rest of this
            if (groupcount < 2) {
              return;
            }

            if (group == undefined) {
              //create group
              //find group details
              let groupdata = data.groups.find(
                (x) => x.internal_key == method.group_key
              );
              group = {
                name: groupdata.name,
                description: groupdata.description,
                internal_key: groupdata.internal_key,
                icon: groupdata.icon,
                options: data.methods.filter(
                  (x) => x.group_key == method.group_key
                ),
                selected: method,
              };

              this.groupedMethods.push(group);
              data.methods = data.methods.filter(
                (x) => !(x.group_key == method.group_key)
              );
            } else {
              //add method to group
            }
          }
        });

        this.paymentMethods = data.methods;
        returned++;
      });
    //
    //while (returned < 2) {
    // await new Promise((resolve) => setTimeout(resolve, 100));
    //}
    return true;
  }

  async closeMenu() {
    this.menu.isOpen();

    //close menu
    await this.menu.close();
  }

  methodSelected() {
    
    Haptics.selectionChanged();
    this.analy.addEvent("Payment Method Updated", this.SelectedMethod);
    this.tip = 0;
  }

  async CancelPayment() {
    if (
      this.route_to != undefined &&
      this.route_to.success == false &&
      this.route_to.route_to != "close" &&
      this.route_to.route_to != "stay"
    ) {
      console.log("Cancelling");
      let action: PaymentUserActionRequest = {
        old_state: this.route_to.state,
        new_state: "cancelled",
      };

      let act = await this.paymentSvc
        .postPaymentAction(this.recieptId, this.route_to.id, action)
        .toPromise();

      this.route_to = undefined;
      this.analy.addEvent("Payment Cancelled", this.SelectedMethod);
    } else {
      console.log("no need to cancell");
    }
  }

  async ConfirmPayment() {
    if (
      this.route_to != undefined &&
      this.route_to.success == false &&
      this.route_to.route_to != "close" &&
      this.route_to.route_to != "stay"
    ) {
      console.log("Pending");
      let action: PaymentUserActionRequest = {
        old_state: this.route_to.state,
        new_state: this.route_to.state,
      };

      let act = await this.paymentSvc
        .postPaymentAction(this.recieptId, this.route_to.id, action)
        .toPromise();
    } else {
      console.log("no need to cancell");
    }
  }

  log(event) {
    console.log(event);
  }

  async CollectPayment() {
    if (this.loading) {
      return;
    }

    Haptics.impact();
    console.log("tip", this.tip);
    this.loading = true;
    this.analy.addEvent("Collect Payment", this.SelectedMethod);
    let collect: ReceiptPaymentPost;
    if (this.splitpayment) {
      collect = {
        internal_key: this.SelectedMethod.internal_key,
        amount: this.outstanding,
        tip: this.tip,
      };
    } else {
      console.log("reciept", "gettingr");

      let reciept = await this.reciept$(this.recieptId)
        .pipe(take(1))
        .toPromise();
      console.log("reciept", reciept);
      collect = {
        internal_key: this.SelectedMethod.internal_key,
        amount: reciept.outstanding,
        tip: this.tip,
      };
    }

    if (collect.internal_key == "stripe_tap_to_pay") {
      let ready = await this.mobilePayments.ready$.pipe(take(1)).toPromise();
      if (!ready) {
        this.toastCtrl
          .create({
            message:
              "Mobile payments are not ready yet, please wait or try another method",
            duration: 2000,
            position: "top",
            swipeGesture: "vertical",

            color: "danger",
          })
          .then((x) => x.present());
        this.loading = false;

        return;
      }
    }

    try {
      let paym = await this.paymentSvc
        .postRecieptPayment(this.recieptId, collect)
        .toPromise();

      await this.GetData();

      Haptics.selectionEnd();

      console.log("paym", paym);
      this.actionData(paym, 1);
      this.splitpayment = false;
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
  temp_route_to;
  last_measured_route;
  async actionData(data, attempt) {
    this.loading = false;
    console.log("Actioning Payment Action", data);
    //if success
    if (data.success) {
      this.temp_route_to = data;
      let reciept = await this.reciept$(this.recieptId)
        .pipe(take(1))
        .toPromise();
      if (reciept.outstanding > 0) {
        this.success = true;
        this.ref.detectChanges();
        //wait 1 sec
        await new Promise((resolve) => setTimeout(resolve, 1000));

        this.success = false;
        this.ref.detectChanges();
      } else {
        if (data.route_to == "close") {
          //clear out visuals for a sec
          this.route_to = undefined;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      this.temp_route_to = undefined;
    }
    if (data.failure) {
      this.temp_route_to = data;

      this.failure = true;
      this.ref.detectChanges();
      //wait 1 sec
      await new Promise((resolve) => setTimeout(resolve, 3000));

      this.failure = false;
      this.ref.detectChanges();
      this.temp_route_to = undefined;
    }

    switch (data.route_to) {
      case "internal_url":
        this.router.navigate([data.value], { relativeTo: this.route });
        break;

      case "external_url":
        //navigate to external URL using router
        this.router.navigate([data.url]);

        break;

      case "stay":
        this.route_to = undefined;
        this.GetData();
        this.ref.detectChanges();
        //close modal if its open and stay here
        break;

      case "close":
        console.log("Redirecting to close");
        let isMenu = await this.menu.isOpen("side-custom");
        if (isMenu) {
          await this.menu.close();
        } else {
          //check if url includes vip-orders
          if (this.route.snapshot.queryParamMap.get("return_url")) {
            //route to vip-orders
            if (data.success) {
              Haptics.notification({
                type: NotificationType.Success
              })
            }
            await this.router.navigate([
              this.route.snapshot.queryParamMap.get("return_url"),
            ]);
          } else {
            //ceck if platform is ios
            try {
              if (Capacitor.isPluginAvailable("Activity")) {
                if (this.route.snapshot.queryParamMap.get("a_o")) {
                  //set timeout of 20 seconds
                  let reciept = await this.reciept$(this.recieptId)
                    .pipe(take(1))
                    .toPromise();

                  if (reciept.outstanding > 0) {
                    this.success = true;
                  }
                  let o_a = this.route.snapshot.queryParamMap.get("a_o");

                  let activity = await Activity.createActivity({
                    orderColumn: "Confirming",
                  });
                  if (activity.value == "Requested") {
                    let tokens = [];
                    let cease = false;
                    console.log("activity created");
                    console.log(activity);

                    let list = Activity.addListener(
                      "PUSH_NOTIFICATION_TOKEN_UPDATE",
                      async (data) => {
                        //wait for 1
                        console.log("PUSH_NOTIFICATION_TOKEN_UPDATE");
                        console.log(data);
                        if (data && !cease) {
                          //add token to list
                          tokens.push(data);
                        }
                      }
                    );
                    //wait for tokens length to be 1
                    while (tokens.length < 1) {
                      await new Promise((resolve) => setTimeout(resolve, 100));
                    }
                    //remove listener
                    cease = true;
                    (list as any).remove();
                    //send tokens to server
                    console.log(
                      "Registering token",
                      tokens[0],
                      "for order",
                      o_a
                    );
                    let registered = await this.orderSvc
                      .registerActivity(tokens[0], o_a)
                      .toPromise();
                    console.log("registered", registered);

                    this.success = false;
                    this.analy.addEvent(
                      "Live Activity Created",
                      this.SelectedMethod
                    );
                  }
                }
              }
            } catch (e) {
              console.warn("Some error occured with activity plugin");
              console.warn(e);
              this.success = false;
            }

            //route to orders
            //if success add success to query param
            if (data.success) {
              Haptics.notification({
                type: NotificationType.Success
              })
              await this.router.navigate(
                [this.reg.getRegion(), "tab", "dashboard"],
                {
                  queryParams: { success: true },
                }
              );
            } else {
              await this.router.navigate([
                this.reg.getRegion(),
                "tab",
                "dashboard",
              ]);
            }
          }
        }
        break;

      case "display":
        this.route_to = data;

        //poll until route_to event changes
        //wait 1 sec
        console.log("waiting for ", this.hiddennow, "on attempt", attempt);

        await new Promise((resolve) => setTimeout(resolve, 1000));
        let paym = await this.paymentSvc.getRecieptPayment(data.id).toPromise();

        if (
          attempt > (await this.analy.getFeatureFlag("paymenttimeout", 250))
        ) {
          await this.CancelPayment();
          let to = await this.toastCtrl.create({
            message: "Payment timed out",
            duration: 2000,
            color: "danger",
          });
          to.present();
          this.analy.addEvent("Payment Timed Out", data);
        }

        this.actionData(paym, (attempt += 1));

        break;

      case "tap_to_pay":
        this.route_to = data;

        //poll until route_to event changes
        //wait 1 sec
        console.log("waiting for ", this.hiddennow, "on attempt", attempt);
        //wait for payment shit to do its thing then request again
        let result = await this.mobilePayments.StartPayment(
          data.stripe_client_secret
        );

        if (result == "failure") {
          this.route_to.note = "Payment Collection Failure";
          this.route_to.title = "Cancelling Payment";
          await new Promise((resolve) => setTimeout(resolve, 1000));
          await this.CancelPayment();
        }
        if (result == "success") {
          let successfound = false;
          this.route_to.note = "Confirming Payment";
          this.route_to.title = "Processing";
        }

        let tayp = await this.paymentSvc.getRecieptPayment(data.id).toPromise();

        this.actionData(tayp, (attempt += 1));

        break;
      case "alert":
        if (this.last_measured_route != data) {
          this.last_measured_route = data;

          //show alert
          let alert = await this.alertCtrl.create({
            header: data.title,
            message: data.note,
            backdropDismiss: false,
            buttons: [
              {
                text: "Cancel",
                handler: async () => {
                  //first, send back the cancel instruction
                  try {
                    await this.CancelPayment();
                  } catch (e) {
                    console.warn("Error confirming payment");
                  }
                  //api request goes here

                  let paym = await this.paymentSvc
                    .getRecieptPayment(data.id)
                    .toPromise();
                  console.log(
                    "Got this result after the cancelling shit went down",
                    paym
                  );
                  this.actionData(paym, (attempt += 1));
                },
              },
              {
                text: "Confirm",
                handler: async () => {
                  //first, send back the confirm instruction
                  //api request goes here
                  try {
                    await this.ConfirmPayment();
                  } catch (e) {
                    console.warn("Error confirming payment");
                  }
                  let paym = await this.paymentSvc
                    .getRecieptPayment(data.id)
                    .toPromise();
                  this.actionData(paym, (attempt += 1));
                },
              },
            ],
          });
          await alert.present();
        } else {
          let paym = await this.paymentSvc
            .getRecieptPayment(data.id)
            .toPromise();
          //data is the same, just refresh i guess
          this.actionData(paym, (attempt += 1));
        }

        break;
      case "card_reader":
        this.route_to = data;

        break;
      default:
        //thow not implemented error toast
        let toast = await this.toastCtrl.create({
          header: "Not Supported",
          message: data.display_message,
          duration: 2000,
          position: "top",
          swipeGesture: "vertical",

          color: "danger",
        });
        await toast.present();

        break;
    }
  }
}
