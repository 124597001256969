import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CMSv2MenuModifierGroup } from "src/app/models/cms_db_equiv";
import { MenuService } from "src/app/services/menu/menu.service";
import { CmsMenuService } from "src/app/services/cms/menu/cms-menu.service";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-modifier-modal",
  templateUrl: "./modifier-modal.component.html",
  styleUrls: ["./modifier-modal.component.scss"],
})
export class ModifierModalComponent implements OnInit {
  @Input() ModifierGroup: CMSv2MenuModifierGroup;
  minmax;

  constructor(
    private menuSvc: CmsMenuService,
    private modal: ModalController
  ) {}

  ngOnInit() {
    if (this.ModifierGroup.max_selections == undefined) {
      this.minmax = { lower: 0, upper: 0 };
    } else {
      this.minmax = {
        lower: this.ModifierGroup.min_selections,
        upper: this.ModifierGroup.max_selections,
      };
    }
  }

  addModifier() {
    if (this.ModifierGroup.modifiers == undefined) {
      this.ModifierGroup.modifiers = [];
    }
    this.ModifierGroup.modifiers.push({
      name: "Default Name",
      price: "0",
      id: 0,
    });
  }

  SaveModifier() {
    this.ModifierGroup.max_selections = this.minmax.upper;

    this.ModifierGroup.min_selections = this.minmax.lower;

    if (this.minmax.upper == 0 && this.minmax.lower == 0) {
      this.ModifierGroup.max_selections = undefined;
      this.ModifierGroup.min_selections = undefined;
    }
    if (this.ModifierGroup.id == 0) {
      this.menuSvc.createModifierGroup(this.ModifierGroup).subscribe((data) => {
        this.modal.dismiss();
      });
    } else {
      this.menuSvc.saveModifierGroup(this.ModifierGroup).subscribe((data) => {
        this.modal.dismiss();
      });
    }
  }
}
