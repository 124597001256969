import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AlertController } from "@ionic/angular";
import { DqGratuityType } from "src/app/services/order/order.service";

export interface PaymentFormGratuityData {
  value: number;
  type: DqGratuityType;
}

@Component({
  selector: "app-payment-form-gratuity",
  templateUrl: "./payment-form-gratuity.component.html",
  styleUrls: ["./payment-form-gratuity.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PaymentFormGratuityComponent,
      multi: true,
    },
  ],
})
export class PaymentFormGratuityComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() currencySymbol: string;
  @Input() total: number;
  value: number;
  type: DqGratuityType = "percentage";

  disabled = false;
  onChangeCb: (gratuity: PaymentFormGratuityData) => void;
  onTouchedCb: () => void;

  constructor(private alertCtrl: AlertController) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  writeValue(gratuity: PaymentFormGratuityData) {
    if (gratuity && gratuity.value && gratuity.value >= 0) {
      this.value = gratuity.value;
    }
    if (gratuity && gratuity.type) {
      this.type = gratuity.type;
    }
  }

  registerOnChange(onChangeCb: (gratuity: PaymentFormGratuityData) => void) {
    this.onChangeCb = onChangeCb;
  }

  registerOnTouched(onTouchedCb: () => void) {
    this.onTouchedCb = onTouchedCb;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  gratuityDidChange(amount) {
    this.value = +amount;
    if (this.onChangeCb) {
      this.callOnChangeCb();
    }
    if (this.onTouchedCb) {
      this.onTouchedCb();
    }
  }

  setGratuityType(type: DqGratuityType) {
    this.type = type;
    this.callOnChangeCb();
  }

  callOnChangeCb() {
    this.onChangeCb({ value: this.value, type: this.type });
  }

  async alertamount() {
    //collect a number in the alert
    let al = this.alertCtrl.create({
      header: "Gratuity",
      message: "Please enter gratuity amount",
      inputs: [
        {
          name: "amount",
          type: "number",
          placeholder: "Amount",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
        {
          text: "Ok",

          handler: (data) => {
            console.log("Ok clicked");
            console.log(data.amount);
            this.gratuityDidChange(data.amount);
          },
        },
      ],
    });

    (await al).present();
  }
}
