<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button color="primary">
    <ion-icon ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>
  </ion-fab-button>

  <ion-fab-list side="top" class="fab-actions">
    <div class="group">
      <ion-fab-button color="primary" (click)="callRunner()">
        <ion-icon name="call" color="text"> </ion-icon>
      </ion-fab-button>
      <ion-label>Call Runner</ion-label>
    </div>

    <div class="group">
      <ion-fab-button color="danger" (click)="callSecurity()">
        <ion-icon name="warning" color="text"> </ion-icon>
      </ion-fab-button>
      <ion-label>Call Security</ion-label>
    </div>

    <div class="group">
      <ion-fab-button
        color="warning"
        (click)="callManager()"
        class="callManager"
      >
        <ion-icon name="call" color="text"> </ion-icon>
      </ion-fab-button>
      <ion-label>Call Manager</ion-label>
    </div>

    <div class="group" *ngIf="RequestDQ | async">
      <ion-fab-button color="medium" (click)="callDQ()" class="callManager">
        <ion-img class="dq" src="../../../assets/logo.svg"></ion-img>
      </ion-fab-button>
      <ion-label>Request Help</ion-label>
    </div>
  </ion-fab-list>
</ion-fab>
