import {
  Component,
  ViewChild,
  EventEmitter,
  OnInit,
  ElementRef,
  Input,
  Output,
  Type,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import * as Pixi from "pixi.js";
import { GlowFilter } from "@pixi/filter-glow";

import { fromEvent, Observable, Subject } from "rxjs";
import { take, tap } from "rxjs/operators";
import { Viewport } from "pixi-viewport";
import { Interface } from "readline";
import {
  MapObject,
  MapItem,
  Offset,
  Map as MEP,
} from "../../models/map_editor_models";

import { Table } from "./MapParts/table.graphic";
import { Lines } from "./MapParts/line.graphic";
import { Text } from "./MapParts/text.graphic";
import { Actor } from "./MapParts/mapactor.graphic";
import { ToolBar } from "./MapParts/toolbar.graphic";
import { MapItemTypes } from "./MapParts/maptypes";
import * as uuid from "uuid";
import { AlertController, Platform, ToastController } from "@ionic/angular";
import { DisplayObject } from "pixi.js";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild("pixiContainer", { read: ElementRef }) pixiContainer: ElementRef; // this allows us to reference and load stuff into the div container
  @ViewChild("follow", { read: ElementRef }) follow: ElementRef;
  @ViewChild("input", { read: ElementRef }) inputBox: ElementRef;

  public SelectedTable = new Subject<Table>();
  public SelectedObject = new Subject<Table | Lines | Text>();
  public TableStatus = new Subject<
    {
      table_id: string;
      status: "disabled" | "primary" | "secondary";
    }[]
  >();

  public lasttable: Table | Lines | Text;
  @Input() data?;
  @Input() lightMode = false;
  @Input() grid = true;
  @Input() Editing?: boolean = false;
  @Input() height?: number = window.innerHeight;
  @Input() width?: number = window.innerWidth;
  @Input() test?;
  @Output() TableChanged = new EventEmitter<string>();
  @Output() MapSave = new EventEmitter<any>();

  //possible optimisation for big files, only subscribe if you belive you are the current
  //selected object, when another is selected unsubscribe

  public app: Pixi.Application; // this will be our pixi application
  private actors: Actor[] = [];
  private viewport: Viewport | any;
  private jsontest: string;
  public HasMap: boolean = true;
  MapItems = MapItemTypes;
  constructor(
    private platform: Platform,
    private toaster: ToastController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {

    if (this.Editing) {
      this.SelectedObject.subscribe((object) => {
        this.lasttable = object;
        this.TableChanged.emit((object.mapObject.Data as any).table_id);
        
      });
    } else {
      
    
    this.SelectedTable.subscribe((table) => {
      this.lasttable = table;

      this.TableChanged.emit((table.mapObject.Data as any).table_id);
    });
  }
  }

  ngAfterViewInit() {
    let black = 0x000000;
    let white = 0xffffff;
    this.app = new Pixi.Application({
      width: this.width,
      height: this.height,
      backgroundColor: this.lightMode ? white : black,
    }); // this creates our pixi application
    this.pixiContainer.nativeElement.appendChild(this.app.view); // this places our pixi application onto the viewable document
    // create viewport
    const viewport = new Viewport({
      screenWidth: this.width,
      screenHeight: this.height,
      worldWidth: 2000,
      worldHeight: 2500,

      events: this.app.renderer.events, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    } as any);

    // add the viewport to the stage
    this.app.stage.addChild(viewport as unknown as DisplayObject);

    // activate plugins
    viewport.drag().pinch().wheel().decelerate();

    if (this.lightMode || this.grid == false) {
      viewport.clampZoom({
        minScale: 0.3,
        maxScale: 3,
      });
    } else {
      viewport.clampZoom({
        minScale: 0.5,
        maxScale: 3,
      });
    }

    viewport.clamp({
      left: true, // whether to clamp to the left and at what value
      right: false, // whether to clamp to the right and at what value
      top: false, // whether to clamp to the top and at what value
      bottom: false, // whether to clamp to the bottom and at what value
      direction: "all", // (all, x, or y) using clamps of [0, viewport.worldWidth / viewport.worldHeight]; replaces left / right / top / bottom if set
      underflow: "center", // where to place world if too small for screen (e.g., top - right, center, none, bottomleft)
    });

    this.viewport = viewport;
    if (this.data != undefined && this.viewport != undefined) {
      //wait for the viewport to be created
      console.log("loading map", this.data);
      this.LoadMap(this.data);
    }

    this.initActors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data != undefined && this.viewport != undefined) {
      //wait for the viewport to be created
      console.log("loading map", this.data);

      this.LoadMap(this.data);
    }
  }
  initActors() {
    /*
      Call actor update method per frame
    */

    for (let i = 0; i < this.actors.length; i++) {
      const actor = this.actors[i];
      (this.viewport as any).addChild(actor);
      this.app.ticker.add((e) => actor.update(e));
    }

    /*
      Capture key downs
    */
    fromEvent(document, "keydown")
      .pipe(
        tap((e: any) => {
          for (let i = 0; i < this.actors.length; i++) {
            const actor = this.actors[i];
            actor.onKeydown(e);
          }
        })
      )
      .subscribe();

    /*
      Capture key ups
    */
    fromEvent(document, "keyup")
      .pipe(
        tap((e: any) => {
          for (let i = 0; i < this.actors.length; i++) {
            const actor = this.actors[i];
            actor.onKeyup(e);
          }
        })
      )
      .subscribe();

    //add a
  }

  async savecurrent() {
    var MapJSON: MapObject[] = [];
    for (let i = 0; i < this.viewport.children.length; i++) {
      const child = this.viewport.children[i] as unknown as Table;
      if (child.mapObject != undefined) {
        MapJSON.push(child.getMapObject());
      } else {
        //write a proper switch here for all the types
      }
    }

    this.jsontest = JSON.stringify(MapJSON);
    await await this.MapSave.emit({ map: MapJSON });
  }

  LoadMap(Mapper: MEP) {
    var MapData = Mapper.map;

    if (this.Editing == true && MapData.length == 0) {
      this.HasMap = false;
    }

    while (this.viewport.children[0]) {
      this.viewport.removeChild(this.viewport.children[0]);
    }
    if (this.grid) {
      this.createGrid(this.viewport);
    }
    for (let i = 0; i < MapData.length; i++) {
      var mapObject = MapData[i];
      if (this.platform.is("capacitor")) {
        mapObject.scale.x = mapObject.scale.x / 2;
        mapObject.scale.y = mapObject.scale.y / 2;
      }

      switch (mapObject.type) {
        case "table":
          //we need to replace the mapItem with the static map item
          mapObject.mapItem = this.MapItems[mapObject.mapItem.type];
          const actor = new Table(
            mapObject,
            (this.Editing? this.SelectedObject : this.SelectedTable),
            this.Editing,
            this,
            this.TableStatus
          );
          this.viewport.addChild(actor);
          this.app.ticker.add((e) => actor.update(e));
          break;
        case "wall":
          const wall = new Lines(mapObject, this.Editing, this.SelectedObject);
          this.viewport.addChild(wall);
          break;
        case "text":
          const text = new Text(mapObject, this.Editing, this.SelectedObject, this.alertCtrl);
          this.viewport.addChild(text);
      }
    }
    this.ZoomToObject();
  }

  ZoomToObject(table?: Pixi.DisplayObject) {
    if (table) {
      //Zoom to an object

      this.viewport.snap(table.x, table.y + 0, {
        removeOnComplete: true,
        removeOnInterrupt: true,
      });
      this.viewport.snapZoom({
        width: 1700,

        removeOnComplete: true,
        removeOnInterrupt: true,
      });
    } else {
      //get the highest point, lowest width and heights of the objects
      //in the viewport, and create a max width, and hieght based on those points,
      //and calculate a center point based on the points
      var maxX = 0;
      var maxY = 0;
      var minX = 0;
      var minY = 0;
      for (let i = 0; i < this.viewport.children.length; i++) {
        const child = this.viewport.children[i];
        //if child is a line ignore
        if (child instanceof Lines) {
          continue;
        }
        if (child.x > maxX) {
          maxX = child.x;
        }
        if (child.y > maxY) {
          maxY = child.y;
        }
        if (child.x < minX) {
          minX = child.x;
        }
        if (child.y < minY) {
          minY = child.y;
        }
      }
      var centerX = (maxX - minX) / 2;
      var centerY = (maxY - minY) / 2;
      var Mwidth = maxX - minX;
      var Mheight = maxY - minY + 100;

      //Zoom to the whole map
      /*this.viewport.snapZoom({
        width: width,
        height: height,
        time: 0,
        center: new Pixi.Point(centerX, centerY),
      });*/
      /*this.viewport.snapZoom({
        width: width,
        height: height,
        time: 1,
        removeOnComplete: true,
      });*/
      //console.log(`${centerX}, ${centerY}`);
      //this.viewport.snap(centerX, centerY, { removeOnComplete: true });
      var zoom = Mheight / (this.height + this.viewport.height);
      // console.log(
      //  `${zoom} = ${Mheight} / ${this.height} / ${this.viewport.height}`
      // );
      this.viewport.snap(centerX, centerY, {
        removeOnComplete: true,
        removeOnInterrupt: true,
      });
      if (this.lightMode || this.grid == false) {
        this.viewport.setZoom(0.45, true);
      } else {
        this.viewport.setZoom(0.5, true);
      }
    }
  }

  createObject(object?: MapObject) {
    var initobject: MapObject = {
      x: 1000,
      y: 1000,
      id: uuid.v4(),
      type: "table",
      rotation: 0,
      scale: { x: 1, y: 1 },
      mapItem: MapItemTypes.Semi_Circle,
      Data: { nothing: "nothing" },
    };
    if (object) {
      initobject = object;
    }
    const actor = new Table(
      initobject,
      (this.Editing? this.SelectedObject : this.SelectedTable),
      this.Editing,
      this,
      this.TableStatus
    );
    this.viewport.addChild(actor);
    (this.app as any).ticker.add((e) => actor.update(e));
    return actor;
  }
  createWall(itemType?: string) {
    if (itemType == undefined) {
      itemType = "Wall";
    }
    var ExampleObject: MapObject = {
      x: 1000,
      y: 1000,
      id: uuid.v4(),
      type: "wall",
      rotation: 0,
      scale: { x: 1, y: 1 },
      Data: [1000, 1000, 1100, 1100],

      mapItem: this.MapItems.Wall_Booth,
    };
    const actor = new Lines(ExampleObject, this.Editing, this.SelectedObject);
    this.viewport.addChild(actor);
  }
  createText(value?: string) {
    if (value == undefined) {
      value = "Hello World";
    }
    var itemType = "Text";

    var ExampleObject: MapObject = {
      x: 1000,
      y: 1000,
      id: uuid.v4(),
      type: "text",
      rotation: 0,
      scale: { x: 1, y: 1 },
      mapItem: this.MapItems.Wall_Booth,
      Data: value,
    };
    const actor = new Text(ExampleObject, this.Editing, this.SelectedObject, this.alertCtrl);
    this.viewport.addChild(actor);
  }

  public async deletecurrent() {
    //get the current selected table from the subject

    this.lasttable.destroyobject();
  }

  public async rotatecurrent(target?: number) {
    //get the current selected table from the subject
    if (target == undefined) {
      this.lasttable.angle += 45;
    } else {
      this.lasttable.angle = target;
    }
  }

  public ChangeType(type: MapItem) {
    var curr = this.lasttable.getMapObject();
    curr.mapItem = type;
    this.lasttable.destroy();
    const created = this.createObject(curr);
    created.onSelect();
  }

  //will never unselect a table
  public selectTable(table: string) {
    this.viewport.children.forEach((element) => {
      if (
        (element as Table).mapObject.type == "table" &&
        ((element as Table).mapObject.Data as any).table_id == table
      ) {
        (element as Table).onSelect();
      }
    });
  }

  gridSize = 100;
  gridAlpha = 0.06;

  // Function to generate the grid
  createGrid(view) {
    const container = view;
    let gridColor = this.lightMode ? 0x000000 : 0xffffff;

    // Vertical lines
    for (let x = -1500; x <= 3500; x += this.gridSize) {
      const line = new Pixi.Graphics();
      line.lineStyle(3.5, gridColor, this.gridAlpha);
      line.moveTo(x, -1500);
      line.lineTo(x, 3500);
      line.zIndex = -10;
      container.addChild(line);
    }

    // Horizontal lines
    for (let y = -1500; y <= 3500; y += this.gridSize) {
      const line = new Pixi.Graphics();
      line.lineStyle(3.5, gridColor, this.gridAlpha);
      line.moveTo(-1500, y);
      line.lineTo(3500, y);
      line.zIndex = -10;
      container.addChild(line);
    }

    return container;
  }

  private clampViewport() {
    const maxX = 2000;
    const maxY = 2000; /* Your max Y value */

    this.app.stage.position.x = this.clamp(this.app.stage.position.x, 0, maxX);

    this.app.stage.position.y = this.clamp(this.app.stage.position.y, 0, maxY);
  }

  private clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }
}

export class Background extends Actor {
  private space = 20;
  constructor() {
    super();

    this.beginFill(0x222222);
    var width = 20;
    for (let i = 0; i < width * width; i++) {
      this.drawRect(0, i * this.space, width * width * width, 1);
      this.drawRect(i * this.space, 0, 1, width * width * width);
    }
    this.endFill();
  }
}
