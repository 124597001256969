import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { Platform } from "@ionic/angular";
import { HttpCacheManager } from "@ngneat/cashew";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { defer } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import {
  authCheckAtStartupNoDetails,
  authCheckAtStartupSavedDetails,
  authTreatUserCheckAtStartupSavedDetails,
  userLoginSuccess,
  userLogOut,
} from "../actions/auth.actions";
import { StorageAdapter } from "../services/storage-adapter/storage-adapter.service";
import { STORAGE_KEY_VENUE_ID } from "./venues/venues.effects";

const STORAGE_KEY_LOGIN_DETAILS = "queuebar:loginDetils";

@Injectable()
export class AuthEffects {
  onReady$ = defer(() => this.platform.ready());

  checkLoginAtStartOnApp$ = createEffect(() =>
    this.onReady$.pipe(
      switchMap(async () => {
        try {
          const user = await this.migrateOldLoginAndDoCognitoLogin();
          if (user.signInUserSession.idToken.payload["custom:user_type"]) {
            return authCheckAtStartupSavedDetails({ user });
          } else {
            return authTreatUserCheckAtStartupSavedDetails({ user });
          }
        } catch (error) {
          return authCheckAtStartupNoDetails();
        }
      })
    )
  );

  treatUserLogin$ = createEffect(() =>
    this.onReady$.pipe(
      switchMap(async () => {
        try {
          const user = await this.migrateOldLoginAndDoCognitoLogin();
          //if not a staff member, then assume its a treat user
          if (user.signInUserSession.idToken.payload["custom:user_type"]) {
            return authCheckAtStartupSavedDetails({ user });
          } else {
            return authTreatUserCheckAtStartupSavedDetails({ user });
          }
        } catch (error) {
          return authCheckAtStartupNoDetails();
        }
      })
    )
  );

  treatUserLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authTreatUserCheckAtStartupSavedDetails),
        tap(() => {})
      ),
    { dispatch: false }
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLoginSuccess),
        tap(() => this.clearHttpCache())
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLogOut),
        tap(async () => {
          await this.auth.signOut();
          await this.storage.remove(STORAGE_KEY_LOGIN_DETAILS);
          await this.storage.remove(STORAGE_KEY_VENUE_ID);

          // clear all http cache so old authorization headers don't remain
          this.clearHttpCache();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private auth: AuthClass,
    private cacheManager: HttpCacheManager,
    private platform: Platform,
    private router: Router,
    private storage: StorageAdapter
  ) {}

  private async migrateOldLoginAndDoCognitoLogin() {
    const savedLogin = await this.storage.getObject<{
      username: string;
      password: string;
    }>(STORAGE_KEY_LOGIN_DETAILS);
    if (savedLogin) {
      await this.storage.remove(STORAGE_KEY_LOGIN_DETAILS);
      return this.auth.signIn({
        username: savedLogin.username,
        password: savedLogin.password,
      });
    }
    return this.auth.currentAuthenticatedUser();
  }

  private clearHttpCache() {
    this.cacheManager.delete("");
  }
}
