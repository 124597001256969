import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Package,
  SyncV1Reciept,
  SyncV1Table,
  SyncV1User,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class UsersState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1User }>();

  //fields specific to the service
  service_icon: string = "people-outline";
  service_title: string = "Users Store";
  service_id: string = "Users"; //id of the service
  service_url: string = "users";

  //fields specific to the data
  data_id: string = "cog_user_name";
  data_detail: string = "total";
  permissions_required: string[] = ["manage_users_ops", "manage_users_cms"];
  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));

  async Sync() {
    if (this.serviceState.get("refreshing")) {
      return "uness";
    }
    this.serviceState.set({
      refreshing: true,
    });
    ///throw error

    let data: any = await this.syncapis
      .getCompleteSync(this.service_url)
      .toPromise();

    let map = this.state.get();
    if (map != undefined) {
    //remove everything from the map
    Object.keys(map).forEach((key) => {
      delete map[key];
    });
    console.log(data);
  } else {
    map = {};
  }

    data.users.map((item) => {
      item.sort_key = data.sort_order;
      map[item[this.data_id]] = item;
    });
   
    //check the difference between the keys and if there is one that is missing, set the value of it  in the new map to undefined
    

    this.state.set(map);

    this.serviceState.set({
      refreshing: false,
    });

    return "success";
  }

  //get table using id
  
  user$ = (id) => this.state.select(id);
  users$ = this.state.select(map((menuItems) => Object.values(menuItems)));
}
