<ng-container *ngIf="isTreatMeOrder && !isVenueUser">
    <div class="" *ngIf="treatVenue != undefined">
      <div
        class="venueBackground"
        [style.background]="'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('+treatVenue?.logo_image+')'"
      ></div>
    </div>
      <br />
      <dq-treat-me-influencer-header *ngIf="(order$| async)?.status != 'Cancelled'"
        class="indexhigher mobilewidth"
        [isStaff]="isVenueUser"
        [details]="treatMeDetails"
        [step]="(order$ | async)?.status != 'Cancelled' ? orderActiveStep : undefined"
      >
      </dq-treat-me-influencer-header>

      <ion-icon name="sad-outline" color=dark class="large-icon" *ngIf="(order$ | async)?.status == 'Cancelled'"></ion-icon>

     <div *ngIf="(order$ | async)?.status != 'Cancelled'">
      <ion-label class="ion-text-center mobilewidth" [style.display]="'block'" [style.font-size.px]="17" *ngIf="orderActiveStep == 0">
        Awaiting Approval
      </ion-label>
      <ion-label class="ion-text-center mobilewidth" [style.display]="'block'" [style.font-size.px]="17" *ngIf="orderActiveStep == 1">
        Approved
      </ion-label>
      <ion-label class="ion-text-center mobilewidth" [style.display]="'block'" [style.font-size.px]="17" *ngIf="orderActiveStep == 2">
        Ready To Go!
      </ion-label>
      <ion-label class="ion-text-center mobilewidth" [style.display]="'block'" [style.font-size.px]="17" *ngIf="orderActiveStep == 3">
        Delivered
      </ion-label>
    </div>
    

  <ion-list lines="none" class="infoList mobilewidth" *ngIf="!isVenueUser">
    <ion-item color="transparent" class="transpa" *ngIf="(order$ | async)?.status == 'Cancelled'">
      <ion-text class="descriptionText ion-text-center" >
        Unfortunately your gift was unable to be accepted, this could be for many reasons. We have refunded your card.
      </ion-text>
    </ion-item>
    
      
    <ion-item color="transparent" class="transpa"    *ngIf="(order$ | async)?.status != 'Cancelled'"


      ><ion-text class="descriptionText ion-text-center" *ngIf="orderActiveStep == 0">
        {{ treatMeDetails?.thanks_for_using_text }}
      </ion-text>
      <ion-text class="descriptionText ion-text-center" *ngIf="orderActiveStep == 1">
        Whoop! Your gift has been accepted by the venue, we've let your lucky recipient know!
        We'll reach out when its about to be delivered
      </ion-text>
      <ion-text class="descriptionText ion-text-center" *ngIf="orderActiveStep == 2">
        Your gift has been prepared and setup! The venue will deliver it shortly, keep an eye out!
      </ion-text>
      <ion-text class="descriptionText ion-text-center" *ngIf="orderActiveStep == 3">
        Your gift was delivered! Keep on the lookout for thanks from your lucky recipient and to see how their night went!
      </ion-text>
      
      </ion-item
    >
  </ion-list>
</ng-container>
<div class="mobilewidth" >
  <ion-refresher slot="fixed" (ionRefresh)="refresh$.next($event.target)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
 

  <ng-container
    *ngIf="order$ | async as order else loadingTemplate"
  >
    
      <ion-header *ngIf="platform.is('tablet')">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              fill="clear"
              (click)="menu.close(); $event.stopPropagation()"
            >
              <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
              Back</ion-button
            >
          </ion-buttons>
          <ion-title>Order Details</ion-title>
        </ion-toolbar>
      </ion-header>
      <h1 class="ion-text-center orderTitle">
        Order {{ order.title
        }}<ion-badge *ngIf="order.display_payment_status" color="success">{{
          order.display_payment_status
        }}</ion-badge>
      </h1>
      <ion-button
        expand="block"
        *ngIf="isVenueUser"
        class="status-text"
        [fill]="order.current_column == 'Delivered' || order.current_column == 'Cancelled' ? 'outline' : 'solid'"
        [color]="order.current_column == 'Delivered' ? 'success' : order.current_column == 'Cancelled' ? 'danger' : 'primary'"
        (click)="
          orderStateButtonClicked()
        "
        >
        <ion-label color="light">{{ order.current_column }}</ion-label>
        <ion-spinner id="statusSpinner" class="statusSpinner hide"></ion-spinner
      ></ion-button>
      <ion-list  #voidlist [class.rad]="treatVenue != undefined">
        <ion-item-group>
          <ion-item-divider [style.margin-top.px]="20" color="dark-grey">
            <ion-label class="ion-text-uppercase">
              DETAILS
            </ion-label>
          </ion-item-divider>
          <app-cart-item
            [first]="i == 0"
            [last]="i == order.card_lines.length - 1"
            *ngFor="
              let item of order.card_lines;
              let i = index
            "
            [itemId]="item.item_id"
            [name]="item.item_name"
            [quantity]="item.quantity"
            [modifiers]="item.modifiers"
            [canEdit]="false"
            [price]="item.line_item_price"
            [voidable]="true"
            [voided]="item.voided"
            [currencyCode]=""
            (voidItem)="voidItem(item); voidlist.closeSlidingItems()"
          ></app-cart-item>
        </ion-item-group>

        <ng-container *ngIf="order?.package_id != undefined && (package$(order?.package_id) | async) != undefined">

          <ion-item-divider class="ion-margin-top" color="dark-grey">
            <ion-label class="ion-text-uppercase">
              SOURCE PACKAGE
            </ion-label>
          </ion-item-divider>
          <ion-item-group class="rounded-group">

            <app-package-card color="secondary" (click)="openPackage()" [detail]="true"  [package]="package$(order?.package_id) | async"></app-package-card>
          </ion-item-group>
        </ng-container>

        <ion-item-group
          class="group-summary"
          *ngIf="
            !(order.status === 'Cancelled' || order.status === 'Delivered') &&
            order.show_pickup_delivery_details &&
            order.service_text != null           "
        >
          <ion-item-divider class="ion-text-uppercase" color="dark-grey"
            >{{ order.table_service }} Details</ion-item-divider
          >
          <ion-item color="secondary">
            <ion-label class="ion-text-wrap">{{
              order.service_text
            }}</ion-label>
          </ion-item>
        </ion-item-group>
        
        <ion-item-group class="group-summary"
          ><ion-item-divider color="dark-grey"
            ><ion-label class="ion-text-uppercase"> Summary </ion-label>
          </ion-item-divider>
          <ng-container *ngIf="order.treat_note">
            <ion-item color="secondary"  lines="full" class="first backgr" *ngIf="isVenueUser" >
              <ion-label>
                <ion-label>Gift for {{order.guest_name}}</ion-label>
                <ion-note
                >{{order?.treat_note}}</ion-note
                >
              </ion-label>
              <ion-avatar slot="end">
                <ion-img [src]="order.guest_image | imagePipe: 'small'"/>
                
              </ion-avatar>
           
            </ion-item>
            
          </ng-container>

          <ng-container>
            <ion-item (click)="openTable()" [detail]="true" color="secondary" [class.first]="!order?.treat_note">
              <ion-label>Table</ion-label>
              <ion-note slot="end" class="ion-text-right">{{
                order.table_name
              }}</ion-note>
            </ion-item>
          </ng-container>

        
          
          <ion-item
          [detail]="true"
            color="secondary"
            (click)="openGuest()"
          >
            <ion-label>Guest</ion-label>
            <ion-note slot="end" class="ion-text-right ion-text-wrap">{{
              (guest$(order.guest_id) | async)?.name
            }}</ion-note>
          </ion-item>

          <ion-item
          *ngIf="order.staff_expected_incentive_text != undefined"
            color="secondary"
          >
            <ion-label>Staff Incentive</ion-label>
            <ion-note slot="end" class="ion-text-right ion-text-wrap">{{
              order.staff_expected_incentive_text
            }}</ion-note>
          </ion-item>
          <ion-item
          *ngIf="order.last_touched_user_name != undefined"
            color="secondary"
          >
            <ion-label>Last Touched</ion-label>
            <ion-note slot="end" class="ion-text-right ion-text-wrap">{{
              order.last_touched_user_name 
            }}</ion-note>
          </ion-item>
          <ion-item
            color="secondary"
          >
            <ion-label>Placed</ion-label>
            <ion-note slot="end" class="ion-text-right ion-text-wrap">{{
              order.created_at_local | date: 'mediumTime'
            }}</ion-note>
          </ion-item>
          <a [href]="'tel:' + order.phone_e164" *ngIf="order.phone_e164">
            <ion-item button="true" detail="false" color="secondary">
              <ion-label>Guest Telephone</ion-label>
              <ion-note slot="end" class="ion-text-right">{{
                order.phone_e164
              }}</ion-note>
            </ion-item>
          </a>
          <ion-item color="secondary">
            <ion-label>Sub Total</ion-label>
            <ion-note slot="end" class="ion-text-right">{{
              order.subtotal
            }}</ion-note>
          </ion-item>
          <ion-item color="secondary">
            <ion-label>Tax</ion-label>
            <ion-note slot="end" class="ion-text-right">{{
              order.tax
            }}</ion-note>
          </ion-item>
          <ion-item
            *ngIf="order.gratuity && +order.gratuity !== 0"
            color="secondary"
          >
            <ion-label>Gratuity</ion-label>
            <ion-note slot="end" class="ion-text-right">{{
              order.gratuity
            }}</ion-note>
          </ion-item>
          
         
          <ion-item color="secondary" class="last">
            <ion-label>Total</ion-label>
            <ion-note slot="end" class="ion-text-right">{{
              order.total_price_text
               
            }}</ion-note>
          </ion-item>
        </ion-item-group>
        <ion-item-group *ngIf="order.receipt_id != undefined && isVenueUser">
          <app-reciept
            [recieptId]="order.receipt_id"
            [Actionable]="true"
            (paymentOpen)="OpenPayments($event)"
          ></app-reciept>
        </ion-item-group>
      </ion-list>
    </ng-container>
</div>

<ng-template #loadingTemplate>
  <ng-container *ngIf="loadingTimeout$ | async else timeoutTemplate">
    <h1 class="ion-text-center orderTitle">
      <ion-skeleton-text
        [animated]="true"
        [style.width]="'40%'"
        [style.height]="'31px'"
        class="center"
      ></ion-skeleton-text>
    </h1>

    <ion-button expand="block" class="status-text"
      ><ion-skeleton-text
        [animated]="true"
        [style.width]="'80%'"
      ></ion-skeleton-text>
      <ion-spinner id="statusSpinner" class="statusSpinner hide"></ion-spinner
    ></ion-button>

    <ion-list lines="none">
      <ion-item-group>
        <ion-item-divider color="dark-grey"
          ><ion-label class="ion-text-uppercase"> </ion-label>
        </ion-item-divider>
        <ion-item color="secondary" class="rounded-first">
          <ion-label class="ion-text-wrap"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
        </ion-item>
        <ion-item color="secondary" class="rounded-last">
          <ion-label class="ion-text-wrap"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
        </ion-item>
      </ion-item-group>

      <ion-item-group class="group-summary"
        ><ion-item-divider color="dark-grey"
          ><ion-label class="ion-text-uppercase"> Summary </ion-label>
        </ion-item-divider>
        <ng-container>
          <ion-item color="secondary" class="rounded-first">
            <ion-label
              ><ion-skeleton-text
                [animated]="true"
                [style.width]="'80%'"
              ></ion-skeleton-text
            ></ion-label>
            <ion-note slot="end" class="ion-text-right"
              ><ion-skeleton-text
                [animated]="true"
                [style.width]="'80%'"
              ></ion-skeleton-text
            ></ion-note>
          </ion-item>
        </ng-container>
        <ion-item color="secondary" class="">
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note slot="end" class="ion-text-right"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-item>
        <ion-item color="secondary" class="">
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note slot="end" class="ion-text-right"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-item>

        <ion-item color="secondary" class="">
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note slot="end" class="ion-text-right"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-item>
        <ion-item color="secondary" class="">
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note slot="end" class="ion-text-right"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-item>

        <ion-item color="secondary" class="rounded-last">
          <ion-label
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-label>
          <ion-note slot="end" class="ion-text-right"
            ><ion-skeleton-text
              [animated]="true"
              [style.width]="'80%'"
            ></ion-skeleton-text
          ></ion-note>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </ng-container>
</ng-template>

<ng-template #timeoutTemplate>

 <app-empty height="full-height"  title="Order Not Found" label="This maybe because this order is too old, and has been archived from service, or the order doesnt exist anymore" icon="alert-circle-outline">

 </app-empty>
</ng-template>
