import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RegionsService } from "../regions/regions.service";
import {
  PaymentMethods,
  Receipt,
  ReceiptPaymentPost,
} from "src/app/models/payment_models";
import { MenuController, Platform } from "@ionic/angular";
import { MenuService } from "../menu/menu.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private platform: Platform,
    private menuSvc: MenuController,
    private router: Router
  ) {}

  selected_payment;

  selected_order;

  async paymentOnReciept(re_uuid, default_amount = undefined) {
    console.log("Payment on reciept");
    //if menu is open close it
    //get window width
    //if window width is less than 600px
    if (
      this.platform.is("tablet") ||
      this.platform.is("desktop") ||
      window.innerWidth > 1050
    ) {
      //add default_amount query param to the url we are on currently

      if (default_amount != undefined) {
        //split url so dont have to worry about query params
        let url = this.router.url.split("?")[0];
        console.log("2");

        await this.router.navigate([url], {
          queryParams: { default_amount: default_amount },
          queryParamsHandling: "merge", // merge with the current query params
        });
        console.log("4");
      }

      await this.OpenSidebar("payment", re_uuid);
      console.log("5");
    } else {
      //naviaget to payment
      //route to pay with this url as the return_url

      let defsettings = { return_url: this.router.url };
      if (default_amount != undefined) {
        defsettings["default_amount"] = default_amount;
      }

      await this.router.navigate([this.reg.getRegion(), "pay", re_uuid], {
        queryParams: defsettings,
        queryParamsHandling: "merge", // merge with the current query params
      });
      console.log("6");
    }
  }

  async OpenSidebar(type, id) {
    this.menuSvc.open("side-custom");

    this.selected_payment = undefined;
    this.selected_order = undefined;
    if (type == "order") {
      await new Promise((resolve) => setTimeout(resolve, 20));
    }
    if (await this.menuSvc.isOpen("side-custom")) {
      this.menuSvc.close("side-custom");
      while (await this.menuSvc.isOpen("side-custom")) {
        console.log("waiting for menu to close");
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    }
    await this.menuSvc.open("side-custom");

    switch (type) {
      case "payment":
        this.selected_payment = id;
        break;
      case "order":
        this.selected_order = id;

        break;
    }
  }

  getRecieptMethods(recieptId?: string, params?) {
    //if there are any keys in params add them and their values to the url

    return this.http.get<PaymentMethods>(
      `${this.reg.ServerURL()}/receipt/${recieptId}/methods`,
      { params: params }
    );
  }

  getReciept(recieptId: string) {
    return this.http.get<Receipt>(
      `${this.reg.ServerURL()}/receipt/${recieptId}`
    );
  }

  postRecieptPayment(recieptId: string, payment: ReceiptPaymentPost) {
    return this.http.post(
      `${this.reg.ServerURL()}/receipt/${recieptId}`,
      payment
    );
  }

  getRecieptPayment(paymentId: string) {
    return this.http.get(`${this.reg.ServerURL()}/payment/${paymentId}`);
  }

  run_external_request(url: string) {
    return this.http.get(url);
  }

  postPaymentAction(re_uuid, pi_uuid, action) {
    return this.http.post(
      `${this.reg.ServerURL()}/receipt/${re_uuid}/payment/${pi_uuid}/action`,
      action
    );
  }

  sendPaymentReceipt(pi_uuid, contact) {
    return this.http.post(
      `${this.reg.ServerURL()}/payment/${pi_uuid}/receipt`,
      {
        phone_or_email: contact,
      }
    );
  }
}
