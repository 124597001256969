import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { from, Observable, throwError } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { StorageAdapter } from "../services/storage-adapter/storage-adapter.service";
import { RegionsService } from "../services/regions/regions.service";
import { ToastController } from "@ionic/angular";
import { SyncService } from "../services/sync-engine/sync/sync.service";
const STORAGE_KEY_USER_ID = "queuebar:user_id";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

@Injectable()
export class SyncInterceptor implements HttpInterceptor {
  constructor(
    public toast: ToastController,
    private sync: SyncService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event.type == HttpEventType.Response) {
          if (event.status == 200 ) {
            if(event.body.sync_events != undefined) {
              console.log('Syncing Events', event.body.sync_events.events.length);

              event.body.sync_events.events.forEach(element => {
                this.sync.RecivedEvent(element);
              });
            }
          }
        }
      })
    );
  }
}
