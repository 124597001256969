import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CMSv2CollectionList,
  CMSv2CollectionPoint,
  CMSv2Logs,
  CMSv2ManagementModelOutput,
  CMSv2MenuCategory,
  CMSv2Table,
  CMSv2TableList,
  CMSv2UserList,
  CMSv2VenueSettings,
  CMS_Package,
  CMS_Package_List,
  NestedLogData,
} from "src/app/models/cms_db_equiv";
import { RegionsService } from "../../regions/regions.service";
import { SyncV1Package } from "src/app/models/sync_models";
import {
  AllFilterableOptions,
  AllGuestFilterResponses,
} from "src/app/models/live_tags";

@Injectable({
  providedIn: "root",
})
export class CmsSettingsService {
  constructor(public reg: RegionsService, public http: HttpClient) {}

  getSettings() {
    return this.http.get<CMSv2VenueSettings>(
      `${this.reg.ServerURL()}/cms_v2/venue/settings`
    );
  }

  createDeviceUser(obj) {
    return this.http.post<AllFilterableOptions>(
      `${this.reg.ServerURL()}/cms_v2/venue/device_user`,
      obj
    );
  }


  getSearchData() {
    return this.http.get<AllFilterableOptions>(
      `${this.reg.ServerURL()}/live_tags/filter_options`
    );
  }
  searchGuests(data) {
    return this.http.post<AllGuestFilterResponses>(
      `${this.reg.ServerURL()}/live_tags/guests/query`,
      data
    );
  }
  searchGuestsCSV(data) {
    return this.http.post(
      `${this.reg.ServerURL()}/live_tags/guests/query.csv`,
      data,
      { responseType: "blob" }
    );
  }
  runAIAssistant(search) {
    return this.http.post(`${this.reg.ServerURL()}/live_tags/ai_query`, {
      searchterm: search,
    });
  }

  getDojoIntegration() {
    return this.http.get(`${this.reg.ServerURL()}/cms_v2/integrations/dojo`);
  }
  postDojoIntegration(body) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/integrations/dojo`,
      body
    );
  }

  updateRole(role) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/role/${role.r_id}`,
      role
    );
  }

  newRole(role) {
    return this.http.post(`${this.reg.ServerURL()}/cms_v2/venue/role`, role);
  }

  saveSettings(settings) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/settings`,
      settings
    );
  }

  updatePayment(id: string, value: boolean) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/payments/${id}`,
      {
        value,
      }
    );
  }
  createCollection(collection: CMSv2CollectionPoint) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/collection`,
      collection
    );
  }
  saveCollection(collection: CMSv2CollectionPoint) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/collection/${collection.vc_id}`,
      collection
    );
  }
  deleteCollection(collection: CMSv2CollectionPoint) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/collection/${collection.vc_id}`
    );
  }

  setupStripe() {
    return this.http.get(`${this.reg.ServerURL()}/cms_v2/venue/stripe`);
  }

  getUsers(type = "") {
    return this.http.get<CMSv2UserList>(
      `${this.reg.ServerURL()}/cms_v2/venue/users`,
      { params: { user_Types: type } }
    );
  }

  getPromoter() {
    return this.http.get<CMSv2UserList>(
      `${this.reg.ServerURL()}/cms_v2/venue/users/promoters`
    );
  }

  createUser(user: CMSv2ManagementModelOutput) {
    return this.http.post(`${this.reg.ServerURL()}/cms_v2/venue/users`, user);
  }

  changeUserStatus(user: CMSv2ManagementModelOutput) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/users/${user.cog_user_name}`,
      { enabled: user.is_enabled }
    );
  }

  deleteUser(username: string) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/venue/users/${username}`
    );
  }

  getTableExport() {
    this.http
      .get(`${this.reg.ServerURL()}/cms_v2/table/csv`, { responseType: "blob" })
      .subscribe(async (res) => {
        let text = await res.text();
        console.log(res);
        const link = document.createElement("a");
        link.setAttribute("style", "display: none");
        link.href = "data:attachment/csv," + encodeURI(text); // <- it was as simple as this.
        const date = new Date();
        link.download = "DQTables.csv";
        link.click();
        link.remove();
      });
  }

  GetBookingData() {
    this.http
      .get(`${this.reg.ServerURL()}/cms_v2/guests/csv`, {
        responseType: "blob",
      })
      .subscribe(async (res) => {
        let text = await res.text();
        console.log(res);
        const link = document.createElement("a");
        link.setAttribute("style", "display: none");
        link.href = "data:attachment/csv," + encodeURI(text); // <- it was as simple as this.
        const date = new Date();
        link.download = "DQGuests.csv";
        link.click();
        link.remove();
      });
  }

  GetLightSpeed() {
    return this.http.get(
      `${this.reg.ServerURL()}/cms_v2/integrations/lightspeed`
    );
  }

  PullMenu() {
    return this.http.get(
      `${this.reg.ServerURL()}/cms_v2/integrations/lightspeed/menu_pull`
    );
  }

  RefreshTokens() {
    return this.http.get(
      `${this.reg.ServerURL()}/cms_v2/integrations/lightspeed/refresh_tokens_manual`
    );
  }

  getLogs(
    event: NestedLogData,
    table: NestedLogData,
    order: NestedLogData,
    staff: NestedLogData,
    start: Date,
    end: Date
  ) {
    //add query strings to the url, if object are not undefined or null
    let queryParams = new HttpParams();

    if (event != undefined) {
      queryParams = queryParams.append("event", event.id);
    }
    if (table != undefined) {
      queryParams = queryParams.append("table", table.id);
    }
    if (order != undefined) {
      queryParams = queryParams.append("order", order.id);
    }
    if (staff != undefined) {
      queryParams = queryParams.append("owner", staff.id);
    }
    if (start != undefined) {
      queryParams = queryParams.append("start_date", start.toISOString());
    }
    if (end != undefined) {
      queryParams = queryParams.append("end_date", end.toISOString());
    }
    return this.http.get<CMSv2Logs>(
      `${this.reg.ServerURL()}/cms_v2/venue/audit_logs`,
      { params: queryParams }
    );
  }

  getLogTypes() {
    let types = {
      "order.created": {
        level: "staff",
        template: "Order {order} created by {owner} for {price}",
        description: "Order Created, Can be done by staff, or users",
      },
      "order.preparing": {
        level: "staff",
        template: "Order {order} was moved to preparing by {owner}",
        description: "This order was marked as being prepared",
      },
      "order.ready": {
        level: "staff",
        template: "Order {order} was moved to ready by {owner}",
        description: "This order was marked as being ready",
      },
      "order.delivered": {
        level: "staff",
        template: "Order {order} was moved to delivered by {owner}",
        description: "This order was marked as being delivered",
      },
      "order.cancelled": {
        level: "staff",
        template: "Order #{order} was cancelled by {.owner}",
        description:
          "This order was cancelled, this will trigger any payments to refund",
      },
      "payment.created": {
        level: "staff",
        template: "Payment for order #{order} was started by {owner}",
        description:
          "A payment was created, this does not confirm payment has been taken",
      },
      "payment.completed": {
        level: "staff",
        template: "Payment for order #{order} was completed by {owner}",
        description:
          "The Payment was completed, this confirms payment has been taken",
      },
      "payment.updated": {
        level: "staff",
        template: "Payment for order #{order} was updated by {owner}",
        description: "The Payment was updated",
      },
      "table.opened": {
        level: "staff",
        template: "Table {table} was opened by {owner}",
        description: "This Table was opened and a guest was added",
      },
      "table.closed": {
        level: "staff",
        template: "Table {table} was closed by {owner}",
        description:
          "Table Closed, any Payments must be closed out before this happens",
      },
      "treat.created": {
        level: "manager",
        template: "Treat link was created for {guest} by {owner}",
        description: "A Treat Link was created by a customer",
      },
      "order.accepted": {
        level: "manager",
        template: "Treat Order was accepted for {guest} by {owner}",
        description:
          "order was accepted (only required for TREAT), triggering collection of payment, and shows the orders to BOH",
      },
      "order.declined": {
        level: "manager",
        template: "Treat Order {order} was declined for {guest} by {owner}",
        description:
          "order was declined (only required for TREATS. This refunds the customers payments",
      },
      "treat.viewed": {
        level: "info",
        template: "Treat link for {guest} was viewed by {owner}",
        description: "Treat link was viewed",
      },
      "test.created": {
        level: "info",
        template: "Test {event} with id {id}",
      },
      "order.expired": {
        level: "manager",
        template: "Treat for {guest} not was not accepted ",
        description: "Treat link was not accepted, triggering release of card",
      },
      "notification.created": {
        level: "manager",
        template: "A notification was submitted for {table} by {owner}",
        description: "A support notification was submitted by a staff member",
      },
      "notification.accepted": {
        level: "manager",
        template: "A notification for {table} was accepted by {owner}",
        description: "A support notification was accepted",
      },
      "notification.deleted": {
        level: "manager",
        template: "A notification for {table} was deleted  by {owner}",
        description: "A support notification was deleted",
      },
      "guest.moved": {
        level: "staff",
        template: "A guest was moved from {table} by {owner}",
        description: "A guest was moved",
      },
      "booking.created": {
        level: "staff",
        template: "A booking for {guest} was created by {owner}",
        description: "A booking was created",
      },
      "booking.accepted": {
        level: "staff",
        template: "A booking for {guest} was accepted by {owner}",
        description: "A booking was accepted",
      },
      "booking.declined": {
        level: "staff",
        template: "A booking for {guest} was declined by {owner}",
        description: "A booking was declined",
      },
      "booking.seated": {
        level: "staff",
        template: "A booking for {guest} was seated at {table} by {owner}",
        description: "A booking was seated",
      },
      "booking.cancelled": {
        level: "staff",
        template: "A booking for {guest} was cancelled by {owner}",
        description: "A booking was cancelled",
      },
      "booking.updated": {
        level: "staff",
        template: "A booking for {guest} was updated by {owner}",
        description: "A booking was updated",
      },
      "pos.submission": {
        level: "staff",
        template: "Pos submission or response",
        description: "Orders sent to the POS, or a response from the POS",
      },
    };

    //map types to array of objects with the key as a name field, and the id field, and the description field as a value
    let typeArray = [];
    for (let key in types) {
      typeArray.push({
        Name: key,
        id: key,
        description: types[key].description,
      });
    }
    let extra = [
      { id: "order", Name: "orders" },
      { id: "table", Name: "tables" },
      { id: "notification", Name: "notifications" },
      { id: "treat", Name: "treats" },
      { id: "payment", Name: "payments" },
    ];
    return typeArray.concat(extra);
  }

  viewBillingPortal() {
    return this.http.get<any>(`${this.reg.ServerURL()}/cms_v2/venue/billing`);
  }

  setupPlan(plan: string) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/billing/${plan}`,
      {}
    );
  }

  getDashboard(startdate, enddate) {
    return this.http.get(`${this.reg.ServerURL()}/cms_v2/venue/dashboard`, {
      params: new HttpParams()
        .set("start_date", startdate)
        .set("end_date", enddate),
    });
  }

  //get the package list
  getPackageList() {
    return this.http.get<CMS_Package_List>(
      `${this.reg.ServerURL()}/cms_v2/venue/packages`
    );
  }

  updatePackage(packg: SyncV1Package) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/packages/${packg.package_id}`,
      packg
    );
  }
  deletePackage(packg: SyncV1Package) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/venue/packages/${packg.package_id}`
    );
  }

  createPackage(packg: SyncV1Package) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/packages`,
      packg
    );
  }
}
