<div class="event-selector">
  <ion-icon
    name="chevron-back-outline"
    (click)="updateMonth(CurrentMonth - 1)"
    color="light"
  ></ion-icon>

  <swiper-container  [loop]="true" #swiper [initialSlide]="CurrentMonth">
    <swiper-slide *ngFor="let month of Months; let i = index">
      <div [class.active]="i === CurrentMonth">
        <h3>{{ month }}</h3>
      </div>
    </swiper-slide>
  </swiper-container>

  <ion-icon
    name="chevron-forward-outline"
    (click)="updateMonth(CurrentMonth + 1)"
    color="light"
  ></ion-icon>
</div>  
