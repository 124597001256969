import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActionSheetController, AlertController } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { map } from "rxjs/operators";
import { DqCartItem } from "src/app/models/cart.model";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { State, venuesSelectOrderNotifcations } from "src/app/reducers";
import { selectOrderNotifcations } from "src/app/reducers/venues/venues.reducer";
import {
  getCartTotalPrice,
  getTaxFromTotal,
} from "src/app/utils/calculate-cart-total";
import { paramsToFormData } from "src/app/utils/object-to-form-data";
import { environment } from "src/environments/environment";
import { RegionsService } from "../regions/regions.service";

export type DqGratuityType = "amount" | "percentage";

export type DqPaymentMethod =
  | "paylater"
  | "cash"
  | "eftpos"
  | "square"
  | "PendingSquare";

export type DqOrderStatus =
  | "Preparing"
  | "Ready"
  | "Delivered"
  | "Pending"
  | "Cancelled";

export type DqLegacyOrderPaymentMethod =
  | "EFTPOS"
  | "Cash"
  | "cash"
  | ""
  | "MyTab"
  | "CreditCard";

export interface DqOrderDetailsItem {
  od_id: string; //  "90639";
  order_round: string; // "32248_1";
  od_order_id: string; // "32248";
  order_collection_point_id: string; // "99";
  od_item_id: string; //  "11417";
  od_item_hmc_id: string; // "45";
  od_item_name: string; // "Espresso Martini";
  drink_type: string; //  "45";
  od_mixture: string; // "";
  garnish_ids: string; // "";
  od_price: string; // "20";
  od_quantity: string; // "1";
  notifi_status: DqOrderStatus; // "Pending";
  od_time: string; // null;
  o_dev_time: string; // "00:00:00";
  o_ingredients: string; // "";
  od_free_prod: string; // "";
  od_tax: string; // null;
}
export interface DqOrderDetails {
  o_id: string; // "32248";
  o_user_id: string; // "11889";
  o_venue_id: string; // "1";
  Order_cp_id: string; // "99";
  o_payment_method: string; // "";
  o_authorization_id: string; // "";
  o_refresh_token: string; //  "";
  PayPalTransactionId: string; // "";
  o_CredCard_trans_id: string; //  "";
  o_date: string; // "2019-08-06";
  o_time: string; // "11:05:18";
  o_status: DqOrderStatus; // "Pending";
  o_gratuity: string; // "0";
  o_tax: string; // "0";
  o_discount: string; // "0";
  o_discount_type: string; // "";
  o_promo_code: string; // "";
  promo_id: string; // "";
  sub_total: string; // "86.00";
  o_total: string; // "86.00";
  tbl_number: string; // "Rooftop C4";
  o_cancel_date: string; // "0000-00-00";
  o_cancel_time: string; // "00:00:00";
  o_canceled_by: string; // "";
  o_cancel_reason: string; // "";
  o_refund: string; // "";
  o_refund_date: string; // "0000-00-00";
  o_refund_time: string; // "00:00:00";
  o_clear_status: string; // "1";
  pay_capture_status: string; // "";
  captured_id: string; // "";
  capture_maxamount: string; //  "";
  o_promo_used_credit: string; // "0.00";
  order_guest_user_id: string; // "11407";
  close_status: string; // null;
  guest_table_id: string; // "184";
  itemcount: string; // "4";
  reciept_id?: string;
  order_details: DqOrderDetailsItem[];
}
export interface DqOrderDetailsResponseSuccess {
  status: "success";
  getorders: DqOrderDetails[];
}

export interface DqOrderDetailsResponseError {
  status: "failure";
  message: string;
}

export interface DqOrderPaymentResponseSuccess {
  payment_method: string;
  payment_status: string;
}

export interface DqOrderPaymentResponseError {
  status: "failure";
  message: string;
}

export type DqOrderDetailsResponse =
  | DqOrderDetailsResponseSuccess
  | DqOrderDetailsResponseError;

export type DqOrderPaymentResponse =
  | DqOrderPaymentResponseSuccess
  | DqOrderPaymentResponseError;

export interface DqBasicOrderDetails {
  o_id: string; // "32082",
  sub_total: string; // "0.0",
  o_payment_method: DqLegacyOrderPaymentMethod;
  o_total: string; // "0.0",
  o_status: DqOrderStatus;
  guest_id: string; // "11178",
  guest_name: string; // "TESTING CLIENT PYTHON",
  guest_email: string; // "",
  tbl_number: string; //  "Rooftop C2"
}

export interface DqCollectionOrderBasicDetails {
  guest_email: string; // ""
  guest_id: string; // "11407"
  guest_name: string; //  "James"
  o_id: string; // "32280"
  o_payment_method: string; //  "Cash"
  o_status: string; // "Pending"
  o_total: string; // "66.00"
  sub_total: string; // "66.00"
  tbl_number: string; // "Rooftop C4"
}
export interface DqCollectionOrderDetails {
  vc_id: string; // "99",
  vc_name: string; // "Rooftop",
  venue_id: string; // "1",
  orders_basic_details: DqCollectionOrderBasicDetails[];
}
export interface DqOrderHistoryResponseSuccess extends DqApiResponse {
  status: "success";
  vieworders: DqCollectionOrderDetails[];
}
export interface DqOrderHistoryResponseError extends DqApiResponse {
  status: "failed";
  vieworders: "Data not found";
}
export type DqOrderHistoryResponse =
  | DqOrderHistoryResponseSuccess
  | DqOrderHistoryResponseError;

export interface DqPlaceOrderParams {
  items: DqCartItem[];
  venue_id: string;
  venue_tax_rate: number;
  guest_id: string;
  table_id: string;
  collection_point_id: string;
  payment_method: DqPaymentMethod;
  gratuity: number;
  gratuityType: DqGratuityType;
  paymentMethod: DqPaymentMethod;
  square_transaction_id?: string;
  square_client_transaction_id?: string;
}
export interface DqPlaceOrderResponseSuccess {
  status: "success";
  message: string;
  main_order_id: number; // 32276
  "order id": number; // 90694
  payment_status: "Paid" | "Unpaid";
}

export interface DqPlaceOrderResponseError {
  status: "failed";
  message: string;
}

export type DqPlaceOrderResponse =
  | DqPlaceOrderResponseSuccess
  | DqPlaceOrderResponseError;

export interface DqPlaceRepeatOrderParams {
  collection_point_id: string;
  guest_id: string;
  guest_name: string;
  items: DqCartItem[];
  order_id: string;
  table_id: string;
  venue_id: string;
}
export interface DqPlaceRepeatOrderResponseSuccess {
  status: "success";
  message: string;
  item_id: string[]; // 32276
}

export interface DqPlaceRepeatOrderResponseError {
  status: "failed";
  message: string;
}

export type DqPlaceRepeatOrderResponse =
  | DqPlaceRepeatOrderResponseSuccess
  | DqPlaceRepeatOrderResponseError;

export interface OrderColumns {
  order_columns: OrderColumn[];
}

export interface OrderColumn {
  oc_key: string;
  oc_name: string;
  oc_count?: number;
  oc_is_primary?: true;
}

interface DqOrderItem {
  od_free_prod: "";
  od_item_id: string; // 12587,
  od_price: string; // "12.50",
  order_collection_point_id: string; // "99",
  drink_type: string; //  "155",
  /**
   * This parameter is used to collect comments
   */
  o_ingredients: string; // "",
  /**
   * Delivery time in HH:MM:SS
   */
  o_dev_time: string; // "",
  od_item_name: string; // "Belvedere",
  od_quantity: string; // "1",
  od_item_hmc_id: string; // "155"
  modifier_groups: { mg_id: number; mod_ids: number[] }[];
}

interface DqOrderParams {
  o_user_id: string;
  o_venue_id: string;
  o_payment_method: DqPaymentMethod | "";
  /**
   * 2019-07-31
   */
  o_date: string;
  /**
   * 16:22:25
   */
  o_time: string;
  o_status: "Pending";
  o_gratuity: string;
  o_tax: string;
  o_discount: string;
  o_total: string;
  tbl_number: string;
  // o_discount_type: string
  Order_cp_id: string;
  order_flag: string;
  order_details: string; // DqOrderItem[] // (POST, not as an argument),
  square_transaction_id?: string;
  square_client_transaction_id?: string;
}

export interface DqSendEmailResponse extends DqApiResponse {
  message: string;
}

export interface DqTransaction {
  guest_name: string; // "Customer Order Example",
  background_colour_mobile?: string;
  background_colour_tablet?: string;
  location: string; // "Rooftop C1",
  o_total: string; // "100.35000000",
  o_uuid: string; // "125df6b9-9768-4f87-a0bc-4dbaf080206d",
  order_statuses: {
    o_id: number; // 39593,
    o_status: string; // "Delivered"
  }[];
  payment_method: string; // "Credit Card [Customer]",
  payment_status: string; // "paid",
  service_text: string; // "Delivered to Rooftop C1",
  table_id: number;
  timestamp: string; // "2020-06-17T05:48:09Z"
  vcp_id: number;
}

export interface DqGuestTypes {
  segment_id: number;
  segment_name: string;
}

export interface DqSegementTypes {
  guest_type_id: number;
  guest_type_name: string;
}
export interface DqOrdersResponse {
  collection_points: DqOrderCollectionPoint[];
  tables: DqOrderTable[];
  order_cards: DqOrderCard[];
  guest_types: DqGuestTypes[];
  segments: DqSegementTypes[];
  order_columns?: OrderColumn[];
  order_notifications?: string[];
}
export interface DqOrderCollectionPoint {
  collection_point_id: number;
  tablecollection_point_name_name: string;
}
export interface DqOrderTable {
  table_id: number;
  table_name: string;
}
export interface DqOrderCard {
  title: string;
  table_id: number;
  collection_point_id: number;
  total: number;
  location: string;
  guest_name: string;
  placed_by_me: boolean;
  oc_keys?: string[];
  status: string;
  order_summary?: string;
  state_changes: DqOrderStateChange[];
  o_uuid?: string;
  guest_type_id: number;
  segment_id: number;
  timestamp: string;
  tablet_view_line_groups?: DqTabletViewLineGroups[];
}

export interface DqTabletViewLineGroups {
  lines: DqTabletViewLines[];
}

export interface DqTabletViewLines {
  background_colour?: string;
  elements?: DqTabletViewElement[];
}

export interface DqTabletViewElement {
  bold?: boolean;
  italic?: boolean;
  alignment?: string;
  text?: string;
  font_size?: number;
  background_colour?: string;
}
export interface DqOrderStateChange {
  name: string;
  state: string;
  url: string;
  enabled: true;
  style: string;
  show_on_tablet_card?: boolean;
  role: string;
}

export interface DqMenuNotifications {
  show: boolean;
  text: string;
}

@Injectable({
  providedIn: "root",
})
export class OrderService {
  orderNotifcations$ = this.store$.pipe(select(venuesSelectOrderNotifcations));
  constructor(
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    private http: HttpClient,
    private store$: Store<State>,
    private reg: RegionsService
  ) {}

  getDetails(id: string) {
    const path = "/admin/master/WS/hotel_ws/get_order_details.php";
    return this.http
      .get<DqOrderDetailsResponse[]>(`${this.reg.ServerURL()}${path}`, {
        params: { o_id: id },
      })
      .pipe(map(([data]) => data));
  }

  checkPaymentStatus(id: string) {
    const path = `/v4/orders/${id}/payment`;
    return this.http.get<DqOrderPaymentResponse>(
      `${this.reg.ServerURL()}${path}`
    );
  }

  voidItem(url: string, num_to_void: number, current_quantity: number) {
    const path = url;
    return this.http.post(`${this.reg.ServerURL()}${path}`, {
      num_to_void,
      current_quantity,
    });
  }

  registerActivity(token, o_uuid) {
    const path = "/v3/register_activity/" + o_uuid + "/order";
    return this.http.post<DqApiResponse>(
      `${this.reg.ServerURL()}${path}`,
      token
    );
  }
  /*
  [
  {
    "status": "success",
    "vieworders": [
      {
        "vc_id": "99",
        "vc_name": "Rooftop",
        "venue_id": "1",
        "orders_basic_details": [
          {
            "o_id": "32082",
            "sub_total": "0.0",
            "o_payment_method": "Cash",
            "o_total": "0.0",
            "o_status": "Pending",
            "guest_id": "11178",
            "guest_name": "TESTING CLIENT PYTHON",
            "guest_email": "",
            "tbl_number": "Rooftop C2"
          },
          {
            "o_id": "32081",
            "sub_total": "0.0",
            "o_payment_method": "Cash",
            "o_total": "0.0",
            "o_status": "Pending",
            "guest_id": "11177",
            "guest_name": "TESTING CLIENT PYTHON",
            "guest_email": "",
            "tbl_number": "Rooftop C2"
          },
          {
            "o_id": "32080",
            "sub_total": "0.0",
            "o_payment_method": "Cash",
            "o_total": "0.0",
            "o_status": "Pending",
            "guest_id": "11176",
            "guest_name": "TESTING CLIENT PYTHON",
            "guest_email": "",
            "tbl_number": "Rooftop C1"
          }
        ]
      },
      {
        "vc_id": "154",
        "vc_name": "VIP",
        "venue_id": "1",
        "orders_basic_details": [
          {
            "o_id": "32077",
            "sub_total": "0.0",
            "o_payment_method": "Cash",
            "o_total": "0.0",
            "o_status": "Pending",
            "guest_id": "11173",
            "guest_name": "TESTING CLIENT PYTHON",
            "guest_email": "",
            "tbl_number": "VIP B2"
          }
        ]
      },
      {
        "vc_id": "155",
        "vc_name": "Club",
        "venue_id": "1",
        "orders_basic_details": [
          {
            "o_id": "32079",
            "sub_total": "0.0",
            "o_payment_method": "Cash",
            "o_total": "0.0",
            "o_status": "Pending",
            "guest_id": "11175",
            "guest_name": "TESTING CLIENT PYTHON",
            "guest_email": "",
            "tbl_number": "Club A2"
          },
          {
            "o_id": "32078",
            "sub_total": "0.0",
            "o_payment_method": "Cash",
            "o_total": "0.0",
            "o_status": "Pending",
            "guest_id": "11174",
            "guest_name": "TESTING CLIENT PYTHON",
            "guest_email": "",
            "tbl_number": "Club A1"
          }
        ]
      }
    ]
  }
]

  */
  getOrderHistory(params: { venue_id: string }) {
    const path = "/admin/master/WS/hotel_ws/view_orders.php";
    return this.http
      .get<DqOrderHistoryResponse[]>(`${this.reg.ServerURL()}${path}`, {
        params,
      })
      .pipe(map(([data]) => data));
  }

  //legacy method of gettings transaction lists.
  getTransactionListOld(venue_id?: string) {
    return this.http.get<DqTransaction[]>(
      `${this.reg.ServerURL()}/v4/transactions?venue_id=${venue_id}`
    );
  }

  placeOrder({
    items,
    venue_id,
    venue_tax_rate,
    guest_id,
    table_id,
    collection_point_id,
    payment_method,
    gratuity,
    gratuityType,
    paymentMethod,
    square_transaction_id,
    square_client_transaction_id,
  }: DqPlaceOrderParams) {
    const path = "/admin/master/WS/hotel_ws/place_order.php";

    const order_details: DqOrderItem[] = items.map((item) =>
      this.mapDqCartItemToOrderItem(item, collection_point_id)
    );

    /**
     * o_user_id=11385
     * o_venue_id=1
     * o_payment_method=Cash
     * o_date=2019-07-31
     * o_time=16:22:25
     * o_status=Pending
     * o_gratuity=0.0
     * o_tax=0.0
     * o_discount=0.0
     * o_total=0.0
     * tbl_number=Rooftop C4
     * PayPalTransactionId=
     * pu_id=
     * currTime=
     * o_discount_type=
     * o_promo_code=
     * Order_cp_id=99
     * o_promo_id=
     * o_CredCard_trans_id=
     * userFirstName=james
     * time=16:22:25
     * date=2019-07-31
     * venueTradingName=
     * orderTotal=20.00
     * gst=3.33
     * gratuity=0.00
     * itemcount=1
     * email=
     * promo_credit=0.00
     * o_promo_used_credit=0.00
     * order_from=staff_app
     * guest_id=11385
     * area_id=99
     * o_service_type=0
     * order_flag=0
     * order_details=[{"od_item_id":11417,"od_price":"20.00","od_quantity":"1","od_mixture":"","od_item_name":"Espresso Martini","drink_type":"45","order_collection_point_id":"99","garnish_ids":"","o_dev_time":"","o_ingredients":"","od_free_prod":"","od_tax":"0.00","od_item_hmc_id":"45"}]
     */
    const cartTotal = getCartTotalPrice(items);
    const cartTax = getTaxFromTotal(cartTotal, venue_tax_rate);
    const tip = this.calculateGratuityAmount(cartTotal, gratuity, gratuityType);
    const now = DateTime.local();
    const body: DqOrderParams = {
      o_user_id: guest_id,
      o_venue_id: venue_id,
      o_payment_method: payment_method !== "paylater" ? payment_method : "",
      /**
       * 2019-07-31
       */
      o_date: now.toFormat("yyyy-LL-dd"),
      /**
       * 16:22:25
       */
      o_time: now.toFormat("hh:mm:ss"),
      o_status: "Pending",
      o_gratuity: tip.toFixed(2),
      o_tax: cartTax.toFixed(2),
      o_discount: "0",
      o_total: (cartTotal + tip).toFixed(2),
      tbl_number: table_id,
      // o_discount_type: string
      Order_cp_id: collection_point_id,
      order_flag: paymentMethod !== "paylater" ? "0" : "1",
      order_details: JSON.stringify(order_details),
      square_transaction_id,
      square_client_transaction_id,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    return this.http
      .post<DqPlaceOrderResponse[]>(`${this.reg.ServerURL()}${path}`, form)
      .pipe(
        map(
          ([data]) =>
            ({
              ...data,
              status: data.status.toLowerCase(),
            } as DqPlaceOrderResponse)
        )
      );
  }

  calculateGratuityAmount(
    cartTotal: number,
    gratuity: number = 0,
    gratuityType: DqGratuityType
  ) {
    return gratuityType === "amount" ? gratuity : cartTotal * (gratuity / 100);
  }

  placeRepeatOrder({
    items,
    venue_id,
    guest_id,
    table_id,
    collection_point_id,
    guest_name,
    order_id,
  }: DqPlaceRepeatOrderParams) {
    // https://mw8jvcgv5m.execute-api.ap-southeast-2.amazonaws.com/dev/admin/master/WS/hotel_ws/place_order_repeat_mobile.php
    const path = "/admin/master/WS/hotel_ws/place_order_repeat_mobile.php";
    /**
     * od_order_id=32446
     * o_total=40.0
     * o_discount=0.0
     * o_user_id=11641
     * venue_id=1
     * collection_id=99
     * area_id=0
     * table_number=Rooftop C2
     * name=James
     * order_details=[{"od_free_prod":"","od_mixture":"","od_item_id":11417,"od_price":"20.00","order_collection_point_id":"99","drink_type":"45","o_ingredients":"","o_dev_time":"","garnish_ids":"","od_item_name":"Espresso Martini","od_quantity":"2","od_item_hmc_id":"45"}]

     */
    const order_details: DqOrderItem[] = items.map((item) =>
      this.mapDqCartItemToOrderItem(item, collection_point_id)
    );

    const cartTotal = getCartTotalPrice(items);
    const body = {
      od_order_id: order_id,
      o_user_id: guest_id,
      name: guest_name,
      venue_id,
      area_id: "0",
      o_discount_type: "",
      o_promo_code: "",
      o_discount: "0.0",
      collection_id: collection_point_id,
      o_total: cartTotal.toFixed(2),
      table_number: table_id,

      order_details: JSON.stringify(order_details),
    };

    const form = paramsToFormData(body);
    return this.http
      .post<DqPlaceRepeatOrderResponse[]>(
        `${this.reg.ServerURL()}${path}`,
        form
      )
      .pipe(
        map(
          ([data]) =>
            ({
              ...data,
              status: data.status.toLowerCase(),
            } as DqPlaceRepeatOrderResponse)
        )
      );
  }

  private mapDqCartItemToOrderItem(
    item: DqCartItem,
    collection_point_id: string
  ): DqOrderItem {
    /**
     * Params
     *  [
     *    NOTE : THIS IS A MIXTURE WITH GARNISHES
     *     {
     *       "od_free_prod": "",
     *       "od_mixture": "33,36",
     *       "od_item_id": 12587,
     *       "od_price": "12.50",
     *       "order_collection_point_id": "99",
     *       "drink_type": "155",
     *       "o_ingredients": "",
     *       "o_dev_time": "",
     *       "garnish_ids": "68,34",
     *       "od_item_name": "Belvedere",
     *       "od_quantity": "1",
     *       "od_item_hmc_id": "155"
     *     },
     *    NOTE: THE FOLLOWING ARE NORMAL ITEMS
     *     {
     *       "od_free_prod": "",
     *       "od_mixture": "",
     *       "od_item_id": 11417,
     *       "od_price": "20.00",
     *       "order_collection_point_id": "99",
     *       "drink_type": "45",
     *       "o_ingredients": "",
     *       "o_dev_time": "",
     *       "garnish_ids": "",
     *       "od_item_name": "Espresso Martini",
     *       "od_quantity": "3",
     *       "od_item_hmc_id": "45"
     *     },
     *     {
     *       "od_free_prod": "",
     *       "od_mixture": "",
     *       "od_item_id": 11418,
     *       "od_price": "24.00",
     *       "order_collection_point_id": "99",
     *       "drink_type": "45",
     *       "o_ingredients": "",
     *       "o_dev_time": "",
     *       "garnish_ids": "",
     *       "od_item_name": "Sweet Contessa",
     *       "od_quantity": "1",
     *       "od_item_hmc_id": "45"
     *     }
     *   ]
     *
     */
    const orderItem: DqOrderItem = {
      od_free_prod: "",
      od_item_id: item.itemDetails.i_id, // 12587,
      od_price: item.itemDetails.i_price, // "12.50",
      order_collection_point_id: collection_point_id, // "99",
      drink_type: item.itemDetails.i_hmc_id, //  "155",
      o_ingredients: item.comment || "", // "",
      o_dev_time: "00:00:00", // "",
      modifier_groups: (item.consumerModifiers ?? []).map(
        ({ mg_id, modifiers }) => ({
          mg_id,
          mod_ids: modifiers.map((m) => m.mod_id),
        })
      ),
      od_item_name: item.itemDetails.i_name, // "Belvedere",
      od_quantity: item.quantity.toString(), // "1",
      od_item_hmc_id: item.itemDetails.i_hmc_id, // "155"
    };
    return orderItem;
  }

  emailInvoice({
    email,
    order_id,
    guest_name,
    venue_id,
    table_id,
    tax,
  }: {
    email: string;
    order_id: string;
    guest_name: string;
    venue_id: string;
    table_id: string;
    tax: string;
  }) {
    const params = {
      username: guest_name,
      user_email: email,
      o_id: order_id,
      venue_id,
      table_id,
      o_discount: "0.00",
      promo_credit: "0.00",
      gst: tax,
    };
    const path = "/admin/master/WS/hotel_ws/send_mail.php";

    const formData = paramsToFormData(params);
    return this.http.post<DqSendEmailResponse>(
      `${this.reg.ServerURL()}${path}`,
      formData
    );
  }

  changeOrderStatus(url: string, qparams) {
    return this.http.post(
      `${this.reg.ServerURL()}/v4/order/${url}/state`,
      {},
      {
        params: qparams,
      }
    );
  }

  async orderStateActionSheet(state_changes) {
    let buttons = [];
    let response;
    for (let state of state_changes) {
      let icon;
      switch (state) {
        case "Accept":
          icon = "thumbs-up-outline";
          break;
        case "Pending":
          icon = "hand-right-outline";
          break;
        case "Preparing":
          icon = "fast-food-outline";
          break;
        case "Ready":
          icon = "checkmark-circle-outline";
          break;
        case "Delivered":
          icon = "checkmark-done-circle-outline";
          break;
        case "Cancelled":
          icon = "trash-outline";
          break;
        case "Reject":
          icon = "trash-outline";
          break;
        default:
          icon = "share";
      }

      buttons.push({
        css: icon,
        text: state,
        role: state,
        icon: icon,
        handler: () => {
          response = state;
        },
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: "Change Order Status",
      cssClass: "orderActionSheet",
      buttons: buttons,
    });
    await actionSheet.present();

    const state = await actionSheet.onDidDismiss();

    if (response == "Reject" || response == "Cancelled") {
      let responsetext = response == "Reject" ? "Reject" : "Cancel";
      const cancelOrderAlert = await this.alertController.create({
        cssClass: "",
        header: `Confirm ${responsetext}`,
        // message: `This order will be ${response.name}`,
        buttons: [
          {
            text: `No, don't ${responsetext} the order`,
            role: "noCancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: `Yes, ${responsetext} the order`,
            role: "confirm",
            handler: () => {},
          },
        ],
      });

      await cancelOrderAlert.present();
      const confirmCancel: any = await cancelOrderAlert.onDidDismiss();
      if (confirmCancel?.role == "noCancel") {
        return "cancelSelectedChoice";
      }
    }

    return response;
  }

  manageFilters(venue_id?) {
    return this.http.get(
      `${this.reg.ServerURL()}/v4/order/manage-filters?v_id=${venue_id}`
    );
  }

  getWalletCard(booking_id) {
    return this.http.get(`${this.reg.ServerURL()}/passkit/` + booking_id);
  }
}
