import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IonRow, PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
@Component({
  selector: "dq-metric",
  templateUrl: "./metric.component.html",
  styleUrls: ["./metric.component.scss"],
})
export class MetricComponent implements OnInit {
  @Input() metrics: {
    title: string;
    value: string;
    subtitle: string;
    gender_male?: number;
    gender_female?: number;
    change?: number;
    inverted?: boolean;
    sparkline?: number[];
  }[] = [];
  @Input() chart_title;
  @Input() chart_value;
  @Input() line_chart;
  @Input() date_line_chart;
  @Input() bar_chart;
  @Input() max_value;
  @Input() target;
  @Input() chart_comiing;

  constructor(
    private region: RegionsService,
    private tagSt: TagState,
    private popover: PopoverController
  ) {}

  ngOnInit() {
    //log output of tableDetail
    console.log("Metric", this.metrics);
  }

  colorScheme = {
    domain: ["#e36936", "#6d280a", "#e36936", "#e36936", "#e36936", "#e36936"],
  };
  dateTickFormatting(val: any): string {
    //format as 5:20PM

    let d = new Date(val);
    return (
      d.getHours() -
      12 +
      ":" +
      d.getMinutes() +
      (d.getHours() > 12 ? "PM" : "AM")
    );
  }
  bigdateTickFormatting(val: any): string {
    //format as 5th Jan

    let d = new Date(val);
    return (
      d.getDate() + "th " + d.toLocaleString("default", { month: "short" })
    );
  }
}
