<ion-list
  class="menu"
  [class.show-thumbnail]="items | arraySome: 'media'"
>
  <app-dq-infinite-scroll
    chunkSize="4"
    [passThrough]="searchString != undefined && searchString.length > 1"
    [data]="categories | async"
    (chunkedData)="itemsToDisplay = $event"
    *ngrxLet="campMap$ | async as comMap"
  >
    <ng-container
      *ngFor="let cat of itemsToDisplay; trackBy: getCategoryId; let i = index"
    >
      <ion-item-divider color="black" >
        <ion-note
          class="divider ion-margin-start "
          >{{ cat?.name }}</ion-note
        >
      </ion-item-divider>
      <ion-item-group class="rounded-group ion-margin-bottom ion-margin-horizontal">

    
      <ng-container *ngFor="let itemid of cat.mi_ids; trackBy: getItemId; let j = index">
        <ng-container *ngrxLet="menuitem$(itemid) as item">
          <ng-container *ngIf="item.is_available_staff_now == true">
            <ion-item
              *ngIf="!item.is_mixer && !item.i_user_input_price"
              button
              detail="false"
              [routerLink]="['item', item.mi_id]"
              color="secondary"
              class="itemline"
              [lines]="j === cat.mi_ids.length - 1 ? 'none' : 'inset'"
              [class.border]="comMap[item.mi_id] > 0"
            >
            <ion-label class="no-padding" >

              <ion-label>{{ item.name }}</ion-label>
              <ion-note class="note-note ml" *ngIf="item?.static_commission > 0|| comMap[item.mi_id] > 0" [class.primary-text]="comMap[item.mi_id] > 0">Commission</ion-note>
            </ion-label>
                <ion-label class="no-padding" slot="end">
  
                  <ion-note class="price">{{ item.price | currency: currency }}</ion-note>
                  <ion-note class="note-note ion-text-end" *ngIf="item?.static_commission > 0 || comMap[item.mi_id] > 0" [class.primary-text]="comMap[item.mi_id] > 0"> {{
                    (comMap[item.mi_id] != undefined
                      ? item?.static_commission + comMap[item.mi_id]
                      : item?.static_commission
                    ) | currency: currency
                  }}</ion-note>
                </ion-label>
              <ion-button
                slot="end"
                color="secondary"
                (click)="addToCart($event, item)"
              >
                <ion-icon
                  slot="icon-only"
                  [name]="gift == true ? 'gift-outline' : 'add-outline'"
                ></ion-icon>
              </ion-button>
            </ion-item>

            <ion-item
              *ngIf="item.is_mixer && !item.i_user_input_price"
              [routerLink]="['item', item.mi_id]"
              detail="false"
              button
              color="secondary"
              class="itemline"
              [lines]="j === cat.mi_ids.length - 1 ? 'none' : 'inset'"

              [class.border]="comMap[item.mi_id] > 0"
            >
          <ion-label class="no-padding" >

            <ion-label>{{ item.name }}</ion-label>
            <ion-note class="note-note ml"  *ngIf="item?.static_commission > 0|| comMap[item.mi_id] > 0" [class.primary-text]="comMap[item.mi_id] > 0">Commission</ion-note>
          </ion-label>
              <ion-label class="no-padding" slot="end">

                <ion-note class="price">{{ item.price | currency: currency }}</ion-note>
                <ion-note class="note-note ion-text-end"  *ngIf="item?.static_commission > 0 || comMap[item.mi_id] > 0" [class.primary-text]="comMap[item.mi_id] > 0"> {{
                  (comMap[item.mi_id] != undefined
                    ? item?.static_commission + comMap[item.mi_id]
                    : item?.static_commission
                  ) | currency: currency
                }}</ion-note>
              </ion-label>
              <ion-button slot="end" color="secondary">
                <ion-icon
                  name="ellipsis-horizontal"
                  slot="icon-only"
                ></ion-icon>
              </ion-button>
            </ion-item>
            <ion-item
              *ngIf="item.i_user_input_price"
              detail="false"
              button
              (click)="addToCart($event, item)"
              color="secondary"
              [lines]="j === cat.mi_ids.length - 1 ? 'none' : 'inset'"
              [class.border]="comMap[item.mi_id] > 0"
            >
              <ion-label>{{ item.name }}</ion-label>
              <ion-note>{{ item.price | currency: currency }}</ion-note>
              <ion-button slot="end" color="" (click)="addToCart($event, item)">
                <ion-icon
                  slot="icon-only"
                  [name]="gift == true ? 'gift-outline' : 'add-outline'"
                ></ion-icon>
              </ion-button>
            </ion-item>
            
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-item-group>
    </ng-container>
  </app-dq-infinite-scroll>
</ion-list>

<ng-template #categoriesTemplate>
  <ion-list class="menu condem" lines="none" color="light">
    <ng-container *ngFor="let cat of categories; trackBy: getItemId">
      <ion-item
        color="secondary"
        [routerLink]="['category', cat.menu_cat_id]"
        detail="true"
        class="itemline"
      >
        <ion-label>{{ cat.name }}</ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ng-template>
