import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { ParsedInterval } from "./category-modal.component";

@Pipe({ name: "filterinterval" })
@Injectable()
export class IntervalFilter implements PipeTransform {
  transform(value: ParsedInterval[], filterBy: string): ParsedInterval[] {
    //filter intervals by the day field of the interval
    return filterBy
      ? value.filter(
          (interval) =>
            interval.day.toLowerCase().indexOf(filterBy.toLowerCase()) !== -1
        )
      : value;
  }
}
