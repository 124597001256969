import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SyncV1Modifier } from "src/app/models/sync_models";
import { DqConsumerModifier } from "src/app/services/consumer-table/consumer-table.service";

@Component({
  selector: "app-modifiers",
  templateUrl: "./modifiers.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ModifiersComponent,
      multi: true,
    },
  ],
  styleUrls: ["./modifiers.component.scss"],
})
export class ModifiersComponent implements OnInit, ControlValueAccessor {
  @Input() modifiers: SyncV1Modifier[];
  @Input() min: number | null = null;
  @Input() max: number | null = null;

  //output events that include the SelecedModifiers
  @Output() ChangedModifiers = new EventEmitter();

  selectedModifiers = new Map<string, SyncV1Modifier>();

  private onChangeCb: (value: SyncV1Modifier[]) => void;
  private onTouchedCb: () => void;
  private touched = false;

  constructor() {}

  ngOnInit() {
    //output the selected modifiers
    this.notifyOnChanges();
  }

  writeValue(modifiers: SyncV1Modifier[]) {
    if (Array.isArray(modifiers)) {
      this.selectedModifiers = modifiers.reduce((map, mod) => {
        map.set(mod.mm_id, mod);
        return map;
      }, new Map<string, SyncV1Modifier>());
    }
  }

  registerOnChange(fn: (value: SyncV1Modifier[]) => void) {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCb = fn;
  }

  getModifierId(_: string, modifier: SyncV1Modifier) {
    return modifier.mm_id;
  }

  toggleModifier(modifier: SyncV1Modifier) {
    if (!this.modifierHasBeenSelected(modifier)) {
      if (this.max && this.selectedModifiers.size >= this.max) {
        // remove the first one
        const firstModifierId = this.selectedModifiers.keys().next().value;
        this.selectedModifiers.delete(firstModifierId);
      }
      this.selectedModifiers.set(modifier.mm_id, modifier);
      this.notifyOnChanges();
    } else {
      this.selectedModifiers.delete(modifier.mm_id);
      this.notifyOnChanges();
    }
  }

  modifierHasBeenSelected(modifier: SyncV1Modifier) {
    return this.selectedModifiers.has(modifier.mm_id);
  }

  notifyOnChanges() {
    if (this.onChangeCb) {
      const modifiers = [...this.selectedModifiers.values()];
      console.log("modifiers", modifiers);
      this.onChangeCb(modifiers);
    }
    if (!this.touched && this.onTouchedCb) {
      this.onTouchedCb();
      this.touched = true;
    }
    this.ChangedModifiers.emit(this.selectedModifiers);
  }
}

let examplejson = {
  mappable_categories: [
    {
      pos_id: 1,
      pos_name: "Category 1",
      our_category: undefined,
    },
  ],
  new_items: [
    {
      pos_id: 1,
      pos_name: "Category 1",
      pos_category: "Category 1",
      pos_price: 1.99,
      pos_description: "Description 1",
      import: true,
    },
  ],
  updated_items: [
    {
      pos_id: 1,
      our_name: "Item 1",
      our_price: 1.99,
      pos_price: 4.99,
      import: true,
    },
  ],
};
