<ion-card
  class="cardpadding"
  COLOR="secondary"
  [class.giftwrap]="order.is_treat"
  (click)="OpenOrder($event, order.o_uuid)"
  [class.activity]="shouldShowUser$(order) | async"
  [style.border-color]="
    (shouldShowUser$(order) | async)
      ? usernameToColor(order.last_touched_user_name)
      : 'var(--ion-color-secondary-shade)'
  "
  button
>
  <div class="treatclassifier" *ngIf="order.is_treat">
    <ion-icon name="gift-outline" color="warning"></ion-icon>
    <ion-note color="warning">Treat</ion-note>
  </div>
  <ion-card-content>
    <ion-row class="ion-justify-content-between nowrap">
      <h2>{{ guest?.name }} {{ order.title }}</h2>
      <h2 class="ion-text-end">{{ order.table_name }}</h2>
    </ion-row>
    <ion-row class="ion-justify-content-between nowrap">
      <ion-note class="max60">{{ order.placed_by }}</ion-note>
      <ion-note>{{ order.created_at_utc | timeAgo: "limited": updateTrigger$}}</ion-note>
    </ion-row>
    <ion-col class="lineitems">
      <ng-container
        *ngFor="let line of order.card_lines; trackBy: TrackOrderLine"
      >
        <ion-row class="ion-justify-content-between item nowrap">
          <ion-label> {{ line.quantity }} x {{ line.item_name }} </ion-label>
          <ion-label> {{ line.line_item_price }} </ion-label>
        </ion-row>
        <div class="voided" *ngIf="line.voided"></div>
        <div
          class="chips"
          *ngIf="line.tags != undefined && line.tags.length > 0"
        >
          <ion-chip *ngFor="let tag of line.tags">{{ tag }}</ion-chip>
        </div>
        <div class="note" *ngIf="line.comment != undefined || ''">
          {{ line.comment }}
        </div>
      </ng-container>
    </ion-col>

    <ion-row class="ion-justify-content-between nowrap">
      <ion-button
        [disabled]="!(hasClaim$(['manage_orders']) | async)"
        [fill]="(hasClaim$(['manage_orders']) | async) ? 'solid' : 'outline'"
        *ngIf="
          NextColumn(order.current_column) != undefined &&
            order.current_column != 'Delivered';
          else nocol
        "
        class="status"
        (click)="
          orderStateButtonClicked($event, NextColumn(order.current_column))
        "
      >
        <ion-label *ngIf="!order.loading">
          {{ NextColumn(order.current_column) }}</ion-label
        >
        <ion-spinner *ngIf="order.loading"></ion-spinner>
      </ion-button>
      <ng-template #nocol>
        <ion-button class="status" fill="outline" color="light">
          <ion-label color="light" *ngIf="!order.loading">
            {{ order.current_column }}</ion-label
          >
          <ion-spinner *ngIf="order.loading"></ion-spinner>
        </ion-button>
      </ng-template>
      <ion-button
        [fill]="reciept?.outstanding <= 0 ? 'solid' : 'clear'"
        [class]="PaymentColor(reciept.outstanding)"
        (click)="OpenPayment($event)"
      >
        <ion-label color="light">{{ order.total_price_text }}</ion-label>
        <ion-icon
          color="light"
          class="iconend"
          slot="end"
          [name]="order.pay_icon || 'card-outline'"
        ></ion-icon>
      </ion-button>
    </ion-row>
  </ion-card-content>
  <div
    *ngIf="percent < 50 && percent != 0"
    class="angrybar"
    [style.background]="
      'linear-gradient(89.95deg, #E36936 ' +
      percent.toString() +
      '%, rgba(227, 105, 54, 0) ' +
      (percent + 12).toString() +
      '%);'
    "
    [style]="
      'background: linear-gradient(89.95deg, #E36936 ' +
      percent.toString() +
      '%, rgba(227, 105, 54, 0) ' +
      (percent + 12).toString() +
      '%);'
    "
  ></div>
  <div
    *ngIf="percent > 50"
    class="angrybar"
    [style.background]="
      'linear-gradient(89.95deg, #AD181B ' +
      percent.toString() +
      '%, rgba(255, 0, 0, 0) ' +
      (percent + 12).toString() +
      '%);'
    "
    [style]="
      'background: linear-gradient(89.95deg, #AD181B ' +
      percent.toString() +
      '%, rgba(255, 0, 0, 0) ' +
      (percent + 25).toString() +
      '%);'
    "
  ></div>
</ion-card>

<div
  class="activity-badge"
  *ngIf="shouldShowUser$(order) | async"
  [style.background-color]="usernameToColor(order.last_touched_user_name)"
>
  <ion-label>
    {{ order.last_touched_user_name }}
  </ion-label>
</div>
