<ion-list color="secondary">
  <ion-list-header color="secondary">Add Objects</ion-list-header>
  <ion-item button color="secondary" (click)="AddObject('Table')"
    >Add Table</ion-item
  >

  <ion-item button color="secondary" (click)="AddObject('Wall')"
    >Add Wall</ion-item
  >
  <ion-item button color="secondary" (click)="AddObject('Text')"
    >Add Text</ion-item
  >
  <ion-item
    lines="none"
    color="secondary"
    detail="false"
    button
    (click)="dismissPopover()"
    >Close</ion-item
  >
</ion-list>
