<ion-header>
  <ion-toolbar>
    <ion-title>Open Table</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-item-group class="ion-margin rounded-group" color="secondary">
    <ion-item *ngIf="event == undefined">
      <ion-label>No Event Selected</ion-label>
    </ion-item>
   <app-event-card [clickable]="false" [style.--ion-background-color]="'var(--ion-color-secondary)'" [event]="event"*ngIf="event != undefined">
   </app-event-card>
  </ion-item-group>

  <form #openTableForm="ngForm">
    <ion-item-group class="ion-margin rounded-group" color="secondary">
    <ion-item color="secondary" [style.--padding-start.px]="10">

      <ion-input
      type="text"
      autocapitalize="words"
      placeholder="Guest Name"
      required
      #nameinput
      name="guestName"
      ></ion-input>
    </ion-item>
    </ion-item-group>
  </form>
 

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button
      size="large"
      expand="block"
      [disabled]="openTableForm.invalid"
    >
      <ion-label color="light">Open Manually</ion-label></ion-button
    >
  </ion-toolbar>
</ion-footer>
