import { Pipe, PipeTransform } from "@angular/core";
import { DqModifierGroup } from "src/app/models/cart.model";

@Pipe({
  name: "appModifierTotal",
})
export class ModifierTotalPipe implements PipeTransform {
  transform(mg: DqModifierGroup): number {
    if (!Array.isArray(mg?.modifiers)) {
      return 0;
    }

    return mg.modifiers.reduce((sum, mod) => {
      sum += +mod.price;
      return sum;
    }, 0);
  }
}
