import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

import { createReducer, on, Action, createSelector } from "@ngrx/store";
import { userLogOut } from "src/app/actions/auth.actions";
import { DqMenuNotifications } from "src/app/services/order/order.service";
import { DqVenue, DqVenueList } from "src/app/services/venue/venue.service";
import * as VenueActions from "../../actions/venue.actions";

export interface State extends EntityState<DqVenue> {
  // additional entities state properties
  selectedId: string;
  userVenuesHaveBeenLoaded: boolean;
  selected_venue_details?: {
    v_id: string;
    v_api_key: string;
    v_api_passphrase: string;
    v_merchant_uuid: string;
    v_name: string;
  };
  terminal_location: string;
  orderNotifications: DqMenuNotifications;
}

export const adapter = createEntityAdapter<DqVenue>({
  selectId: (venue) => venue.v_id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  userVenuesHaveBeenLoaded: false,
  orderNotifications: null,
  terminal_location: null,
});

const venueReducer = createReducer(
  initialState,
  on(VenueActions.loadVenuesSuccess, (state, { venue_list }): State => {
    return adapter.addMany(venue_list.venues, {
      ...state,
      userVenuesHaveBeenLoaded: true,
    });
  }),
  on(userLogOut, (state): State => initialState),
  on(VenueActions.selectVenue, (state, payload): State => {
    return {
      ...state,
      selectedId: payload.selectedVenueId,
    };
  }),
  on(VenueActions.venueCheckInSuccess, (state, payload): State => {
    // console.log(payload);
    return {
      ...state,
      selected_venue_details: {
        v_id: payload.loginResponse.marchantdetails[0].v_id,
        v_api_key: payload.loginResponse.marchantdetails[0].v_api_key,
        v_api_passphrase:
          payload.loginResponse.marchantdetails[0].v_api_passphrase,
        v_merchant_uuid:
          payload.loginResponse.marchantdetails[0].v_merchant_uuid,
        v_name: payload.loginResponse.marchantdetails[0].v_name,
      },
      terminal_location:
        payload.loginResponse.marchantdetails[0].v_stripe_physical_location,
    };
  }),
  on(VenueActions.selectVenueAtStartupSucess, (state, payload): State => {
    return {
      ...state,
      selectedId: payload.selectedVenueId,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return venueReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectVenuesHaveBeenLoaded = (state: State) => {
  return state.userVenuesHaveBeenLoaded;
};

export const selectTerminalId = (state: State) => {
  return state.terminal_location;
};

export const selectSelectedVenueId = (state: State) => {
  // auto load venue if only one venue associated with user, otherise redirect to venues page.
  // console.log(state);
  if (state.ids && state.ids.length === 1) {
    return state.ids[0].toString();
  } else {
    return state.selectedId;
  }
};

export const selectSelectedVenue = createSelector(
  selectEntities,
  selectSelectedVenueId,
  (venues, selectedId) => venues[selectedId]
);

export const selectOrderNotifcations = (state: State) =>
  state.orderNotifications;
