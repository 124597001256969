import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { DqConsumerVenueInfo } from "../consumer-table/consumer-table.service";

const THEME_STYLESHEET_ID = "venue-theme";

@Injectable({ providedIn: "root" })
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  lightTheme(action: boolean) {
    if (environment.treatMeEnabled === true) {
      const classList = this.document.body.classList;
      if (action === true) {
        classList.add("light-theme");
      } else {
        classList.remove("light-theme");
      }
    }
  }

  private setBodyClass(venueId: string) {
    const classNamePrefix = "venue-";
    const classList = this.document.body.classList;
    const venueBodyClass = !!venueId
      ? `${classNamePrefix}${venueId}`
      : undefined;
    // first see if there is a class for a different venueId. If there is remove it.
    classList.forEach((className) => {
      if (
        className.startsWith(classNamePrefix) &&
        className !== venueBodyClass
      ) {
        classList.remove(className);
      }
    });
    if (venueBodyClass) {
      classList.add(venueBodyClass);
    }
  }
}
