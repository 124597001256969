<div
  id="splash-screen"
  class="ion-text-center splash-screen"
  *ngIf="show && (!!imageUrl || !!thumbnailUrl)"
  @fadeOut
>
  <img class="blur" [src]="thumbnailUrl" />
  <img
    [src]="imageUrl"
    [class.transparent]="!showHighResSplash"
    (load)="highResImageIsLoaded()"
  />
  <h1 *ngIf="title">{{ title }}</h1>
  <h3 *ngIf="subtitle">{{ subtitle }}</h3>
  <ion-spinner class="ion-align-self-center" color="white"></ion-spinner>
</div>
