<ion-split-pane contentId="main">
  <!--  the side menu  -->
  <ion-menu contentId="main">
    <div class="ion-justify-content-between higher">
      <ion-list>
        <ion-img src="../../../assets/logo.svg"> </ion-img>

        <div
          class="menu-button"
          [style.margin-top.px]="10"
          lines="none"
          detail="false"
          (click)="openPage('dashboard')"
          [class.disabled-item]="!(has_permission$('detailed_reports_cms') | async)"

        >
          <ion-icon
            [color]="currentpage == 'dashboard' ? 'primary' : 'light'"
            name="home-outline"
          ></ion-icon>
        </div>
        <div
          class="menu-button"
          lines="none"
          detail="false"
          (click)="openPage('tables')"
          [class.disabled-item]="!(has_permission$('manage_venue') | async)"

        >
          <ion-icon
            [color]="currentpage == 'tables' ? 'primary' : 'light'"
            name="list-outline"
          ></ion-icon>
        </div>

        <div
          class="menu-button"
          lines="none"
          detail="false"
          (click)="openPage('menu')"
          [class.disabled-item]="!(has_permission$('manage_venue') | async)"

        >
          <ion-icon
            [color]="currentpage == 'menu' ? 'primary' : 'light'"
            name="wine-outline"
          ></ion-icon>
        </div>

        <div
          class="menu-button"
          lines="none"
          detail="false"
          (click)="openPage('bookings')"
          [class.disabled-item]="!(has_permission$('manage_bookings') | async)"

        >
          <ion-icon
            [color]="currentpage == 'bookings' ? 'primary' : 'light'"
            name="calendar-outline"
          ></ion-icon>
        </div>

        <div
          class="menu-button"
          lines="none"
          detail="false"
          (click)="openPage('manage-guests')"
          [class.disabled-item]="!(has_permission$('detailed_reports_cms') | async)"

        >
          <ion-icon
            [color]="currentpage == 'manage-guests' ? 'primary' : 'light'"
            name="people-outline"
          ></ion-icon>
        </div>

        <div
          class="menu-button"
          lines="none"
          detail="false"
          [class.disabled-item]="!(has_permission$('detailed_reports_cms') | async)"
          (click)="openPage('reports')"
        >
          <ion-icon
            [color]="
              currentpage == 'reports' ||
              currentpage == 'reports/performance' ||
              currentpage == 'reports/staff'
                ? 'primary'
                : 'light'
            "
            name="analytics-outline"
          ></ion-icon>
        </div>

        <div class="menu-button" id="hover-button" lines="none" detail="false">
          <ion-icon
            [color]="
              currentpage == 'map' ||
              currentpage == 'manage-events' ||
              currentpage == 'logs' ||
              currentpage == 'staff' ||
              currentpage == 'links'
                ? 'primary'
                : 'light'
            "
            name="chevron-forward-outline"
          ></ion-icon>
        </div>
      </ion-list>
      <ion-list>
        <div
          class="menu-button reduced relative"
          id="notifications-button"
          lines="none"
          detail="false"
        >
          <ion-icon color="light" name="notifications-outline"></ion-icon>
          <ion-badge
            class="notifbadge"
            *ngIf="(unreadNotifications$ | async) > 0"
            >{{ unreadNotifications$ | async }}</ion-badge
          >
        </div>
        <div
          *ngIf="(venues$ | async).length > 1"
          class="menu-button reduced"
          lines="none"
          detail="false"
          (click)="switchVenue()"
        >
          <ion-icon [color]="'light'" name="swap-horizontal-outline"></ion-icon>
        </div>
        <div
          class="menu-button reduced"
          lines="none"
          detail="false"
          (click)="openPage('settings')"
         *ngIf="(has_permission$('integration_and_billing') | async)"

        >
          <ion-icon
            [color]="currentpage.includes('settings') ? 'primary' : 'light'"
            name="cog-outline"
          ></ion-icon>
        </div>
        <div class="menu-button reduced" (click)="logout()" icon-only>
          <ion-icon color="light" name="log-out-outline"></ion-icon>
        </div>
      </ion-list>
    </div>
  </ion-menu>

  <!-- the main content -->
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-split-pane>
<ion-popover
  class="notifications"
  [class.notif0]="(notifications$ | async).length == 0"
  [class.notif1]="(notifications$ | async).length == 1"
  [class.notif2]="(notifications$ | async).length == 2"
  [class.notif3]="(notifications$ | async).length >= 3"
  [backdropDismiss]="true"
  [dismissOnSelect]="true"
  [showBackdrop]="true"
  trigger="notifications-button"
  arrow="false"
  (willDismiss)="viewedNotifs()"
  (willPresent)="viewedNotifs()"
  *ngIf="(notifications$ | async) != undefined"
>
  <ng-template>
    <ion-content
      ><ion-item color="secondary"
        *ngFor="let notif of (notifications$ | async).slice().reverse()"
        class="fillthe"
        id="hover-button"
        detail="false"
      >
        <ion-col>
          <h4 class="title">{{ notif.title }}</h4>
          <ion-label class="msg">{{ notif.message }}</ion-label>
        </ion-col>
        <ion-note class="msg">{{
          notif.created_utc | date: "shortTime"
        }}</ion-note>
      </ion-item>
      <div
        class="center-empty ion-margin-top ion-margin-bottom"
        *ngIf="(notifications$ | async).length == 0"
      >
        <ion-label class="empty-text">No Notifications</ion-label>
      </div>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-popover
  class="above"
  alignment="bottom"
  side="right"
  trigger="hover-button"
  [showBackdrop]="true"
  triggerAction="hover"
  [backdropDismiss]="true"
  [dismissOnSelect]="true"
  arrow="false"
  [animated]="false"
>
  <ng-template>
    <ion-content>
      <ion-item color="secondary" 
        [button]="true"
        class="fillthe"
        id="hover-button"
        detail="false"
        (click)="openPage('manage-events')"
        [class.disabled-item]="!(has_permission$('manage_bookings') | async)"

      >
        <ion-icon
          [color]="currentpage == 'manage-events' ? 'primary' : 'light'"
          slot="start"
          name="bookmark-outline"
        ></ion-icon>
        <ion-label
          [color]="currentpage == 'manage-events' ? 'primary' : 'light'"
          >Events</ion-label
        >
      </ion-item>
      <ion-item color="secondary"   
        [button]="true"
        class="fillthe"
        id="hover-button"
        detail="false"
        (click)="openPage('staff')"
        [class.disabled-item]="!(has_permission$('manage_users_cms') | async)"

      >
        <ion-icon
          [color]="currentpage == 'staff' ? 'primary' : 'light'"
          slot="start"
          name="clipboard-outline"
        ></ion-icon>
        <ion-label [color]="currentpage == 'staff' ? 'primary' : 'light'"
          >Staff</ion-label
        >
      </ion-item>
      <ion-item color="secondary"
        [button]="true"
        class="fillthe"
        id="hover-button"
        detail="false"
        (click)="openPage('map')"
        [class.disabled-item]="!(has_permission$('manage_venue') | async)"

      >
        <ion-icon
          [color]="currentpage == 'map' ? 'primary' : 'light'"
          slot="start"
          name="map-outline"
        ></ion-icon>
        <ion-label [color]="currentpage == 'map' ? 'primary' : 'light'"
          >Venue Map</ion-label
        >
      </ion-item>

      <ion-item color="secondary"
        [button]="true"
        class="fillthe"
        id="hover-button"
        lines="none"
        detail="false"
        (click)="openPage('logs')"
        [class.disabled-item]="!(has_permission$('detailed_reports_cms') | async)"

      >
        <ion-icon
          [color]="currentpage == 'logs' ? 'primary' : 'light'"
          slot="start"
          name="newspaper-outline"
        ></ion-icon>
        <ion-label [color]="currentpage == 'logs' ? 'primary' : 'light'"
          >Audit Log</ion-label
        >
      </ion-item>
    </ion-content>
  </ng-template>
</ion-popover>
