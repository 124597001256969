<ion-col>
  <ion-card color="secondary">
    <ion-card-content>
      <ion-row class="ion-justify-content-between">
        <h1>{{ seating.title }}</h1>
        <div [id]="'trigger-popover' + seating.tabs_uuid">
          <ion-icon size="large" name="ellipsis-horizontal"></ion-icon>
        </div>

        <ion-popover
          [trigger]="'trigger-popover' + seating.tabs_uuid"
          triggerAction="click"
          dismissOnSelect="true"
        >
          <ng-template>
            <ion-content>
              <ion-item color="secondary"
                (click)="copyRecieptLink(seating.tabs_uuid)"
                button
                detail="false"
              >
                <ion-label>Copy Reciept Link</ion-label>
              </ion-item>
              <ion-item color="secondary" 
                *ngIf="seating.booking != undefined"
                (click)="copyBookingLink(seating.booking.booking_id)"
                button
                detail="false"
              >
                <ion-label>Copy Booking Link</ion-label>
              </ion-item>
            </ion-content>
          </ng-template>
        </ion-popover>
      </ion-row>

      <ion-row
        class="ion-justify-content-between"
        *ngFor="let topline of seating.top_line_totals"
      >
        <h5>{{ topline.name }}</h5>
        <h5>{{ topline.price_formatted }}</h5>
      </ion-row>
      <div *ngIf="seating.upsell_revenue != undefined" class="divider"></div>
      <ion-row
        *ngIf="seating.upsell_revenue != undefined"
        class="ion-justify-content-between"
      >
        <h5>Upsell Revenue</h5>
        <h5>{{ seating.upsell_revenue }}</h5>
      </ion-row>

      <div
        class="divider"
        *ngIf="
          seating.payment_method_lines != undefined &&
          seating.payment_method_lines.length > 0
        "
      ></div>
      <ion-row
        class="ion-justify-content-between"
        *ngFor="let method of seating.payment_method_lines"
      >
        <h5>{{ method.name }}</h5>
        <h5>{{ method.price_formatted }}</h5>
      </ion-row>
    </ion-card-content>
  </ion-card>
  <ion-card color="secondary" *ngIf="seating.booking">
    <ion-card-content>
      <ion-row class="ion-justify-content-between">
        <h1>{{ seating.booking.package_name }}</h1>
        <h1>{{ seating.booking.total }}</h1>
      </ion-row>
      <ion-row class="ion-justify-content-between">
        <h5>Package</h5>
        <h5></h5>
      </ion-row>
    </ion-card-content>
  </ion-card>
  <ng-container *ngIf="seating.shortfalls != undefined">
    <ion-card *ngFor="let shortfall of seating.shortfalls" color="secondary">
      <ion-card-content>
        <ion-row class="card ion-justify-content-between">
          <h1 [style.margin-bottom.px]="0" [style.font-weight]="'bold'">
            {{ shortfall.title }}
          </h1>
          <h1>{{ shortfall.total }}</h1>
        </ion-row>
        <ion-row *ngFor="let description of shortfall.description">
          <h5>{{ description }}</h5>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ng-container>
  <div class="itemline" *ngIf="seating.booking_items">
    <app-cart-item
      *ngFor="let item of seating.booking_items; let i = index"
      [canEdit]="false"
      [price]="'Package'"
      [first]="i == 0"
      [last]="
        seating.item_lines.length == 0 && i == seating.booking_items.length - 1
      "
      [name]="item.name"
      [quantity]="item.quantity"
    ></app-cart-item>
  </div>

  <div class="itemline">
    <app-cart-item
      *ngFor="let item of seating.item_lines; let i = index"
      [canEdit]="false"
      [price]="item.price_formatted"
      [first]="seating.booking_items.length == 0 && i == 0"
      [last]="i == seating.item_lines.length - 1"
      [name]="item.name"
      [quantity]="item.quantity"
    ></app-cart-item>
  </div>
  <div class="itemline orderline ion-margin-top">
    <ion-item
      color="secondary"
      class=""
      *ngFor="let staff of seating.staff_lines"
      lines="none"
    >
      <ion-label>{{ staff.name }}</ion-label>
      <ion-badge *ngIf="staff.commission != undefined"
        >{{ staff.commission }} Commission</ion-badge
      >

      <ion-note class="ion-margin-start" style="margin-right: 12px"
        >{{ staff.orders }} Orders</ion-note
      >
    </ion-item>
  </div>
  <ng-container *ngFor="let order of seating.order_cards">
    <app-order-card
      class="no-interaction"
      [order]="order"
      [reciept]="{ outstanding: 1 }"
      [guest]="{ name: '' }"
      [moveable]="false"
    >
    </app-order-card>
  </ng-container>
</ion-col>
