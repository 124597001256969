<ion-list lines="none">
  <ion-menu-toggle auto-hide="false">
    <ion-item routerDirection="root" routerLink="../login">
      <ion-icon slot="start" name="log-in"></ion-icon>
      <ion-label>
        Log In
      </ion-label>
    </ion-item>
  </ion-menu-toggle>
</ion-list>
