import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { BehaviorSubject, Observable, map } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";
import { Card } from "src/app/models/bump_screen_models";
import { OrderService } from "src/app/services/order/order.service";
import { PaymentService } from "src/app/services/payment/payment.service";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import {
  SyncV1Guest,
  SyncV1Order,
  SyncV1Reciept,
} from "src/app/models/sync_models";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

@Component({
  selector: "app-order-card",
  templateUrl: "./order-card.component.html",
  styleUrls: ["./order-card.component.scss"],
})
export class OrderCardComponent implements OnInit {
  @Input() order: SyncV1Order;
  @Input() guest: SyncV1Guest;
  @Input() reciept: SyncV1Reciept;
  @Input() moveable: boolean = true;
  @Input() margin = true;
  //output event emiitter
  @Output() openOrder = new EventEmitter();
  @Output() openPayment = new EventEmitter();

  percent = 0;
  updateTrigger$ = new BehaviorSubject<number>(0);
  currency$ = this.settingSt.currencyCode$;
  constructor(
    public modalController: ModalController,
    private toast: ToastController,
    public alertController: AlertController,
    private orderSvc: OrderService,
    private nav: NavController,
    private active: ActivatedRoute,
    private paymentSvc: PaymentService,
    private analy: AnalyticsService,
    private recieptSt: RecieptState,
    private settingSt: SettingsState,
    private guestSt: GuestState
  ) {}

  hasClaim$ = this.settingSt.hasClaim$;

  ngOnInit() {
    //log output of tableDetail
    this.calcTime();
    var interval = setInterval(() => {
      //stop interval if order is delivered or cancelled
      if (
        this.order.current_column == "Delivered" ||
        this.order.current_column == "Cancelled"
      ) {
        clearInterval(interval);
      }

      this.calcTime();
      this.updateTrigger$.next(Date.now());
    }, 10000);
  }

  calcTime() {
    //get the seconds between now (utc), and order creation time (utc) in seconds
    if (
      this.order.current_column != "Delivered" &&
      this.order.current_column != "Cancelled"
    ) {
      let time = Math.abs(
        Math.floor(
          (new Date().getTime() -
            new Date(this.order.created_at_utc).getTime()) /
            1000
        )
      );

      if (time < 120) {
        this.percent = 0;
      } else {
        this.percent = (time / 600) * 100 - 12;
      }
    } else {
      this.percent = 0;
    }
    //get the minutes between now (utc), and order creation time (utc) in seconds
  }

  OpenOrder(event, o_uuid: string) {
    event.stopPropagation();
    Haptics.impact({
      'style': ImpactStyle.Light
    })
    this.analy.addEvent("View Order", this.order);
    this.openOrder.emit(this.order);
  }

  NextColumn(state) {
    switch (state) {
      case "Pending":
        return "Preparing";
        break;
      case "Preparing":
        return "Ready";
        break;
      case "Ready":
        return "Delivered";
        break;
      case "Delivered":
        return "Delivered";
      case "For Approval":
        return "Approve";
        break;
      case "Cancelled":
        return "Cancelled";
        break;
    }
    return "Preparing";
  }

  PaymentColor(r) {
    if (r > 0) {
      return "primary";
    }
    if (r < 0) {
      return "danger";
    }
    if (r == 0) {
      return "success";
    }
    return "success";
  }

  OpenPayment(event) {
    //track event
    event.stopPropagation();
    Haptics.impact({
      'style': ImpactStyle.Light
    })
    this.analy.addEvent("View Payment", this.order);
    this.paymentSvc.paymentOnReciept(this.order.receipt_id);
  }

  TrackOrderLine(index, line) {
    return line.item_name + line.quantity;
  }

  async orderStateButtonClicked(event, column) {
    if (this.moveable == false) {
      return;
    }
    event.stopPropagation();
    this.order.loading = true;
    this.analy.addEvent("Bump Order", this.order);
    try {
      Haptics.impact({
        'style': ImpactStyle.Heavy
      });
      await this.orderSvc
        .changeOrderStatus(this.order.o_uuid, {
          old_state: (this.order.current_column as string).toLowerCase(),
          new_state: this.NextColumn(this.order.current_column).toLowerCase(),
          o_ids: [this.order.order_id],
        })
        .toPromise();
    } catch (error) {
      Haptics.vibrate();
      console.error(error);
      //create toast
    }
    this.order.loading = false;
  }

  shouldShowUser$ = (order: SyncV1Order) =>
    this.settingSt.currentUser$.pipe(
      map((user) => {
        let rightcol = ["Preparing", "Ready"].includes(order.current_column);
        let touchedExists = order.last_touched_user_id != undefined;
        let thiuser = order.last_touched_user_id == user["username"];
        return rightcol && touchedExists && !thiuser;
      })
    );

  colours = ["#EB4444", "#49DCB1", "#E36936", "#FF9FE5", "#337CA0", "#FFC857"];
  usernameToColor(name: string) {
    // this is NOT a great hash but serves as an example
    let sum = 0;
    for (let i = 0; i < name.length; i++) {
      sum += name.charCodeAt(i);
    }
    return this.colours[sum % this.colours.length];
  }
}
