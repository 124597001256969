<ion-header>
  <ion-toolbar color="medium">
    <ion-title>Change Tag Selection</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngrxLet="tag_groups$ | async as groups">
  <ion-list *ngIf="groups != undefined" class="ion-margin-top">
    <app-tag-item
      (tagClicked)="selectTag($event)"
      title="General Tags"
      [tags]="groups.other"
    >
    </app-tag-item>
    <app-tag-item
      (tagClicked)="selectTag($event)"
      title="Music Tags"
      [tags]="groups.music"
    >
    </app-tag-item>
    <app-tag-item
      (tagClicked)="selectTag($event)"
      title="Taste Tags"
      [tags]="groups.taste"
    >
    </app-tag-item>
    <app-tag-item
      (tagClicked)="selectTag($event)"
      title="Brand Tags"
      [tags]="groups.brand"
    >
    </app-tag-item>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar color="medium">
    <ion-button expand="block" (click)="dismiss()"> Done </ion-button>
  </ion-toolbar>
</ion-footer>
