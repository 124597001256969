<ion-card [button]="button" [color]="color" [class.border]="border">
  <div class="img-wrapper">
    <ion-img [src]="backgroundImageUrl | imagePipe: 'small'"></ion-img>
  </div>
  <ion-card-content>
    <div>
      {{ text }}
    </div>
    <div class="price">
      {{ price | currency: currency }}
    </div>
  </ion-card-content>
</ion-card>
