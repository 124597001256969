<ion-list>
  <ion-item
    [style.border-color]="notificationOpt.type == 'notif' ? 'red' : ''"
    (click)="set({ type: 'notif', opt: 'all' }, $event)"
  >
    <ion-icon
      [color]="notificationOpt.type == 'notif' ? 'primary' : 'light'"
      slot="start"
      name="notifications-outline"
    ></ion-icon>
    <ion-label [color]="notificationOpt.type == 'notif' ? 'primary' : 'light'"
      >Notify</ion-label
    >
    <ion-chip
      [color]="isus('notif', 'all') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'notif', opt: 'all' }, $event)"
      >All</ion-chip
    >
    <ion-chip
      [color]="isus('notif', 'boh') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'notif', opt: 'boh' }, $event)"
      >BOH</ion-chip
    >
    <ion-chip
      [color]="isus('notif', 'foh') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'notif', opt: 'foh' }, $event)"
      >FOH</ion-chip
    >
  </ion-item>
  <ion-item (click)="set({ type: 'force', opt: 'all' }, $event)">
    <ion-icon
      [color]="notificationOpt.type == 'force' ? 'primary' : 'light'"
      slot="start"
      name="alert-outline"
    ></ion-icon>
    <ion-label [color]="notificationOpt.type == 'force' ? 'primary' : 'light'"
      >Force Notify</ion-label
    >
    <ion-chip
      [color]="isus('force', 'all') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'force', opt: 'all' }, $event)"
      >All</ion-chip
    >
    <ion-chip
      slot="end"
      [color]="isus('force', 'boh') ? 'primary' : 'light'"
      (click)="set({ type: 'force', opt: 'boh' }, $event)"
      >BOH</ion-chip
    >
    <ion-chip
      [color]="isus('force', 'foh') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'force', opt: 'foh' }, $event)"
      >FOH</ion-chip
    >
  </ion-item>
  <ion-item (click)="set({ type: 'bottle', opt: 'all' }, $event)">
    <ion-icon
      [color]="notificationOpt.type == 'bottle' ? 'primary' : 'light'"
      slot="start"
      name="balloon-outline"
    ></ion-icon>
    <ion-label [color]="notificationOpt.type == 'bottle' ? 'primary' : 'light'"
      >Bottle Procession</ion-label
    >
    <ion-chip
      [color]="isus('bottle', '1') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'bottle', opt: '1' }, $event)"
      >1</ion-chip
    >
    <ion-chip
      [color]="isus('bottle', '2') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'bottle', opt: '2' }, $event)"
      >2</ion-chip
    >
    <ion-chip
      [color]="isus('bottle', '3') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'bottle', opt: '3' }, $event)"
      >3</ion-chip
    >
    <ion-chip
      [color]="isus('bottle', '4') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'bottle', opt: '4' }, $event)"
      >4</ion-chip
    >
    <ion-chip
      [color]="isus('bottle', '5') ? 'primary' : 'light'"
      slot="end"
      (click)="set({ type: 'bottle', opt: '5' }, $event)"
      >5</ion-chip
    >
  </ion-item>
</ion-list>
