import { Actor } from "./mapactor.graphic";
import { ToolBar } from "./toolbar.graphic";
import {
  Application,
  Container,
  Sprite,
  Texture,
  Graphics,
  DisplayObject,
} from "pixi.js";
import { GlowFilter } from "@pixi/filter-glow";
import { MapObject, Scale } from "../../../models/map_editor_models";
import { Subject } from "rxjs";

export class Lines extends Actor {
  data: any | null;
  public SavedPoints;
  public ControllPoints: Graphics;
  public mapObject: MapObject;
  public CurrentlySelected: boolean = false;
  private SelectedObject: Subject<any>;
  editing: boolean = false

  constructor(InputObject: MapObject, editing: boolean, Selected: Subject<any>) {
    super();
    this.mapObject = InputObject;
    var points = [200, 150, 0, 0];
    if (InputObject.Data != undefined) {
      points = InputObject.Data as any;
    }
    var s = (this.line.width = 9);
    var c = (this.line.color = 0x191919);
    this.editing = editing;
    this.SelectedObject = Selected;
    
    this.SavedPoints = points;

    Selected.subscribe((next) => {
      if (this.CurrentlySelected == true && next != this) {
        this.DeSelect();
      }
      if (next == this && this.CurrentlySelected == false) {
        this.onSelect();
      }
    });

    this.lineStyle(s, c);

    this.moveTo(points[0], points[1]);
    this.lineTo(points[2], points[3]);
    this.interactive = true;
    const toselect = this
    //create point container for easy manipulation
    this.ControllPoints = new Graphics();
    this.addChild(this.ControllPoints as any);
    //create two points to control the line
    var point1 = new Graphics();
    point1.beginFill(0xffffff, editing ? 1 : 0);
    point1.drawCircle(points[0], points[1], 5);
    point1.endFill();
    point1.pivot.x = points[0];
    point1.pivot.y = points[1];
    point1.x = points[0];
    point1.y = points[1];

    point1.interactive = true;

    this.ControllPoints.addChild(point1 as any);

    point1.onpointerdown = function (e) {
      this.data = e.data;
      const viewport = this.parent;
      this.dragging = true;
      this.parent.parent.parent.drag({ pressDrag: false });
      toselect.onSelect()
    };
    point1.onpointerup = function (e) {
      this.data = e.data;
      const viewport = e.target;
      this.dragging = false;
      this.parent.parent.parent.drag();
    };
    point1.onpointerupoutside = function (e) {
      this.data = e.data;
      const viewport = e.target;
      this.dragging = false;
      this.parent.parent.parent.drag();
      this.Selected = this;
    };
    point1.onglobalpointermove = function (e) {
      if (this.dragging) {
        const newPosition = this.data!.getLocalPosition(this.parent);
        this.x = newPosition.x;
        this.y = newPosition.y;
        this.Selected = this;

        this.parent.parent.SavedPoints[0] = newPosition.x;
        this.parent.parent.SavedPoints[1] = newPosition.y;
        this.parent.parent.updatePoints();
      }
    };

    var point2 = new Graphics();
    point2.beginFill(0xffffff, editing ? 1 : 0);
    point2.drawCircle(points[2], points[3], 5);
    point2.pivot.x = points[2];
    point2.pivot.y = points[3];
    point2.x = points[2];
    point2.y = points[3];
    point2.endFill();
    point2.interactive = true;
    this.ControllPoints.addChild(point2 as any);
    point2;
    point2.onpointerdown = function (e) {
      this.data = e.data;
      const viewport = this.parent;

      toselect.onSelect()
      this.dragging = true;
      this.parent.parent.parent.drag({ pressDrag: false });
    };
    point2.onpointerup = function (e) {
      this.data = e.data;
      const viewport = e.target;
      this.dragging = false;
      this.parent.parent.parent.drag();
    };
    point2.onpointerupoutside = function (e) {
      this.data = e.data;
      const viewport = e.target;
      this.dragging = false;
      this.parent.parent.parent.drag();
      this.Selected = this;
    };
    point2.onglobalpointermove = function (e) {
      if (this.dragging) {
        const newPosition = this.data!.getLocalPosition(this.parent);
        this.x = newPosition.x;
        this.y = newPosition.y;
        this.Selected = this;

        this.parent.parent.SavedPoints[2] = newPosition.x;
        this.parent.parent.SavedPoints[3] = newPosition.y;
        this.parent.parent.updatePoints();
      }
    };
  }

  updatePoints() {
    var points = (this.SavedPoints = this.SavedPoints.map(
      (val, index) => val || this.SavedPoints[index]
    ));

    var s = this.line.width,
      c = this.line.color;

    this.clear();
    this.lineStyle(s, c);
    this.moveTo(points[0], points[1]);
    this.lineTo(points[2], points[3]);
  }

  getMapObject(): MapObject {
    this.mapObject.x = this.x;
    this.mapObject.y = this.y;
    this.mapObject.scale = { x: this.scale.x, y: this.scale.y };
    this.mapObject.rotation = this.angle;
    this.mapObject.Data = this.SavedPoints;
    return this.mapObject;
  }



  onSelect() {
    if (this.CurrentlySelected == true && this.editing == true) {
      this.DeSelect();
    } else {
      if (this.CurrentlySelected == false) {
        this.CurrentlySelected = true;

        this.filters = [
          new GlowFilter({
            color: 0x494949,
            distance: 12,
            outerStrength: 2,
            quality: 1,
          }) as any,
        ];
        //get current value of selected table, if selected table is this objec
        //this.viewport.ZoomToObject(this as unknown as DisplayObject);
        this.SelectedObject.next(this);
      }
    }
  }

  DeSelect() {
    this.CurrentlySelected = false;
    this.filters = [];
  }
}
