import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CMSPOSImport,
  CMSv2AvailabilityGroupList,
  CMSv2CollectionList,
  CMSv2CollectionPoint,
  CMSv2Menu,
  CMSv2MenuCategory,
  CMSv2MenuItem,
  CMSv2MenuModifierGroup,
  CMSv2MenuModifierGroupList,
  CMSv2Table,
  CMSv2TableList,
  CMSv2VenueSettings,
} from "src/app/models/cms_db_equiv";
import { RegionsService } from "../../regions/regions.service";
import { SyncV1MenuItem } from "src/app/models/sync_models";
import { PaceLimit, PaceLimits } from "src/app/models/bookings";

@Injectable({
  providedIn: "root",
})
export class CmsMenuService {
  constructor(public reg: RegionsService, public http: HttpClient) {}

  StartMenuImport() {
    return this.http.get<CMSPOSImport>(
      `${this.reg.ServerURL()}/cms_v2/menu/pos_import`
    );
  }
  EndMenuImport(updated) {
    return this.http.post<CMSPOSImport>(
      `${this.reg.ServerURL()}/cms_v2/menu/pos_import`,
      updated
    );
  }

  getMenu() {
    return this.http.get<CMSv2Menu>(`${this.reg.ServerURL()}/cms_v2/menu`);
  }
  addItem(item: SyncV1MenuItem) {
    return this.http.post(`${this.reg.ServerURL()}/cms_v2/menu`, item);
  }
  updateItem(item: SyncV1MenuItem) {
    if (item.image == undefined) {
      item.image = "";
    }
    
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/menu/${item.mi_id}`,
      item
    );
  }
  deleteItem(item: SyncV1MenuItem) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/menu/${item.mi_id}`
    );
  }
  getCategories() {
    return this.http.get<CMSv2Menu>(`${this.reg.ServerURL()}/cms_v2/category`);
  }
  addCategory(collection: CMSv2MenuCategory) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/category`,
      collection
    );
  }
  deleteCategory(collection: CMSv2MenuCategory) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/category/` + collection.menu_cat_id
    );
  }
  updateCategory(collection: CMSv2MenuCategory) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/category/` + collection.menu_cat_id,
      collection
    );
  }

  updateCategoryOrders(data) {
    return this.http.put(`${this.reg.ServerURL()}/cms_v2/menu`, data);
  }

  updateSuperCategoryOrders(data) {
    return this.http.put(`${this.reg.ServerURL()}/cms_v2/category/order`, data);
  }

  getModifierGroups() {
    return this.http.get<CMSv2MenuModifierGroupList>(
      `${this.reg.ServerURL()}/cms_v2/modifiers`
    );
  }

  getPaceLimit() {
    return this.http.get<PaceLimits>(
      `${this.reg.ServerURL()}/cms_v2/pace_limit`
    );
  }

  createPaceLimit(pace) {
    return this.http.post<PaceLimit>(
      `${this.reg.ServerURL()}/cms_v2/pace_limit`,
      pace
    );
  }

  updatePaceLimit(pace) {
    return this.http.put<PaceLimits>(
      `${this.reg.ServerURL()}/cms_v2/pace_limits`,
      pace
    );
  }

  deleteModifierGroup(modifier: CMSv2MenuModifierGroup) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/modifiers/${modifier.id}`
    );
  }
  saveModifierGroup(modifier: CMSv2MenuModifierGroup) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/modifiers/${modifier.id}`,
      modifier
    );
  }
  createModifierGroup(modifier: CMSv2MenuModifierGroup) {
    return this.http.post(`${this.reg.ServerURL()}/cms_v2/modifiers`, modifier);
  }
  //get availabilitys
  getAvailabilitys() {
    return this.http.get<CMSv2AvailabilityGroupList>(
      `${this.reg.ServerURL()}/cms_v2/availabilities`
    );
  }

  getPrices(id) {
    return this.http.get<any>(
      `${this.reg.ServerURL()}/cms_v2/menu/${id}/price_overrides`
    );
  }
}
