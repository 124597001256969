import { CurrencyPipe, DOCUMENT, registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeAu from "@angular/common/locales/en-AU";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Inject,
  Injectable,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { JwtConfig, JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { Amplify } from "@aws-amplify/core";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { HttpCacheInterceptorModule } from "@ngneat/cashew";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { AuthEffects } from "./effects/auth.effects";
import { CartEffects } from "./effects/cart/cart.effects";
import { NotificationsEffects } from "./effects/notifications/notification.effects";
import { SubscriptionEffects } from "./effects/subscriptions/subscription.effects";
import { TablesEffects } from "./effects/tables.effects";
import { VenueEffects } from "./effects/venues/venues.effects";
import { AnonymousUserIdInterceptor } from "./interceptors/anonymous-user-id.interceptor";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { metaReducers, reducers } from "./reducers";
import { QRCodeModule } from "angularx-qrcode";
//import { EmmAppConfig } from "@waqasjamil/emm-app-config/ngx";
import { BatteryStatus } from "@awesome-cordova-plugins/battery-status/ngx";
import { FilterpipePipe } from "./filterpipe.pipe";
import { IonicStorageModule } from "@ionic/storage-angular";

//import { AppVersion } from "@ionic-native/app-version/ngx";
//import { FileSelectDirective } from "ng2-file-upload";
import { NativeStorage } from "@awesome-cordova-plugins/native-storage/ngx";
//import { ElementModule } from "./element.module";
import { VenueHeaderInterceptor } from "./interceptors/venue.interceptor";
import { PushModule } from "@ngrx/component";
import { SyncService } from "./services/sync-engine/sync/sync.service";
import { SyncInterceptor } from "./interceptors/sync.interceptor";
registerLocaleData(localeAu);

export function jwtOptionsFactory(auth: AuthClass): JwtConfig {
  return {
    tokenGetter: () => {
      return auth
        .currentSession()
        .then((session) => {
          const idToken = session.getIdToken();
          return idToken.getJwtToken();
        })
        .catch(() => undefined);
    },
    allowedDomains: environment.validjwtdomain.map((originalUrl) => {
      const url = new URL(originalUrl);
      if (originalUrl == "http://127.0.0.1:5000") {
        return "127.0.0.1:5000";
      } else if (originalUrl == "http://localhost:5000") {
        return "localhost:5000";
      } else if (originalUrl.includes("http://127.0.0.1:3000")) {
        return "127.0.0.1:3000";
      } else {
        return url.hostname;
      }
    }),
  };
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, FilterpipePipe],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    QRCodeModule,
    ComponentsModule,
    EffectsModule.forRoot([
      AuthEffects,
      TablesEffects,
      CartEffects,
      NotificationsEffects,
      SubscriptionEffects,
      VenueEffects,
    ]),
    HttpCacheInterceptorModule.forRoot(),
    HttpClientModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({ mode: "ios" }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthClass],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionWithinNgZone: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CurrencyPipe,
    //EmmAppConfig,
    BatteryStatus,
    //AppVersion,
    NativeStorage,
    SyncService,
    {
      provide: AuthClass,
      useFactory: () => {
        return Amplify.Auth;
      },
    },
    {
      provide: LOCALE_ID,
      useValue: "en-AU",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AnonymousUserIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SyncInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VenueHeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
