
<ion-item-group class="rounded-group" 
>

<ion-item

  [style.--padding-start.px]="reduced ? 8 : 16"
  class="manual"
  lines="full"
  [color]="color"
  (click)="editios ? editTags() : null"
>
      <ion-label >
        {{ title }}</ion-label
      >
      <ion-note slot="end" 
      
        *ngIf="subtitle != undefined"
        >{{ subtitle }}</ion-note
      >
  </ion-item>
  <ion-item *ngIf="showableTags.length > 0" lines="full" [style.--padding-start.px]="reduced ? 8 : 16"
 [color]="color">


    <div class="tags" [class.wrap]="wrap">
      <app-tag
        *ngFor="let tag of showableTags; trackBy: trackByTag"
        [tag]="tag"
        [edit]="edit"
        (clicked)="tagClicked.emit(tag)"
        (removed)="edit ? removeTag(tag) : tagClicked.emit(tag)"
      >
      </app-tag>
      <ion-chip

        (click)="editTags()"
        *ngIf="edit && editios == false"
        [outline]="true"
        color="light"
      >
        <ion-icon [style.margin-left.px]="-4" name="create-outline"></ion-icon>
      </ion-chip>
    </div>


</ion-item>

<ion-item (click)="editTags()" button [detail]="false" lines="none" *ngIf="editios" color="secondary">
  <ion-label color="tertiary">
    Add Tag
  </ion-label>
  <ion-icon color="tertiary" slot="end" name="add-outline"></ion-icon>
</ion-item>

</ion-item-group>