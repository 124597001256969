import {
  Component,
  Injectable,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CMSv2AailabilityInterval,
  CMSv2MenuCategory,
  CMSv2MenuModifierGroup,
} from "src/app/models/cms_db_equiv";
import { MenuService } from "src/app/services/menu/menu.service";
import { CmsMenuService } from "src/app/services/cms/menu/cms-menu.service";
import { ModalController } from "@ionic/angular";
import { float } from "@zxing/library/esm/customTypings";
import { ConsumerItemDetailsPage } from "src/app/pages/consumer-item-details/consumer-item-details.page";

@Component({
  selector: "app-category-modal",
  templateUrl: "./category-modal.component.html",
  styleUrls: ["./category-modal.component.scss"],
})
export class CategoryModalComponent implements OnInit {
  @Input() Category: CMSv2MenuCategory;

  Intervals: ParsedInterval[] = [
    {
      id: 0,
      start: "09:00",
      end: "21:00",
      day: "everyday",
      start_parse: 9,
      end_parse: 21,
    },
    {
      id: 0,
      start: "09:00",
      end: "21:00",
      day: "friday",
      start_parse: 3,
      end_parse: 12,
    },
  ];
  days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  constructor(
    private menuSvc: CmsMenuService,
    private modal: ModalController
  ) {}

  ngOnInit() {
    this.ConvertModel();
  }

  addInterval() {
    if (this.Category.availability == undefined) {
      this.Category.availability = { id: 0, name: "", intervals: [] };
    }
    let newthing: CMSv2AailabilityInterval = {
      id: 0,
      start: "09:00",
      end: "21:00",
      day: "friday",
    };
    this.Category.availability.intervals.push(newthing);
  }
  removeInterval(interval: CMSv2AailabilityInterval) {
    let index = this.Category.availability.intervals.indexOf(interval);
    this.Category.availability.intervals.splice(index, 1);
    if (this.Category.availability.intervals.length == 0) {
      delete this.Category.availability;
    }
  }

  donothing() {}

  ConvertModel() {
    this.Intervals = [];
    //map intervals to Parsed Intervals
    this.Category.availability.intervals.forEach((interval) => {
      let startfloat = parseFloat(
        interval.start.split(":")[0] +
          "." +
          (parseFloat(interval.start.split(":")[1]) * 1.61).toString()
      );
      let endfloat = parseFloat(
        interval.end.split(":")[0] +
          "." +
          (parseFloat(interval.end.split(":")[1]) * 1.61).toString()
      );
      let newInterval: ParsedInterval = {
        id: interval.id,
        start: interval.start,
        end: interval.end,
        day: interval.day,
        start_parse: startfloat, //parse time to hours and ,
        end_parse: endfloat,
      };

      if (newInterval.start_parse === newInterval.end_parse) {
        if (interval.day == "always_available") {
          interval.day = "everyday";
        }

        let newInterval: ParsedInterval = {
          id: interval.id,
          start: interval.start,
          end: interval.end,
          day: interval.day,
          start_parse: startfloat, //parse time to hours and ,
          end_parse: endfloat + 24,
        };

        this.Intervals.push(newInterval);
      } else if (newInterval.start_parse > newInterval.end_parse) {
        let endfloat2 = endfloat + 24;

        if (endfloat2 > 24) {
          endfloat2 = 24;
        }

        let newInterval: ParsedInterval = {
          id: interval.id,
          start: interval.start,
          end: interval.end,
          day: interval.day,
          start_parse: startfloat, //parse time to hours and ,
          end_parse: endfloat2,
        };
        this.Intervals.push(newInterval);

        //get the index of the current day selected
        let index = this.days.indexOf(interval.day);
        if (index == 6) {
          index = -1;
        }

        let start2 = startfloat - 24;
        if (start2 < 0) {
          start2 = 0;
        }
        let day2: ParsedInterval = {
          id: interval.id,
          start: interval.start,
          end: interval.end,
          day: this.days[index + 1],
          start_parse: start2, //parse time to hours and ,
          end_parse: endfloat,
        };
        this.Intervals.push(day2);
      } else {
        this.Intervals.push(newInterval);
      }
    });
  }

  SaveCategory() {
    if (this.Category.menu_cat_id == 0) {
      this.menuSvc.addCategory(this.Category).subscribe((data) => {
        this.modal.dismiss(this.Category);
      });
    } else {
      this.menuSvc.updateCategory(this.Category).subscribe((data) => {
        this.modal.dismiss(this.Category);
      });
    }
  }
}
export interface ParsedInterval extends CMSv2AailabilityInterval {
  start_parse: float;
  end_parse: float;
}
