import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, shareReplay, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Booking,
  SyncV1Guest,
  SyncV1Table,
} from "src/app/models/sync_models";
import { MapService } from "../../map/map.service";

@Injectable({
  providedIn: "root",
})
export class ManualState extends BaseSynced {
  state = new RxState<{ [key: string]: any }>();

  //fields specific to the service
  service_icon: string = "partly-sunny-outline";
  service_title: string = "Manual Store";
  service_id: string = "ManualStore"; //id of the service
  service_url: string = "manual";

  //fields specific to the data
  data_id: string = "booking_id";
  data_detail: string = "number";

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService,
    private mapSvc: MapService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  //get the menu items as an array
  map$ = this.state.select("map");

  async Sync() {
    if (this.serviceState.get("refreshing")) {
      return "uness";
    }
    this.serviceState.set({
      refreshing: true,
    });
    ///throw error

    let data = await this.mapSvc.getMap().toPromise();

    let map = {
      map: data,
    };

    this.state.set(map);

    this.serviceState.set({
      refreshing: false,
    });

    return "success";
  }
}
