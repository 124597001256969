import { createAction, props } from "@ngrx/store";
import { DqNotificationSubscription } from "../models/notification-subscription";

const ACTION_PREFIX = "[FCM Api]";

export const subscribeToTableNotificationsSuccess = createAction(
  `${ACTION_PREFIX} Subscribe to Table Notifications Success`,
  props<{ venueId: string; tableId: string }>()
);

export const subscribeToTableNotificationsError = createAction(
  `${ACTION_PREFIX} Subscribe to Table Notifications Error`,
  props<{ venueId: string; tableId: string }>()
);

export const unsubscribeToTableNotificationsSuccess = createAction(
  `${ACTION_PREFIX} Unsubscribe to Table Notifications Success`,
  props<{ venueId: string; tableId: string }>()
);

export const unsubscribeToTableNotificationsError = createAction(
  `${ACTION_PREFIX} Unsubscribe to Table Notifications Error`,
  props<{ venueId: string; tableId: string }>()
);
