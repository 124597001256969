<ion-header>
  <ion-toolbar>
    <ion-buttons class="xButton" slot="end" (click)="close()">
      <ion-icon size="large" color="primary" name="close"></ion-icon>
    </ion-buttons>
    <ion-item lines="none"><ion-title class="title">TREAT</ion-title></ion-item>
    <ion-title>How Does TREAT Work?</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <hr />
  <ion-list lines="none" color="dark-grey">
    <ion-item><ion-header> Gift as though you were there</ion-header></ion-item>
    <ion-item>
      <ion-icon name="gift-outline"></ion-icon>
      <ion-label class="description">
        Your friend or someone you follow shared a link allowing you to buy from
        the menu and have it delivered to them right now.
      </ion-label></ion-item
    >

    <ion-item>
      <ion-icon name="share-social-outline"></ion-icon>
      <ion-label class="description">
        You can choose to let them know who you are, or remain anonymous.
      </ion-label></ion-item
    >

    <ion-item>
      <ion-icon name="bag-check-outline"></ion-icon>
      <ion-label class="description">
        Once accepted by the venue, your recipient will receive an SMS to let
        them know that their treat is on it’s way.
      </ion-label></ion-item
    >

    <ion-item>
      <ion-icon name="person-remove-outline"></ion-icon>
      <ion-label class="description">
        If for some reason, they have left, or the venue cannot find your your
        recipient - your card won’t be charged.
      </ion-label></ion-item
    >
    <ion-item>
      <ion-icon name="eye-off-outline"></ion-icon>
      <ion-label class="description">
        TREAT respects your privacy. For information see our Terms and
        Conditions and Privacy Policy.
      </ion-label></ion-item
    >

    <ion-item class="poweredBy"
      ><ion-label class="poweredBy">Powered By</ion-label></ion-item
    >
    <ion-item class="logo_img">
      <ion-img src="assets/logo-light.png"></ion-img>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer
  ><ion-toolbar>
    <ion-button (click)="close()" size="large" expand="block"
      ><ion-label color="light">Close</ion-label></ion-button
    >
  </ion-toolbar></ion-footer
>
