<ion-app>
  <ion-split-pane contentId="main" when="(min-width: 4000px)">
    <ion-menu
      side="end"
      type="overlay"
      contentId="main"
      menuId="side-custom"
      class="custom-menu"
      swipe-gesture="false"
      [maxEdgeStart]="0"
      (ionDidClose)="
        payment.selected_payment = undefined;
        payment.selected_order = undefined;
        closenow()
      "
    >
      <ion-content class="inset">
        <div
          class="paym"
          *ngIf="
            payment.selected_order == undefined &&
            payment.selected_payment != undefined
          "
        >
          <app-payment-page
            [recieptId]="payment.selected_payment"
            [hiddennow]="payment.selected_payment"
            #paym
          >
          </app-payment-page>
        </div>
        <div
          *ngIf="
            payment.selected_order != undefined &&
            payment.selected_payment == undefined
          "
        >
          <app-order-detail [orderId$]="payment.selected_order" #detail>
          </app-order-detail>
        </div>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main" (activate)="showLoader = false">
      <ion-content *ngIf="showLoader" class="splashScreen">
        <ion-grid
          class="ion-text-center ion-justify-content-center splashScreen"
        >
          <ion-row>
            <ion-col>
              <ion-spinner color="white"> </ion-spinner>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-router-outlet>
  </ion-split-pane>
</ion-app>
