import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { Observable, take } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";
import { Card } from "src/app/models/bump_screen_models";
import { OrderService } from "src/app/services/order/order.service";
import { Availability, RequestOrder } from "src/app/models/bookings";
import { MenuState } from "src/app/services/sync-engine/menu-state/menu.service";
import { PackageState } from "src/app/services/sync-engine/package-state/package.service";
import { CmsSettingsService } from "src/app/services/cms/settings/cms-settings.service";
import { BookingService } from "src/app/services/booking/booking.service";
import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";

@Component({
  selector: "app-package-inclusions",
  templateUrl: "./package-inclusions.component.html",
  styleUrls: ["./package-inclusions.component.scss"],
})
export class PackageInclusionsComponent implements OnInit {
  @Input() packageId;
  @Input() status;
  @Output() updated = new EventEmitter();
  @Output() loaded = new EventEmitter();
  @Output() clack = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Input() showSetup = false;
  @Input() editable = false;
  @Input() showTitle = true;
  @Input() detail = false;

  public validSelection = true;

  isTablet =
    (this.platform.is("tablet") || this.platform.is("desktop")) &&
    window.innerWidth > 1200;

  package$ = this.packageSt.package$;
  menu_item$ = this.menuSt.menuItem$;
  modifier_group$ = this.menuSt.modifierGroup$;
  modifiers$ = this.menuSt.modifierForGroup$;
  deliver = {};

  /*ItemModifiers = {
    modifiers: number[];
    mg_id: number;
  }*/

  selectAll() {
    
    this.package$(this.packageId)
      .pipe(take(1))
      .subscribe((pack) => {
        pack.inclusions.forEach((incl) => {
          (incl as any).checkin = true;

          this.updatedDeliver(incl);

          this.deliver[incl.pinc_id].quantity = incl.avail;
        });

        this.update();
      });
  }

  deselectAll() {

    this.package$(this.packageId)
      .pipe(take(1))
      .subscribe((pack) => {
        pack.inclusions.forEach((incl) => {
          (incl as any).checkin = false;

          this.updatedDeliver(incl);
          this.update();
        });
        this.deliver = {};

        this.update();
      });
  }

  updatedDeliver(incl) {
    if (this.deliver[incl.pinc_id] == undefined) {
      let te = [];
      if (incl.item_options != undefined && incl.item_options.length > 0) {
        for (let index = 0; index < incl.quantity; index++) {
          te.push("");
        }
      }

      this.deliver[incl.pinc_id] = {
        id: incl.pinc_id,
        active: incl.checkin,
        quantity: incl.avail,
        mi_id: incl.mi_id,
        options: te,
      };
      console.log("deliver", this.deliver);
    } else {
      this.deliver[incl.pinc_id].active = incl.checkin;
    }
  }

  get RequestedItems() {
    return Object.values(this.deliver).filter((i: any) => i.active == true);
  }

  constructor(
    public modalController: ModalController,
    public alertController: AlertController,
    private modal: ModalController,
    private menuSt: MenuState,
    private packageSt: PackageState,
    private platform: Platform,
    private cms: CmsSettingsService,
    private toast: ToastController,
    private load: LoadingController,
    private book: BookingService
  ) {}

  ngOnInit() {
    this.loaded.emit(true);
    //log output of tableDetail
  }
  trackByID(index: number, obj: any): any {
    return obj.pinc_id;
  }

  async request(booking_id) {
    if (this.RequestedItems.length == 0) {
      return;
    }

    if (this.validSelection == false) {
      const toast = await this.toast.create({
        message: "Please check your selections",
        duration: 2000,
        position: "top",
        swipeGesture: "vertical",

      });
      toast.present();

      return;
    }

    Haptics.impact();

    //loading ctrl
    const loading = await this.load.create({
      message: "Please wait...",
    });
    await loading.present();
    try {
      console.log("request", this.RequestedItems);
      let finalform = [];

      this.RequestedItems.forEach((item: any) => {
        //deep copy item
        let req = JSON.parse(JSON.stringify(item));
        delete req.active;
        delete req.mi_id;
        console.log("The Requested Items", item);
        console.log(req);

        finalform.push(req);
      });

      console.log("final form", finalform);
      
      let result = await this.book
        .postItemRequest(booking_id, finalform)
        .toPromise();
      console.log("result", result);

      Haptics.selectionEnd();
      this.deliver = {};
      this.update();
    } catch (error) {
      console.log("error", error);
    }

    await loading.dismiss();
  }

  update() {
    //are there any items that are active?
    if (this.RequestedItems.length > 0) {
      //check the modifers on all of these are valid
      let valid = true;
      console.log("menu item", this.RequestedItems);

      this.RequestedItems.forEach((item: any) => {
        console.log("menu item", item);

        if (item.options != undefined && item.options.length > 0) {
          //check none of the options are empty
          //loop through options
          for (let index = 0; index < item.options.length; index++) {
            const element = item.options[index];
            if (index >= item.quantity) {
            } else {
              if (element == "") {
                valid = false;
              }
            }
          }
        }
      });

      this.validSelection = valid;
    } else {
      this.validSelection = true;
    }

    this.updated.emit(this.RequestedItems);
  }

  async editQuantity(item, pack) {
    Haptics.impact({
      'style': ImpactStyle.Light
    })
    console.log("edit quantity", item, pack);
    const alert = await this.alertController.create({
      header: 'Edit Available Quantity',
      inputs: [
        {
          name: 'quantity',
          type: 'tel',
          min: 1,
        
          value: item.quantity,
          placeholder: 'Enter quantity'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Update',
          handler: (data) => {
            item.quantity = parseInt(data.quantity);
            // Clear any delivery requests if quantity reduced
            if (this.deliver[item.pinc_id] && data.quantity < item.options?.length) {
              delete this.deliver[item.pinc_id];
            }
            Haptics.notification({
              'type': NotificationType.Success
            })
            this.updatePackage(pack);
            this.update();
          }
        }
      ]
    });

    await alert.present();
  }

  changeSelection(event) {
    Haptics.selectionStart()
    Haptics.selectionChanged()
    Haptics.selectionEnd()
  }

  async removeItem(item, pack) {

    if (this.status == "seated") {
      Haptics.notification({
        'type': NotificationType.Warning
      })
    
    // Show warning before removing item during seated status
    const alert = await this.alertController.create({
      header: 'Remove Item',
      message: 'Removing items once seated can cause accounting changes',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Remove',
          role: 'destructive',
          handler: () => {
            // Clear any delivery requests for this item
            if (this.deliver[item.pinc_id]) {
              delete this.deliver[item.pinc_id];
              this.update();
            }
            return true;
          }
        }
      ]
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'cancel') {
      return;
    }
    } else {
      Haptics.notification({
        'type': NotificationType.Success
      })
    }
    let inclusions = pack.inclusions.filter((i) => i != item);
    pack.inclusions = inclusions;


    this.updatePackage(pack);
  }

  async updatePackage(pack) {
    console.log("update package", pack);

    this.packageSt.state.set({
      [pack.package_id]: pack,
    });

    await this.cms.updatePackage(pack).toPromise();
  }

  //track by pinc_ic
  trackByPincId(index: number, obj: any): any {
    return index;
  }
}
