import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import * as TableActions from "../../actions/table.actions";
import {
  DqTableDetails,
  DqCollectionPointTableDetails,
} from "src/app/services/table/table.service";
import { createReducer, on, Action, createSelector } from "@ngrx/store";
import { state } from "@angular/animations";

export interface State extends EntityState<DqTableDetails> {
  // additional entities state properties
  selectedId: string;
  influencerTableSelected?: number;
}

export const adapter = createEntityAdapter<DqTableDetails>({
  selectId: (table) => table.table_id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
});

const tableReducer = createReducer(
  initialState,
  on(
    TableActions.loadCollectionPointsSuccess,
    (state, { collectionPoints }) => {
      const tables = collectionPoints.reduce<DqTableDetails[]>((acc, cp) => {
        return acc.concat(cp.tables);
      }, []);
      adapter.removeAll(state);
      let nbewst = adapter.setAll(tables, state);
      return nbewst;
    }
  ),
  //change the is_assigned property of the table
  on(TableActions.favTable, (state, { table }) =>
    adapter.updateOne(
      { id: table.table_id, changes: { is_assigned: true } },
      state
    )
  ),
  on(TableActions.unFavTable, (state, { table }) =>
    adapter.updateOne(
      { id: table.table_id, changes: { is_assigned: false } },
      state
    )
  ),
  on(TableActions.updateTable, (state, { table }) =>
    adapter.setOne(table, state)
  ),
  on(TableActions.clearCollectionPoints, (state) => adapter.removeAll(state)),
  on(TableActions.selectTable, (state, { selectedTableId }) => ({
    ...state,
    selectedTableId,
  })),
  on(TableActions.influencerTableSelected, (state, payload): State => {
    return {
      ...state,
      influencerTableSelected: payload.tableId,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectSelectedTableId = (state: State) => state.selectedId;

export const selectTreatSelectedTableId = (state: State) =>
  state.influencerTableSelected;

export const selectSelectedTable = createSelector(
  selectEntities,
  selectSelectedTableId,
  (tables, selectedId) => tables[selectedId]
);
