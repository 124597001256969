import { CommonModule } from "@angular/common";
import { Component, HostListener, NgModule, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import {
  IonicModule,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { RegionsService } from "src/app/services/regions/regions.service";
import { Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { combineLatestWith, filter, map, withLatestFrom } from "rxjs/operators";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { select, Store } from "@ngrx/store";
import { notificationsSelectAll, State } from "src/app/reducers";
import { checkIn } from "src/app/actions/table.actions";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { DataStorePage } from "src/app/pages/data-store/data-store.page";
import { AuthService } from "src/app/services/auth/auth.service";
import { NotificationState } from "src/app/services/sync-engine/notification-state/notification.service";
import { Observable } from "rxjs";
import { SyncV1Notification } from "src/app/models/sync_models";

@Component({
  selector: "app-cms-menu",
  templateUrl: "./cms-menu.component.html",
  styleUrls: ["./cms-menu.component.scss"],
})
export class CmsMenuComponent implements OnInit {
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public region: RegionsService,
    private renderer2: Renderer2,
    private loading: LoadingController,
    private pusher: PusherService,
    private store$: Store<State>,
    private analy: AnalyticsService,
    private notificationSvc: NotificationService,
    private notificationSt: NotificationState,
    private settingSt: SettingsState,
    private modal: ModalController,
    private authSvc: AuthService
  ) {}

  currentpage;
  venue_id;
  has_permission$ = this.settingSt.hasClaim$

  notifications$: Observable<SyncV1Notification[]> =
    this.notificationSt.notifications$.pipe(
      withLatestFrom(this.settingSt.currentUser$),
      map(([notifications, user]) => {
        return notifications.filter((notification) => {
          return this.notificationSt.shouldBeSeen(notification, user);
        });
      })
    );

  unreadNotifications$ = this.notificationSt.notifications$.pipe(
    combineLatestWith(
      this.settingSt.currentUser$,
      this.settingSt.lastReadNotifications$
    ),
    map(([notifications, user, lastRead]) => {
      if (user) {
        return notifications.filter((x) => {
          if (new Date(x.created_utc).getTime() > lastRead) {
            console.log(new Date(x.created_utc).getTime(), lastRead, x);
          }
          return (
            new Date(x.created_utc).getTime() > lastRead &&
            this.notificationSt.shouldBeSeen(x, user) &&
            x.sender != user["username"]
          );
        }).length;
      }
    })
  );

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.code == "KeyD") {
      console.log("Open Menu Modal");
      this.modal
        .create({
          component: DataStorePage,
        })
        .then((modal) => {
          modal.present();
        });
    }
  }

  async ngOnInit() {
    this.settingSt.InitStore();
    this.notificationSt.InitStore();
    try {
      this.currentpage = this.router.url.split("cms/")[1].split("/")[0];
    } catch (e) {
      console.log(e);
    }
   

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        // You only receive NavigationStart events
        console.log(event);

        this.currentpage = event.url.split("cms/")[1].split("/")[0];
        console.log(this.currentpage);
      });
  }

  viewedNotifs() {
    console.log("Viewed Notifs");
    this.settingSt.readNotifications();
  }

  openPage(link: String) {
    this.analy.addEvent("CMS Open " + link, {});
    this.router.navigate([this.region.getRegion(), "cms", link]);
  }
  venues$ = this.settingSt.venues$;
  switchVenue() {
    this.authSvc.switchVenue();
  }

  async logout() {
    /**
     * Note that the menu is manually closed here, rather than using the
     * ion-menu-toggle component. When using the ion-menu-toggle component and
     * logging out, in some cases, this left the login screen unresponsive as
     * the menu close animation is interrupted, leaving html classes that locked
     * out interaction with the login page.
     */

    return this.authSvc.logout();
  }
}

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [CmsMenuComponent],
  declarations: [CmsMenuComponent],
  providers: [],
})
export class CmsMenuModule {}
