import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
@Component({
  selector: "app-reciept-chip",
  templateUrl: "./reciept-chip.component.html",
  styleUrls: ["./reciept-chip.component.scss"],
})
export class RecieptChipComponent implements OnInit {
  @Input() receipt_id: string;

  @Output() clicked = new EventEmitter();
  reciept$ = this.recieptSt.receipt$
  currency$ = this.settingSt.currencyCode$
  constructor(
    private region: RegionsService,
    private settingSt: SettingsState,
    private recieptSt: RecieptState
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  
}
