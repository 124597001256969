<ion-item>
  <ion-label>Payment Method</ion-label>
  <ion-select
    placeholder="Select One"
    interface="action-sheet"
    [value]="paymentMethod"
    (ionChange)="paymentMethodDidChange($event)"
  >
    <ng-container *ngFor="let method of enabledPaymentMethods">
      <ion-select-option [value]="method.value">{{
        method.label
      }}</ion-select-option>
    </ng-container>
  </ion-select>
</ion-item>
