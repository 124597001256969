import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, shareReplay, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Package,
  SyncV1Reciept,
  SyncV1Table,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class RecieptState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Reciept }>();

  //fields specific to the service
  service_icon: string = "receipt-outline";
  service_title: string = "Reciept Store";
  service_id: string = "Receipt"; //id of the service
  service_url: string = "receipts";

  //fields specific to the data
  data_id: string = "receipt_id";
  data_detail: string = "total";

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));



  //get table using id
  reciept_request_paid$ = (re_id: string) => this.receipt$(re_id).pipe(map((receipt) =>{
    console.log("receipt req", receipt)
    let ourPayment = receipt.payments.find((pi) => pi.name.includes("Request"))
    let totalNeeded = ourPayment.amount
    let finishing_relevant_payment
    finishing_relevant_payment = receipt.payments
      .filter(p => new Date(p.time).getTime() > new Date(ourPayment.time).getTime() && p.status == "paid")
      .sort((a,b) => new Date(b.time).getTime() - new Date(a.time).getTime())[0]
    let totalPaid = receipt.payments
      .filter(p => new Date(p.time).getTime() > new Date(ourPayment.time).getTime() && p.status == "paid") 
      .reduce((sum, p) => {
        if (sum + p.amount > totalNeeded && finishing_relevant_payment == undefined) {
          finishing_relevant_payment = p
        }

        return sum + p.amount
      }, 0)

    

    return {status: totalPaid >= totalNeeded, re_id: re_id, payment_time: finishing_relevant_payment?.time}
  } ),
  shareReplay(1)
)

  receipt$ = (id) => this.state.select(id);
  getDirect$ = (id) => this.state.select(id);
}
