import {
  Component,
  ComponentRef,
  ElementRef,
  Injectable,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CMSv2AailabilityInterval,
  CMSv2MenuCategory,
  CMSv2MenuItem,
  CMSv2MenuModifierGroup,
  CMSv2Table,
  CMS_Package,
  CMS_PackageInclusion,
} from "src/app/models/cms_db_equiv";
import { MenuService } from "src/app/services/menu/menu.service";
import { CmsMenuService } from "src/app/services/cms/menu/cms-menu.service";
import { IonPopover, ModalController, PopoverController } from "@ionic/angular";
import { float } from "@zxing/library/esm/customTypings";
import { CmsTablesService } from "src/app/services/cms/tables/cms-tables.service";
import { CmsSettingsService } from "src/app/services/cms/settings/cms-settings.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { PackageType } from "src/app/models/bookings";
import {
  SyncPackageInclusion,
  SyncV1MenuItem,
  SyncV1Package,
} from "src/app/models/sync_models";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { MenuState } from "src/app/services/sync-engine/menu-state/menu.service";

interface CMS_Package_Extend extends CMS_Package {
  price_number: number;
}

@Component({
  selector: "app-package-modal",
  templateUrl: "./package-modal.component.html",
  styleUrls: ["./package-modal.component.scss"],
})
export class PackageModalComponent implements OnInit {
  @Input() Package: SyncV1Package;
  tables: CMSv2Table[] = [];
  searchbar = "";
  search = "";

  menu_items$ = this.menuSt.allMenuItems$;

  type: PackageType = "package";
  price = 0;
  deposit = 0;

  onlyItems = false;
  latestOnlyItem;

  @ViewChild("tablePop") tablePop: ElementRef;
  //ref tables ion-popover
  currency$ = this.settingSt.currencyCode$;
  table$ = this.tableSt.table$;
  tables$ = this.tableSt.tables_ordered$;

  constructor(
    private menuSvc: CmsMenuService,
    private tableSvc: CmsTablesService,
    private settingsSvc: CmsSettingsService,
    private modal: ModalController,
    private popover: PopoverController,
    private reg: RegionsService,
    private settingSt: SettingsState,
    private tableSt: TableState,
    private menuSt: MenuState
  ) {}

  menu_item$ = this.menuSt.menuItem$;

  async ngOnInit() {
    //remove $ if there is one
    if (this.Package.package_id != "0") {
      if (this.Package.price != undefined) {
        this.price = Number(this.Package.price);
      }
      if (this.Package.deposit != undefined) {
        this.deposit = this.Package.deposit;
      }

      this.Package.package_type = "advanced";

    
    } else {
    
    }

    //convert menu to flat list of items

    this.menuSt.InitStore();
    this.tables = (await this.tableSvc.getTables().toPromise()).tables;
  }
  async presentTablePopover(ev: any) {}

  AddItem(item?: SyncV1MenuItem, swappable: boolean = false) {
    //create popover to select item

    if (
      this.onlyItems == true &&
      this.latestOnlyItem != undefined &&
      item != undefined
    ) {
      this.latestOnlyItem.item_options.push(item.mi_id);
      this.popover.dismiss();

      return;
    }

    if (item != undefined) {
      let inclusion: SyncPackageInclusion = {
        pinc_id: "0",
        name: item.name,
        quantity: 1,
        mi_id: item.mi_id + "",
        avail: 0,
        item_options: undefined,
      };
      this.Package.inclusions.push(inclusion);
    } else {
      let inclusion: SyncPackageInclusion = {
        pinc_id: "0",
        name: this.searchbar,
        quantity: 1,
        mi_id: null,
        avail: 0,
        item_options: swappable ? [] : undefined,
      };
      this.Package.inclusions.push(inclusion);
    }
    this.popover.dismiss();
  }

  removeItem(item) {
    this.Package.inclusions = this.Package.inclusions.filter((i) => i != item);
  }

  removeItemOption(item, miopt) {
    item.item_options = item.item_options.filter((i) => i != miopt);
  }

  AddTable(table) {
    (this.Package as any).table_ids.push(table.table_id);
    //close popover
    this.popover.dismiss();
  }
  close() {
    this.modal.dismiss({ data: "yes" });
  }

  removeTable(table) {
    (this.Package as any).table_ids = (this.Package as any).table_ids.filter(
      (t) => t != table
    );
  }

  async save() {
    //apply price and deposit
    
    

    if (this.Package.package_id != "0") {
      await this.settingsSvc.updatePackage(this.Package).toPromise();
      this.modal.dismiss(this.Package);
    } else {
      await this.settingsSvc.createPackage(this.Package).toPromise();
      this.modal.dismiss(this.Package);
    }
  }

  async duplicate() {
    await this.save();
    //create a deep copy
    this.Package = JSON.parse(JSON.stringify(this.Package));
    this.Package.package_id = "0";
    this.Package.name = this.Package.name + " (copy)";
    this.Package.inclusions.forEach((inc) => {
      inc.pinc_id = "0";
    });

    await this.save();
  }

  async delete() {
    if (this.Package.package_id != "0") {
      await this.settingsSvc.deletePackage(this.Package).toPromise();
      this.modal.dismiss(this.Package);
    } else {
    }
  }
}
