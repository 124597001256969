import { Component, OnInit, ViewChild } from "@angular/core";
import {  ModalController } from "@ionic/angular";

@Component({
  selector: "app-onboarding-modal",
  templateUrl: "./onboarding-modal.component.html",
  styleUrls: ["./onboarding-modal.component.scss"],
})
export class OnboardingModalComponent implements OnInit {
  constructor(public modalcrt: ModalController) {}

  ngOnInit() {}

  next() {
   
  }
}
