import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { concatMap, filter, take } from "rxjs/operators";
import { RegionsService } from "../services/regions/regions.service";
import { CognitoUser } from "@aws-amplify/auth";
import { environment } from "src/environments/environment";
import Tracker from "@openreplay/tracker";
import { Platform } from "@ionic/angular";
import { SettingsState } from "../services/sync-engine/settings-state/settings-state.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInUserGuard implements CanActivate {
  constructor(
    private settingSt: SettingsState,
    private auth: AuthClass,
    private router: Router,
    private regionService: RegionsService,
    private platform: Platform
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log("Checking if Logged in user guard")

    if (this.settingSt.state.get('venue_id_checked') ) {
    await this.settingSt.venue_checked$.pipe(take(1)).toPromise()
    try {
    let user = this.settingSt.state.get("cognitoUser");
    let venueId = this.settingSt.state.get('currentVenue')
    
    let venue_claims =
    user["signInUserSession"]["idToken"]["payload"]["venue:" + venueId].split(' ')
  

      console.log("Checking if user is logged in USERG", user);
  
      if (user != undefined) {

      

      if (
        venue_claims.includes('management') &&
        !(this.platform.is("capacitor") || this.platform.is("ios"))
      ) {
        console.log("User is a CMS user");
        var reg = await this.regionService.getRegion();
        this.router.navigate([`${reg}/cms/dashboard`]);
        return false;
      } else {
        return true;
      }
    } else {
      console.log("User is not logged in")
    }
    } catch (err) {
      console.log("Error Checking")

      
    }
  } 
    console.log("Falling back to login")
    var reg = await this.regionService.getRegion();
    this.router.navigate([`${reg}/login`], {
      queryParams: {
        redirect: encodeURIComponent(state.url),
      },
    });
    return false;
 


  }
}
