import {
  Component,
  Inject,
  InjectionToken,
  NgModule,
  inject,
} from "@angular/core";
import {
  CanActivateFn,
  NavigationEnd,
  PreloadAllModules,
  Router,
  RouterModule,
  Routes,
} from "@angular/router";
import { WebOnlyGuard } from "./guards/web-only.guard.ts/web-only.guard";

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { map, take } from "rxjs/operators";
import { ManagerOnlyGuard } from "./guards/manager-only-guard/manager-only-guard";
import { LoggedInUserGuard } from "./guards/logged-in-user.guard";

import { environment } from "src/environments/environment";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { tabbarComponent } from "./components/non-standard/tabbar/tabbar.component";
import { RegionsService } from "./services/regions/regions.service";
import { RegionguardGuard } from "./guards/region-guard/regionguard.guard";
import { RegionAuthGuard } from "./guards/region-guard-auth/regionguardauth.guard";
import { CmsMenuComponent } from "./cms/cms-menu/cms-menu.component";
import { LoggedInCMSGuard } from "./guards/logged-in-cms.guard";
import { prombarComponent } from "./components/non-standard/prombar/prombar.component";
import { VenueSelectedGuard } from "./guards/venues-selected-guard/venue-selected.guard";
import { LoggedInAnyUserGuard } from "./guards/logged-in-any.guard";
import { SettingsState } from "./services/sync-engine/settings-state/settings-state.service";
import { AuthService } from "./services/auth/auth.service";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { PermissionsBlockComponent } from "./components/permissions-block/permissions-block.component";
@Injectable({ providedIn: "root" })
export class RedirectStaffToDashboardGuard implements CanActivate {
  constructor(
    private settingSt: SettingsState,
    private router: Router,
    private platform: Platform,
    private regionService: RegionsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.settingSt.state.get("cognitoUser");
    if (!user) {
      return true;
    } else {
      const reg = this.regionService.getRegion();

      //if not a staff member, then assume its a treat user and redirect to treat, else redirect to dashboard.
      let promoter = this.settingSt.hasClaim$('promoter').pipe(take(1)).toPromise();

      if (promoter) {
        this.router.navigate([`${reg}/prombar/promoter`]).then(() => {
          console.log("redirected to dashboard");
        });
        return false;
      }
      this.router.navigate([`${reg}/tab/dashboard`]).then(() => {
        console.log("redirected to dashboard");
      });
      return false;
    }
  }
}

@Injectable({ providedIn: "root" })
export class RedirectStaffInTheWrongPlace implements CanActivate {
  constructor(
    private settingSt: SettingsState,

    private router: Router,
    private platform: Platform,
    private regionService: RegionsService
  ) {}

 
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.settingSt.state.get("cognitoUser");
  
    const reg = this.regionService.getRegion();
    console.log("redirect staff in wrong place", user);
    if (!user) {
      this.router.navigate([`${reg}/login`]);
      console.log("Wrong Place, no user");
  
      return false;
    } else {
      let operations = await this.settingSt.hasClaim$('operations').pipe(take(1)).toPromise();
      let promoter = await this.settingSt.hasClaim$('promoter').pipe(take(1)).toPromise();
  
      if (!operations && promoter) {
        if (state.url.includes("tab")) {
          console.log("Wrong Place Promoter, no user");
  
          this.router.navigate([`${reg}/prombar/promoter`]);
          return false;
        }
      }
  
      if (!promoter && operations) {
        if (state.url.includes("/prombar")) {
          console.log("In Promoter Page without promoter", state.url);
  
          this.router.navigate([`${reg}/tab/dashboard`]);
          return false;
        }
      }
  
      return true;
    }
  }
}

@Injectable({ providedIn: "root" })
export class NotLoggedInGuard implements CanActivate {
  constructor(
    private settingSt: SettingsState,

    private router: Router,
    private platform: Platform,
    private regionService: RegionsService,
    private toast: ToastController
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.settingSt.state.get("cognitoUser");

    const reg = this.regionService.getRegion();

    if (user) {
      this.router.navigate([`${reg}/tab/dashboard`]);
      //create toast
      this.toast
        .create({
          message: "Logged in users cannot access customer pages",
          duration: 2000,
          color: "danger",
          position: "top",
        })
        .then((toast) => {
          toast.present();
        });

      return false;
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: "root" })
export class AuthChecked implements CanActivate {
  constructor(
    private settingSt: SettingsState,
    private auth: AuthClass,
    private router: Router,
    private platform: Platform,
    private regionService: RegionsService,
    private toast: ToastController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      let user = await this.auth.currentAuthenticatedUser();
      console.log("Auth user", user);

      if (user) {
        let promoter = this.settingSt.hasClaim$('promoter').pipe(take(1)).toPromise();
        let operations = this.settingSt.hasClaim$('operations').pipe(take(1)).toPromise();
        let managment = this.settingSt.hasClaim$('managment').pipe(take(1)).toPromise();

        let reg = this.regionService.getRegion();

        if (operations) {
          this.router.navigate([reg, "tab", "dashboard"]);
        }
        if (managment) {
          if (this.platform.is("desktop")) {
            this.router.navigate([reg, "cms", "dashboard"]);
          } else {
            this.router.navigate([reg, "tab", "dashboard"]);
          }
        }

        if (promoter) {
          this.router.navigate([reg, "prombar", "promoter"]);
        }

        //create toast

        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log("Error Checking Auth");
      return true;
    }
  }
}

interface DqRedirects {
  path: string;
  redirectTo: string;
}
export const REDIRECTS = new InjectionToken<DqRedirects[]>("REDIRECTS");

let routes: Routes = [
  {
    path: "location/:locationid",
    redirectTo: "au/location/:locationid",
    pathMatch: "prefix",
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/consumer-home/consumer-home.module").then(
        (m) => m.ConsumerHomePageModule
      ),
    canActivate: [RedirectStaffToDashboardGuard, WebOnlyGuard],
  },

  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: ":region",
    canActivate: [RegionAuthGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./pages/consumer-home/consumer-home.module").then(
            (m) => m.ConsumerHomePageModule
          ),
        canActivate: [RedirectStaffToDashboardGuard, WebOnlyGuard],
      } /*
      {
        path: "signup",
        loadChildren: () =>
          import("./pages/treat-me-signup/treat-me-signup.module").then(
            (m) => m.TreatMeSignupPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },*/,
      {
        path: "pay/:locationId",
        loadChildren: () =>
          import("./pages/staff-payment/staff-payment.module").then(
            (m) => m.StaffPaymentPageModule
          ),
      },
      {
        path: "prombar",
        component: prombarComponent,
        canActivate: [RedirectStaffInTheWrongPlace],
        children: [
          {
            path: "notifications",
            loadChildren: () =>
              import("./pages/notifications/notifications.module").then(
                (m) => m.NotificationsPageModule
              ),
          },
          {
            path: "bookings",
            loadChildren: () =>
              import("./pages/bookings/bookings.module").then(
                (m) => m.BookingsPageModule
              ),
          } /*
          {
            path: `promoter/event/:event_name`,
            loadChildren: () =>
              import("./bookings/event-page/event-page.module").then(
                (m) => m.TreatMeMenuPageModule
              ),
          },*/,
          {
            path: `promoter`,
            loadChildren: () =>
              import("./pages/promoter-venue/promoter-venue.module").then(
                (m) => m.PromoterPageModule
              ),
          },
          {
            path: "bookings",
            loadChildren: () =>
              import("./pages/booking/booking.module").then(
                (m) => m.BookingPageModule
              ),
          },
        ],
      },
      {
        path: "tab",
        component: tabbarComponent,
        canActivate: [LoggedInUserGuard, VenueSelectedGuard],

        children: [
          {
            path: "bookings",
            loadChildren: () =>
              import("./pages/bookings/bookings.module").then(
                (m) => m.BookingsPageModule
              ),
            canActivate: [RedirectStaffInTheWrongPlace],
          },
          {
            path: "dashboard",
            loadChildren: () =>
              import("./pages/dashboard/dashboard.module").then(
                (m) => m.DashboardPageModule
              ),
            canActivate: [RedirectStaffInTheWrongPlace],
          },

          {
            path: "notifications",
            loadChildren: () =>
              import("./pages/notifications/notifications.module").then(
                (m) => m.NotificationsPageModule
              ),
            canActivate: [RedirectStaffInTheWrongPlace],
          },

          {
            path: "vip-orders",
            children: [
              {
                path: "",
                loadChildren: () =>
                  import(
                    "./pages/order-history-tablet/order-history-tablet.module"
                  ).then((m) => m.OrderHistoryTabletPageModule),
              },
              {
                path: "",
                loadChildren: () =>
                  import(
                    "./pages/consumer-order-detail/consumer-order-detail.module"
                  ).then((m) => m.ConsumerOrderDetailPageModule),
                data: {
                  showHomeButton: false,
                  defaultHref: "/tab/vip-orders",
                },
              },
            ],
          },
        ],
      },
      {
        path: "cms/sign-in",
        redirectTo: "login",
      },
      {
        path: "cms",
        component: CmsMenuComponent,
        canActivate: [LoggedInCMSGuard, VenueSelectedGuard],
        children: [
          {
            path: "dashboard",
            loadChildren: () =>
              import("./cms/cms-dashboard/cms-dashboard.module").then(
                (m) => m.CmsDashboardPageModule
              ),
          },
          {
            path: "staff",
            loadChildren: () =>
              import("./cms/cms-staff-report/cms-staff-reporting.module").then(
                (m) => m.CmsStaffReportingPageModule
              ),
          },
          {
            path: "menu",
            loadChildren: () =>
              import("./cms/cms-menu-editing/cms-menu-editing.module").then(
                (m) => m.CmsMenuEditingPageModule
              ),
          },

          {
            path: "links",
            loadChildren: () =>
              import("./cms/treat-management/treat-management.module").then(
                (m) => m.TreatManagementPageModule
              ),
          },

          {
            path: "manage-events",
            loadChildren: () =>
              import("./cms/event-management/event-management.module").then(
                (m) => m.EventManagementPageModule
              ),
          },
          {
            path: "manage-guests",
            loadChildren: () =>
              import("./cms/guest-management/guest-management.module").then(
                (m) => m.GuestManagementPageModule
              ),
          },
          {
            path: "settings",
            loadChildren: () =>
              import("./cms/cms-settings/cms-settings.module").then(
                (m) => m.CmsSettingsPageModule
              ),
          },
          {
            path: "logs",
            loadChildren: () =>
              import("./cms/cms-activity-log/cms-activity-log.module").then(
                (m) => m.CmsActivityLogPageModule
              ),
          },
          {
            path: "tables",
            loadChildren: () =>
              import("./cms/cms-table-editing/cms-table-editing.module").then(
                (m) => m.CmsTableEditingPageModule
              ),
          },
          {
            path: "map",
            loadChildren: () =>
              import("./cms/map-editor/map-editor.module").then(
                (m) => m.MapEditorPageModule
              ),
          },
          {
            path: "reports",
            loadChildren: () =>
              import("./cms/cms-reporting/cms-reporting.module").then(
                (m) => m.CmsReportingPageModule
              ),
          },
          {
            path: "bookings",
            loadChildren: () =>
              import("./cms/cms-bookings/cms-bookings.module").then(
                (m) => m.CmsBookingsPageModule
              ),
          },
       
          {
            path: "bookings",
            loadChildren: () =>
              import("./pages/booking/booking.module").then(
                (m) => m.BookingPageModule
              ),
          },
        ],
      },
      {
        path: "get-started",
        loadChildren: () =>
          import("./cms/get-started/get-started.module").then(
            (m) => m.GetStartedPageModule
          ),
        canActivate: [WebOnlyGuard],
      },
      {
        path: "csreciept",
        loadChildren: () =>
          import("./pages/customer-reciept/customer-reciept.module").then(
            (m) => m.ConsumerRecieptPageModule
          ),
        canActivate: [WebOnlyGuard, NotLoggedInGuard],
      },
      {
        path: "login",
        loadChildren: () =>
          import("./pages/login/login.module").then((m) => m.LoginPageModule),
      },
      /*
      {
        path: `widget/booking/:venue_name`,
        loadChildren: () =>
          import("./bookings/venue-widget/venue-page.module").then(
            (m) => m.VenuePageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },
      */
      {
        path: `book/:event_name`,
        loadChildren: () =>
          import("./pages/redirect/venues.module").then(
            (m) => m.VenuesPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },
      {
        path: `book/:booking_id/checkout`,
        loadChildren: () =>
          import("./pages/redirect/venues.module").then(
            (m) => m.VenuesPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },
      {
        path: `book/:booking_id/view`,
        loadChildren: () =>
          import("./pages/redirect/venues.module").then(
            (m) => m.VenuesPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },
      {
        path: `guest_list/guest/:id`,
        loadChildren: () =>
          import("./pages/redirect/venues.module").then(
            (m) => m.VenuesPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },

      {
        path: `guest_list/:id`,
        loadChildren: () =>
          import("./pages/redirect/venues.module").then(
            (m) => m.VenuesPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },

      /* {
        path: "table/:tableId",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/menu/menu.module").then((m) => m.MenuPageModule),
          },

          {
            path: "search",
            loadChildren: () =>
              import("./pages/menu-search/menu-search.module").then(
                (m) => m.MenuSearchPageModule
              ),
          },
          {
            path: "cart",
            loadChildren: () =>
              import("./pages/cart/cart.module").then((m) => m.CartPageModule),
          },
          {
            path: "close",
            loadChildren: () =>
              import("./pages/close-table/close-table.module").then(
                (m) => m.CloseTablePageModule
              ),
          },
          {
            path: "menu/:catId",
            children: [
              {
                path: "",
                loadChildren: () =>
                  import("./pages/item-add/item-add.module").then(
                    (m) => m.ItemAddPageModule
                  ),
              },
              {
                path: "item/:id",
                loadChildren: () =>
                  import("./pages/item-details/item-details.module").then(
                    (m) => m.ItemDetailsPageModule
                  ),
              },
            ],
          },
          {
            path: "mixture/:catId",
            children: [
              {
                path: "",
                loadChildren: () =>
                  import("./pages/mixtures/mixtures.module").then(
                    (m) => m.MixturesPageModule
                  ),
              },
              {
                path: "add/:mixtureCatId",
                loadChildren: () =>
                  import("./pages/mixture-add/mixture-add.module").then(
                    (m) => m.MixtureAddPageModule
                  ),
              },
            ],
          },
        ],
      },*/
      {
        path: `table`,
        loadChildren: () =>
          import("./pages/staff-menu/staff-menu.module").then(
            (m) => m.StaffMenuPageModule
          ),
        canActivate: [VenueSelectedGuard],
      },
      {
        path: `bookings`,
        loadChildren: () =>
          import("./pages/booking/booking.module").then(
            (m) => m.BookingPageModule
          ),
        canActivate: [VenueSelectedGuard],
      },

      {
        path: "push-test",
        loadChildren: () =>
          import("./pages/push-test/push-test.module").then(
            (m) => m.PushTestPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },
      {
        path: `location`,
        loadChildren: () =>
          import("./pages/consumer-menu/consumer-menu.module").then(
            (m) => m.ConsumerMenuPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },
      {
        path: "event",
        loadChildren: () =>
          import("./pages/consumer-event/consumer-event.module").then(
            (m) => m.ConsumerVenuePageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },

      {
        path: "order/:orderUuid/success",
        redirectTo: "orders/:orderUuid/success",
      },
      {
        path: "check-in",
        loadChildren: () =>
          import("./pages/check-in-detail/check-in-detail.module").then(
            (m) => m.CheckInPageModule
          ),
        canActivate: [NotLoggedInGuard, WebOnlyGuard],
      },

      {
        path: "venues",
        loadChildren: () =>
          import("./pages/venues/venues.module").then(
            (m) => m.VenuesPageModule
          ),
        canActivate: [LoggedInAnyUserGuard],
      },
      {
        path: "no-venues",
        loadChildren: () =>
          import("./no-venues/no-venues.module").then(
            (m) => m.NoVenuesPageModule
          ),
      },
      {
        path: "treat-me-scan-code",
        loadChildren: () =>
          import("./pages/consumer-home/consumer-home.module").then(
            (m) => m.ConsumerHomePageModule
          ),
        canActivate: [WebOnlyGuard],
      } /*
      {
        path: "treat-me-request-info",
        loadChildren: () =>
          import(
            "./pages/treat-me-request/treat-me-request-info/treat-me-request-info.module"
          ).then((m) => m.TreatMeRequestInfoPageModule),
      },
      {
        path: "treat-me-new-user-info",
        loadChildren: () =>
          import(
            "./pages/treat-me-new-user-info/treat-me-new-user-info.module"
          ).then((m) => m.TreatMeNewUserInfoPageModule),
      },*/,
      {
        path: "how-does-treat-me-work",
        loadChildren: () =>
          import("./treat-me-how-works/treat-me-how-works.module").then(
            (m) => m.TreatMeHowWorksPageModule
          ),
      },
      {
        path: `venue/:venue`,
        loadChildren: () =>
          import("./pages/redirect/venues.module").then(
            (m) => m.VenuesPageModule
          ),
      },

      {
        path: `treat`,
        loadChildren: () =>
          import("./pages/treat-me-menu/treat-me-menu.module").then(
            (m) => m.TreatMeMenuPageModule
          ),
        canActivate: [WebOnlyGuard],
      },
      /*
      {
        path: `treat-me`,
        loadChildren: () =>
          import("./pages/treat-me-request/treat-me-request.module").then(
            (m) => m.TreatMeRequestPageModule
          ),
      },

      {
        path: "profile",
        loadChildren: () =>
          import("./pages/treat-me-profile/treat-me-profile.module").then(
            (m) => m.TreatMeProfilePageModule
          ),
        canActivate: [LoggedInUserGuard],
      },*/

      {
        path: "cms",
        children: [
          {
            path: "map-editor",
            loadChildren: () =>
              import("./cms/map-editor/map-editor.module").then(
                (m) => m.MapEditorPageModule
              ),
          },
          {
            path: "orgchart",
            loadChildren: () =>
              import("./pages/org-chart/org-chart.module").then(
                (m) => m.OrgChartPageModule
              ),
          },
        ],
      },
    ],
  },
];

routes = [
  ...routes,
  {
    path: "dashboard",
    redirectTo: "/tab/dashboard",
    pathMatch: "full",
  },
  {
    path: "vip-orders",
    redirectTo: "/tab/vip-orders",
    pathMatch: "full",
  },
  {
    path: "vip-orders-tablet",
    redirectTo: "/tab/vip-orders-tablet",
    pathMatch: "full",
  },
  {
    path: "notifications",
    redirectTo: "/tab/notifications",
    pathMatch: "full",
  },
];

const CATCH_ALL = {
  path: "**",
  redirectTo: "/",
};

console.log(routes)
@NgModule({
  imports: [
    RouterModule.forRoot([...routes, CATCH_ALL], {
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {}
}
