import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ArraySomePipe } from "./array-some/array-some.pipe";
import { CartItemTotalWithAdditionsPipe } from "./cart-item-with-additions/cart-item-with-additions.pipe";
import { FilterItemsPipe } from "./filter-items/filter-items.pipe";
import { FlatMapPipe } from "./flat-map/flat-map.pipe";
import { LocationUrlPipe } from "./location-url/location-url.pipe";
import { MatchGarnishPipe } from "./match-garnish.pipe";
import { MatchMixturesPipe } from "./match-mixtures.pipe";
import { ModifierTotalPipe } from "./modifiers-total/modifiers-total.pipe";
import { TimeAgoPipe } from "./time-ago/time-ago.pipe";
import { lengthDatePipe } from "./length-increment/length-date.pipe";
import { UserTypePipe } from "./user-type.pipe";
import { SecondsMinutesPipe } from "./seconds-mins/seconds-mins.pipe";
import { imageTransformPipe } from "./image-url/imageTransPipe.pipe";
import { DefaultPipe } from "./default.pipe";

const pipes = [
  ArraySomePipe,
  CartItemTotalWithAdditionsPipe,
  FilterItemsPipe,
  FlatMapPipe,
  LocationUrlPipe,
  MatchGarnishPipe,
  MatchMixturesPipe,
  ModifierTotalPipe,
  DefaultPipe,
  TimeAgoPipe,
  lengthDatePipe,
  SecondsMinutesPipe,
  imageTransformPipe
];
@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class PipesModule {}
