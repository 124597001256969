<ion-chip
  [class.background]="tag?.selected == undefined || tag?.selected == true"
  [color]="'tag-' + tag.tag_type"
  [outline]="true"
  (click)="
    edit == true || tag.explanation == undefined
      ? clicked.emit()
      : detail.present($event)
  "
>
  <ion-label>
    {{ tag.name }}
  </ion-label>
  <ion-label
    class="ion-margin-start"
    *ngIf="edit == false && tag.strength_rendered != undefined"
  >
    {{ tag.strength_rendered }}
  </ion-label>
  <ion-icon
    name="close-circle"
    *ngIf="edit == true || tag?.selected == true"
    (click)="removed.emit(); $event.stopPropagation()"
  >
  </ion-icon>
</ion-chip>
<ion-popover #detail [arrow]="true">
  <ng-template>
    <ion-item color="secondary">
      <ion-label class="ion-text-wrap ion-text-center">
        {{ tag.explanation }}
      </ion-label>
    </ion-item>
  </ng-template>
</ion-popover>
