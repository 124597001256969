import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Route,
  Router,
} from "@angular/router";
import {
  AlertController,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { ROUTE_PARAM_LOCATION_ID } from "src/app/pages/consumer-menu/consumer-menu-route-params";
import { RegionsService } from "src/app/services/regions/regions.service";
import { TableService } from "src/app/services/table/table.service";
import { environment } from "src/environments/environment";
import { QRPaymentModalComponent } from "../qrpayment-modal/qrpayment-modal.component";

@Component({
  selector: "app-more-actions",
  templateUrl: "./more-actions.component.html",
  styleUrls: ["./more-actions.component.scss"],
})
export class MoreActionsComponent implements OnInit {
  @Input() table_info?;
  @Input() table_id;
  //output refresh
  @Output() refresh: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public modalController: ModalController,
    private router: Router,
    private region: RegionsService,
    private alert: AlertController,
    private tableSvc: TableService,
    private pop: PopoverController,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log("tbl info", this.table_info);
  }

  showQRCode() {
    var url =
      this.region.getRegion() +
      "/treat-me/" +
      window.location.href.split("table/")[1];

    if (environment.production == true) {
      url = "https://order.getdqd.com/" + url;
    } else {
      url = "https://dev.getdqd.com/" + url;
    }
    const modal = this.modalController
      .create({
        component: QRPaymentModalComponent,
        cssClass: "PaymentModal",
        componentProps: {
          qrCode: url,
          payments: false,
        },
        backdropDismiss: true,
      })
      .then((modal) => modal.present());
  }

  showRecieptCode() {
    this.pop.dismiss("viewreciept");
  }

  adjustSpend() {
    this.pop.dismiss("adjustspend");
  }
  moveTable() {
    this.pop.dismiss("movetable");
  }
  geust() {
    this.pop.dismiss("guest");
  }
  package() {
    this.pop.dismiss("package");
  }
}
