<ion-content class="modal" color="secondary">
  <ion-list class="modal">
    <h1 color="secondary"></h1>
    <h1 color="secondary" *ngIf="ModifierGroup.id != 0">Edit Modifier Group</h1>
    <h1 color="secondary" *ngIf="ModifierGroup.id == 0">
      Create Modifier Group
    </h1>
    <ion-item>
      <ion-label position="stacked">Name</ion-label>
      <ion-input
        type="text"
        [(ngModel)]="ModifierGroup.name"
        name="title"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Description</ion-label>
      <ion-textarea
        [(ngModel)]="ModifierGroup.desc"
        name="phone_num"
        rows="2"
      ></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Selection Amount</ion-label>
      <ion-note
        class="topright"
        *ngIf="minmax.lower == 0 && minmax.upper == 0"
        position="stacked"
        >User selects as many as as they want</ion-note
      >
      <ion-note
        class="topright"
        *ngIf="
          minmax.lower <= minmax.upper &&
          minmax.upper >= 0 &&
          minmax.lower != minmax.upper
        "
        position="stacked"
        >User must select between {{ minmax.lower }} and
        {{ minmax.upper }}</ion-note
      >
      <ion-note
        class="topright"
        *ngIf="minmax.lower != 0 && minmax.lower == minmax.upper"
        position="stacked"
        >User must select {{ minmax.upper }}</ion-note
      >
      <ion-range
        [(ngModel)]="minmax"
        dualKnobs="true"
        min="0"
        max="10"
        step="1"
        snaps="true"
        pin="true"
      ></ion-range>
    </ion-item>
    <ion-item lines="none">
      <ion-row class="modifiergroups">
        <ion-button
          *ngFor="let modifier of ModifierGroup.modifiers"
          class="btn-mix-items"
          size="large"
          [color]="false ? 'primary' : 'white'"
          [fill]="false ? 'solid' : 'outline'"
        >
          <ion-input
            class="large"
            [(ngModel)]="modifier.name"
            max="10"
          ></ion-input>
          <div class="small">
            <ion-label>$</ion-label>
            <ion-input
              inputmode="decimal"
              [(ngModel)]="modifier.price"
              type=""
            ></ion-input>
          </div>
        </ion-button>
        <ion-button
          class="btn-mix-items"
          size="large"
          (click)="addModifier()"
          [color]="false ? 'primary' : 'white'"
          [fill]="false ? 'solid' : 'outline'"
        >
          <ion-icon name="add-outline"></ion-icon>
        </ion-button>
      </ion-row>
    </ion-item>
    <ion-button *ngIf="ModifierGroup.id == 0" (click)="SaveModifier()"
      >Create Modifier</ion-button
    >
    <ion-button *ngIf="ModifierGroup.id != 0" (click)="SaveModifier()"
      >Save Modifier</ion-button
    >
  </ion-list>
</ion-content>
