import { Pipe, PipeTransform } from "@angular/core";
import {
  DqGarnish,
  DqGarnishBasic,
  DqMixture,
  DqMixtureBasic,
} from "src/app/services/mixture/mixture.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";

@Pipe({
  name: "appCartItemTotalWithAdditions",
})
export class CartItemTotalWithAdditionsPipe implements PipeTransform {
  transform(
    price: string | number,
    {
      mixtures,
      garnishes,
      modifiers,
    }: {
      garnishes?: DqGarnish[] | DqGarnishBasic[];
      mixtures?: DqMixture[] | DqMixtureBasic[];
      modifiers?: DqConsumerModifier[];
    }
  ): number {
    if (!price) {
      return;
    }

    let garnishesPrice = 0;
    let mixturesPrice = 0;
    let modifiersPrice = 0;

    if (Array.isArray(garnishes)) {
      garnishesPrice = (garnishes as any[]).reduce(
        (sum: number, g: DqGarnish | DqGarnishBasic) => {
          return sum + +g.gar_price;
        },
        0
      );
    }

    if (Array.isArray(mixtures)) {
      mixturesPrice = (mixtures as any[]).reduce(
        (sum: number, mix: DqMixture | DqMixtureBasic) => {
          const price = "mix_price" in mix ? +mix.mix_price : +mix.cfm_price;
          return sum + price;
        },
        0
      );
    }

    if (Array.isArray(modifiers)) {
      modifiersPrice = modifiers.reduce((sum, mod) => sum + +mod.price, 0);
    }

    return +price + garnishesPrice + mixturesPrice + modifiersPrice;
  }
}
