import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Assignment,
  SyncV1TagDefinition,
} from "src/app/models/sync_models";
import { SyncV1TagInstance } from "src/app/components/tag-groups/tag-groups.component";

@Injectable({
  providedIn: "root",
})
export class TagState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1TagDefinition }>();

  //fields specific to the service
  service_icon: string = "pricetags-outline";
  service_title: string = "Tag Store";
  service_id: string = "TagDef"; //id of the service
  service_url: string = "live_tag_defs";

  //fields specific to the data
  data_id: string = "tag_id";
  data_detail: string = "name";
  data_expiry: number = 2000; //in minutes, the amount of time before the data is considered unusable

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);

    //add data to the state
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(
    map((assignments) => Object.values(assignments))
  );
  //get the menu items as an array

  tags$ = this.state.select(map((tags) => Object.values(tags)));

  tag_maps$ = this.state.select();
  tag$ = (id) => this.state.select(id);
  tag_groups$ = this.tags$.pipe(
    map((tags) => {
      let groups = {
        music: [],
        taste: [],
        brand: [],
        system: [],
        general: [],
        highlight: [],
        other: [],
      };

      tags.forEach((element) => {
        if (
          element.tag_type == "system" ||
          element.tag_type == "general" ||
          element.tag_type == "highlight"
        ) {
          groups.other.push(element);
        }

        if (groups[element.tag_type] == undefined) {
          groups[element.tag_type] = [];
        }
        groups[element.tag_type].push(element);
      });

      return groups;
    })
  );

  tag_list$ = (tagids: string[]) =>
    this.tags$.pipe(
      map((tags) => {
        return tags.filter((x) => tagids.includes(x.tag_id));
      })
    );

  combined_list$ = (tagvals: SyncV1TagInstance[]) =>
    this.tags$.pipe(
      map((tags) => {
        //deep copy actual tag data into the tagvals
        return tagvals.map((tagval) => {
          let tag = tags.find((x) => x.tag_id == tagval.tag_id);
          tag = structuredClone(tag);
          return Object.assign(tag, tagval);
        });
      })
    );
}
