import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: `app-dq-infinite-scroll`,
  template: `<ng-content> </ng-content>
    <ion-infinite-scroll
      *ngIf="data && data.length > 0 && data.length > chunkLength"
      (ionInfinite)="loadMore($event)"
    >
      <ion-infinite-scroll-content> </ion-infinite-scroll-content>
    </ion-infinite-scroll>`,
})
export class DqInfiniteScroll<T> implements OnInit, OnChanges {
  @Input() chunkSize = 50;
  @Input() data: T[];
  @Input() passThrough = false;
  @Output() chunkedData = new EventEmitter<T[]>();
  chunkLength = this.chunkSize;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.passThrough) {
      this.updateDataChunk();
    } else {
      if (changes.data && this.data?.length < this.chunkLength) {
        this.chunkLength = this.chunkSize;
      }
      if (changes.data && this.data) {
        this.updateDataChunk();
      }
    }
  }

  loadMore(ev: any) {
    console.log("loadMore");
    this.chunkLength = this.chunkLength + this.chunkSize;
    ev.target.complete();
    this.updateDataChunk();
  }

  updateDataChunk() {
    if (this.passThrough) {
      this.chunkedData.emit(this.data);
    } else {
      this.chunkedData.emit(this.data.slice(0, this.chunkLength));
    }
  }
}
