import {
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { createAction, props } from "@ngrx/store";
import { DqNotificationSubscription } from "../models/notification-subscription";

const ACTION_PREFIX = "[Notifications]";

export const pushNotificationRegistrationSuccess = createAction(
  `${ACTION_PREFIX} Registration Success`,
  props<{
    registrationId: string;
  }>()
);

export const pushNotificationRegistrationError = createAction(
  `${ACTION_PREFIX} Registration Error`,
  props<{
    error: any;
  }>()
);

export const pushNotificationReceived = createAction(
  `${ACTION_PREFIX} Received Notification`,
  props<{
    notification: PushNotificationSchema;
  }>()
);

export const loadSavedSubscriptions = createAction(
  `${ACTION_PREFIX} Load Saved Subscriptions`,
  props<{ subscriptions: DqNotificationSubscription[] }>()
);

export const subscribeToTableNotifications = createAction(
  `${ACTION_PREFIX} Subscribe to Table Notifications`,
  props<{ venueId: string; tableId: string }>()
);

export const unsubscribeToTableNotifications = createAction(
  `${ACTION_PREFIX} Unsubscribe to Table Notifications`,
  props<{ venueId: string; tableId: string }>()
);

export const clearSubscriptionsAfterLogout = createAction(
  `${ACTION_PREFIX} Clear Subscriptions After Logout`
);
