import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  DqLegacyOrderPaymentMethod,
  DqOrderStatus,
  DqPaymentMethod,
} from "src/app/services/order/order.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";
import { withCache } from "@ngneat/cashew";
import { DqConsumerTabCredit } from "../../consumer-event/services/consumer-event/consumer-event.service";
import { RegionsService } from "../../../services/regions/regions.service";
export interface DqConsumerOrderItem {
  item_id: number;
  name: string;
  price: string;
  quantity: number;
  modifiers: DqConsumerModifier[];
}

export type DqConsumerPaymentMethod = "Quoted" | DqPaymentMethod;

export interface DqTreatMeStatus {
  active: boolean;
  gift_status_descrip: string | null;
  gift_status_name:
    | "ORDER PAID"
    | "ORDER ACCEPTED"
    | "ORDER BEING PREPARED"
    | "ORDER DELIVERED";
  success: boolean;
  status: "complete" | "active" | "pending";
}

export interface DqConsumerOrderSummary {
  augmented_guest_values: {
    field_name: string;
    value: string;
  }[];
  balance_for_payment: string; // "0E-8"
  e_id: number;
  gift_invite_public_id: string; // "6eL4ASNB77AQiN9pykZfPp"
  gift_statuses: DqTreatMeStatus[];
  gratuity: string;
  guest_name: string;
  item_total: string;
  local_currency: {
    lc_country_code_iso_3166_three_char: string; // "AUS",
    lc_currency_code_iso_4217: string; // "AUD",
    lc_language_iso_639_1: string; // "en",
    lc_tax_rate: string; // "0.1",
    lc_tax_text: string; // "GST"
  };
  location: string;
  o_id: number[];
  o_uuid: string;
  order_details: DqConsumerOrderItem[];
  order_title: string; // "Donation #49300",
  payment_method: DqConsumerPaymentMethod;
  payment_status: "paid" | "unpaid" | "failed" | "refunded";
  display_payment_status?: string;
  phone_e164: string;
  real_or_fake_uuid: string;
  referenced_tab_credits: DqConsumerTabCredit[];
  service_text: string; // "Delivered to Rooftop C1";
  show_pickup_delivery_details: boolean; // false,
  status: DqOrderStatus;
  sub_total: string; // "25.00000000",
  surcharge: string; // "0E-8",
  tab_credit_paid: string; // "19.0000"
  tab_credit_redeemed_label?: string;
  table_id: number;
  table_service: "delivery" | "pickup";
  tax: string;
  re_id: string;
  timestamp: string;
  total: string; // "25.00000000"
  v_id: number;
  vcp_id: number;
}

export interface DqTrascationDetailsResponse {
  gift_statuses?: DqTreatMeStatus[];
  orders: DqConsumerOrderSummary[];
  transaction: {
    can_be_cancelled: boolean;
    order_details_text: string;
  };
}

@Injectable({ providedIn: "root" })
export class ConsumerOrderService {
  constructor(private http: HttpClient, private reg: RegionsService) {}

  getOrderHistory(cache$ = false) {
    return this.http.get<DqConsumerOrderSummary[]>(
      `${this.reg.ServerURL()}/v4/orders`,
      { context: withCache(), withCredentials: true }
    );
  }

  getOrderDetails(orderId: string, cache$ = false) {
    return this.http.get<DqTrascationDetailsResponse>(
      `${this.reg.ServerURL()}/v4/orders/${orderId}`,
      { withCredentials: true }
    );
  }

  cancelOrder(orderId: string) {
    return this.http.delete(`${this.reg.ServerURL()}/v4/orders/${orderId}`);
  }

  emailReceipt({
    transactionUuid,
    email,
  }: {
    transactionUuid: string;
    email: string;
  }) {
    return this.http.post(
      `${this.reg.ServerURL()}/v4/orders/${transactionUuid}/receipt`,
      { customer_email: email }
    );
  }
}
