import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, createSelector, on } from "@ngrx/store";
import { userLogOut } from "src/app/actions/auth.actions";
import { closeTableSuccess } from "src/app/actions/table.actions";
import { DqCartItem } from "src/app/models/cart.model";
import { cartPageDidEnter } from "src/app/pages/cart/actions/cart-page.actions";
import {
  didEnter as consumerCheckoutPageDidEnter,
  didGetBindingQuote,
} from "src/app/pages/consumer-cart-step2/actions/consumer-cart-step2-page.actions";
import {
  didEnter as consumerCartPageDidEnter,
  updateTip,
} from "src/app/pages/consumer-cart/actions/consumer-cart-page.actions";
import { DqConsumerTabCredit } from "src/app/pages/consumer-event/services/consumer-event/consumer-event.service";
import { didEnter as consumerItemDetailsPageDidEnter } from "src/app/pages/consumer-item-details/actions/consumer-item-details-page.actions";
import { didEnter as consumerMenuPageDidEnter } from "src/app/pages/consumer-menu/actions/consumer-menu-page.actions";
import { menuPageDidEnter } from "src/app/pages/menu/actions/menu.actions";
import { DqGratuityType } from "src/app/services/order/order.service";
import { getCartTotalPrice } from "src/app/utils/calculate-cart-total";
import * as CartActions from "../../actions/cart.actions";

export interface State extends EntityState<DqCartItem> {
  loadedFromStorage: boolean;
  gratuity: {
    value: number;
    gratuityType: DqGratuityType;
  };
  couponCodes: DqConsumerTabCredit[];
  orderUuid: string;
  selectedTableId: string;
  treatValid?: boolean;
}

const adapter = createEntityAdapter<DqCartItem>({
  selectId: (item) => getTableItemId(item.tableId, item.id),
});

const DEFAULT_GRATUITY = {
  value: 0,
  gratuityType: "amount" as DqGratuityType,
};

export const initialState: State = adapter.getInitialState({
  gratuity: { ...DEFAULT_GRATUITY },
  couponCodes: [],
  loadedFromStorage: false,
  orderUuid: null,
  selectedTableId: null,
});

const cartReducer = createReducer(
  initialState,
  on(CartActions.addToCart, addToCart),
  on(CartActions.incrementItemQuantity, incrementQuantity),
  on(CartActions.decrementItemQuantity, decrementQuantity),
  on(CartActions.removeCartItem, (state, { itemId, tableId }): State => {
    return adapter.removeOne(getTableItemId(tableId, itemId), state);
  }),
  on(CartActions.updateItemComment, updateComment),
  on(
    CartActions.loadSavedCartAtStartup,
    (state, { items, gratuity, orderUuid, couponCodes }): State =>
      adapter.setAll(items || [], {
        ...state,
        gratuity,
        orderUuid,
        loadedFromStorage: true,
        couponCodes,
      })
  ),
  on(
    closeTableSuccess,
    (state, action): State => clearTable(state, action.table.table_id)
  ),
  on(
    CartActions.orderPlacedSuccessfully,
    (state, { tableId }): State => clearTable(state, tableId)
  ),
  on(
    updateTip,
    (state, { value, gratuityType, couponCodes }): State => ({
      ...state,
      gratuity: { value, gratuityType },
      couponCodes,
    })
  ),
  on(
    cartPageDidEnter,
    consumerCartPageDidEnter,
    consumerCheckoutPageDidEnter,
    consumerItemDetailsPageDidEnter,
    consumerMenuPageDidEnter,
    menuPageDidEnter,
    (state, { tableId }): State => ({
      ...state,
      selectedTableId: tableId,
    })
  ),
  on(userLogOut, CartActions.clearCart, () => initialState),
  on(
    didGetBindingQuote,
    (state, { orderUuid }): State => ({ ...state, orderUuid })
  ),
  on(
    CartActions.changeCartToTable,
    (state, action): State => changeCartToTable(state, action)
  ),
  on(
    CartActions.monitorTreatStatus,
    (state, { treatId }): State => ({ ...state, treatValid: true })
  ),
  on(
    CartActions.monitorTreatStatusComplete,
    (state): State => ({ ...state, treatValid: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return cartReducer(state, action);
}

function getTableItemId(tableId, itemId) {
  return `${tableId}-${itemId}`;
}

function addToCart(
  state: State,
  { item }: ReturnType<typeof CartActions.addToCart>
): State {
  const uniqueItemId = getTableItemId(item.tableId, item.id);
  const existingItem = selectEntities(state)[uniqueItemId];
  if (existingItem) {
    return adapter.updateOne(
      {
        id: uniqueItemId,
        changes: { quantity: existingItem.quantity + item.quantity },
      },
      state
    );
  }
  return adapter.addOne(item, state);
}

function incrementQuantity(
  state: State,
  { itemId, tableId }: ReturnType<typeof CartActions.incrementItemQuantity>
): State {
  const uniqueItemId = getTableItemId(tableId, itemId);
  const existingItem = selectEntities(state)[uniqueItemId];
  if (existingItem) {
    return adapter.updateOne(
      {
        id: uniqueItemId,
        changes: {
          quantity: existingItem.quantity + 1,
        },
      },
      state
    );
  }
  return state;
}

function decrementQuantity(
  state: State,
  { itemId, tableId }: ReturnType<typeof CartActions.decrementItemQuantity>
): State {
  const uniqueItemId = getTableItemId(tableId, itemId);
  const existingItem = selectEntities(state)[uniqueItemId];
  if (existingItem && existingItem.quantity > 1) {
    return adapter.updateOne(
      {
        id: uniqueItemId,
        changes: { quantity: existingItem.quantity - 1 },
      },
      state
    );
  } else if (existingItem && existingItem.quantity === 1) {
    return adapter.removeOne(uniqueItemId, state);
  }
  return state;
}

function updateComment(
  state: State,
  { itemId, comment, tableId }: ReturnType<typeof CartActions.updateItemComment>
): State {
  const uniqueItemId = getTableItemId(tableId, itemId);
  const existingItem = selectEntities(state)[uniqueItemId];
  if (existingItem) {
    return adapter.updateOne(
      {
        id: uniqueItemId,
        changes: { comment: comment },
      },
      state
    );
  }
  return state;
}

function clearTable(state: State, tableId: string) {
  const itemsToClear = selectAll(state).filter(
    (item) => item.tableId === tableId
  );
  return adapter.removeMany(
    itemsToClear.map((item) => getTableItemId(tableId, item.id)),
    { ...state, couponCodes: [], gratuity: { ...DEFAULT_GRATUITY } }
  );
}

function changeCartToTable(
  state: State,
  {
    newTableId,
    itemIdsNotAvailable,
  }: ReturnType<typeof CartActions.changeCartToTable>
): State {
  const selectedTableCart = selectAllForSelectedTable(state);

  const itemIdsToDelete = selectedTableCart.map((cartItem) =>
    getTableItemId(cartItem.tableId, cartItem.id)
  );
  const newCartItems = selectedTableCart
    .filter((item) => !itemIdsNotAvailable.includes(item.id))
    .map((cartItem) => ({
      ...cartItem,
      tableId: newTableId,
    }));

  const updatedStateWithOldCartDeleted = adapter.removeMany(
    itemIdsToDelete,
    state
  );

  return adapter.addMany(newCartItems, updatedStateWithOldCartDeleted);
}

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();

export const selectSelectedTableId = (state: State) => state.selectedTableId;
export const selectGratuity = (state: State) => state.gratuity;
export const selectOrderUuid = (state: State) => state.orderUuid;
export const selectTreatValid = (state: State) => state.treatValid;
export const selectCartLoadedFromStorage = (state: State) =>
  state.loadedFromStorage;
export const selectCouponCodes = (state: State) => state.couponCodes;
export const selectAllForSelectedTable = createSelector(
  selectAll,
  selectSelectedTableId,
  (allItems, selectedTableId) =>
    allItems.filter((item) => item.tableId === selectedTableId)
);

export const selectCartTotal = createSelector(
  selectAllForSelectedTable,
  (items) => items.reduce<number>((total, item) => total + item.quantity, 0)
);

export const selectCartTotalPrice = createSelector(
  selectAllForSelectedTable,
  getCartTotalPrice
);

export const selectCouponCodeIds = createSelector(
  selectCouponCodes,
  (codes = []) => codes.map((c) => c.tc_lookup_key)
);
