import {
  Directive,
  OnInit,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
  HostBinding,
  Input,
} from "@angular/core";

@Directive({
  selector: "[appLazyBackgroundImage]",
})
export class LazyBackgroundImageDirective implements OnInit, OnDestroy {
  @Input("appLazyBackgroundImage") src: string;

  @HostBinding("style.background-image") get getBackgroundImageUrl() {
    return this.showBackgroundImage && this.src ? `url(${this.src})` : null;
  }

  private showBackgroundImage = this.hasIntersectionObserver();
  private io: IntersectionObserver;

  constructor(private host: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.hasIntersectionObserver()) {
      this.removeIO();
      this.io = new IntersectionObserver(this.ioCallback.bind(this));
      this.io.observe(this.host.nativeElement);
    }
  }
  
  ngOnDestroy() {
    this.removeIO();
  }

  private ioCallback([intersectionEntry]: IntersectionObserverEntry[]) {
    // because there will only ever be one instance
    // of the element we are observing
    // we can just use the first element in the array
    if (intersectionEntry.isIntersecting) {
      this.showBackgroundImage = true;
      this.removeIO();
      this.cdRef.markForCheck();
    }
  }

  private removeIO() {
    if (this.io) {
      this.io.disconnect();
      this.io = undefined;
    }
  }

  private hasIntersectionObserver() {
    return "IntersectionObserver" in window;
  }
}
