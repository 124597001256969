import { Directive, HostListener, Input } from "@angular/core";
import { IonInput } from "@ionic/angular";

@Directive({ selector: "[appMoveFocus]" })
export class MoveFocusDirective {
  @Input("appMoveFocus") nextInput: IonInput;
  constructor() {}

  @HostListener("keyup.enter")
  moveFocus() {
    if (this.nextInput && this.nextInput instanceof IonInput) {
      this.nextInput.setFocus();
    }
  }
}
