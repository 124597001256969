<ion-header>
  <ion-toolbar color="">
    <ion-buttons slot="start" *ngIf="createAllowed">
      <ion-button (click)="create()">
        <ion-icon slot="icon-only" name="add-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Select Guest</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      #searchbar
      [(ngModel)]="guestRaw"
      placeholder="Search Guests"
      [debounce]="500"
      autofocus
      
      (ngModelChange)="
        guestSearch = guestRaw.toLowerCase(); searchGuests(); guests = []
      "
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content class="thinmodal transparentmodal" color="" class="">
  <ion-list class="tertlist">
    <ion-list>
      <ion-item (click)="selectGuest({guest_id: undefined})" *ngIf="anonGuest">
        <ion-label>Anonymous Guest</ion-label>
        <ion-note slot="end">Ignores Data</ion-note>
      </ion-item>
      <ng-container *ngFor="let guest of guests">
        <ion-item (click)="selectGuest(guest)">
          <ion-label>{{ guest.name }}</ion-label>
          <ion-note slot="end">{{ guest.mobile_num }}</ion-note>
          <app-guest-status slot="end" [guest]="guest"> </app-guest-status>
        </ion-item>
      </ng-container>
     
      <div
        class="center-empty ion-margin-top"
        *ngIf="guests.length == 0 && guestRaw == ''"
      >
        <ion-label class="empty-text">Search Guests</ion-label>
      </div>
      <div
        class="center-empty ion-margin-top"
        *ngIf="guests.length == 0 && guestRaw == undefined"
      >
        <ion-label class="empty-text">Search Guests</ion-label>
      </div>
      <div
        class="center-empty ion-margin-top"
        *ngIf="guests.length == 0 && guestRaw != '' && !saving"
      >
        <ion-label class="empty-text">No Guests Found</ion-label>
      </div>
    </ion-list>
    <div class="eventsearch">
      <ion-spinner
        class="ion-margin-top auto-center"
        *ngIf="saving"
      ></ion-spinner>
    </div>
  </ion-list>
</ion-content>
<ion-footer *ngIf="createAllowed">
  <ion-toolbar>
    <ion-button expand="block" (click)="create()">
      <ion-label color="light">Create Guest</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
