import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Event,
  SyncV1Guest,
  SyncV1Table,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class EventState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Event }>();

  //fields specific to the service
  service_icon: string = "business-outline";
  service_title: string = "Event Store";
  service_id: string = "VenueEvent"; //id of the service
  service_url: string = "events";

  //fields specific to the data
  data_id: string = "event_id";
  data_detail: string = "name";
  data_expiry: number = 600; //in minutes, the amount of time before the data is considered unusable
  
  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  //get the menu items as an array

  //get table using id
  event$ = (id) => this.state.select(id);
  events$ = this.state.select(map((menuItems) => Object.values(menuItems)));

  active_events$ = this.events$.pipe(
    map((events) => events.filter((event) => {
      const eventDate = new Date(event.e_date);
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      return event.is_recurring_base == false && eventDate >= twoDaysAgo;
    }).sort((a, b) => new Date(a.e_date).getTime() - new Date(b.e_date).getTime()))
  );

  async Sync() {
    if (this.serviceState.get("refreshing")) {
      return "uness";
    }
    this.serviceState.set({
      refreshing: true,
    });
    let obs = new EventEmitter();

    ///throw error
    if (window.location.href.includes("/cms/")) {
      this.service_url = "events?cms=true";
    }
    let data: any = await this.syncapis
      .getCompleteSync(this.service_url)
      .toPromise();

    let map = {};
    data.items.map((item) => {
      item.sort_key = data.sort_order;
      map[item[this.data_id]] = item;
    });

    this.state.set(map);

    await obs.next(data);
    this.serviceState.set({
      refreshing: false,
    });

    return "success";
  }
}
