import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, throwError } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  take,
  withLatestFrom,
} from "rxjs/operators";
import {
  addToCart,
  decrementItemQuantity,
  incrementItemQuantity,
  orderPlacedSuccessfully,
  removeCartItem,
  updateItemComment,
} from "src/app/actions/cart.actions";
import { DqCartItem, DqModifierGroup } from "src/app/models/cart.model";
import {
  cartSelectAllForSelectedTable,
  cartSelectCount,
  cartSelectEntities,
  cartSelectTotalPrice,
  State,
} from "src/app/reducers";
import { DqCurrencyDetails } from "src/app/services/auth/auth.service";
import { DqMenuItemDetails } from "src/app/services/menu/menu.service";
import { DqGarnish, DqMixture } from "src/app/services/mixture/mixture.service";
import {
  DqPlaceOrderV2Response,
  OrderV2Service,
} from "src/app/services/order-v2/order-v2.service";
import {
  DqGratuityType,
  DqPaymentMethod,
  OrderService,
} from "src/app/services/order/order.service";
import { SquarePaymentsService } from "src/app/services/square-payments/square-payments.service";
import { DqGuest, DqTableDetails } from "src/app/services/table/table.service";
import { getCartTotalPrice } from "src/app/utils/calculate-cart-total";
import { SquarePaymentResponse } from "src/app/utils/capacitor-types";
import { environment } from "src/environments/environment";
import { TablesFacadeService } from "../tables-facade/tables-facade.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

export interface PlaceOrderParams {
  tableId: string;
  gratuity: number;
  gratuityType: DqGratuityType;
  paymentMethod: DqPaymentMethod;
}

const DEFAULT_TAX_RATE = 0.1;
@Injectable({
  providedIn: "root",
})
export class CartFacadeService {
  cartTotal$ = this.store$.pipe(select(cartSelectCount));
  cartEntities$ = this.store$.pipe(select(cartSelectEntities));
  cartAll$ = this.store$.pipe(select(cartSelectAllForSelectedTable));
  cartTotalPrice$ = this.store$.pipe(select(cartSelectTotalPrice));
  cartTax$ = combineLatest([this.cartTotalPrice$]).pipe(
    map(([totalPrice]) => calculateTax(totalPrice, 0))
  );

  constructor(
    private currencyPipe: CurrencyPipe,
    private ordersV2Svc: OrderV2Service,
    private orderSvc: OrderService,
    private squarePayments: SquarePaymentsService,
    private store$: Store<State>,
    private tableFacade: TablesFacadeService,
    private settingSt: SettingsState
  ) {}

  addItemToCart(item: DqMenuItemDetails, tableId: string) {
    this.store$.dispatch(
      addToCart({
        item: {
          id: item.i_id,
          tableId,
          quantity: 1,
          itemDetails: item,
        },
      })
    );
  }

  addMixtureToCart(
    item: DqMenuItemDetails,
    consumerModifiers: DqModifierGroup[],
    quantity: number,
    tableId: string
  ) {
    this.store$.dispatch(
      addToCart({
        item: {
          id: (Math.random() * Math.pow(10, 6)).toFixed(0),
          tableId,
          quantity,
          itemDetails: item,
          consumerModifiers,
        },
      })
    );
  }

  incrementItemQuantity(itemId: string, tableId: string) {
    this.store$.dispatch(incrementItemQuantity({ itemId, tableId }));
  }

  decrementItemQuantity(itemId: string, tableId: string) {
    this.store$.dispatch(decrementItemQuantity({ itemId, tableId }));
  }

  removeItem(itemId: string, tableId: string) {
    this.store$.dispatch(removeCartItem({ itemId, tableId }));
  }

  updateItemComment(itemId: string, comment: string, tableId: string) {
    this.store$.dispatch(updateItemComment({ itemId, comment, tableId }));
  }

  clearCartForTable(tableId: string) {
    this.store$.dispatch(orderPlacedSuccessfully({ tableId }));
  }

  placeOrder({
    tableId,
    gratuity = 0,
    gratuityType,
    paymentMethod,
  }: PlaceOrderParams) {
    return combineLatest([
      this.tableFacade.selectTableById(tableId),
      this.tableFacade.getGuestForTable(tableId),
      this.cartAll$,
      this.settingSt.venueId$,
    ]).pipe(
      take(1),
      concatMap(([table, guest, items, venueId]) => {
        return this.payForOrder(
          table,
          guest,
          items,
          venueId + "",
          "venueLocale" as any,
          paymentMethod,
          gratuity,
          gratuityType
        );
      })
    );
  }

  placeOrderV2({
    tableId,
    gratuity = 0,
    gratuityType,
    paymentMethod,
  }: PlaceOrderParams) {
    return combineLatest([
      this.tableFacade.selectTableById(tableId),
      this.cartAll$,
    ]).pipe(
      take(1),
      map(([table, items]) => {
        if (paymentMethod === "square") {
          return this.payBySquare(table, items);
        }
        return this.ordersV2Svc.newOrder({
          items,
          collectionPointId: table.collection_id,
          gratuity,
          gratuityType,
          paymentMethod,
          tableId,
        });
      })
    );
  }

  private payForOrder(
    table: DqTableDetails,
    guest: DqGuest,
    items: DqCartItem[],
    venueId: string,
    venueLocale: DqCurrencyDetails,
    paymentMethod: DqPaymentMethod,
    gratuity: number,
    gratuityType: DqGratuityType
  ) {
    const { order_id } = guest;
    if (order_id && order_id !== "0" && paymentMethod === "paylater") {
      // there is an existing order, so place an order using the placeRepeatOrder endpoint
      return this.orderSvc.placeRepeatOrder({
        items,
        venue_id: venueId,
        collection_point_id: table.collection_id,
        table_id: table.table_number,
        guest_id: guest.guest_id,
        guest_name: guest.guest_name,
        order_id: guest.order_id,
      });
    } else {
      // this is a paid order, or there isn't an existing order for this table
      return this.orderSvc.placeOrder({
        items,
        venue_id: venueId,
        venue_tax_rate: +venueLocale.lc_tax_rate,
        table_id: table.table_number,
        collection_point_id: table.collection_id,
        payment_method: paymentMethod,
        gratuity,
        gratuityType,
        paymentMethod,
        guest_id: guest.guest_id,
      });
    }
  }

  private payBySquare(table: DqTableDetails, items: DqCartItem[]) {}

  private getSquareNoteForOrder(
    orderId: string,
    items: DqCartItem[],
    currencyCode: string
  ) {
    const itemsString = items
      .map(
        (item) =>
          `${item.itemDetails.i_name} - ${
            item.quantity
          } @ ${this.currencyPipe.transform(
            item.itemDetails.i_price,
            currencyCode
          )} = ${this.currencyPipe.transform(
            +item.quantity * +item.itemDetails.i_price,
            currencyCode
          )}`
      )
      .join("\n");

    return `${itemsString}

Queuebar Order Id #${orderId}`;
  }
}
function calculateTax(totalPrice: number, taxRate: number): number {
  return totalPrice - totalPrice / (1 + taxRate);
}
