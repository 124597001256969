import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import {
  AlertController,
  IonRouterOutlet,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { Observable, combineLatest, map, take } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";
import { Card } from "src/app/models/bump_screen_models";
import { OrderService } from "src/app/services/order/order.service";
import { Availability } from "src/app/models/bookings";
import { BookingService } from "src/app/services/booking/booking.service";
import {
  collapseOnLeaveAnimation,
  expandOnEnterAnimation,
} from "angular-animations";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { SyncV1Booking } from "src/app/models/sync_models";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { EntryState } from "src/app/services/sync-engine/guest-entry/entry.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { PackageState } from "src/app/services/sync-engine/package-state/package.service";
import { EventState } from "src/app/services/sync-engine/event-state/event.service";
import { BookingState } from "src/app/services/sync-engine/booking-state/booking.service";
import { RxState } from "@rx-angular/state";
import { PaymentService } from "src/app/services/payment/payment.service";
import { UsersState } from "src/app/services/sync-engine/users-state/users.service";
import { EditGuestComponent } from "../edit-guest/edit-guest.component";
import { SearchGuestComponent } from "../search-guests/search-guest.component";

@Component({
  selector: "[app-booking-line]",
  templateUrl: "./booking-line.component.html",
  styleUrls: ["./booking-line.component.scss"],
  animations: [
    collapseOnLeaveAnimation({ duration: 250 }),
    expandOnEnterAnimation({ duration: 250 }),
    //animation that slowly expands the height of a div bec
  ],
})
export class BookingLineComponent implements OnInit {
  @Input() booking: SyncV1Booking;


  avaibility_tables$ = combineLatest([
    this.tableSt.tables_ordered$,
    this.bookSt.bookings$,
  ]).pipe(
    map(([tables, booking]) => {
      //get the tables that are available
     
      tables.map((table: any) => {
        //add available count field to the table
        table.available = true;
        //check if the table is available
        booking.map((b) => {
          if ((b.table_id == table.table_id || b.linked_table_ids?.includes(table.table_id)) && b.event_id == this.booking.event_id) {
            table.available = false;
          }
        });
        return table
      })

      return tables

    })
  );

  table_included$ = (id) =>
    this.bookSt.booking$(this.booking.booking_id).pipe(
      map((x) => {
        if (x.table_id == id) {
          return true;
        }
        if (x?.linked_table_ids?.includes(id)) {
          return true;
        }
        return false;
      })
    );


    

  
  tables$ = this.tableSt.tables_ordered$
  guest$ = this.guestSt.guest$;
  table$ = this.tableSt.table$;
  receipt$ = this.recieptSt.getDirect$;
  package$ = this.packageSt.package$
  event$ = this.eventSt.event$;
  booking$ = this.bookSt.booking$
  currency$ = this.settingSt.currencyCode$;

  @Output() open = new EventEmitter();

  entryLoaded$ = this.entrySt.loaded$;
  guest_entry$ = this.entrySt.guest_entry$;
  empty: boolean = false;
  loading

  users$ = this.userSt.getItems$
  presentingElement = this.routerOutlet.nativeEl;

  constructor(
    public modalController: ModalController,
    private toast: ToastController,
    public alertController: AlertController,
    private orderSvc: OrderService,
    private nav: NavController,
    private active: ActivatedRoute,
    private platform: Platform,
    private booqSvc: BookingService,
    private guestSt: GuestState,
    private packageSt: PackageState,
    private tableSt: TableState,
    private entrySt: EntryState,
    private recieptSt: RecieptState,
    private settingSt: SettingsState,
    private eventSt: EventState,
    private bookSt: BookingState,
    private paymSvc: PaymentService,
    private userSt: UsersState,
    private routerOutlet: IonRouterOutlet
    
  ) {}

  ngOnInit() {
    //log output of tableDetail

    if (this.settingSt.hasClaim$(this.userSt.permissions_required).pipe(take(1)).toPromise()) {
      this.userSt.InitStore()
    }
  }

  calcTime() {}

  paymentOpen() {
    this.paymSvc.OpenSidebar("payment", this.booking.receipt_id)
  }


  async SelectBooking(table_id) {
    let booking = this.booking;
    if (
      booking.linked_table_ids != undefined &&
      booking.linked_table_ids.includes(table_id)
    ) {
      booking.linked_table_ids = booking.linked_table_ids.filter(
        (x) => x != table_id
      );
      console.log("Removing table from linked tables");
    } else {
      if (booking.table_id == table_id) {
        if (booking?.linked_table_ids?.length > 0) {
          console.log(
            "Removing table from booking and replacing with a linked table"
          );

          booking.table_id = booking.linked_table_ids[0];
          booking.linked_table_ids = booking.linked_table_ids.filter(
            (x) => x != booking.table_id
          );
        } else {
          console.log("no tables available to replace with");
        }
      } else {
        if (booking.table_id == undefined) {
          booking.table_id = table_id;
          console.log("just setting base table");
          return;
        }

        if (booking.linked_table_ids == undefined) {
          booking.linked_table_ids = [];
        }
        booking.linked_table_ids.push(table_id);
        console.log("adding table to linked tables");
      }
    }

    //run a filter so the change is detected
    booking.linked_table_ids = booking.linked_table_ids.filter(
      (x) => x != undefined
    );

    this.bookSt.state.set({
      [booking.booking_id]: booking,
    });
  }


  async ChangeStatus(status) {
    this.loading = true;
    await this.booqSvc
      .changeBookingStatus(this.booking.booking_id, status)
      .toPromise();
    this.booking.status = status;
    this.loading = false;
  }


  async updateBooking() {
    if (this.booking.booking_id == "new") {
      return;
    }

    this.loading = true;
    await this.booqSvc.updateBooking(this.booking)
      .toPromise();
    this.loading = false;
  }


  async viewEditGuest() {
    let gs = await this.modalController.create({
      component: EditGuestComponent,
      componentProps: {
        guest_id: this.booking.guest_id,
        change: true
      },
      presentingElement: await this.presentingElement
      
    })

    await gs.present()

    gs.onDidDismiss().then(async (data) => {
      if (data.data == "change") {
        let searchGuests = await this.modalController.create({
          component: SearchGuestComponent,
          componentProps: {
           
          },
          presentingElement: await this.presentingElement
        })

        await searchGuests.present()

        searchGuests.onDidDismiss().then(async (data) => {
          if (data.data == "create") {
            this.createguest()
            return;
          }
          if (data.data) {
            console.log("data", data.data)
            this.booking.guest_id = data.data.guest_id
            this.updateBooking()
          }
        })

        return;
      }
    });
  }



  async createguest() {
    console.log("creating guest")
    let newguest = {
      id: "0",
      guest_name: "",
      guest_email: "",
      guest_mobile_num: "",
      guest_mobile_code: this.settingSt.getPrefix(),
      spend: "0",
      visits: "0",
      orders: "",
      tags: ["Manual"],
    };
    const modal = await this.modalController.create({
      component: EditGuestComponent,
      componentProps: {
        guest_id: "0",
        change: false,
        header: true,
      },
      presentingElement: await this.presentingElement,
      handle: true,
    });

    await modal.present();


    modal.onDidDismiss().then(async (data) => {
      
      if (data.data != undefined) {
        this.booking.guest_id = data.data.guest_id
        this.updateBooking()
      }
    });
  }

  discardBooking() {
    //remove the booking with id "new" from the state (thats it)
    //deep copy current state so its a nrew object then remove the booking
    let newState = this.bookSt.state.get()
 
    delete newState[this.booking.booking_id];
    this.bookSt.state.set(newState)

    console.log("Booking discarded", this.bookSt.state.get())
    
  }

  log(data) {
    console.log(data);
  }

  getX(element) { 

    //get child of element ion-content and then get the bounding rect of that
    
    if (!element.el) {
      return 0;
    }
    // Retrieve the child element of type 'ion-content' from the provided element
    const ionContentChild = element.el.querySelector('ion-content');

    if (!ionContentChild) {
      return 0;
    }
    // Get the bounding rectangle of the ion-content child element
    const boundingRect = ionContentChild.getBoundingClientRect();
    // Log the bounding rectangle details


    let rect = element.el.getBoundingClientRect();

    let final =    window.outerHeight - boundingRect.y - 50;


    //minimum 400 tall
    if (final < 400) {
      final = 400;
    }

    return final
  }

  getXOffset(element) { 

    //get child of element ion-content and then get the bounding rect of that
    
    if (!element.el) {
      return 0;
    }
    // Retrieve the child element of type 'ion-content' from the provided element
    const ionContentChild = element.el.querySelector('ion-content');

    if (!ionContentChild) {
      return 0;
    }
    // Get the bounding rectangle of the ion-content child element
    const boundingRect = ionContentChild.getBoundingClientRect();
    // Log the bounding rectangle details


    let rect = element.el.getBoundingClientRect();

    let final = window.outerHeight - boundingRect.y - 50;
    //minimum 400 tall
    if (final < 400) {
      console.log("final", final)
      //offset by how much below 400 it is
       final = 400 - final;

   

      
      return `${final}px`;

    } else {


          //check the element css transforms
          let style = window.getComputedStyle(element.el);
          let matrix = new WebKitCSSMatrix(style.transform);
          let translateY = matrix.m42;
          


      return `${translateY}px`;
    }
    
  }

  async saveBooking() {
    this.loading = true

    let result: any = await this.booqSvc.CreateNewBooking(this.booking).toPromise()

    //remove the new booking from the state
    let newState = this.bookSt.state.get()
    delete newState["new"];
    this.bookSt.state.set(newState)

    this.loading = false
  }

  async updateListGuest(guest) {
    //wait 100ms
    await new Promise((resolve) => setTimeout(resolve, 100));
    await this.booqSvc
      .UpdateListGuest(this.booking.booking_id, guest)
      .toPromise();
    //do this locally
    //are all the guests checked in?
    let allCheckedIn = true;
    for (let g of this.booking.gle_ids) {
      // if (g.seated == false) {
      //   allCheckedIn = false;
      // }
    }
    if (allCheckedIn) {
      //this.booking.guest_status = "seated";
    } else {
      //this.booking.guest_status = "seating";
    }
  }

  guest_entry_status(gle_ids: string[]) {
    let allloaded = true;
    gle_ids.map((x) => {
      if (allloaded == false) {
        return;
      }

      let en = this.entrySt.state.get(x);
      //if one of the entries is not loaded then the whole thing is not loaded
      if (!en.arrived) {
        allloaded = false;
        //dont run the rest of the loop
        return;
      }
    });

    return allloaded;
  }

  

  convertToPretty(string) {
    //remove the underscores and make the first letter capital
    return string.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  }
}
