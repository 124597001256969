import { Pipe, PipeTransform } from "@angular/core";
import { DqGarnishBasic } from "src/app/services/mixture/mixture.service";

@Pipe({
  name: "matchGarnish",
})
export class MatchGarnishPipe implements PipeTransform {
  transform(ids: string, garnishes: DqGarnishBasic[]): DqGarnishBasic[] {
    if (!ids || !garnishes) {
      return;
    }
    return ids
      .split(",")
      .filter(id => !!id)
      .map(id => garnishes.find(garnish => garnish.gar_id === id));
  }
}
