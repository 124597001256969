/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

/**
 * New Payment Service options.
 */
export type PaymentState =
  | "initial"
  | "pending"
  | "paid"
  | "failed"
  | "pending_refund"
  | "refunded"
  | "requires_capture"
  | "cancelled";
/**
 * These strings are persisted in a column in the database,
 * so if you change one here, remember to change all the type
 * columns in the pay_item table. You don't need to touch the
 * DB to add a new one in though Bodhi.
 */
export type PaymentTypeKey =
  | "stripe_tap_to_pay"
  | "treat_authorisation"
  | "payment_cash_request"
  | "payment_cash_direct"
  | "payment_card_request"
  | "payment_comp_request"
  | "payment_tab_request"
  | "payment_comp_direct"
  | "payment_prepaid_direct"
  | "payment_card_direct"
  | "payment_text_link"
  | "payment_link"
  | "custom_checkout"
  | "pay_now_link"
  | "eftpos_request"
  | "payment_paynow_direct"
  | "reader_request"
  | "dojo_reader_request"
  | "stripe_pay_at_table"
  | "no_payment_required"
  | "tab_closed"
  | "card_method"
  | "cash_method"
  | "included_spend_full"
  | "included_spend_draft_full"
  | "included_spend_partial"
  | "fallback";
export type ResponseRouteTo =
  | "internal_url"
  | "external_url"
  | "stay"
  | "close"
  | "display"
  | "tap_to_pay"
  | "card_reader"
  | "alert";
export type ReceiptState = "unpaid" | "partial" | "paid" | "overpaid" | "refunded";

export interface PayItemResponse {
  name: string;
  icon: string;
  status: PaymentState;
  time: string;
  person: string;
  amount: number;
  tip_paid: number;
  refunded: boolean;
  refundable?: PaymentUserActionResponse;
  pi_uuid: string;
  view_process?: boolean;
}
export interface PaymentUserActionResponse {
  old_state: PaymentState;
  new_state: PaymentState;
  text: string;
  url: string;
}
export interface PaymentMethod {
  internal_key: PaymentTypeKey;
  name: string;
  description: string;
  icon: string;
  group_key: string;
  supports_tips: boolean;
  tip_1?: number;
  tip_2?: number;
  tip_3?: number;
}
export interface PaymentMethods {
  methods: PaymentMethod[];
  groups: PaymentMethod[];
}
export interface PaymentResponse {
  id: string;
  state?: PaymentState;
  route_to: ResponseRouteTo;
  success?: boolean;
  success_title?: string;
  failure?: boolean;
  failure_title?: string;
  failure_note?: string;
  title?: string;
  desc?: string;
  qr?: string;
  note?: string;
  loading?: boolean;
  stripe_client_secret?: string;
  url?: string;
  receipt_id?: string;
}
export interface PaymentUserActionRequest {
  old_state: PaymentState;
  new_state: PaymentState;
}
export interface PaymentUserActionResponseList {
  actions: PaymentUserActionResponse[];
}
export interface Receipt {
  id: string;
  currency_code: string;
  total: number;
  tip_paid: number;
  outstanding: number;
  payments: PayItemResponse[];
  receipt_state: ReceiptState;
  supports_partial: boolean;
}
export interface ReceiptPaymentPost {
  internal_key: PaymentTypeKey;
  amount: number;
  tip?: number;
}
export interface TreatPlaceReq {
  gift_id: string;
  item_id: string;
  purchaser_message?: string;
  purchaser_social_tag?: string;
  t_c_acceptance: boolean;
}
export interface TreatPlaceResp {
  o_uuid: string;
  total: number;
  re_uuid: string;
  pi_uuid: string;
  state: PaymentState;
  session_id: string;
  session_url: string;
  payment_intent: string;
}
