import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  DqConsumerMenuCategory,
  DqConsumerMenuItem,
} from "src/app/services/consumer-table/consumer-table.service";
import { ConsumerCartFacadeService } from "src/app/facades/consumer-cart-facade/consumer-cart-facade.service";
import { AlertController } from "@ionic/angular";
import { Observable } from "rxjs";
import { SyncV1MenuCategory, SyncV1MenuItem } from "src/app/models/sync_models";
import { MenuState } from "src/app/services/sync-engine/menu-state/menu.service";
import { CampaignState } from "src/app/services/sync-engine/campaign-state/campaign.service";

@Component({
  selector: "app-menu-page-menu",
  templateUrl: "menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnChanges {
  @Input() categories: Observable<SyncV1MenuCategory[]>;
  @Input() currency: string;
  @Input() items: DqConsumerMenuItem[];
  @Input() searchString: string;
  @Input() showCategories = false;
  @Input() tableId: string;
  @Input() gift: boolean = false;

  @Output() itemAdded = new EventEmitter<DqConsumerMenuItem>();

  itemsToDisplay;
  categoryMap: Map<number, DqConsumerMenuCategory>;

  menuitem$ = this.menuSt.menuItem$;
  campMap$ = this.campSt.campItemMapPrice$;
  constructor(
    private cartFacade: ConsumerCartFacadeService,
    private alertCtrl: AlertController,
    private menuSt: MenuState,
    private campSt: CampaignState
  ) {}

  ngOnChanges(changes: SimpleChanges) {}

  showHeader(index: number) {
    return (
      this.itemsToDisplay[index - 1]?.menu_cat_id !==
      this.itemsToDisplay[index]?.menu_cat_id
    );
  }

  combineIncentive(item, camps) {
    return (
      item.static_commission +
      camps.reduce((acc, camp) => {
        return acc + camp.unit_price;
      }, 0)
    );
  }

  async addToCart(event: CustomEvent, item: DqConsumerMenuItem) {
    event.preventDefault();
    event.stopPropagation();

    if (this.gift) {
      this.itemAdded.emit(item);

      return;
    }

    if (!item.i_user_input_price) {
      this.cartFacade.addConsumerItemToCart(item, this.tableId);
    } else {
      const alert = await this.alertCtrl.create({
        inputs: [
          {
            name: "donation",
            type: "number",
            min: 0,
            placeholder: "Enter your donation",
          },
        ],
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Donate",
          },
        ],
      });
      await alert.present();
      const {
        data: { values },
      } = await alert.onDidDismiss();
      if (+values.donation > 0) {
        this.cartFacade.addConsumerItemToCart(
          item,
          this.tableId,
          undefined,
          values.donation
        );
      }
    }
  }

  getCategoryId(index: number, category: SyncV1MenuCategory) {
    return category.mc_id;
  }

  getItemId(index: number, item: SyncV1MenuItem) {
    return item.mi_id;
  }
}
