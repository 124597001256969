import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { OrderService } from "src/app/services/order/order.service";
import { Availability } from "src/app/models/bookings";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

@Component({
  selector: "app-package-card",
  templateUrl: "./package-card.component.html",
  styleUrls: ["./package-card.component.scss"],
})
export class PackageCardComponent implements OnInit {
  @Input() package;
  @Input() availabil?: Availability = undefined;
  @Input() showAvail?;
  @Input() border? = true;
  @Input() light = false;
  @Input() margin? = false;
  @Input() exitable = false;
  @Input() detail = false;
  @Input() price = true;

  @Output() exit = new EventEmitter();
  currency$ = this.settingsSt.currencyCode$;
  constructor(
    public modalController: ModalController,
    private toast: ToastController,
    public alertController: AlertController,
    private orderSvc: OrderService,
    private nav: NavController,
    private active: ActivatedRoute,
    private settingsSt: SettingsState
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  calcTime() {}

  OpenOrder(event, o_uuid: string) {
    event.stopPropagation();
    this.nav.navigateForward(["../vip-orders-tablet", o_uuid], {
      relativeTo: this.active,
    });
  }

  async orderStateButtonClicked(event, column) {}
}
