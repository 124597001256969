<ion-content class="modal" color="secondary">
  <ion-list class="modal">
    <h1 color="secondary" *ngIf="Category.menu_cat_id">Editing Category</h1>
    <h1 color="secondary" *ngIf="!Category.menu_cat_id">Create Category</h1>
    <ion-item>
      <ion-label position="stacked">Name</ion-label>
      <ion-input
        type="text"
        [(ngModel)]="Category.name"
        name="title"
      ></ion-input>
    </ion-item>

    <div class="fakeitem">
      <div class="spread">
        <ion-label slot="start">Availability</ion-label>
        <ion-note *ngIf="Category.availability == undefined"
          >Categories are always available unless otherwise specified
        </ion-note>
        <ion-button size="small" (click)="addInterval(); ConvertModel()">
          <ion-icon name="add-outline"></ion-icon>
        </ion-button>
      </div>
      <div class="availability" *ngIf="Category.availability != undefined">
        <div
          class="interval"
          *ngFor="let interval of Category.availability.intervals"
        >
          <ion-input
            class="startime"
            [(ngModel)]="interval.start"
            (ngModelChange)="ConvertModel()"
            type="time"
          >
          </ion-input>
          <div class="gap"></div>
          <ion-select
            class="middle"
            [(ngModel)]="interval.day"
            (ngModelChange)="ConvertModel()"
          >
            <ion-select-option value="everyday">Everyday</ion-select-option>
            <ion-select-option value="monday">Monday</ion-select-option>
            <ion-select-option value="tuesday">Tuesday</ion-select-option>
            <ion-select-option value="wednesday">Wednesday</ion-select-option>
            <ion-select-option value="thursday">Thursday</ion-select-option>
            <ion-select-option value="friday">Friday</ion-select-option>
            <ion-select-option value="saturday">Saturday</ion-select-option>
            <ion-select-option value="sunday">Sunday</ion-select-option>
          </ion-select>
          <div class="gap"></div>
          <ion-input
            type="time"
            [(ngModel)]="interval.end"
            (ngModelChange)="ConvertModel()"
            class="stoptime"
          >
          </ion-input>
          <ion-button
            size="small"
            (click)="removeInterval(interval)"
            fill="clear"
            icon-only
          >
            <ion-icon color="light" name="close-circle-outline"></ion-icon>
          </ion-button>
        </div>

        <div class="calander">
          <div class="row">
            ‎
            <div class="date preview">
              <div>12am</div>
              <div>midday</div>
              <div>12am</div>
            </div>
          </div>
          <div class="row" *ngFor="let day of days">
            {{ day }}
            <div class="date">
              <div class=""></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class=""></div>

              <div
                class="blockevery"
                *ngFor="let item of Intervals | filterinterval: 'everyday'"
                [style.left.%]="(item.start_parse / 24) * 100"
                [style.right.%]="(item.end_parse / 24) * 100"
                [style.width.%]="
                  (item.end_parse / 24) * 100 - (item.start_parse / 24) * 100
                "
              ></div>
              <div
                class="blockday"
                *ngFor="let item of Intervals | filterinterval: day"
                [style.left.%]="(item.start_parse / 24) * 100"
                [style.right.%]="(item.end_parse / 24) * 100"
                [style.width.%]="
                  (item.end_parse / 24) * 100 - (item.start_parse / 24) * 100
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ion-button *ngIf="!Category.menu_cat_id" (click)="SaveCategory()"
      >Create Category</ion-button
    >
    <ion-button *ngIf="Category.menu_cat_id" (click)="SaveCategory()"
      >Save Category</ion-button
    >
  </ion-list>
</ion-content>
