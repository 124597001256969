import { Capacitor } from "@capacitor/core";
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from "@ngrx/store";
import { environment } from "../../environments/environment";
import * as fromAuth from "./auth/auth.reducer";
import * as fromCart from "./cart/cart.reducer";
import * as fromCollectionPoints from "./collection-points/collection-points.reducer";
import * as fromNotifications from "./notifications/notifications.reducer";
import * as fromTables from "./tables/tables.reducer";
import * as fromVenues from "./venues/venues.reducer";

const STATE_AUTH = "auth";
const STATE_COLLECTION_POINTS = "collectionPoints";
const STATE_TABLES = "tables";
const STATE_VENUES = "venues";
const STATE_CART = "cart";
const STATE_NOTIFICATIONS = "notifications";

export interface State {
  [STATE_AUTH]: fromAuth.State;
  [STATE_COLLECTION_POINTS]: fromCollectionPoints.State;
  [STATE_TABLES]: fromTables.State;
  [STATE_VENUES]: fromVenues.State;
  [STATE_CART]: fromCart.State;
  [STATE_NOTIFICATIONS]: fromNotifications.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  collectionPoints: fromCollectionPoints.reducer,
  tables: fromTables.reducer,
  venues: fromVenues.reducer,
  cart: fromCart.reducer,
  notifications: fromNotifications.reducer,
};

const capacitorReducers = Capacitor.isNativePlatform() ? [debug] : [];

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [...capacitorReducers]
  : [];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    const updatedState = reducer(state, action);
    // console.log("state", { originalState: state, action, updatedState });

    return updatedState;
  };
}

/**
 * Auth Selectors
 */
const authSelectState = createFeatureSelector<fromAuth.State>(STATE_AUTH);
export const authSelectCheckedAtStartup = createSelector(
  authSelectState,
  fromAuth.selectCheckedAtStartup
);
export const authSelectCognitoUser = createSelector(
  authSelectState,
  fromAuth.selectCognitoUser
);
export const authSelectCurrentUser = createSelector(
  authSelectState,
  fromAuth.selectCurrentUser
);
export const authSelectCurrentUserId = createSelector(
  authSelectState,
  fromAuth.selectCurrentUserId
);
export const authSelectCurrentVenue = createSelector(
  authSelectState,
  fromAuth.selectCurrentVenue
);
export const authSelectCurrentVenueId = createSelector(
  authSelectState,
  fromAuth.selectCurrentVenueId
);
export const authSelectCurrentVenueLocale = createSelector(
  authSelectState,
  fromAuth.selectCurrentVenueLocale
);
export const authSelectTimeOfLastLogin = createSelector(
  authSelectState,
  fromAuth.selectTimeOfLastLogin
);
export const authSelectSquareLocationId = createSelector(
  authSelectState,
  fromAuth.selectSquareLocationId
);

/**
 * Collection Point Selectors
 */

export const collectionPointsSelectState =
  createFeatureSelector<fromCollectionPoints.State>(STATE_COLLECTION_POINTS);
export const collectionPointsSelectAll = createSelector(
  collectionPointsSelectState,
  fromCollectionPoints.selectAll
);
export const colelctionPointsSelectSelectedCP = createSelector(
  collectionPointsSelectState,
  fromCollectionPoints.selectSelectedCp
);

/**
 * Table Selectors
 */

export const tablesSelectState =
  createFeatureSelector<fromTables.State>(STATE_TABLES);
export const tablesSelectAll = createSelector(
  tablesSelectState,
  fromTables.selectAll
);
export const tablesSelectEntities = createSelector(
  tablesSelectState,
  fromTables.selectEntities
);
export const tablesSelectSelectedTableId = createSelector(
  tablesSelectState,
  fromTables.selectSelectedTableId
);

export const selectTreatInfluencerTable = createSelector(
  tablesSelectState,
  fromTables.selectTreatSelectedTableId
);

/**
 * Cart Selectors
 */
export const cartSelectState =
  createFeatureSelector<fromCart.State>(STATE_CART);
export const cartSelectCount = createSelector(
  cartSelectState,
  fromCart.selectCartTotal
);
export const cartSelectAll = createSelector(
  cartSelectState,
  fromCart.selectAll
);
export const cartSelectGratuity = createSelector(
  cartSelectState,
  fromCart.selectGratuity
);

export const cartSelectEntities = createSelector(
  cartSelectState,
  fromCart.selectEntities
);
export const cartSelectAllForSelectedTable = createSelector(
  cartSelectState,
  fromCart.selectAllForSelectedTable
);
export const cartSelectTotalPrice = createSelector(
  cartSelectState,
  fromCart.selectCartTotalPrice
);

export const cartSelectLoadedFromStorage = createSelector(
  cartSelectState,
  fromCart.selectCartLoadedFromStorage
);

export const cartSelectCouponCodes = createSelector(
  cartSelectState,
  fromCart.selectCouponCodes
);

export const cartSelectCouponCodeIds = createSelector(
  cartSelectState,
  fromCart.selectCouponCodeIds
);

export const cartSelectBindingQuoteId = createSelector(
  cartSelectState,
  fromCart.selectOrderUuid
);

export const cartTreatIsValid = createSelector(
  cartSelectState,
  fromCart.selectTreatValid
);

/**
 * Notification Selectors
 */
export const notificationsSelectState =
  createFeatureSelector(STATE_NOTIFICATIONS);
export const notificationsSelectAll = createSelector(
  notificationsSelectState,
  fromNotifications.selectAll
);
export const notificationsSelectEntities = createSelector(
  notificationsSelectState,
  fromNotifications.selectEntities
);
export const notificationsSelectDeviceToken = createSelector(
  notificationsSelectState,
  fromNotifications.selectDeviceToken
);
export const notificationsRegistrationComplete = createSelector(
  notificationsSelectState,
  fromNotifications.selectRegistrationComplete
);

/**
 * Venue Selectors
 */

export const venuesSelectState =
  createFeatureSelector<fromVenues.State>(STATE_VENUES);
export const venuesSelectCurrentVenueId = createSelector(
  venuesSelectState,
  fromVenues.selectSelectedVenueId
);
export const venuesSelectAll = createSelector(
  venuesSelectState,
  fromVenues.selectAll
);
export const venuesSelectEntities = createSelector(
  venuesSelectState,
  fromVenues.selectEntities
);
export const venuesSelectSelectedTableId = createSelector(
  venuesSelectState,
  fromVenues.selectSelectedVenue
);
export const venuesSelectCurrentVenue = createSelector(
  venuesSelectState,
  fromVenues.selectSelectedVenue
);
export const venuesSelectHaveBeenLoaded = createSelector(
  venuesSelectState,
  fromVenues.selectVenuesHaveBeenLoaded
);
export const venuesTerminalId = createSelector(
  venuesSelectState,
  fromVenues.selectTerminalId
);
export const venuesSelectOrderNotifcations = createSelector(
  venuesSelectState,
  fromVenues.selectOrderNotifcations
);
