<ion-item-sliding
  (click)="item.close()"
  (ionDrag)="dragging($event, item)"
  #item
  *ngIf="table != undefined"
>
  <ng-container
    *ngrxLet="session$(table.seat_id ? table.seat_id : '') | async as seating"
  >
    <ion-item-options side="start">
      <ion-item-option
        color="secondary"
        expandable
        *ngIf="fav"
        (click)="unfavTable(); item.close()"
      >
        <ion-icon
          name="person-remove-outline"
          color="primary"
          slot="icon-only"
        ></ion-icon>
      </ion-item-option>
      <ion-item-option
        color="primary"
        expandable
        *ngIf="!fav"
        (click)="favTable(); item.close()"
      >
        <ion-icon name="person-add" color="light" slot="icon-only"></ion-icon>
      </ion-item-option>
    </ion-item-options>
    <ng-container
      *ngrxLet="
        booking$(seating?.booking_id != undefined ? seating?.booking_id : '')
          | async as booking
      "
    >
      <ion-item
        class="item-remove-animate"
        (click)="
          selectedTable.emit(table); item.close(); log('clicked'); checkAnger()
        "
        [detail]="table.guest"
        [disabled]="!fav && (free_access$ | async) == false"
        [class.selec]="selected"
        [class.groupsel]="
          getTables(table, booking?.linked_table_ids).length > 1
        "
        [color]="transparent ? 'secondary' : ''"
      >
        <ion-label
          *ngrxLet="getTables(table, booking?.linked_table_ids) as tablelong"
        >
          <ion-label
            *ngFor="let tableI of tablelong; trackBy: trackByFn; let i = index"
            [class.ion-margin-bottom]="
              tablelong.length > 1 && i != tablelong.length - 1
            "
          >
            {{ (table$(tableI) | async).name }}
            <ng-container
              *ngIf="seating != undefined && isHalfway(i, tablelong.length)"
            >
              -
              {{
                fav || (free_access$ | async)
                  ? booking != undefined && booking.group_name != undefined && booking.group_name != ''? booking.group_name : (guest$(seating.guest_id) | async)?.name
                  : "Occupied"
              }}
            </ng-container>
            <div
              *ngIf="seating != undefined && isHalfway(i, tablelong.length)"
              class="opened_at"
            >
              {{
                seating.booking_id != undefined ? "Seated Booking" : "Walk In"
              }}
            </div>
            <ng-container *ngrxLet="is_upcoming$(tableI) as upcoming">
              <div
                *ngIf="seating == undefined && upcoming != undefined"
                class="opened_at"
              >
                {{ upcoming }}
              </div>
            </ng-container>
          </ion-label>
        </ion-label>
        <ion-spinner slot="end" *ngIf="table.loading"></ion-spinner>
        <ion-chip
          slot="end"
          [class.top]="
            getTables(table, booking?.linked_table_ids).length % 2 == 0
          "
          [color]="seating.met_goals ? 'success' : 'primary'"
          *ngIf="seating != null"
        >
          {{
            (fav || (free_access$ | async))
              ? seating.spent_and_unpaid
              : "--------------"
          }}
        </ion-chip>
      </ion-item>
      <app-waiting-item
        *ngIf="table?.table_id != undefined"
        [table_id]="table?.table_id"
        [success_timeout]="8"
      ></app-waiting-item>
    </ng-container>
    <ion-item-options side="end" *ngIf="fav || (free_access$ | async)">
      <ion-item-option
        color="secondary"
        expandable
        *ngIf="seating != null"
        (click)="closeTable.emit(table); item.close()"
      >
        <ion-label color="primary"> Close </ion-label>
      </ion-item-option>
      <ion-item-option
        color="tertiary"
        expandable
        *ngIf="seating != null && table.booking_id"
        (click)="openTable.emit(table); item.close()"
      >
        <ion-icon
          name="calendar-outline"
          color="light"
          slot="icon-only"
        ></ion-icon>
      </ion-item-option>
      <ion-item-option
        color="primary"
        expandable
        *ngIf="seating == null"
        (click)="openTable.emit(table); item.close()"
      >
        Open
      </ion-item-option>
    </ion-item-options>
  </ng-container>
</ion-item-sliding>

<ng-template #loading></ng-template>
