import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { Platform } from "@ionic/angular";

@Directive({
  selector: "[appShareIsAvailable]",
})
export class ShareIsAvailableDirective implements OnInit {
  constructor(
    private platform: Platform,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    if (webShareIsAvailable() || this.platform.is("capacitor")) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

export function webShareIsAvailable() {
  return "share" in navigator;
}
