import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-featured-card",
  templateUrl: "./featured-card.component.html",
  styleUrls: ["./featured-card.component.scss"],
})
export class FeaturedCardComponent implements OnInit {
  @Input() button = false;
  @Input() text: string;
  @Input() backgroundImageUrl: string;
  @Input() color: string = "secondary";
  @Input() currency: string;
  @Input() price: string;
  @Input() border: boolean;

  constructor() {}

  ngOnInit() {}
}
