<ng-container
  *ngIf="recieptId != undefined && (recieptLoaded$ | async) == true; else load"
>
  <ion-list
    lines="none"
    class="recieptlist"
    *ngrxLet="reciept$(recieptId) | async as reciept"
  >


    <ng-container *ngIf="Actionable && reciept != undefined">
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'paid'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="success"
      >
        <ion-label color="light">Payment Completed</ion-label>
        <ion-icon
          slot="end"
          color="light"
          name="checkmark-done-outline"
        ></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'overpaid'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="danger"
      >
        <ion-label color="light">Overpaid</ion-label>
        <ion-icon
          slot="end"
          color="light"
          name="alert-circle-outline"
        ></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'unpaid'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="primary"
      >
        <ion-label color="light">Unpaid</ion-label>
        <ion-icon slot="end" color="light" name="add-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'partial'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="primary"
      >
        <ion-label color="light">Partially Paid</ion-label>
        <ion-icon slot="end" color="light" name="add-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'refunded'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="danger"
      >
        <ion-label color="light">Payment Refunded</ion-label>
        <ion-icon slot="end" color="light" name="arrow-undo-outline"></ion-icon>
      </ion-button>    
      <ng-content></ng-content>


    </ng-container>
 

    <ion-item>
      <ion-label class="notop"> Total </ion-label>
      <ion-label class="notop" slot="end" *ngIf="reciept">
        {{ reciept.total | currency: (currency$ | async) }}
      </ion-label>
    </ion-item>
    <ion-item class="sosmall">
      <ion-label class="notop"> Tip </ion-label>
      <ion-label class="notop" slot="end" *ngIf="reciept">
        {{ reciept.tip_paid | currency: (currency$ | async) }}
      </ion-label>
    </ion-item>

    <ng-container *ngIf="reciept">
      <div class="center" *ngIf="showEmpty && reciept.payments.length == 0">
        <ion-label class="empty-text">No Payments</ion-label>
      </div>
        <app-timeline-reciept [payments]="reciept.payments" [recieptId]="recieptId"></app-timeline-reciept>
    </ng-container>
  </ion-list>
</ng-container>

<ng-template #load>
  <ion-list lines="none" class="recieptlist">
    <ng-container *ngIf="Actionable">
      <ion-skeleton-text
        [animated]="true"
        class="paymc expand"
        [style.height]="'45px'"
      ></ion-skeleton-text>
    </ng-container>
    <ion-item>
      <ion-label class="notop"> Total </ion-label>
      <ion-label class="notop" slot="end">
        <ion-skeleton-text
          [animated]="true"
          [style.width]="'60px'"
          [style.height]="'20px'"
        ></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ion-item class="sosmall">
      <ion-label class="notop"> Tip </ion-label>
      <ion-label class="notop" slot="end">
        <ion-skeleton-text
          [animated]="true"
          [style.width]="'60px'"
          [style.height]="'20px'"
        ></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ion-list>
</ng-template>
