<ion-card class="cardpadding" COLOR="secondary" (click)="viewMenu(tableDetail)">
  <ion-card-header>
    <ion-row class="ion-justify-content-between">
      <ion-card-title>
        <h2 class="ion-text-nowrap ellipsis ion-text-left">
          {{ tableDetail.table_number }}
        </h2>
        <!--
        <h6 class="Caption ion-no-margin notop ion-text-left">
          Attended Ashley, James
        </h6>-->
      </ion-card-title>
      <div>
        <ng-container>
          <ion-button
            *ngIf="hasActiveSubscription(tableDetail.table_id)"
            slot="end"
            fill="clear"
            color=""
            (click)="removeSubscriptionClicked(tableDetail, $event)"
          >
            <ion-icon name="star" color="" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button
            *ngIf="!hasActiveSubscription(tableDetail.table_id)"
            slot="end"
            (click)="addSubscriptionClicked(tableDetail, $event)"
            fill="clear"
            color="white"
          >
            <ion-icon name="star-outline" color="" slot="icon-only"></ion-icon>
          </ion-button>
        </ng-container>

        <ion-button
          *ngIf="tableDetail.table_flag === '1'"
          class="table-action"
          color="dark"
          slot="end"
          size="default"
          (click)="presentCloseTableConfirm(tableDetail, $event)"
          >Close</ion-button
        >
        <ion-button
          *ngIf="tableDetail.table_flag === '0'"
          slot="end"
          size="default"
          color="primary"
          (click)="presentOpenTableModal(tableDetail, $event)"
          >Open</ion-button
        >
      </div>
    </ion-row>
  </ion-card-header>
  <ion-card-content>
    <ion-row class="ion-justify-content-between">
      <div *ngIf="tableDetail.guest">
        <ion-text>
          <h6 class="Caption ion-text-left">{{ tableDetail.description }}</h6>
          <h3 class="BottomText ion-text-left">
            {{ tableDetail?.guest[0]?.guest_name }}
          </h3>
        </ion-text>
      </div>
      <div *ngIf="!tableDetail.guest">
        <ion-text>
          <h6 class="Caption ion-text-left">Click Open</h6>
          <h3
            class="BottomText ion-text-left"
            *ngIf="tableDetail.description == ''"
          >
            Table Closed
          </h3>
          <h3
            class="BottomText ion-text-left"
            *ngIf="tableDetail.description != ''"
          >
            {{ tableDetail.description }}
          </h3>
        </ion-text>
      </div>
      <div>
        <!--
        <ion-text>
          <h6 class="ion-text-end Caption">tab</h6>
          <h3 class="BottomText">{{ tableDetail.guest[0]?.guest_email }}</h3>
        </ion-text> -->
      </div>
    </ion-row>
  </ion-card-content>
</ion-card>
