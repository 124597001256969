import { Pipe, PipeTransform } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  ROUTE_PARAM_EVENT_ID,
  ROUTE_PARAM_REGION_ID,
} from "src/app/pages/consumer-event/consumer-event-routing-params";
import { ROUTE_PARAM_LOCATION_ID } from "src/app/pages/consumer-menu/consumer-menu-route-params";

@Pipe({
  name: "appLocationUrl",
})
export class LocationUrlPipe implements PipeTransform {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  transform(
    params: Params,
    url: string[] = ["location", params[ROUTE_PARAM_LOCATION_ID]],
    suffix: string[] = []
  ): string[] {
    const eventId = params[ROUTE_PARAM_EVENT_ID];
    const regionId = params[ROUTE_PARAM_REGION_ID];
    const locationId = params[ROUTE_PARAM_LOCATION_ID];
    const isTreatMe = this.router.url.includes("treat");
    if (url.includes("location") && isTreatMe) {
      const i = url.findIndex((el) => el === "location");
      url[i] = "treat";
    }

    if (url.includes("table")) {
      return ["/", "table", params[ROUTE_PARAM_LOCATION_ID]];
    }
    if (eventId && regionId) {
      return ["/event", eventId, "region", regionId, ...url, suffix];
    } else if (eventId) {
      return ["/event", eventId, ...url, ...suffix];
    } else {
      return ["/", ...url, ...suffix];
    }
  }
}
