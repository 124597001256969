<ion-item
  *ngIf="!loading && booking != undefined"
  (click)="OpenBooking($event, booking.booking_id)"
  [class.selec]="selected"
  [lines]="border ? 'full' : 'none'"
  [class.ion-margin-end]="outline"
  button
  detail="false"
>
  <div
    class="interaction-left"
    [class.outline-desk]="outline"
    [class.outline-desk-white]="outline && booking.guest_id != undefined"
    *ngIf="OutputInteractions"
    (click)="Guest.emit()"
  ></div>
  <div
    class="interaction-right"
    *ngIf="OutputInteractions"
    [class.nobottom]="booking.status == 'pending'"
    [class.outline-desk-white]="outline && booking.table_id != undefined"
    [class.outline-desk]="outline"
    (click)="Table.emit()"
  ></div>
  <ion-icon
    *ngIf="outline"
    class="large-desk-icon ion-margin-start ion-margin-end ion-padding-start"
    [color]="booking.guest_id != undefined ? 'primary' : 'light'"
    name="people-outline"
  ></ion-icon>
  <ion-col class="inside-item">
    <ion-row class="ion-justify-content-between"
      ><ion-label *ngIf="booking.guest_id != undefined; else guestempty">
        <ng-container *ngrxLet="guest$(booking.guest_id) | async as guest">
          <h2 class="ion-text-capitalize" *ngIf="guest != undefined">
            {{ booking_name(booking, guest) }} 
          </h2>

          <p *ngIf="guest != undefined">
            {{
               booking.group_name != undefined && booking.group_name != ''?  guest.name : convertToPretty(guest.status) 

            }}
          </p>
        </ng-container>
      </ion-label>

      <ion-label
        class="status ion-text-end"
        [color]="booking.status == 'seated' ? 'primary' : 'light'"
      >
        <h2
          *ngIf="booking.status != undefined"
          [class]="
            booking.status == 'seated'
              ? 'seated ion-text-capitalize'
              : 'not-seated ion-text-capitalize'
          "
          [class.primary-text]="booking.table_id == undefined"
        >
          {{
            booking.table_id == undefined
              ? "Select Table"
              : booking.status == "new"
              ? "Quote"
              : booking.status
          }}
        </h2>
        <p *ngIf="booking.table_id == undefined">Unset</p>
        <p *ngIf="booking.table_id != undefined">
          {{
            booking.arrival != undefined
              ? booking.arrival.arrive_text_primary +
                booking.arrival.arrive_text_trailing +
                " - "
              : ""
          }}
          {{
            (booking_table_name$(booking) | async)
          }}
        </p>
      </ion-label>
    </ion-row>
    <ion-row class="ion-justify-content-between ion-align-items-end">
      <app-list-chip [booking]="booking" (click)="ListOpen = !ListOpen; $event.stopPropagation()" [interactable]="interactable">

      </app-list-chip>
      <div *ngIf="booking.status !== 'pending' || !interactable">
        <ion-chip
          *ngIf="
            booking.unapproved_treats != undefined &&
            booking.unapproved_treats > 0
          "
          outline="true"
          color="primary"
          class="chip-primary chipnopad"
        >
          <ion-icon name="gift-outline" class="chipout"></ion-icon>
          {{ booking.unapproved_treats }}
        </ion-chip>
        <ng-container *ngIf="booking.status != 'new'">
          <app-reciept-chip [receipt_id]="booking.receipt_id"></app-reciept-chip>
        </ng-container>
        <ion-chip
          *ngIf="booking.status == 'new'"
          [disabled]="true"
          outline="true"
          >Quote</ion-chip
        >
      </div>
      <div *ngIf="booking.status === 'pending' && interactable">
        <ion-button
          (click)="ChangeStatus('confirmed'); $event.stopPropagation()"
          size="small"
        >
          <ion-label *ngIf="statusUpdating != 'confirmed'">Approve</ion-label>
          <ion-spinner *ngIf="statusUpdating == 'confirmed'"></ion-spinner
        ></ion-button>
        <ion-button
          (click)="ChangeStatus('denied'); $event.stopPropagation()"
          color="danger"
          fill="outline"
          size="small"
          ><ion-label *ngIf="statusUpdating != 'denied'">Deny</ion-label>
          <ion-spinner *ngIf="statusUpdating == 'denied'"></ion-spinner
        ></ion-button>
      </div>
    </ion-row>
  </ion-col>
  <ion-icon
    *ngIf="outline"
    class="large-desk-icon ion-margin-start ion-margin-end"
    [color]="booking.table_id != undefined ? 'primary' : 'light'"
    name="id-card-outline"
  ></ion-icon>

  <ng-content slot="helper"></ng-content>
</ion-item>

<ion-item *ngIf="loading">
  <ion-col class="inside-item">
    <ion-row class="ion-justify-content-between"
      ><ion-label>
        <h2>
          <ion-skeleton-text
            [style.width]="'100px'"
            [style.height]="'20px'"
            [animated]="true"
          ></ion-skeleton-text>
        </h2>
        <p>
          <ion-skeleton-text
            [style.width]="'80px'"
            [style.height]="'20px'"
            [animated]="true"
          ></ion-skeleton-text>
        </p>
      </ion-label>

      <ion-label class="status">
        <h2>
          <ion-skeleton-text
            [style.width]="'46px'"
            [style.height]="'20px'"
            [animated]="true"
          ></ion-skeleton-text>
        </h2>
      </ion-label>
    </ion-row>
    <ion-row class="ion-justify-content-between ion-align-items-end">
      <ion-chip>
        <ion-skeleton-text
          [style.width]="'50px'"
          [style.height]="'16px'"
          [animated]="true"
        ></ion-skeleton-text>
      </ion-chip>

      <ion-chip>
        <ion-skeleton-text
          [style.width]="'50px'"
          [style.height]="'16px'"
          [animated]="true"
        ></ion-skeleton-text>
      </ion-chip>
    </ion-row>
  </ion-col>
</ion-item>

<div
  *ngIf="
    (entryLoaded$ | async) &&
    booking != undefined &&
    booking.gle_ids != undefined &&
    booking.gle_ids.length > 0
  "
  [class.collapsed]="!ListOpen"
  [class.expanded]="ListOpen"
  [style.max-height.px]="ListOpen ? booking.gle_ids.length * 50 : 0"
>
  <ng-container *ngFor="let guest_id of booking.gle_ids; let i = index">
    <ng-container *ngrxLet="guest_entry$(guest_id) | async as guest">
      <ion-item
        class="guest-item"
        *ngIf="
          (guest != undefined && guest?.hidden == undefined) ||
          guest?.hidden == false
        "
        [lines]="i == booking.gle_ids.length - 1 ? 'full' : 'inset'"
      >
        <ion-label *ngIf="guest.guest_id != undefined">
          {{ (guest$(guest.guest_id) | async)?.name }}
        </ion-label>
        <ion-label *ngIf="guest.guest_id == undefined">Anon Guest</ion-label>
        <ion-chip slot="end" color="primary">{{ guest.notes }}</ion-chip>
        <ion-checkbox
          [style.pointer-events]="interactable ? 'auto' : 'none'"
          slot="end"
          [(ngModel)]="guest.arrived"
          (click)="$event.stopPropagation(); updateListGuest(guest)"
        >
        </ion-checkbox>
      </ion-item>
    </ng-container>
  </ng-container>
</div>

<ng-template #guestempty>
  <ion-label>
    <h2 class="ion-text-capitalize primary-text">Select Guest</h2>

    <p>Unset</p>
  </ion-label>
</ng-template>
