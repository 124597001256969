import { createAction, props } from "@ngrx/store";
import { DqLoginResponseSuccess } from "../services/auth/auth.service";
import { DqMenuNotifications } from "../services/order/order.service";
import { DqVenueList } from "../services/venue/venue.service";

const ACTION_PREFIX = "[Venues]";

export const loadVenues = createAction(`${ACTION_PREFIX} Fetch Venues`);
export const loadVenuesSuccess = createAction(
  `${ACTION_PREFIX} Fetch Venues Success`,
  props<{ venue_list: DqVenueList }>()
);
export const loadVenuesNoVenues = createAction(
  `${ACTION_PREFIX} Fetch Venues - No Venues Found`
);
export const loadVenuesError = createAction(
  `${ACTION_PREFIX} Fetch Venues Error`,
  props<{ error: any }>()
);
export const clearVenues = createAction(`${ACTION_PREFIX} Clear Venues`);
export const clearVenuesSuccess = createAction(`${ACTION_PREFIX} Clear Venues`);
export const clearVenuesError = createAction(`${ACTION_PREFIX} Clear Venues`);
export const selectVenue = createAction(
  `${ACTION_PREFIX} Select Venue`,
  props<{ selectedVenueId: string }>()
);
export const selectVenueSucess = createAction(
  `${ACTION_PREFIX} Select Venue Success`,
  props<{ selectedVenueId: string }>()
);
export const selectVenueError = createAction(
  `${ACTION_PREFIX} Select Venue Error`
);
export const selectVenueComplete = createAction(
  `${ACTION_PREFIX} Select Venue Complete`
);

export const venueCheckInSuccess = createAction(
  `${ACTION_PREFIX} Check In Success`,
  props<{ loginResponse: DqLoginResponseSuccess }>()
);
export const venueCheckInError = createAction(
  `${ACTION_PREFIX} Check In Error`,
  props<{ error: any }>()
);
export const selectVenueAtStartupSucess = createAction(
  `${ACTION_PREFIX} Select Venue At Startup Success`,
  props<{ selectedVenueId: string }>()
);

export const selectVenueAtStartupNoDetails = createAction(
  `${ACTION_PREFIX} Select Venue At Startup No Details`
);
