import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CMSv2CreateVenue,
  CMSv2CreateVenueResult,
  CMSv2UserEnabledInput,
} from "src/app/models/cms_db_equiv";
import { paramsToFormData } from "src/app/utils/object-to-form-data";
import { environment } from "src/environments/environment";
import { DqLoginResponseSuccess } from "../auth/auth.service";
import { RegionsService } from "../regions/regions.service";
import { SyncV1User } from "src/app/models/sync_models";

export interface DqVenueList {
  venues: DqVenue[];
}

export interface VenueStaffMember {
  users: [
    {
      email?: string;
      phone_num: string;
      full_name: string;
      cog_user_name?: string;
      is_enabled?: boolean;
      user_type?: string;
    }
  ];
}

export interface DqVenue {
  v_id: number;
  v_name: string;
}

@Injectable({
  providedIn: "root",
})
export class VenueService {
  constructor(private http: HttpClient, private reg: RegionsService) {}

  //get the venues associated with a user
  getStaffVenues(reg?: string) {
    return this.http.get<DqVenueList>(
      `${this.reg.ServerURL()}/v3/staff/venues`
    );
  }

  //get the users associated with a venue
  getVenueUsers(v_id: string, assignments?: {}) {
    return this.http.get<{ users: any[] }>(
      `${this.reg.ServerURL()}/venue/${v_id}/list_users`,
      {
        params: assignments,
      }
    );
  }

  AssignUsers(table_id: string, user) {
    return this.http.post<VenueStaffMember>(
      `${this.reg.ServerURL()}/v3/table/${table_id}/assignments`,
      user
    );
  }

  FavTable(table_id: string, status) {
    return this.http.post<VenueStaffMember>(
      `${this.reg.ServerURL()}/v3/table/${table_id}/assignment`,
      { favourite: status }
    );
  }


  updateUser(user) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/venue/users/${user.cog_user_name}`,
      user
    );
  }

  //add user to venue
  addEditVenueUser({
    v_id,
    full_name,
    phone_num,
    is_enabled,
    user_type,
  }: {
    v_id: string;
    full_name: string;
    phone_num: string;
    is_enabled: boolean;
    user_type: string;
  }) {
    console.log(user_type);
    return this.http.post<VenueStaffMember>(
      `${this.reg.ServerURL()}/venue/${v_id}/user`,
      {
        full_name,
        phone_num,
        is_enabled,
        user_type,
      }
    );
  }

  CreateVenue(Venue: CMSv2CreateVenue) {
    return this.http.post<CMSv2CreateVenueResult>(
      `${this.reg.ServerURL()}/cms_v2/venue`,
      Venue
    );
  }

  //add user to venue
  removeVenueUser({
    v_id,
    cog_user_name,
  }: {
    v_id: string;
    cog_user_name: string;
  }) {
    return this.http.delete<VenueStaffMember>(
      `${this.reg.ServerURL()}/venue/${v_id}/user/${cog_user_name}`
    );
  }

  resetVenueUser({
    v_id,
    cog_user_name,
  }: {
    v_id: string;
    cog_user_name: string;
  }) {
    return this.http.patch<VenueStaffMember>(
      `${this.reg.ServerURL()}/venue/${v_id}/user/${cog_user_name}`,
      {}
    );
  }
}
