import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Guest,
  SyncV1GuestListEntry,
  SyncV1Table,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class EntryState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1GuestListEntry }>();

  //fields specific to the service
  service_icon: string = "list-outline";
  service_title: string = "Entry Store";
  service_id: string = "GuestAssignedBooking"; //id of the service
  service_url: string = "guest_list_entries";

  //fields specific to the data
  data_id: string = "gle_id";
  data_detail: string = "guest_id";

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  //get the menu items as an array

  //get table using id
  guest_entry$ = (id) => this.state.select(id);

  entrys_for_booking$ = (id) =>
    this.state.select(
      map((menuItems) =>
        Object.values(menuItems).filter((x) => x.booking_id == id)
      )
    );
}
