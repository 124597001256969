import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { StorageAdapter } from "../storage-adapter/storage-adapter.service";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class RegionsService {
  constructor(
    private activeroute: ActivatedRoute,
    private storage: StorageAdapter
  ) {}

  async getRegionStorage() {
    return this.storage.getObject("region");
  }

  getRegionViaAZ(az: string) {
    switch (az) {
      case "us-east-1":
        return "us";
        break;
      case "ap-southeast-2":
        return "au";
        break;
      case "eu-west-2":
        return "eu";
        break;
    }
  }
  
  
  getPrefix() {
    
    switch (this.getRegion()) {
      case "us":
        return "+1";
        break;
      case "au":
        return "+61";
        break;
      case "eu":
        return "+44";
        break;
    }
  }

  getISO() {
   
  }

  getRegion(): string {
    var foundregion = "";
    if (this.activeroute.snapshot.paramMap.get("region") != null) {
      foundregion = this.activeroute.snapshot.paramMap.get("region");
    } else {
      foundregion = window.location.href.split("/")[3];
    }

    if (environment.validregions.includes(foundregion)) {
      var region = foundregion;
      this.storage.set("region", region);
      return region;
    } else {
      //fetches the region from the browser

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      console.log(timezone);
      switch (timezone) {
        case "America/New_York":
        case "America/Chicago":
        case "America/Denver":
        case "America/Los_Angeles":
        case "America/Phoenix":
        case "America/Anchorage":
        case "America/Adak":
        case "America/Juneau":
          return "us";
          break;
        case "Australia/Sydney":
        case "Australia/Melbourne":
        case "Australia/Brisbane":
        case "Australia/Perth":
          return "au";
          break;
        case "Europe/London":
        case "Europe/Paris":
        case "Europe/Berlin":
        case "Europe/Amsterdam":
        case "Europe/Madrid":
        case "Europe/Rome":
        case "Europe/Athens":
        case "Europe/Dublin":
        case "Europe/Helsinki":
        case "Europe/Stockholm":
        case "Europe/Copenhagen":
          return "eu";
          break;
        default:
          console.log("No Valid Timezone, FallBack");
          return "au";
      }
    }
  }

  ServerURL(reg?: string) {
    if (reg != undefined) {
      return environment[reg].serverUrl;
    } else {
      return environment[this.getRegion()].serverUrl;
    }
  }

  ServerSocket(reg?: string) {
    if (reg != undefined) {
      return environment[reg].serverSocket;
    } else {
      return environment[this.getRegion()].serverSocket;
    }
  }

  getCurrentEnvironment() {
    return environment[this.getRegion()];
  }

  getCognitoConfig() {
    return environment[this.getRegion()].cognitoConfig;
  }
  getServerPushKey() {
    return environment[this.getRegion()].serverPushPublicKey;
  }

  getCurrentTime() {
    var region = this.getRegion();
    //get current time in current region as DateTime

    var regionoffset = {
      us: -7,
      au: 10,
      eu: 2,
    };

    var currenttime = DateTime.local()
      .setZone("UTC")
      .plus({ hours: regionoffset[region] });
    return currenttime;
  }
}
