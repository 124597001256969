<app-horizontal-scroller>
  <ion-button
    *ngFor="let modifier of modifiers; trackBy: getModifierId"
    class="btn-mix-items"
    size="large"
    [class.notselected]="modifierHasBeenSelected(modifier)"
    [color]="modifierHasBeenSelected(modifier) ? 'primary' : 'primary'"
    [fill]="modifierHasBeenSelected(modifier) ? 'solid' : 'outline'"
    (click)="toggleModifier(modifier)"
  >
    {{ modifier.name }}
  </ion-button>
</app-horizontal-scroller>
