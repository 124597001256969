import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable } from "rxjs";
import { Platform } from "@ionic/angular";
import { BaseSynced } from "../base-synced/base.service";
import {
  SyncV1MenuCategory,
  SyncV1MenuItem,
  SyncV1MenuSuperCategory,
  SyncV1Modifier,
  SyncV1ModifierGroup,
  SyncV1User,
  SyncV1Venue,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class SyncAPIService {
  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private palt: Platform
  ) {}

  getCompleteSync(objectType: string) {
    let params = {};

    //check if the url includes cms
    if (this.palt.url().includes("cms")) {
      params = { cms: true };
    }

    return this.http.get(`${this.reg.ServerURL()}/sync/v1/${objectType}`, {
      params: params,
    });
  }
  getMenuItems() {
    return this.http.get<{
      menu_items: SyncV1MenuItem[];
      menu_cats: SyncV1MenuCategory[];
      menu_super_cats: SyncV1MenuSuperCategory[];
      modifier_groups: SyncV1ModifierGroup[];
      modifiers: SyncV1Modifier[];
      timestamp: number;
    }>(`${this.reg.ServerURL()}/sync/v1/menu`);
  }

  checkIn(fcm_id?) {
    let data = {};
    if (fcm_id != null && fcm_id != undefined && fcm_id != "") {
      data = { fcm_id: fcm_id };
    }

    return this.http.post<{
      venue: SyncV1Venue;
      user: SyncV1User;
    }>(`${this.reg.ServerURL()}/v3/check_in`, data);
  }
}
