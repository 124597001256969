import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { SwUpdate } from "@angular/service-worker";
import { StatusBar, Style } from "@capacitor/status-bar";
import { MenuController, Platform } from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import {
  filter,
  map,
  take,
  takeLast,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ROUTE_PARAM_REGION_ID } from "./pages/consumer-event/consumer-event-routing-params";
import { DqConsumerGetTableInfoResponse } from "./services/consumer-table/consumer-table.service";
import { CustomerIdService } from "./services/customer-id/customer-id.service";
import { ThemeService } from "./services/theme/theme.service";
import awsConfig from "../aws-exports";
import { CapacitorAmplifyStorage } from "./services/capacitor-amplify-storage/capacitor-amplify-storage.service";
import { Amplify } from "@aws-amplify/core";
import { RegionsService } from "./services/regions/regions.service";
import Tracker from "@openreplay/tracker";
import { PaymentService } from "./services/payment/payment.service";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { AnalyticsService } from "./services/analytics/analytics.service";
import { PaymentPageComponent } from "./components/payment-page/payment-page.component";
import { SyncService } from "./services/sync-engine/sync/sync.service";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Storage } from "@ionic/storage-angular";
import { SettingsState } from "./services/sync-engine/settings-state/settings-state.service";
import { combineLatest } from "rxjs";
import { MobilePayService } from "./services/mobile-pay/mobile-pay.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  showLoader = true;

  @ViewChild("paym") private Paym: PaymentPageComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    customerIdSvc: CustomerIdService,
    private regionsSvc: RegionsService,
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
    private router: Router,
    private swUpdate: SwUpdate,
    private themeSvc: ThemeService,
    private menu: MenuController,
    public payment: PaymentService,
    private zone: NgZone,
    private analy: AnalyticsService,
    private syncService: SyncService,
    private storage: Storage,
    private settingSt: SettingsState,
    private mpay: MobilePayService
  ) {
    var region = this.activatedRoute.snapshot.paramMap.get("region");

    this.initializeApp();
    this.setupReloadOnBack();
    this.monitorForSwUpdates;

    activatedRoute.queryParams
      .pipe(customerIdSvc.cacheCustomerId())
      .subscribe();
      
     

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url = (event as NavigationEnd).url;
        var lightmode = false;
        if (
          url.includes("treat") ||
          url.includes("how-does-treat-me-work") ||
          url.includes("treat-me") ||
          url.includes("au/profile") ||
          url.includes("us/profile") ||
          url.includes("eu/profile") ||
          url.includes("/venue/")
        ) {
          lightmode = true;
        }
        if (url.includes("location")) {
          lightmode = true;
        }
        if (url.includes("/orders")) {
          lightmode = true;
        }
        if (url.includes("/event/") && !url.includes("/prombar/")) {
          lightmode = true;
        }
        if (url.includes("/book/")) {
          lightmode = true;
        }
        if (url.includes("/venue/")) {
          lightmode = true;
        }
        if (url.includes("/csreciept/")) {
          lightmode = true;
        }
        if (url.includes("/guest_list/")) {
          lightmode = true;
        }

        this.themeSvc.lightTheme(lightmode);
        //this is a quick fix untill we can remove oldreliable
        var showmenu = lightmode;
        if (url.includes("/login") || url.includes("/home")) {
          showmenu = true;
        }
        this.menu.enable(showmenu, "oldreliable");
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("capacitor")) {
        StatusBar.setStyle({ style: Style.Dark });
        setTimeout(() => {
          SplashScreen.hide();
        }, 150);
      }

      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        console.log("Open Listner");
        this.zone.run(() => {
          console.log("Zone Run");
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          console.log("Deep Link Incoming: " + event);
          const slug = event.url.split("//").pop();
          console.log("Deep Link Opening: " + slug);
          if (slug) {
            this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
        });
      });
    });
  }

  /**
   * This is to force the app to reload after the back button has been pressed.
   * It is to avoid a back/forward cache issue in safari. See
   * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
   */

  async closenow() {
    if (this.Paym != undefined) {
      console.log("Force Cancell if posisble");
      await this.Paym.CancelPayment();
    }
  }
  private setupReloadOnBack() {
    if (!Capacitor.isNativePlatform()) {
      window.addEventListener("pageshow", (ev) => {
        if (ev.persisted) {
          window.location.reload();
        }
      });
    }
  }

  private monitorForSwUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {
        this.swUpdate
          .activateUpdate()
          .then(() => this.document.location.reload());
      });
    }
  }
}

function getVenueInfo(
  route: ActivatedRouteSnapshot,
  data: Record<string, any>
): Record<string, any> {
  Object.keys(route.data).forEach((key) => (data[key] = route.data[key]));
  return route.children.reduce((acc, child) => {
    const childData = getVenueInfo(child, acc);
    return childData;
  }, data);
}
