<ion-list class="recieptlist">


<ion-item-sliding
*ngFor="let charge of payments; let indexOfelement = index"
>
<ion-item [class.distance]="charge.status == 'pending'">
  <div
    class="vl"
    [class.firstn]="indexOfelement == 0"  
    [class.lastn]="indexOfelement == payments.length - 1"
    slot="start"
  ></div>
  <div slot="start">
    <div class="iconholder" [class]="charge.status">
      <ion-icon
        *ngIf="charge.status != 'refunded'"
        [name]="charge.icon"
      ></ion-icon>
    </div>
    <div
      class="iconholder overtop refundedextra"
      *ngIf="charge.status == 'refunded'"
    >
      <ion-icon name="arrow-undo-outline"></ion-icon>
    </div>
  </div>
  <ion-col class="flex-col">
 
  
    <ion-note color="danger" *ngIf="charge.status == 'refunded'"
      >Refunded</ion-note
    >

    <ion-note class="smallfon" [style.margin-top.px]="-5"  *ngIf="charge.location"
    >Payment On {{ charge.location }}</ion-note
  >

    <ion-label>
      <b>{{ charge.name }}</b>
      <em> - {{ charge.time | date: "shortTime" }}</em>
    </ion-label>
    <ion-note class="smallfon">{{ charge.person }}</ion-note>
  </ion-col>
  <div class="numbers" slot="end">
    <ion-note
        [color]="charge.status == 'refunded' ? 'danger' : ''"
      *ngIf="charge.amount > 0"
    >
      {{ charge.amount | currency: (currency$ | async) }}
    </ion-note>
    <ion-note
      [color]="charge.status == 'refunded' ? 'danger' : ''"
      *ngIf="charge.tip_paid > 0"
      class="smallfon"
    >
      {{ charge.tip_paid | currency: (currency$ | async) }}
    </ion-note>
  </div>
</ion-item>
<ion-item-options side="start" *ngIf="charge.status == 'paid' && recieptId != undefined">
  <ion-item-option color="secondary" (click)="recieptSend(charge)">
    <ion-icon slot="icon-only" name="send-outline"></ion-icon>
  </ion-item-option>
</ion-item-options>
<ion-item-options side="end" *ngIf="charge.refundable && recieptId != undefined">
  <ion-item-option
    *ngIf="charge.status != 'pending'"
    color="danger"
    (click)="refundPayment(charge)"
  >
    <ion-spinner *ngIf="cancelling"></ion-spinner>
    <ion-icon
      slot="icon-only"
      *ngIf="!cancelling"
      name="arrow-undo-outline"
    ></ion-icon>
  </ion-item-option>
  <ion-item-option
    *ngIf="charge.status == 'pending'"
    color="secondary"
    (click)="CancellPayment(charge)"
  >
    <ion-spinner *ngIf="cancelling"></ion-spinner>
    <ion-icon
      slot="icon-only"
      *ngIf="!cancelling"
      name="stop-circle-outline"
    ></ion-icon>
  </ion-item-option>
</ion-item-options>
</ion-item-sliding>
</ion-list>