import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Platform } from "@ionic/angular";
import { RegionsService } from "src/app/services/regions/regions.service";

@Injectable({ providedIn: "root" })
export class WebOnlyGuard implements CanActivate {
  constructor(
    private platform: Platform,
    private router: Router,
    private regionService: RegionsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.platform.is("capacitor")) {
      return true;
    } else {
      var reg = this.regionService.getRegion();

      this.router.navigate([`${reg}/tab/dashboard`]);
      return false;
    }
  }
}
