import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phonescreen',
  templateUrl: './phonescreen.component.html',
  styleUrls: ['./phonescreen.component.scss'],
})
export class PhonescreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
