import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-filter-footer",
  templateUrl: "./filter-footer.component.html",
  styleUrls: ["./filter-footer.component.scss"],
})
export class filterfooter implements OnInit {
  constructor() {}

  ngOnInit() {}
}
