<ion-header>
  <ion-toolbar>
    <ion-title>Open Table</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form #openTableForm="ngForm">
    <ion-input
      type="text"
      autocapitalize="words"
      placeholder="Guest Name"
      required
      #nameinput
      name="guestName"
      [(ngModel)]="guestName"
    ></ion-input>
  </form>
  <ng-container
    *ngIf="(bookings$ | async) != undefined && (bookings$ | async).length> 0"
  >
    <ion-item-divider
      class="ion-margin-top ion-margin-bottom ion-padding-top ion-padding-bottom"
      color="secondary"
    >
      <ion-label>Upcoming Bookings</ion-label>
    </ion-item-divider>
    <app-booking-card
      [booking]="booking"
      *ngFor="let booking of (bookings$ | async)"
      (click)="viewBooking(booking);cancel()"
    ></app-booking-card>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button
      size="large"
      expand="block"
      [disabled]="openTableForm.invalid"
      (click)="openTable()"
    >
      <ion-label color="light">Open Manually</ion-label></ion-button
    >
  </ion-toolbar>
</ion-footer>
