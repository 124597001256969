import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RegionsService } from "../regions/regions.service";


@Injectable({ providedIn: "root" })
export class EventService {
  constructor(private http: HttpClient, private reg: RegionsService) {}

  getActiveEventId() {
    return this.http.get<{ e_id: string | null; r_id: number | null }>(
      `${this.reg.ServerURL()}/event/current_id`
    );
  }
}
