import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import {
  combineLatestAll,
  filter,
  map,
  mapTo,
  pluck,
  share,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import {
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastButton,
  ToastController,
} from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { CognitoUser } from "@aws-amplify/auth";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { Hub, Logger } from "aws-amplify";
import { SyncV1User, SyncV1Venue } from "src/app/models/sync_models";
import { Storage } from "@ionic/storage-angular";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, Unsubscribe } from "firebase/messaging";
import { Router } from "@angular/router";
import {
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { NotificationService } from "../../notification/notification.service";
import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";
import { NotificationBroadcast } from "src/app/models/notification_models";

@Injectable({
  providedIn: "root",
})
export class SettingsState extends BaseSynced {
  state = new RxState<{
    user: SyncV1User;
    cognitoUser: CognitoUser;
    currentVenue: number;
    lastReadNotifications: number;
    lastReadAssignments: number;

    venue: SyncV1Venue;
    deviceToken: string;
    venue_id_checked: boolean;
  }>();

  //fields specific to the service
  service_icon: string = "settings-outline";
  service_title: string = "Settings Store";
  service_id: string = "settings"; //id of the service

  //fields specific to the data
  data_id: string = "key";
  data_detail: string = "value";
  data_expiry: number = 1440; //extended because its a menu

  loaded$ = this.state.select().pipe(
    map((state) => {
      return (
        state.user != undefined &&
        state.venue != undefined &&
        state.cognitoUser != undefined
      );
    })
  );

  venue_checked$ = this.state.select("venue_id_checked");
  subscription: Unsubscribe
  messaging;

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService,
    private auth: AuthClass,
    public storage: Storage,
    private platform: Platform,
    private router: Router,
    private toast: ToastController,
    private notificationSvc: NotificationService
  ) {
    super(syncapis);

    this.state.select("cognitoUser").subscribe((user) => {
      if (user != undefined && user["signInUserSession"] != undefined) {
        console.log(user)
      

        let venue_list =  user["signInUserSession"]["idToken"]["payload"]['dq_venues'].split(' ')
        
        let venues = venue_list.filter(venue => !venue.includes(":false"));
      
        console.log(venues)


        if (
          venues.length == 1 ||
          user["attributes"]["custom:user_type"] == 13 && this.state.get('currentVenue') == undefined
        ) {
          this.state.set({
            currentVenue: Number(venues[0].split(":")[1]),
            venue_id_checked: true,
          });
        }
      }
    });

    this.venueId$.subscribe((venueId) => {
      //save the last venue id to storage
      this.storage.set("lastVenueID", venueId);
    });

    combineLatest([
      this.state.select("cognitoUser"),
      this.state.select("currentVenue"),
    ]).subscribe((user) => {
      if (user != undefined) {
        this.checkIn();
      }
    });

    this.state.select("deviceToken").subscribe((token) => {
      if (token != undefined) {
        this.checkIn();
      }
    });

    this.initNotifications();

    const logger = new Logger("My-Logger");

    Hub.listen("auth", (data) => {
      if (data.payload.event === "signOut") {
        this.state.set({ user: null, cognitoUser: null });
        //need to disable stripe terminals
      }
      if (data.payload.event === "signIn") {
        this.auth.currentAuthenticatedUser().then((user) => {
          let data = { cognitoUser: user };

          this.state.set(data);
        });
      }
    });

    this.storage.create().then((storage) => {
      storage.keys().then((keys) => {
        if (keys.includes("lastReadNotifications")) {
          storage.get("lastReadNotifications").then((val) => {
            this.state.set({ lastReadNotifications: val });
          });
        } else {
          this.readNotifications();
        }
        if (keys.includes("lastReadAssignments")) {
          storage.get("lastReadAssignments").then((val) => {
            this.state.set({ lastReadAssignments: val });
          });
        } else {
          //create date 5 seconds in the future
          this.readAssignments();
        }

        if (keys.includes("lastVenueID")) {
          storage.get("lastVenueID").then((val) => {
            this.venues$
              .pipe(
                filter((x) => x.length > 0),
                take(1)
              )
              .subscribe((venues) => {
                if (venues.includes("venue_" + val)) {
                  this.state.set({ currentVenue: val, venue_id_checked: true });
                  console.log("Prev Venue Identified", val);
                } else {
                  console.log("Venue ID not in Active Venues", val);
                  this.state.set({
                    venue_id_checked: true,
                  });
                }
              });
          });
        } else {
          this.state.set({
            venue_id_checked: true,
          });
        }
      });
    });

    this.auth.currentAuthenticatedUser().then((user) => {
      let data = { cognitoUser: user };
      if (user != undefined) {
        this.state.set(data);
      }
    });

    this.InitStore();
  }

  async checkIn() {
    try {
      if (
        this.state.get("cognitoUser") != undefined &&
        this.state.get("currentVenue") != undefined
      ) {
        let deviceToken = this.state.get("deviceToken");
        let x = await this.syncapis.checkIn(deviceToken).toPromise();
        this.state.set({
          user: x.user,
          venue: x.venue,
        });
      }
    } catch (e) {
      this.serviceState.set({ in_error: true });
    }
  }

  readNotifications() {
    this.state.set({ lastReadNotifications: new Date().getTime() });
    this.storage.set("lastReadNotifications", new Date().getTime());
  }

  readAssignments() {
    //create date 5 seconds in the future
    let futda = new Date();
    futda.setSeconds(futda.getSeconds() + 0.1);
    this.state.set({ lastReadAssignments: futda.getTime() });
    this.storage.set("lastReadAssignments", futda.getTime());
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  async Sync() {
    console.log(
      "%c init " + this.service_id,
      "background: #00000; color: #FF0000"
    );

    if (this.serviceState.get("in_error")) {
      this.checkIn();
    }

    return "success";
    return "uness";
  }
  //get an array of the data in the state map for debug purposes
  //it should return like [{key: "key", value: "value"}, {key: "key", value: "value"}}]
  getItems$ = this.state.select().pipe(
    map((stateData) => {
      const itemsArray = [];
      for (const key in stateData) {
        if (stateData.hasOwnProperty(key)) {
          itemsArray.push({ key, value: stateData[key], ...stateData[key] });
        }
      }
      return itemsArray;
    })
  );

  UpdateState(newState) {
    this.state.set(JSON.parse(newState));
  }
  GetState() {
    return JSON.stringify(this.state.get());
  }

  venueId$ = this.state.select("currentVenue");
  currentUser$ = this.state.select("cognitoUser");

  currentClaims$ = combineLatest([this.currentUser$, this.venueId$]).pipe(
    map(([user, venueId]) => {
      if (user != undefined && venueId != undefined) {
        let venue_claims =
          user["signInUserSession"]["idToken"]["payload"]["venue:" + venueId];
        return venue_claims.split(" ");
      } else {
        return [];
      }
      
    }),
  );

  hasClaim$ = (claim: string | string[]) =>
    this.currentClaims$.pipe(
      map((x) => {
        if (x != undefined) { 
          if (Array.isArray(claim)) {
            return claim.every((c) => x.includes(c));
          } else {
            return x.includes(claim);
          }
        } else {
          return false;
        }
      }),
    );

  hasClaim(claim: string | string[]) {
    let venueId = this.state.get("currentVenue");
    let user = this.state.get("cognitoUser");
    let claims = user["signInUserSession"]["idToken"]["payload"]["venue:" + venueId]

    return claims.includes(claim);
  }



  user$ = this.state.select("user");
  venue$ = this.state.select("venue");
  venues$ = this.state.select("cognitoUser").pipe(
    map((user) => {
      if (user != undefined) {
        let groups =
          user["signInUserSession"]["accessToken"]["payload"]["cognito:groups"];
        let venues = groups.filter((group) => !group.includes("ever"));
        return venues;
      }
    })
  );

 
  free_access$ = this.hasClaim$('manage_assignments')

  lastReadNotifications$ = this.state.select("lastReadNotifications");
  lastReadAssignments$ = this.state.select("lastReadAssignments");
  currencyCode$ = this.state.select("venue").pipe(pluck("currency_code"));
  collectionPoints$ = this.state
    .select("venue")
    .pipe(pluck("collection_points"));

  async initNotifications() {
    if (
      this.platform.is("desktop") &&
      !window.location.href.includes("local.getdqd.com") &&
      !this.platform.is("capacitor")
    ) {
      const app = initializeApp({
        apiKey: "AIzaSyAsJrudep2BnYThbWuJ4T36a1pdZpVR8HA",
        authDomain: "dq-admin.firebaseapp.com",
        databaseURL: "https://dq-admin.firebaseio.com",
        projectId: "dq-admin",
        storageBucket: "dq-admin.appspot.com",
        messagingSenderId: "423361901692",
        appId: "1:423361901692:web:fa8eba05eb994ee7c105f9",
        measurementId: "G-WJKVHSNN0H",
      });
      console.log("On web");
      this.messaging = getMessaging(app);

      this.subscription = onMessage(this.messaging, async (payload) => {
        console.log("Notification received. ", payload);
        console.log(payload);
        let notification = payload.data as any;
        this.handleDesktopNotification(notification);
      });


      let permission = await Notification.requestPermission();

      if (permission === "granted") {
        console.log("Notification permission granted.");

        let currentToken = await getToken(this.messaging, {
          vapidKey:
            "BBxmW4HzyVHiJxW5Zib6VSe1WDMd_SgvrFdVXUUWNTG4PW8iJ_HjpibfZ3-XyweDfBHX_cSmlEMLQtH2c1tG0SI",
        });
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          console.log("Token Web Generated", currentToken);
          this.state.set({ deviceToken: currentToken });
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      } else {
        console.log("Unable to get permission to notify.", permission);
      }
    }

    //if is capacitor
    if (this.platform.is("capacitor") && !this.platform.is("desktop")) {
      await PushNotifications.removeAllListeners()
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          this.handleMobileNotification(notification);
        }
      );

      const actionPerformedListener = PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (action) => {
          if (
            action.notification.data &&
            action.notification.data.deep_link &&
            action.notification.data.deep_link != ""
          ) {
            console.log("pushNotificationActionPerformed", action);
            this.router.navigate([action.notification.data.deep_link]);
          }
          this.handleMobileNotification(action.notification);
        }
      );

      const listener = PushNotifications.addListener(
        "registration",
        (token) => {
          this.state.set({ deviceToken: token.value });
        }
      );

      let registerres = await PushNotifications.requestPermissions();
      if (registerres.receive == "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    }
  }
  async handleMobileNotification(notification) {
    console.log("Handling mobile notification", notification);

    if (notification.data.sticky != undefined) {
      const DQNotif = notification.data as NotificationBroadcast;
      console.log("notification", notification);
      // Only show the accept button if there is a notification id in the message
      let buttons: ToastButton[] = !!DQNotif.accept_link
        ? [
            {
              text: "Accept",
              handler: async () => {
                toast.dismiss();
                await this.acceptNotification(DQNotif.accept_link);
              },
            },
            {
              role: "cancel",
              icon: "close-circle-outline",
              cssClass: "toast-close-button",
            },
          ]
        : [
            {
              icon: "close-circle-outline",
              role: "cancel",
              cssClass: "toast-close-button",
            },
          ];

      //if there is an associated click_action with the push notification
      if (notification.click_action && notification.click_action != "") {
        buttons = [
          {
            text: "View",
            icon: "eye-outline",
            handler: () => {
              toast.dismiss();
              this.router.navigate([notification.click_action]);
            },
          },
          ,
          ...buttons,
        ];
      }

      let toastSettings: any = {
        header: DQNotif.title,
        message: DQNotif.message,
        duration: (DQNotif.sticky as any) == "true" ? 20000 : 4000,
        buttons,
        position: "top",
      };

      try {
        if ((DQNotif.sticky as any) == "true") {
          await Haptics.notification({ type: NotificationType.Error });
        } else {
          await Haptics.impact({ style: ImpactStyle.Medium });
        }
      } catch (e) {
        console.log(e);
      }
      const toast = await this.toast.create(toastSettings);
      return toast.present();
    } else {
      const { id, title, message, toastDuration } = notification.data;
      // Only show the accept button if there is a notification id in the message
      let buttons: ToastButton[] = !!id
        ? [
            {
              text: "Accept",
              handler: () => {
                toast.dismiss();
                this.acceptNotification(id);
              },
            },
            {
              role: "cancel",

              icon: "close-circle-outline",
              cssClass: "toast-close-button",
            },
          ]
        : [
            {
              role: "cancel",
              icon: "close-circle-outline",
              cssClass: "toast-close-button",
            },
          ];

      //if there is an associated click_action with the push notification
      if (notification.click_action && notification.click_action != "") {
        buttons = [
          {
            text: "View",
            icon: "eye-outline",
            handler: () => {
              toast.dismiss();
              this.router.navigate([notification.click_action]);
            },
          },
          ,
          ...buttons,
        ];
      }

      let toastSettings: any = {
        header: title,
        position: "top",
        message,
        buttons,
      };

      if (toastDuration) {
        toastSettings = {
          ...toastSettings,
          duration: toastDuration,
        };
      }

      const toast = await this.toast.create(toastSettings);
      return toast.present();
    }
  }

  async handleDesktopNotification(notification) {
    console.log("Handling desktop notification", notification);

    let buttons: ToastButton[] =
      notification.id != undefined
        ? [
            {
              text: "Accept",
              handler: () => {
                toast.dismiss();
              },
            },
            {
              role: "cancel",
              icon: "close",
              cssClass: "toast-close-button",
            },
          ]
        : [
            {
              role: "cancel",
              icon: "close",
              cssClass: "toast-close-button",
            },
          ];

    //if there is an associated click_action with the push notification
    if (notification.click_action && notification.click_action != "") {
      buttons = [
        {
          text: "View",
          handler: () => {
            toast.dismiss();
            this.router.navigate([notification.click_action]);
          },
        },
        ,
        ...buttons,
      ];
    }

    let toastSettings: any = {
      header: notification.title,
      message: notification.message,
      buttons,
      position: "top",
    };

    const toast = await this.toast.create(toastSettings);
    return toast.present();
    // ...
    /*this.toast
  .create({
    message: payload.notification.body,
    duration: 3000,
  })
  .then((toast) => {
    toast.present();
  }); */
  }

  async acceptNotification(url: string) {
    try {
      let data = await this.notificationSvc.acceptNotification(url).toPromise();
      const message =
        (data as any).status == "OK"
          ? (data as any).message
          : "There was an error accepting the notification";

      let toastSettings: any = {
        message: message,
        buttons: [
          {
            text: "Close",
            role: "cancel",
            cssClass: "toast-close-button",
          },
        ],
        duration: environment.defaultToastDuration,
      };

      const toast = await this.toast.create(toastSettings);
      await toast.present();
    } catch (e) {
      console.log(e);
      let toastSettings: any = {
        message: "There was an error accepting the notification",
        buttons: [
          {
            text: "Close",
            role: "cancel",
            cssClass: "toast-close-button",
          },
        ],
        duration: environment.defaultToastDuration,
      };

      const toast = await this.toast.create(toastSettings);
      await toast.present();
    }
  }

  getPrefix() {
    let venue = this.state.get("venue");
    if (
      venue != undefined &&
      venue.default_prefix != undefined &&
      venue.default_prefix != ""
    ) {
      return venue.default_prefix;
    } else {
      return this.reg.getPrefix();
    }
  }
}
