<ion-item
  [color]="light ? 'dark' : 'secondary'"
  [class.ion-margin-horizontal]="margin"
  (click)="exitable ? exit.emit() : null"
  button
  detail="false"
>
  <ion-label>
    <ion-note position="stacked" *ngIf="package.included_guests"
      >Up to {{ package.included_guests }} Guests</ion-note
    >
    
    <ion-badge
      class="soldbadge"
      size="large"
      *ngIf="!showAvail && package.chip"
      >{{ package.chip }}</ion-badge
    >

   
      <ion-label class="title"> {{ package.name }}</ion-label>

     
    
  </ion-label>

  <ion-label slot="end" class="title" *ngIf="price">{{
    package.min_customer_liability | currency: (currency$ | async)
  }}</ion-label>
  <ng-content></ng-content>
  <ion-icon   *ngIf="exitable" slot="end" color="light" name="close-outline"></ion-icon>


</ion-item>
