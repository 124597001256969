import { Injectable, NgZone } from "@angular/core";
import { Platform } from "@ionic/angular";
import { defer, Observable, race } from "rxjs";
import { filter, map, skipUntil, switchMap } from "rxjs/operators";
import { SquarePaymentRequestParams } from "src/app/utils/capacitor-types";
import { wrapInZone } from "src/app/utils/wrap-in-zone";
import { environment } from "src/environments/environment";
import { RegionsService } from "../regions/regions.service";

@Injectable({
  providedIn: "root",
})
export class SquarePaymentsService {
  constructor(private platform: Platform, private zone: NgZone) {}

  pay(args: SquarePaymentRequestParams) {
    /* if (!this.platform.is("capacitor")) {
      throw new Error("Square Integration is only possible in Capacitor Apps");
    }
    const pay$ = this.platform.is("ios")
      ? this.payIos(args)
      : this.payAndroid(args);
    return race([
      // On Android, when onAppStateChange$ is subscribed to, it immediately
      // emits.  Using skipUntil isActive === false ensure that this does
      // nothing until the app goes inactive and will then emit if it becomes
      // active again.
      this.onAppStateChange$.pipe(
        skipUntil(
          this.onAppStateChange$.pipe(filter(({ isActive }) => !isActive))
        ),
        filter(({ isActive }) => isActive)
      ),
      pay$,
    ]).pipe(
      wrapInZone(this.zone),
      map(response => {
        if ("transactionId" in response || "clientTransactionId" in response) {
          return response;
        }
        throw new Error("Square Payment was not completed");
      })
    ); */
  }

  private payAndroid(args: SquarePaymentRequestParams) {
    //return defer(() => SquarePayments.payAndroid(args));
  }

  private payIos(args: SquarePaymentRequestParams) {
    /*const onAppUrlOpen$ = new Observable<AppUrlOpen>(observer => {
      const cb = App.addListener("appUrlOpen", data => observer.next(data));
      return () => cb.remove();
    });

    return defer(() => SquarePayments.payIos(args)).pipe(
      switchMap(() => onAppUrlOpen$),
      switchMap(async response => {
        if (
          !(
            "url" in response &&
            decodeURIComponent(response.url).startsWith(
              environment.squareCallbackUrl
            )
          )
        ) {
          throw new Error("Square Payment was not completed");
        }

        const parseResult = await SquarePayments.validateResponse({
          url: response.url,
        });
        return parseResult;
      })
    ); */
  }
}
