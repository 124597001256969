import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterItems",
})
export class FilterItemsPipe implements PipeTransform {
  transform<T extends Record<string, any>>(
    items: T[],
    filterString: string,
    keyName: keyof T = "name"
  ): T[] {
    return !!filterString
      ? items.filter((item) =>
          (item[keyName] as string)
            ?.toLocaleLowerCase()
            .includes(filterString.toLocaleLowerCase())
        )
      : items;
  }
}
