import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { withCache } from "@ngneat/cashew";
import { DqConsumerTabCredit } from "src/app/pages/consumer-event/services/consumer-event/consumer-event.service";
import { environment } from "src/environments/environment";
import { DqCurrencyDetails } from "../auth/auth.service";
import { RegionsService } from "../regions/regions.service";

export interface DqConsumerMenuItem {
  is_featured: boolean;
  is_mixer: boolean;
  item_id: number;
  media: string | null; // null
  menu_cat_id: number;
  name: string; // "Coopers Pale Ale"
  price: string; // "9.0"
  thumbnail?: string;
  i_user_input_price?: boolean;
}
export interface DqConsumerMenuCategory {
  is_mixer: boolean;
  menu_cat_id: number;
  name: string;
}

export interface ConsumerTableGetMenuResponse {
  categories: DqConsumerMenuCategory[];
  items: DqConsumerMenuItem[];
  super_categories: {
    name: string;
    menu_cat_ids: number[]; // [6513, 6514, 6515, 6516, 6517, 6518, 6520, 6522, 6523, 6524, 6525, 6526],
    sort_order: number; //0
  }[];
  toast_message: string;
}

export interface DqVenueSurcharge {
  v_sc_active_utc_gt: string; // "2038-01-01T00:00:00Z";
  v_sc_active_utc_lte: string; // "1980-01-01T00:00:00Z";
  v_sc_fixed: string; // "0.3000";
  v_sc_ratio: string; // "0.01750000";
  v_sc_total_gt: string; // "10.0000";
  v_sc_total_lte: string; // "0.0000";
}

export interface DqConsumerVenueAddendumCheckbox {
  cb_default_state: boolean; //
  cb_key: string; // "venue_consent",
  cb_text: string; // "OK for Far Bar to let me know about upcoming events?"
}

export interface DqConsumerVenueInfo {
  add_to_order_text: "ADD TO ORDER" | "SELECT FOR DONATE" | string; // "something else..."
  addendum_checkboxes: DqConsumerVenueAddendumCheckbox[];
  checkin_email_is_required: boolean; // true;
  checkin_privacy: string;
  menu_header_text: "MENU" | null;
  is_event_venue: boolean; // false,
  locale: DqCurrencyDetails;
  logo_image: string;
  menu_header_image: string | null; //"https://dq-static-media.s3.amazonaws.com/72ffb8552feccc957e0fbd04db09c9ef" | null,
  name: string; // "Test Venue";
  order_details_text: "ORDER DETAILS" | "DONATION DETAILS" | string; // "something else...",
  payment_button_text: string;
  show_tip: boolean; // true,
  splash_image_thumbnail: string; // "/v4/venue/1/splash-thumb";
  splash_image: string; // "/v4/venue/1/splash";
  stripe_account_id: string;
  sub_title: string; // "DQ DQ DQ Subtitle";
  success_text: string;
  surcharge_header_text: string; // "Additional",
  surcharge_item_text: string; // "Service",
  surcharges: DqVenueSurcharge[];
  tab_credit_header_text: string;
  tab_credit_item_text: string;
  tab_credit_placeholder_text: string;
  tags: string[]; // ["Mexican", "Chicken", "Vegetarian"]
  terms_and_conditions: string;
  theme_css: string; // "/v4/venue/1/theme-css";
  title: string; // "Test Venue";
  v_id: number; // 1;
  v_supports_tab_credit: boolean;
  v_gifts_enabled: boolean;
  v_uuid: string; // "af57xszix62ikls4updhhzmyg63yhytgialjsh7u7thzw2whxeoa",
  view_cart_text: "VIEW CART" | "VIEW DONATION" | string; // "..."
}

export interface DqConsumerTableInfo {
  dispense_id: number; // 99,
  dispense_name: number; // "Rooftop",
  location: string; // "Rooftop C1"
  nested_menu: boolean;
  notification_text: string;
  service_text: string; // "Delivered to Rooftop C1"
  service: "delivery" | "pickup";
  show_change_location: boolean; // true,
  show_checkin: boolean;
  show_menu: boolean;
  show_treat: boolean;
  table_details_header:
    | "DELIVERY DETAILS"
    | "PICKUP DETAILS"
    | "YOUR TABLE"
    | string; // "some other string..."
}

export interface DqConsumerGetTableInfoResponse {
  table: DqConsumerTableInfo;
  venue: DqConsumerVenueInfo;
}

export interface DqConsumerMixture {
  mix_id: number; // 33
  name: string; // "Cola"
  price: string; // "0.0"
}

export interface DqConsumerGarnish {
  gar_id: number; // 68
  name: string; // "Shot"
  price: string; // "0.00"
}

export interface DqConsumerModifier {
  mod_id: number; // 1051,
  name: string; // "Vegan Option",
  price: string; // "0.0000"
}

export interface DqConsumerModifierGroup {
  mg_description: string; // "You can select one or more.",
  mg_id: number; // 196,
  mg_max_selections: number | null;
  mg_min_selections: number | null;
  modifiers: DqConsumerModifier[];
  mg_name: string; // "garnish"
}

export interface DqConsumerItemAdditionsResponse {
  /** @deprecated */
  mixers: DqConsumerMixture[];
  /** @deprecated */
  garnishes: DqConsumerGarnish[];
  modifier_groups: DqConsumerModifierGroup[];
}

interface DqConsumerOrderDetailsParams {
  table_or_gift_id: string;
  order_details: {
    item_id: number;
    quantity: number;
    comment?: string;
    modifier_groups?: { mg_id: number; mod_ids: number[] }[];
  }[];
  gratuity?: number;
  aws_identity_id: string;
  tab_credit_codes: string[];
}

export interface DqConsumerItemDetails {
  i_desc: string; // "A sweet and subtle take on the classic Negroni. With elements of apricot, plum and rhubarb, this royal lady will have you coming back for more",
  image: string; // "https://dq-static-media.s3.amazonaws.com/61d2edeaab1e8b789905102953786b34",
  is_featured: boolean; // true,
  is_mixer: boolean; // false,
  item_id: number; //
  418;
  media: string | null; // null,
  menu_cat_id: number; // 455,
  name: string; // "Sweet Contessa",
  price: string; // "24.0"
}

export interface DqConsumerVerifyCouponCodesResponse {
  tab_credits: DqConsumerTabCredit[];
}

@Injectable({
  providedIn: "root",
})
export class ConsumerTableService {
  constructor(private http: HttpClient, private reg: RegionsService) {}

  getMenuForTable(tableId: string, cache: boolean = false) {
    return this.http.get<ConsumerTableGetMenuResponse>(
      `${this.reg.ServerURL()}/v4/table/${tableId}/menu`,
      {
        context: withCache(),
      }
    );
  }

  getTableInfo(tableId: string, cache: boolean = false) {
    return this.http.get<DqConsumerGetTableInfoResponse>(
      `${this.reg.ServerURL()}/v4/table/${tableId}`,
      {
        context: withCache({
          ttl: 500000,
        }),
      }
    );
  }

  getMixturesForItem(itemId: string, cache: boolean = false) {
    return this.http.get<DqConsumerItemAdditionsResponse>(
      `${this.reg.ServerURL()}/v4/item/${itemId}/additions`,
      {
        context: withCache(),
      }
    );
  }

  getItemDetails(itemId: string, cache: boolean = false) {
    return this.http.get<DqConsumerItemDetails>(
      `${this.reg.ServerURL()}/v4/item/${itemId}`,
      {
        context: withCache(),
      }
    );
  }

  getVenueTerms(termsUrl: string) {
    return this.http.get(termsUrl, { responseType: "text" });
  }

  getBindingQuote(params: DqConsumerOrderDetailsParams) {
    return this.http.post(
      `${this.reg.ServerURL()}/v4/orders/binding_quote`,
      params,
      { withCredentials: true }
    );
  }

  getBindingQuoteV2(params: DqConsumerOrderDetailsParams) {
    return this.http.post<{
      session_id: string;
      id: string;
      o_uuid?: string;
      route_to: "stripe" | "internal_url" | string;
      url?: string;
    }>(`${this.reg.ServerURL()}/v5/orders/binding_quote`, params, {
      withCredentials: true,
    });
  }

  deleteBindingQuote(orderId: string) {
    return this.http.delete<any>(
      `${this.reg.ServerURL()}/v4/orders/${orderId}/binding_quote`,
      { withCredentials: true }
    );
  }

  initOrder(params: {
    orderUuid: string;
    customer_name?: string;
    customer_email?: string;
    customer_phone?: string;
    customer_push_url?: string;
    [key: string]: string;
  }) {
    const { orderUuid, ...body } = params;
    return this.http.post<{
      session_id: string;
      o_uuid?: string;
      route_to: "stripe" | "internal_url" | string;
      url?: string;
    }>(`${this.reg.ServerURL()}/v4/orders/${orderUuid}/init`, body, {
      withCredentials: true,
    });
  }

  getCouponCode({ code, tableId }: { code: string; tableId: string }) {
    return this.http.get<DqConsumerTabCredit>(
      `${this.reg.ServerURL()}/v4/table/${tableId}/tab_credit/${code}`
    );
  }

  verifyCouponCodes({
    tableId,
    codes,
  }: {
    tableId: string;
    codes: DqConsumerTabCredit[];
  }) {
    return this.http.get<DqConsumerVerifyCouponCodesResponse>(
      `${this.reg.ServerURL}/v4/table/${tableId}/tab_credits_applicable/${codes
        .map((c) => c.tc_lookup_key)
        .join(",")}`
    );
  }
}
