import { Component, OnInit, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";

@Component({
  selector: "app-onboarding-modal",
  templateUrl: "./onboarding-modal.component.html",
  styleUrls: ["./onboarding-modal.component.scss"],
})
export class OnboardingModalComponent implements OnInit {
  @ViewChild("slides", { static: false }) slides: IonSlides;
  constructor(public modalcrt: ModalController) {}

  ngOnInit() {}

  next() {
    this.slides.getActiveIndex().then((index) => {
      if (index + 1 == 3) {
        this.modalcrt.dismiss();
      }
      this.slides.slideTo(index + 1);
    });
  }
}
