<ion-item-sliding
  (ionDrag)="dragging($event, item)"
  #item
  ionSwipe="swipeStaffMember(staffMember,$event)"
>
  <ion-item
    color="secondary"
    [disabled]="editing == false && staffMember.is_enabled == false"
  >
    <ion-label class="name" button color="">
      {{ staffMember?.full_name }}
      <br />
      <div class="opened_at" *ngIf="staffMember?.phone_num != ''">
        {{ staffMember?.phone_num }}<br />
        {{ staffMember?.email }}
      </div>
    </ion-label>

    <ion-note slot="end" [style.margin-right.px]="0"  *ngIf="longInactive"> Inactive {{staffMember.last_active_at |timeAgo: 'reldays' }} </ion-note>


    <ion-spinner *ngIf="loading"></ion-spinner>

    <ion-toggle
    [style.padding-left.px]="10"
      class="enabled"
      slot="end"
      name="enabled"
      color="primary"
      [(ngModel)]="staffMember.is_enabled"
      [checked]="staffMember.is_enabled"
      (ngModelChange)="toggleEnabled(staffMember)"
    ></ion-toggle>
    <ion-button
    
      slot="end"
      [id]="'edituser' + staffMember.cog_user_name"
      color="secondary"
      *ngIf="isDesktop && editing && hideButtons == false"
    >
      <ion-icon
        color="light"
        slot="icon-only"
        name="ellipsis-horizontal"
      ></ion-icon
    ></ion-button>
    <ion-popover
      [trigger]="'edituser' + staffMember.cog_user_name"
      [animated]="true"
      [dismissOnSelect]="true"
      [backdropDismiss]="true"
    >
      <ng-template>
        <ion-content>
          <ion-item (click)="changeRoles()">
            Change Roles <ion-icon slot="end" name="person-outline"></ion-icon
          ></ion-item>
          <ion-item (click)="renameUser()">
            Rename User <ion-icon slot="end" name="create-outline"></ion-icon
          ></ion-item>
          <ion-item (click)="restUser()">
            Reset User <ion-icon slot="end" name="key-outline"></ion-icon
          ></ion-item>
          <ion-item (click)="deleteUser()">
            Delete User <ion-icon slot="end" name="trash-outline"></ion-icon
          ></ion-item>
          
        </ion-content>
      </ng-template>
    </ion-popover>
    <ng-content></ng-content>
  </ion-item>
  <ion-item-options *ngIf="editing && !isDesktop">
    <ion-item-option color="primary" (click)="presentOptions(); item.close()">
      <ion-icon
        color="light"
        slot="icon-only"
        name="ellipsis-horizontal"
      ></ion-icon>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
