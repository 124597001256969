import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Assignment,
  SyncV1TagDefinition,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class MetricState extends BaseSynced {
  state = new RxState<{ [key: string]: any }>();

  //fields specific to the service
  service_icon: string = "analytics-outline";
  service_title: string = "Metric Store";
  service_id: string = "Metric"; //id of the service
  service_url: string = undefined;

  //fields specific to the data
  data_id: string = "key";
  data_detail: string = "name";

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);

    //add data to the state
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(
    map((assignments) => Object.values(assignments))
  );
  //get the menu items as an array

  guest_metric$ = (id) =>
    this.state.select("metric_" + id).pipe(
      map((x) => {
        console.log("MetricTesT", x);
        if (x.length == 0 || x == undefined) {
          return undefined;
        }

        let y = {};

        x.forEach((element) => {
          y[element.metric_or_tag_name.replace("metric_", "")] = element.value;
        });

        return y;
      })
    );
  guest_history$ = (id) => this.state.select("history_" + id);
  guest_tags$ = (id) => this.state.select("tags_" + id);

  async Sync() {
    if (this.serviceState.get().refreshing) {
      return "uness";
    }

    return "success";
  }
}
