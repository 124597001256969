import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Haptics, NotificationType } from "@capacitor/haptics";
import { ModalController } from "@ionic/angular";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  combineLatestWith,
  map,
  withLatestFrom,
} from "rxjs";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { EventEmitter } from "stream";

@Component({
  selector: "app-tag-search",
  templateUrl: "./tag-search.component.html",
  styleUrls: ["./tag-search.component.scss"],
})
export class TagSearchComponent implements OnInit, OnChanges {
  @Input() tags: string[] = [];

  @Output() tagsUpdated = new BehaviorSubject<string[]>(null);
  tag_groups$ = this.tagSt.tag_groups$.pipe(
    combineLatestWith(this.tagsUpdated),
    map(([tag_groups, _]) => {
      tag_groups = structuredClone(tag_groups);

      Object.keys(tag_groups).forEach((key) => {
        tag_groups[key] = tag_groups[key].map((x) => {
          this.tags.includes(x.tag_id)
            ? (x.selected = true)
            : (x.selected = false);

          return x;
        });
      });
      return tag_groups;
    })
  );

  constructor(
    private region: RegionsService,
    private tagSt: TagState,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    Haptics.selectionStart ()
    //log output of tableDetail
    //  <ion-note slot="end" *ngIf="subtitle != undefined">{{ subtitle }}</ion-note>
  }

  selectTag(tag) {
    Haptics.selectionChanged()
    console.log("tag", tag);
    //add tag to tags
    if (this.tags.includes(tag.tag_id)) {
      this.tags = this.tags.filter((x) => x != tag.tag_id);
    } else {
      this.tags.push(tag.tag_id);
    }
    this.tagsUpdated.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tagsUpdated.next(null);
  }

  dismiss() {
    Haptics.selectionEnd()
    Haptics.notification({
      'type': NotificationType.Success
    })
    this.modalCtrl.dismiss(this.tags);
  }
}
