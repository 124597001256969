import { Pipe, PipeTransform } from "@angular/core";
import { DateTime, ToRelativeUnit } from "luxon";
import { BehaviorSubject } from "rxjs";

@Pipe({
  name: "timeAgo",
})
export class TimeAgoPipe implements PipeTransform {
  transform(
    timeString: string,
    fallbackFormat: string = "d MMM yy - h:mm a",
    updateTrigger?: number | BehaviorSubject<number>
  ): string {
    const time = DateTime.fromISO(timeString);
    const now = DateTime.local();

    let canrelative = fallbackFormat.includes("limited") ? now.diff(time, "hours").hours < 2  : now.diff(time, "days").days < 2
    if (canrelative) {
      const diffInSeconds = now.diff(time, 'seconds').seconds;
      if (diffInSeconds < 60) {
        return 'just now';
      }
      return time.toRelative({
        style: 'short',
      });
    } else if (fallbackFormat.includes("rel")) {
      return time.toRelative({
        style: 'short',
        
        unit: fallbackFormat.replace("rel", "") as ToRelativeUnit
      }).replace(" ago", "");
    } else {
      
      const hoursDiff = now.diff(time, 'hours').hours;
      if (hoursDiff > 12) {
        return time.toFormat("d MMM h:mm");
      } else {
        return time.toFormat("h:mm");
      }
    }
  }
}
