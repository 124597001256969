import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ModalController, NavController } from "@ionic/angular";

import { Event } from "../../models/bookings";
import { RegionsService } from "../../services/regions/regions.service";
import { SyncV1Event } from "src/app/models/sync_models";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";

@Component({
  selector: "app-event-card",
  templateUrl: "./event-card.component.html",
  styleUrls: ["./event-card.component.scss"],
})
export class EventCardComponent {
  @Input() event: SyncV1Event;
  @Input() can_book: boolean = true;
  @Input() clickable: boolean = true;
  
  tag$ = this.tagSt.tag$

  @Output() clicked = new EventEmitter();

  constructor(public modalController: ModalController, private tagSt: TagState) {}
}
