import { Actor } from "./mapactor.graphic";
import { ToolBar } from "./toolbar.graphic";
import {
  Application,
  Container,
  Sprite,
  Texture,
  DisplayObject,
} from "pixi.js";
import { GlowFilter } from "@pixi/filter-glow";
import { MapObject, Scale } from "../../../models/map_editor_models";
import { Subject } from "rxjs";
import { MapComponent } from "../map.component";
import { ObservablePoint } from "pixi.js";
export class Table extends Actor {
  //inputs include the x and y coordinates of the table (defaults to middle of screen)
  //the table type,
  //the number of seats,
  //and the table ID
  data: any | null;
  public mapObject: MapObject;
  public HoverBar: Container;
  public CurrentlySelected: boolean = false;
  private SelectedTable: Subject<Table>;
  private Editing: boolean;
  private viewport: MapComponent;
  private Disabled: Subject<
    {
      table_id: string;
      status: "disabled" | "primary" | "secondary";
    }[]
  >;
  constructor(
    InputObject: MapObject,
    Selected: Subject<any>,
    Editing: boolean,
    viewport: MapComponent,
    Disabled: Subject<
      {
        table_id: string;
        status: "disabled" | "primary" | "secondary";
      }[]
    >
  ) {
    super();
    this.SelectedTable = Selected;
    this.mapObject = InputObject;
    this.x = InputObject.x;
    this.y = InputObject.y;
    this.angle = InputObject.rotation;
    this.scale = InputObject.scale as ObservablePoint;
    this.interactive = true;
    this.Editing = Editing;
    this.viewport = viewport;
    this.Disabled = Disabled;

    Selected.subscribe((next) => {
      if (this.CurrentlySelected == true && next != this) {
        this.DeSelect();
      }
      if (next == this && this.CurrentlySelected == false) {
        this.onSelect();
      }
    });

    Disabled.subscribe((next) => {
      let d = this.mapObject.Data as any;
      if (d.table_id != undefined) {
        let findTablS = next.find((x) => x.table_id == "table_" + d.table_id);

        if (findTablS == undefined) {
          findTablS = {
            table_id: "",
            status: "primary",
          };
        }
        if (findTablS.status.includes("disabled")) {
          this.alpha = 0.6;
        } else {
          this.alpha = 1;
        }

        this.beginFill(0xffffff);
        if (findTablS.status.includes("secondary")) {
          //hide the first child and show the second
          this.children[0].visible = false;
          this.children[1].visible = true;
        } else {
          this.children[0].visible = true;
          this.children[1].visible = false;
        }
        this.endFill();
      }
    });

    this.onpointertap = function (e) {
      this.onSelect();
    };
    this.onpointerdown = function (e) {
      this.onSelect();
    };
    this.onpointerdown = function (e) {
      const sprite = e.currentTarget;
      const viewport = sprite.parent as any;
      this.alpha = 0.5;
      this.dragging = true;
      this.data = e.data;

      if (this.Editing) {
        viewport.drag({ pressDrag: false });
      }
      this.Selected = this;
    };
    this.onpointerup = function (e) {
      const sprite = e.currentTarget;
      const viewport = sprite.parent as any;
      this.data = e.data;
      this.alpha = 1;
      this.dragging = false;
      viewport.drag();
      this.Selected = this;
    };
    this.onpointerupoutside = function (e) {
      const sprite = e.currentTarget;
      const viewport = sprite.parent as any;
      this.data = e.data;
      this.alpha = 1;
      this.dragging = false;
      viewport.drag();
      this.Selected = this;
    };

    this.onglobalpointermove = function (e) {
      if (this.dragging) {
        if (this.Editing) {
          const newPosition = this.data!.getLocalPosition(this.parent);
          this.x = newPosition.x;
          this.y = newPosition.y;
          this.Selected = this;
        }
      }
    };

    this.beginFill(0xffffff);

    var texture = Texture.from(
      `/assets/MapItems/${this.mapObject.mapItem.svg}.svg`
    );
    var sprite = new Sprite(texture);
    sprite.anchor.set(0.5, 0.5);

    this.addChild(sprite as any);

    var t2 = Texture.from(
      `/assets/MapItems/dis/${this.mapObject.mapItem.svg}.svg`
    );
    var s2 = new Sprite(t2);
    s2.anchor.set(0.5, 0.5);
    this.addChild(s2 as any);
    s2.visible = false;

    this.endFill();
  }

  getMapObject(): MapObject {
    this.mapObject.x = this.x;
    this.mapObject.y = this.y;
    this.mapObject.scale = { x: this.scale.x, y: this.scale.y };
    this.mapObject.rotation = this.angle;
    return this.mapObject;
  }

  onSelect() {
    if (this.CurrentlySelected == true && this.Editing == true) {
      this.DeSelect();
    } else {
      if (this.CurrentlySelected == false) {
        this.CurrentlySelected = true;

        this.filters = [
          new GlowFilter({
            color: 0x494949,
            distance: 12,
            outerStrength: 2,
            quality: 1,
          }) as any,
        ];
        //get current value of selected table, if selected table is this objec
        //this.viewport.ZoomToObject(this as unknown as DisplayObject);
        this.SelectedTable.next(this);
      }
    }
  }

  DeSelect() {
    this.CurrentlySelected = false;
    this.filters = [];
  }
}
