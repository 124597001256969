import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "appFlatMap",
})
export class FlatMapPipe implements PipeTransform {
  transform(value: any[], key: string): any[] {
    return Array.isArray(value) ? value.flatMap((v) => v[key]) : null;
  }
}
