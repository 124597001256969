import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "arraySome",
})
export class ArraySomePipe implements PipeTransform {
  transform(array: any[], key: string): boolean {
    if (!(Array.isArray(array) && typeof key === "string")) {
      return false;
    }
    return array.some((item) => typeof item === "object" && !!item[key]);
  }
}
