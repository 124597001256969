import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { BaseSynced } from "../base-synced/base.service";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { SyncV1Seating } from "src/app/models/sync_models";
import { combineLatest } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SessionState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Seating }>();

  //fields specific to the service
  service_icon: string = "file-tray-outline";
  service_title: string = "Session Store";
  service_id: string = "TabSession"; //id of the service
  service_url: string = "seatings";

  //fields specific to the data
  data_id: string = "seat_id";
  data_detail: string = "table_id";

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((items) => Object.values(items)));
  //get the menu items as an array

  session$ = (id) => this.state.select(id);
  state$ = this.state.select();

  book_to_sess$ = this.state.select(
    map((items) => {
      let book_ids: any = {};

      Object.values(items).forEach((item) => {
        if (item.booking_id != undefined) {
          book_ids[item.booking_id] = item.seat_id;
        }
      });
      return book_ids;
    })
  );
}
