import { Component, OnInit, Input } from "@angular/core";
import { fadeOut } from "src/app/utils/animations";

@Component({
  selector: "app-splash-screen",
  templateUrl: "./splash-screen.component.html",
  styleUrls: ["./splash-screen.component.scss"],
  animations: [fadeOut("0.5s ease-in-out")],
})
export class SplashScreenComponent implements OnInit {
  @Input() show = true;
  @Input() imageUrl: string;
  @Input() thumbnailUrl: string;
  @Input() title: string;
  @Input() subtitle: string;
  hideSplashScreen = false;
  showHighResSplash = false;

  constructor() {}

  ngOnInit() {}


  highResImageIsLoaded() {
    this.showHighResSplash = true;
  }
}
