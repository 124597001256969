/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export interface Map {
  map: MapObject[];
}
export interface MapObject {
  x: number;
  y: number;
  id: string;
  type: string;
  rotation: number;
  scale: Scale;
  mapItem: MapItem;
  Data?: unknown;
}
export interface Scale {
  x: number;
  y: number;
}
export interface MapItem {
  type: string;
  reference: string;
  svg: string;
  toolbar: Offset;
}
export interface Offset {
  offset_x: number;
  offset_y: number;
}
