import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-popoverlist",
  templateUrl: "./popoverlist.component.html",
  styleUrls: ["./popoverlist.component.scss"],
})
export class PopoverlistComponent implements OnInit {
  @Input() Items: any;
  @Input() Placeholder: any;
  @Input() Label: any = "name";
  @Input() Type: any;

  Data;
  constructor(public popover: PopoverController) {}

  ngOnInit() {}

  choose(item) {
    this.popover.dismiss(item);
  }

  save() {
    this.popover.dismiss(this.Data);
  }
}
