import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { combineLatestWith, map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { SyncSEvent } from "../sync/sync.service";
import {
  SyncV1MenuCategory,
  SyncV1MenuItem,
  SyncV1MenuSuperCategory,
  SyncV1Modifier,
  SyncV1ModifierGroup,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class MenuState extends BaseSynced {
  items = new RxState<{ [key: string]: SyncV1MenuItem }>();
  categories = new RxState<{ [key: string]: SyncV1MenuCategory }>();
  super_categories = new RxState<{ [key: string]: SyncV1MenuSuperCategory }>();
  modifier_groups = new RxState<{ [key: string]: SyncV1ModifierGroup }>();
  modifiers = new RxState<{ [key: string]: SyncV1Modifier }>();

  //fields specific to the service
  service_icon: string = "fast-food-outline";
  service_title: string = "Menu Store";
  service_id: string | string[] = [
    "MenuItem",
    "MenuCategory",
    "MenuSuperCategory",

    "Modifier",
    "ModifierGroup",
  ]; //id of the service

  //fields specific to the data
  data_id: string = "type";
  data_detail: string = "name";
  data_expiry: number = 2440; //extended because its a menu

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);

    //add data to the state
  }

  get storageId(): string {
    return "menu-store";
  }

  getItems$ = this.items
    .select(map((menuItems) => Object.values(menuItems)))
    .pipe(
      combineLatestWith(
        this.categories.select(
          map((menuCategories) => Object.values(menuCategories))
        )
      ),
      combineLatestWith(
        this.super_categories.select(
          map((menuSuperCategories) => Object.values(menuSuperCategories))
        )
      ),
      combineLatestWith(
        this.modifier_groups.select(
          map((menuSuperCategories) => Object.values(menuSuperCategories))
        )
      ),
      combineLatestWith(
        this.modifiers.select(
          map((menuSuperCategories) => Object.values(menuSuperCategories))
        )
      ),
      map(
        (
          [
            [[[items, categories], super_categories], modifier_groups],
            modifiers,
          ],
          se
        ) => {
          //return 1 big array
          let arr: any[] = items.map((x) => {
            (x as any).type = "MenuItem";
            return x;
          });
          arr = arr.concat(
            categories.map((x) => {
              (x as any).type = "MenuCategory";
              return x;
            })
          );
          arr = arr.concat(
            super_categories.map((x) => {
              (x as any).type = "SuperCategory";
              return x;
            })
          );
          arr = arr.concat(
            modifier_groups.map((x) => {
              (x as any).type = "ModifierGroup";
              return x;
            })
          );
          arr = arr.concat(
            modifiers.map((x) => {
              (x as any).type = "Modifier";
              return x;
            })
          );

          return arr;
        }
      )
    );
  //get the menu items as an array
  allMenuItems$ = this.items.select(
    map((menuItems) => Object.values(menuItems))
  );
  featuredMenuItems$ = this.items.select(
    map((menuItems) =>
      Object.values(menuItems).filter(
        (item) => item.featured && item.is_available_staff_now == true
      ).sort((a, b) => a.sort_order - b.sort_order)
    )
  );

  menuItem$ = (id) => this.items.select(id);
  modifierGroup$ = (id) => this.modifier_groups.select(id);
  modifier$ = (id) => this.modifiers.select(id);
  modifiers$ = this.modifiers.select(
    map((menuItems) => Object.values(menuItems))
  );

  modifierForGroup$ = (id) =>
    this.modifiers.select(
      map((menuItems) =>
        Object.values(menuItems).filter((item) => item.mmg_id == id)
      )
    );

  superCategories$ = this.super_categories.select(
    map((cat) => Object.values(cat))
  );
  categories$ = this.categories.select(map((cat) => Object.values(cat)));

  menuItemsInCategory$ = (id) =>
    this.items.select(
      map((menuItems) => Object.values(menuItems).filter((item) => item === id))
    );

  RelevantState(event: SyncSEvent) {
    let stateselected: any = this.items;
    if (event.obj_type == "MenuCategory") {
      stateselected = this.categories;
    }
    if (event.obj_type == "MenuSuperCategory") {
      stateselected = this.super_categories;
    }
    if (event.obj_type == "ModifierGroup") {
      stateselected = this.modifier_groups;
    }
    if (event.obj_type == "Modifier") {
      stateselected = this.modifiers;
    }
    return stateselected;
  }

  async Sync() {
    if (this.serviceState.get("refreshing")) {
      return "uness";
    }
    this.serviceState.set({
      refreshing: true,
    });

    let menu = await this.syncapis.getMenuItems().toPromise();
    //create an observable that we can emit a value to when ready
    //this will be used to trigger the sync
    //create

    let menu_items = {};
    menu.menu_items.forEach((x) => {
      (x as any).sort_key = menu.timestamp;
      menu_items[x.mi_id] = x;
    });

    this.items.set(menu_items);

    let menu_cats = {};
    menu.menu_cats.forEach((x) => {
      (x as any).sort_key = menu.timestamp;
      menu_cats[x.mc_id] = x;
    });

    this.categories.set(menu_cats);

    let menu_super_cats = {};
    menu.menu_super_cats.forEach((x) => {
      (x as any).sort_key = menu.timestamp;
      menu_super_cats[x.msc_id] = x;
    });

    this.super_categories.set(menu_super_cats);

    let modifier_groups = {};
    menu.modifier_groups.forEach((x) => {
      (x as any).sort_key = menu.timestamp;
      modifier_groups[x.mmg_id] = x;
    });

    this.modifier_groups.set(modifier_groups);

    let modifiers = {};
    menu.modifiers.forEach((x) => {
      (x as any).sort_key = menu.timestamp;
      modifiers[x.mm_id] = x;
    });

    this.modifiers.set(modifiers);

    this.serviceState.set({
      refreshing: false,
    });

    return "success";
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  UpdateState(newState) {
    this.items.set(newState.MenuItem);
    this.categories.set(newState.MenuCategory);
    this.super_categories.set(newState.MenuSuperCategory);
    this.modifiers.set(newState.Modifier);
    this.modifier_groups.set(newState.ModifierGroup);
  }
  GetState() {
    return {
      MenuItem: this.items.get(),
      MenuCategory: this.categories.get(),
      MenuSuperCategory: this.super_categories.get(),
      Modifier: this.modifiers.get(),
      ModifierGroup: this.modifier_groups.get(),
    };
  }
}
