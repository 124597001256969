import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { SyncV1Package, SyncV1Table } from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class PackageState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Package }>();

  //fields specific to the service
  service_icon: string = "cube-outline";
  service_title: string = "Package Store";
  service_id: string = "VenuePackage"; //id of the service
  service_url: string = "packages";

  //fields specific to the data
  data_id: string = "package_id";
  data_detail: string = "name";
  data_expiry: number = 2000; //in minutes, the amount of time before the data is considered unusable

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  packages$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  packagesPublic$ = this.state.select(
    map((menuItems) =>
      Object.values(menuItems).filter((x) => {
        return x.parent_id == undefined && x.available == true;
      })
    )
  );

  //get table using id
  package$ = (id) => this.state.select(id);
}
