import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import {
  AlertController,
  IonicModule,
  ToastController,
  Platform,
} from "@ionic/angular";
import { SocialLinkPipeModule } from "src/app/pipes/social-link/social-link.pipe";
import { DqTreatMeDetails } from "src/app/services/treat-me-data/treat-me-data.service";
import { NgNavigatorShareService } from "ng-navigator-share";
import { Subject, combineLatest, timer } from "rxjs";
import {
  combineLatestWith,
  exhaustMap,
  filter,
  map,
  retry,
  share,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { RandomiseUrlPipeModule } from "src/app/pipes/randomise-url/randomise-url.pipe";
import { environment } from "src/environments/environment";
import { OrderService } from "src/app/services/order/order.service";
import { selectTreatInfluencerTable } from "src/app/reducers";
import { UserTypePipe } from "../../../pipes/user-type.pipe";
import { AuthService } from "src/app/services/auth/auth.service";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { CognitoUser } from "@aws-amplify/auth";
import { NativeStorage } from "@awesome-cordova-plugins/native-storage/ngx";
import { RegionsService } from "src/app/services/regions/regions.service";
import { StorageAdapter } from "src/app/services/storage-adapter/storage-adapter.service";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { NotificationState } from "src/app/services/sync-engine/notification-state/notification.service";

@Component({
  selector: "dq-tab-bar",
  templateUrl: "tabbar.component.html",
  styleUrls: ["tabbar.component.scss"],
})
export class tabbarComponent implements OnInit {
  isTablet: boolean = false;

  unreadNotifications$ = this.notificationSt.notifications$.pipe(
    combineLatestWith(
      this.settingsSt.currentUser$,
      this.settingsSt.lastReadNotifications$
    ),
    map(([notifications, user, lastRead]) => {
      if (user) {
        return notifications.filter((x) => {
          if (new Date(x.created_utc).getTime() > lastRead) {
            console.log(new Date(x.created_utc).getTime(), lastRead, x);
          }
          return (
            new Date(x.created_utc).getTime() > lastRead &&
            this.notificationSt.shouldBeSeen(x, user) &&
            x.sender != user["username"]
          );
        }).length;
      }
    })
  );

  constructor(
    public orderSvc: OrderService,
    public platform: Platform,
    private auth: AuthClass,
    private storage: StorageAdapter,
    private nativeStorage: NativeStorage,
    private regions: RegionsService,
    private analy: AnalyticsService,
    private settingsSt: SettingsState,
    private notificationSt: NotificationState
  ) {
    if (this.platform.is("tablet")) this.isTablet = true;

    if (this.platform.is("capacitor")) {
    }
  }
  ngOnDestroy() {}

  async ngOnInit() {}
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RandomiseUrlPipeModule,
    SocialLinkPipeModule,
  ],
  exports: [tabbarComponent],
  declarations: [tabbarComponent],
  providers: [],
})
export class TreatMeInfluencerFooterModule {}
