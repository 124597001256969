import { NgZone } from "@angular/core";
import { Observable } from "rxjs";

/**
 * A pipeable operator to wrap an observer in an angular zone This is useful for
 * observers such as cordova callbacks which get executed outside of a ngZone
 * which in turn causes change detection problems
 *
 * References:
 * https://github.com/ReactiveX/rxjs/blob/master/doc/operator-creation.md#operator-as-a-pure-function
 * https://stackoverflow.com/a/37596783/251352
 * https://medium.com/@naveen.kr/rxjs-custom-pipeable-operator-to-run-asynchronous-callbacks-inside-angular-zone-a49bd71c0bf6
 *
 * @param zone the ngZone in which to execute the observable in
 * @example
 * constructor(private ngZone:NgZone, private geolocation: Geolocation){
 *  this.location$ = this.geolocation.watchPosition.pipe(wrapInZone(this.ngZone))
 * }
 *
 */
export function wrapInZone(zone: NgZone) {
  return <T>(source: Observable<T>) =>
    new Observable<T>(observer =>
      source.subscribe({
        next: x => zone.run(() => observer.next(x)),
        error: err => zone.run(() => observer.error(err)),
        complete: () => zone.run(() => observer.complete()),
      })
    );
}
