import { Injectable } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { FCM } from "@capacitor-community/fcm";
import { defer, EMPTY } from "rxjs";
import {
  concatMap,
  filter,
  map,
  mapTo,
  switchMap,
  switchMapTo,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { userLogOut } from "src/app/actions/auth.actions";
import {
  subscribeToTableNotificationsError,
  subscribeToTableNotificationsSuccess,
  unsubscribeToTableNotificationsError,
  unsubscribeToTableNotificationsSuccess,
} from "src/app/actions/fcm-api.actions";
import {
  clearSubscriptionsAfterLogout,
  loadSavedSubscriptions,
  subscribeToTableNotifications,
  unsubscribeToTableNotifications,
} from "src/app/actions/notification.actions";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { notificationsSelectAll, State } from "src/app/reducers";
import { StorageAdapter } from "src/app/services/storage-adapter/storage-adapter.service";
import { environment } from "src/environments/environment";
import { VenueService } from "src/app/services/venue/venue.service";

const STORAGE_KEY_SUBSCRIPTIONS = "dq:notificationSubscriptions";

@Injectable()
export class SubscriptionEffects {
  onAppReady$ = defer(() => {
    return this.platform.is("capacitor") ? this.platform.ready() : EMPTY;
  });

  loadSavedSubscriptions$ = createEffect(() =>
    this.onAppReady$.pipe(
      switchMap(() =>
        this.storage.getObject<DqNotificationSubscription[]>(
          STORAGE_KEY_SUBSCRIPTIONS
        )
      ),
      map((subscriptions) =>
        loadSavedSubscriptions({ subscriptions: subscriptions || [] })
      )
    )
  );

  addSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeToTableNotifications),
      concatMap(async ({ venueId, tableId }) => {
        try {
          //await FCM.subscribeTo({ topic: getTopicName(venueId, tableId) });
          await this.venue.FavTable(tableId, true).toPromise();
          return subscribeToTableNotificationsSuccess({ venueId, tableId });
        } catch (error) {
          return subscribeToTableNotificationsSuccess({ venueId, tableId });
        }
      })
    )
  );

  clearSubscriptionsOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userLogOut),
      withLatestFrom(this.store$.pipe(select(notificationsSelectAll))),
      concatMap(async ([, subscriptions]) => {
        for (const sub of subscriptions) {
          try {
            //await FCM.unsubscribeFrom({
            //  topic: getTopicName(sub.venueId, sub.tableId),
            //});
            //await this.venue.FavTable(sub.tableId, true)
          } catch (error) {
            console.error(
              "An error occurred whilst unsubscribing from topic",
              sub
            );
          }
        }
      }),
      mapTo(clearSubscriptionsAfterLogout())
    )
  );

  unsubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribeToTableNotifications),
      concatMap(async ({ venueId, tableId }) => {
        try {
          //await FCM.unsubscribeFrom({ topic: getTopicName(venueId, tableId) });
          await this.venue.FavTable(tableId, false).toPromise();
          return unsubscribeToTableNotificationsSuccess({ venueId, tableId });
        } catch (error) {
          return unsubscribeToTableNotificationsError({ venueId, tableId });
        }
      })
    )
  );

  saveSubscriptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadSavedSubscriptions),
        filter(() => this.platform.is("capacitor")),
        switchMapTo(this.store$.pipe(select(notificationsSelectAll))),
        tap((subscriptions) =>
          this.storage.setObject(STORAGE_KEY_SUBSCRIPTIONS, subscriptions)
        )
      ),
    { dispatch: false }
  );

  showSubscribeErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(subscribeToTableNotificationsError),
        concatMap(async () => {
          const toast = await this.toastCtrl.create({
            header:
              "Could not subscribe to table notifications, please try again",
            duration: environment.defaultToastDuration,
            position: "top",
            swipeGesture: "vertical",

            buttons: [
              {
                icon: "close-circle-outline",
                role: "cancel",
                cssClass: "toast-close-button",
              },
            ],
          });
          await toast.present();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private platform: Platform,
    private storage: StorageAdapter,
    private store$: Store<State>,
    private toastCtrl: ToastController,
    private venue: VenueService
  ) {}
}

function getTopicName(venueId: string, tableId: string) {
  return `venue_${venueId}_table_${tableId}`;
}
