import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { CartFacadeService } from "src/app/facades/cart-facade/cart-facade.service";
import {
  DqGarnish,
  DqMixture,
  DqGarnishBasic,
  DqMixtureBasic,
} from "src/app/services/mixture/mixture.service";
import { DqOrderStatus } from "src/app/services/order/order.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";
import { DqModifierGroup } from "src/app/models/cart.model";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { ConsumerCartFacadeService } from "src/app/facades/consumer-cart-facade/consumer-cart-facade.service";

@Component({
  selector: "app-cart-item",
  templateUrl: "./cart-item.component.html",
  styleUrls: ["./cart-item.component.scss"],
})
export class CartItemComponent implements OnInit, OnChanges {
  @Input() canEdit = true;
  @Input() comment: string;
  @Input() currencyCode: string;
  @Input() garnishes: DqGarnish[] | DqGarnishBasic[];
  @Input() itemId: string;
  @Input() mixtures: DqMixture[] | DqMixtureBasic[];
  @Input() modifiers: DqConsumerModifier[];
  @Input() name: string;
  @Input() price: string;
  @Input() quantity: number;
  @Input() showCancelButton = false;
  @Input() status: DqOrderStatus;
  @Input() tableId: string;
  @Input() first?: boolean = false;
  @Input() last?: boolean = false;
  @Input() voidable?: boolean = false;
  @Input() voided = false;
  @Input() shadow = false;
  @Output() voidItem = new EventEmitter<string>();
  @Output() cancelItem = new EventEmitter<string>();
  voidflag = this.analy.getFeatureFlag("voiding", true);
  showComment = false;
  showEditButtons = false;

  constructor(
    private cartFacade: ConsumerCartFacadeService,
    private analy: AnalyticsService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  runningaction = false;
  async dragging(event, item) {
    console.log(event);

    if (this.runningaction) {
      return;
    }
    //if value is positive we are fragging right to left (close open table)
    if (event.detail.ratio > 2) {
      this.runningaction = true;
      Haptics.impact();
      item.close();
      //await list.closeSlidingItems();
      if (this.canEdit) {
        this.removeCartItem();
      } else if (this.voidable && !this.voided) {
        item.close();
        this.voidItem.emit(this.itemId);
      }
      this.runningaction = false;
    }
    if (event.detail.ratio < -1.5) {
      this.runningaction = true;
      Haptics.impact();

      // await list.closeSlidingItems();
      if (this.canEdit) {
        item.close();
        this.addCommentToCartItem();
      }
      this.runningaction = false;
    }
    return;
    //if value is negative we are dragging left to right (favourite unfavorite)
    //either way if we get over half way, run relevant action and vibrate the device
  }

  incrementCartItem() {
    Haptics.impact();

    this.cartFacade.incrementItemQuantity(this.itemId, this.tableId);
    this.analy.addEvent("Increment Cart Item", {
      name: this.name,
      price: this.price,
      comment: this.comment,
      modifiers: this.modifiers,
    });
  }

  decrementCartItem() {
    Haptics.impact();

    this.cartFacade.decrementItemQuantity(this.itemId, this.tableId);
    this.analy.addEvent("Decrement Cart Item", {
      name: this.name,
      price: this.price,
      comment: this.comment,
      modifiers: this.modifiers,
    });
  }

  addCommentToCartItem() {
    Haptics.impact();

    this.showComment = !this.showComment;
    if (!this.showComment) {
      this.cartFacade.updateItemComment(this.itemId, "", this.tableId);
    }
    this.analy.addEvent("Comment Cart Item", {
      name: this.name,
      price: this.price,
      comment: this.comment,
      modifiers: this.modifiers,
    });
  }

  removeCartItem() {
    Haptics.impact();

    this.cartFacade.removeItem(this.itemId, this.tableId);
    this.analy.addEvent("Remove Cart Item", {
      name: this.name,
      price: this.price,
      comment: this.comment,
      modifiers: this.modifiers,
    });
  }

  updateComment(event: CustomEvent) {
    Haptics.impact();

    this.cartFacade.updateItemComment(
      this.itemId,
      event.detail.value,
      this.tableId
    );
    this.analy.addEvent("UpComment Cart Item", {
      name: this.name,
      price: this.price,
      comment: this.comment,
      modifiers: this.modifiers,
    });
  }

  showCommentRow() {
    return this.showComment;
  }

  toggleEditButtons() {
    if (this.canEdit) {
      this.showEditButtons = !this.showEditButtons;
    }
  }
}
