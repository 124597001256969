import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DqCartItem, DqModifierGroup } from "src/app/models/cart.model";
import { getCartTotalPrice } from "src/app/utils/calculate-cart-total";
import { paramsToFormData } from "src/app/utils/object-to-form-data";
import { environment } from "src/environments/environment";
import {
  DqGratuityType,
  DqOrderDetails,
  DqPaymentMethod,
  OrderService,
} from "../order/order.service";
import { RegionsService } from "../regions/regions.service";


interface DqOrderItemV2 {
  od_free_prod: "";
  od_item_id: string; // 12587,
  od_price: string; // "12.50",
  order_collection_point_id: string; // "99",
  drink_type: string; //  "155",
  /**
   * This parameter is used to collect comments
   */
  o_ingredients: string; // "",
  /**
   * Delivery time in HH:MM:SS
   */
  o_dev_time: string; // "",
  od_item_name: string; // "Belvedere",
  od_quantity: number; // "1",
  od_item_hmc_id: string; // "155"
  modifier_groups: { mg_id: number; mod_ids: number[] }[];
}

export interface DqPlaceOrderV2Response {
  o_id: number; // 33473
  order_detail_ids: number[]; // [94778]
  payment_status: "Unpaid" | "Paid";
}

export interface DqTableGetUnpaidOrdersResponse {
  unpaid_order_details: {
    [orderId: string]: DqOrderDetails;
  };
  unpaid_total: string;
}

@Injectable({
  providedIn: "root",
})
export class OrderV2Service {
  constructor(private http: HttpClient, private ordersSvc: OrderService, private reg: RegionsService) {}

  getTableUnpaidOrders({ tableId }: { tableId: string }) {
    return this.http.get<DqTableGetUnpaidOrdersResponse>(
      `${this.reg.ServerURL()}/v3/table/${tableId}/order`
    );
  }

  closeTable({ tableId }: { tableId: string }) {
    return this.http.delete(`${this.reg.ServerURL()}/v3/table/${tableId}`);
  }

  newOrder({
    tableId,
    collectionPointId,
    paymentMethod,
    gratuity,
    gratuityType,
    items,
  }: {
    tableId: string;
    collectionPointId: string;
    paymentMethod: DqPaymentMethod;
    gratuity: number;
    gratuityType: DqGratuityType;
    items: DqCartItem[];
  }) {
    const order_details = items.map((item) =>
      this.mapDqCartItemToOrderItem(item, collectionPointId)
    );
    const cartTotal = getCartTotalPrice(items);
    const tip = this.ordersSvc.calculateGratuityAmount(
      cartTotal,
      gratuity,
      gratuityType
    );
    const body = {
      o_payment_method: mapPaymentMethodToServerPayment(paymentMethod),
      o_gratuity: tip,
      order_details: JSON.stringify(order_details),
    };
    const formData = paramsToFormData(body);
    return this.http.post<DqPlaceOrderV2Response>(
      `${this.reg.ServerURL()}/v3/table/${tableId}/order`,
      formData
    );
  }

  pay({
    orderId,
    paymentMethod,
    total,
    gratuity,
    squareTransactionId,
    squareClientTransactionId,
  }: {
    orderId: string;
    paymentMethod: DqPaymentMethod;
    total: number;
    gratuity?: number;
    squareTransactionId?: string;
    squareClientTransactionId?: string;
  }) {
    const body = {
      total_ex_tip: total,
      ...(paymentMethod && {
        o_payment_method: mapPaymentMethodToServerPayment(paymentMethod),
      }),
      ...(gratuity && { tip: gratuity }),
      ...(squareTransactionId && {
        square_transaction_id: squareTransactionId,
      }),
      ...(squareClientTransactionId && {
        square_client_transaction_id: squareClientTransactionId,
      }),
    };
    const formData = paramsToFormData(body);
    return this.http.post(
      `${this.reg.ServerURL()}/v3/order/${orderId}/lodge_payment`,
      formData
    );
  }

  orderSquareFailed({
    orderId,
    total,
    squareErrorMessage,
  }: {
    orderId: string;
    total: number;
    squareErrorMessage: string;
  }) {
    const body = {
      total_ex_tip: total,
      o_payment_method: "FailedSquare",
      failed_reason_code: squareErrorMessage,
    };
    const formData = paramsToFormData(body);
    return this.http.post(
      `${this.reg.ServerURL()}/v3/order/${orderId}/lodge_payment`,
      formData
    );
  }

  payForTable({
    tableId,
    total,
    paymentMethod,
    gratuity,
    squareTransactionId,
    squareClientTransactionId,
  }: {
    tableId: string;
    total: number;
    gratuity?: number;
    paymentMethod: DqPaymentMethod;
    squareTransactionId?: string;
    squareClientTransactionId?: string;
  }) {
    const body = {
      total_ex_tip: total,
      ...(paymentMethod && {
        o_payment_method: mapPaymentMethodToServerPayment(paymentMethod),
      }),
      ...(gratuity && { tip: gratuity }),
      ...(squareTransactionId && {
        square_transaction_id: squareTransactionId,
      }),
      ...(squareClientTransactionId && {
        square_client_transaction_id: squareClientTransactionId,
      }),
    };
    const formData = paramsToFormData(body);
    return this.http.post(
      `${this.reg.ServerURL()}/v3/table/${tableId}/lodge_payment`,
      formData
    );
  }

  mapDqCartItemToOrderItem(
    item: DqCartItem,
    collection_point_id: string
  ): DqOrderItemV2 {
    /**
     * Params
     *  [
     *    NOTE : THIS IS A MIXTURE WITH GARNISHES
     *     {
     *       "od_free_prod": "",
     *       "od_mixture": "33,36",
     *       "od_item_id": 12587,
     *       "od_price": "12.50",
     *       "order_collection_point_id": "99",
     *       "drink_type": "155",
     *       "o_ingredients": "",
     *       "o_dev_time": "",
     *       "garnish_ids": "68,34",
     *       "od_item_name": "Belvedere",
     *       "od_quantity": "1",
     *       "od_item_hmc_id": "155"
     *     },
     *    NOTE: THE FOLLOWING ARE NORMAL ITEMS
     *     {
     *       "od_free_prod": "",
     *       "od_mixture": "",
     *       "od_item_id": 11417,
     *       "od_price": "20.00",
     *       "order_collection_point_id": "99",
     *       "drink_type": "45",
     *       "o_ingredients": "",
     *       "o_dev_time": "",
     *       "garnish_ids": "",
     *       "od_item_name": "Espresso Martini",
     *       "od_quantity": "3",
     *       "od_item_hmc_id": "45"
     *     },
     *     {
     *       "od_free_prod": "",
     *       "od_mixture": "",
     *       "od_item_id": 11418,
     *       "od_price": "24.00",
     *       "order_collection_point_id": "99",
     *       "drink_type": "45",
     *       "o_ingredients": "",
     *       "o_dev_time": "",
     *       "garnish_ids": "",
     *       "od_item_name": "Sweet Contessa",
     *       "od_quantity": "1",
     *       "od_item_hmc_id": "45"
     *     }
     *   ]
     *
     */
    const orderItem: DqOrderItemV2 = {
      od_free_prod: "",
      od_item_id: item.itemDetails.i_id, // 12587,
      od_price: item.itemDetails.i_price, // "12.50",
      order_collection_point_id: collection_point_id, // "99",
      drink_type: item.itemDetails.i_hmc_id, //  "155",
      o_ingredients: item.comment || "", // "",
      o_dev_time: "00:00:00", // "",
      modifier_groups: (
        item.consumerModifiers ?? []
      ).map(({ mg_id, modifiers }) => ({
        mg_id,
        mod_ids: modifiers.map((m) => m.mod_id),
      })),
      od_item_name: item.itemDetails.i_name, // "Belvedere",
      od_quantity: item.quantity, // "1",
      od_item_hmc_id: item.itemDetails.i_hmc_id, // "155"
    };
    return orderItem;
  }
}

export function mapPaymentMethodToServerPayment(
  paymentMethod: DqPaymentMethod
) {
  switch (paymentMethod) {
    case "cash":
      return "Cash";
    case "eftpos":
      return "EFTPOS";
    case "paylater":
      return "Later";
    case "square":
      return "Square";
    default:
      return paymentMethod;
  }
}
