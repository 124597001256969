import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { Observable, map } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";
import { Card } from "src/app/models/bump_screen_models";
import { OrderService } from "src/app/services/order/order.service";
import { Availability } from "src/app/models/bookings";
import { BookingService } from "src/app/services/booking/booking.service";
import {
  collapseOnLeaveAnimation,
  expandOnEnterAnimation,
} from "angular-animations";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { SyncV1Booking } from "src/app/models/sync_models";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { EntryState } from "src/app/services/sync-engine/guest-entry/entry.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

@Component({
  selector: "app-booking-card",
  templateUrl: "./booking-card.component.html",
  styleUrls: ["./booking-card.component.scss"],
  animations: [
    collapseOnLeaveAnimation({ duration: 250 }),
    expandOnEnterAnimation({ duration: 250 }),
    //animation that slowly expands the height of a div bec
  ],
})
export class BookingCardComponent implements OnInit {
  @Input() booking: SyncV1Booking;
  @Input() availabil?: Availability = undefined;
  @Input() showAvail? = true;
  @Input() border? = true;
  @Input() selected? = false;
  @Input() interactable? = true;
  @Input() loading? = false;
  @Input() OutputInteractions? = false;
  @Input() GuestList = false;
  @Input() ListOpen: boolean = false;
  @Input() outline: boolean = false;


  guest$ = this.guestSt.guest$;
  table$ = this.tableSt.table$;
  receipt$ = this.recieptSt.getDirect$;

  currency$ = this.settingSt.currencyCode$;

  @Output() Guest = new EventEmitter();
  @Output() Table = new EventEmitter();

  entryLoaded$ = this.entrySt.loaded$;
  guest_entry$ = this.entrySt.guest_entry$;
  empty: boolean = false;
  statusUpdating = "";
  constructor(
    public modalController: ModalController,
    private toast: ToastController,
    public alertController: AlertController,
    private orderSvc: OrderService,
    private nav: NavController,
    private active: ActivatedRoute,
    private platform: Platform,
    private booqSvc: BookingService,
    private guestSt: GuestState,
    private tableSt: TableState,
    private entrySt: EntryState,
    private recieptSt: RecieptState,
    private settingSt: SettingsState
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  calcTime() {}

  OpenBooking(event, o_uuid: string) {}

  async ChangeStatus(status) {
    this.statusUpdating = status;
    await this.booqSvc
      .changeBookingStatus(this.booking.booking_id, status)
      .toPromise();
    this.booking.status = status;
    this.statusUpdating = "";
  }

  async updateListGuest(guest) {
    //wait 100ms
    await new Promise((resolve) => setTimeout(resolve, 100));
    await this.booqSvc
      .UpdateListGuest(this.booking.booking_id, guest)
      .toPromise();
    //do this locally
    //are all the guests checked in?
    let allCheckedIn = true;
    for (let g of this.booking.gle_ids) {
      // if (g.seated == false) {
      //   allCheckedIn = false;
      // }
    }
    if (allCheckedIn) {
      //this.booking.guest_status = "seated";
    } else {
      //this.booking.guest_status = "seating";
    }
  }

  guest_entry_status(gle_ids: string[]) {
    let allloaded = true;
    gle_ids.map((x) => {
      if (allloaded == false) {
        return;
      }

      let en = this.entrySt.state.get(x);
      //if one of the entries is not loaded then the whole thing is not loaded
      if (!en.arrived) {
        allloaded = false;
        //dont run the rest of the loop
        return;
      }
    });

    return allloaded;
  }

  convertToPretty(string) {
    //remove the underscores and make the first letter capital
    return string.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  }
}
