<ng-container
        *ngrxLet="entrys_seated$(booking.booking_id) | async as seated"
      >
        <ion-chip
          *ngrxLet="seated == booking.gle_ids.length as allseated"
          [outline]="!allseated || booking.gle_ids.length == 0"
          [disabled]="
            booking.gle_ids == undefined || booking.gle_ids.length == 0
          "
          [class.chip-success]="allseated && booking.gle_ids.length > 0"
          [class.chip-primary]="!allseated && booking.gle_ids.length > 0"
          [color]="
            allseated && booking.gle_ids.length > 0
              ? 'success'
              : !allseated && booking.gle_ids.length > 0
              ? 'primary'
              : 'light'
          "
        >
          {{
            interactable == true && seated > 0 && allseated == false
              ? seated + "/" + booking.gle_ids.length
              : booking.gle_ids.length
          }}
          Guests
         
        </ion-chip>
      </ng-container>