<div class="loaderbox secondLayer" [class]="size">
  <ion-col class="dropzoneTemplate" class="spread">
    <ng-container *ngIf="currentimage != undefined">
      <img
        *ngIf="
          currentimage != '' &&
          currentimage != undefined &&
          currentimage.includes('http')
        "
        class="background"
        [src]="currentimage | imagePipe: 'full'"
      />
      <img
        *ngIf="
          currentimage != '' &&
          currentimage != undefined &&
          !currentimage.includes('http')
        "
        class="background"
        [src]="currentimage | imagePipe: 'full'"
      />
    </ng-container>
    <div class="top">
      <ion-spinner *ngIf="Loading"></ion-spinner>
      <h4 *ngIf="currentimage == undefined || currentimage == ''">
        Select Image
      </h4>
    </div>
    <ion-button (click)="file.click()" color="light">
      Upload
      <input
        type="file"
        id="file"
        (change)="uploaded($event.target.files[0])"
        style="display: none"
        accept=".png"
        #file
      />
    </ion-button>
  </ion-col>
</div>
