import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { DqTreatMeDetails } from "src/app/services/treat-me-data/treat-me-data.service";

@Pipe({
  name: "appSocialLink",
})
export class SocialLinkPipe implements PipeTransform {
  transform(details: DqTreatMeDetails, ...args: any[]): string {
    const { social_account_consumer } = details ?? {};
    if (social_account_consumer?.sa_provider === "instagram") {
      return `https://instagram.com/${social_account_consumer?.sa_handle?.replace(
        /^@/,
        ""
      )}`;
    }
  }
}

@NgModule({
  imports: [],
  exports: [SocialLinkPipe],
  declarations: [SocialLinkPipe],
  providers: [],
})
export class SocialLinkPipeModule {}
