<ion-tabs>
  <ion-tab-bar slot="bottom" color="medium" class="tabbar-tab">
    <ion-tab-button tab="dashboard">
      <ion-icon name="reorder-four-outline"></ion-icon>
      <ion-label>Tables</ion-label>
    </ion-tab-button>
    <!-- Choose between Tablet and non Tablet -->
    <ion-tab-button tab="vip-orders">
      <ion-icon name="file-tray-full-outline"></ion-icon>
      <ion-label>Orders</ion-label>
      <ion-badge *ngIf="(orderSvc.orderNotifcations$ | async)?.show">{{
        (orderSvc.orderNotifcations$ | async).text
      }}</ion-badge>
    </ion-tab-button>

    <!-- END -->
    <ion-tab-button tab="notifications">
      <ion-icon name="chatbox-outline"></ion-icon>
      <ion-label>Notif</ion-label>
      <ion-badge *ngIf="(unreadNotifications$ | async) > 0">{{
        unreadNotifications$ | async
      }}</ion-badge>
    </ion-tab-button>
    <ion-tab-button tab="bookings">
      <ion-icon name="calendar-outline"></ion-icon>
      <ion-label>Bookings</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
