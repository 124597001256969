// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  validregions: ["au", "us", "eu"],
  au: {
    name: "Australia",
    // serverUrl: "http://127.0.0.1:5000",
    serverUrl: "https://api.au.dev.getdqd.com",
    serverPushPublicKey:
      "BPnWI_sfCJvXJsiYzIPBo6JaXJDxCG3yI7ilJ4q99cgQz9d4igFX-33g9W_hS-apcSqMu3U4r_F1LfoXJxuHiOE",
    serverSocket: " wss://ws.au.dev.getdqd.com",
    pusher: {
      key: "52c43dc30ce9df97f24f",
      cluster: "ap4",
    },
  },
  us: {
    name: "America",
    serverUrl: "https://api.us.dev.getdqd.com",
    serverPushPublicKey:
      "BPnWI_sfCJvXJsiYzIPBo6JaXJDxCG3yI7ilJ4q99cgQz9d4igFX-33g9W_hS-apcSqMu3U4r_F1LfoXJxuHiOE",
    serverSocket: "wss://ws.us.dev.getdqd.com",
    pusher: {
      key: "26b89f9e2387d6122bbf",
      cluster: "us3",
    },
  },
  eu: {
    name: "Europe",
    serverUrl: "https://api.eu.dev.getdqd.com",
    serverPushPublicKey:
      "BPnWI_sfCJvXJsiYzIPBo6JaXJDxCG3yI7ilJ4q99cgQz9d4igFX-33g9W_hS-apcSqMu3U4r_F1LfoXJxuHiOE",
    serverSocket: "wss://ws.eu.dev.getdqd.com",
    pusher: {
      key: "fd60266529f010c6e3f5",
      cluster: "eu",
    },
  },
  cognitoConfig: {
    region: "ap-southeast-2",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_8UnjB1D9t", // Dev: "ap-southeast-2_8UnjB1D9t".  Prod: "ap-southeast-2_HLEUrWGHr"
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "1hpga2e02taklgfcpphps2ts2l", // Dev: "1hpga2e02taklgfcpphps2ts2l"  Prod: "3n0ueb0fhn1usvgnh11vsub6ef"
    pusher: {
      key: "89d3c2c54f36e1993596",
      cluster: "ap4",
    },
  },

  configCat: "RNDaCOyg00GKOjRazt311Q/r1ngurytXkCXZW7_VxfSCA",
  // In Hours
  maxTableOpenDuration: 14,
  defaultToastDuration: 3000,
  orderAutoRefreshInterval: 15000,
  production: false,
  qrCodeValidation: {
    validDomains: [
      "https://d1uca3w587bis9.cloudfront.net",
      "https://getdqd.com",
      "https://www.getdqd.com",
    ],
    validPathRegex: /\/location\/(\d+)$/,
  },
  squareApplicationId: "sq0idp-sPeuwGjsBbQ-sBgtxETQlQ",
  squareAppUrl: "square-commerce-v1://",
  squareCallbackUrl: "queuebar://square_request",
  squareAllowedPaymentMethods: undefined,
  stripePublishableKey: "pk_test_jWue6IQNAA9EUedGDgTAOgXM00XeE0W4wc",
  treatMeEnabled: true,
  honeyBadgerAPIKey: "b4f76047",
  validjwtdomain: [
    "https://api.au.dev.getdqd.com",
    "https://api.us.dev.getdqd.com",
    "https://api.eu.dev.getdqd.com",
    "http://127.0.0.1:5000",
    "http://localhost:5000",
    "http://127.0.0.1:3000",
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/plugins/zone-error"; // Included with Angular CLI.
