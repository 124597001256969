<ng-container *ngrxLet="package$(packageId) as package">
  <ion-list class="" *ngIf="package != undefined">
    <ng-container *ngFor="let item of package.inclusions; trackBy: trackByID">
      <ion-item-sliding>
        <ion-item class="inset">
          <ng-container *ngIf="status != 'seated' && status != 'finished'">
            <ion-textarea
              rows="1"
              maxlength="20"
              [readonly]="status == 'new'"
              placeholder="Name"
              [(ngModel)]="item.name"
              [debounce]="1000"
              (ngModelChange)="updatePackage(package)"
            ></ion-textarea>
            <div slot="end" class="endslot">
              <ion-chip *ngIf="item.mi_id != null && isTablet" color="primary"
                >Menu Item
              </ion-chip>
              <ion-chip
                *ngIf="item.item_options != null && isTablet"
                color="primary"
                >Item Options
              </ion-chip>
              <ion-icon
                *ngIf="item.mi_id != null && !isTablet"
                color="primary"
                name="fast-food-outline"
                class="horizadd"
              ></ion-icon>
              <ion-icon
                *ngIf="
                  item.item_options != null &&
                  item.item_options.length > 0 &&
                  !isTablet
                "
                color="primary"
                name="cube-outline"
                class="horizadd"
              ></ion-icon>
              <ion-input
                class="smallone"
                rows="1"
                [readonly]="status == 'new'"
                placeholder="Quantity"
                type="number"
                [(ngModel)]="item.quantity"
                [debounce]="1000"
                (ngModelChange)="updatePackage(package)"
              ></ion-input>
            </div>
          </ng-container>
          <ng-container *ngIf="status == 'seated' || status == 'finished'">
            <ion-checkbox
              [(ngModel)]="item.checkin"
              (ngModelChange)="updatedDeliver(item); update()"
              *ngIf="status == 'seated'"
              slot="start"
              [disabled]="
                item.avail <= 0 ||
                (item.mi_id == undefined && item.item_options.length == 0)
              "
            ></ion-checkbox>
            <ion-label>{{ item.name }}</ion-label>
            <ng-container
              *ngIf="
                (item.mi_id != undefined || item.item_options != undefined) &&
                item.avail > 0 &&
                status == 'seated'
              "
            >
              <ion-button
                [color]="item.checkin ? 'primary' : 'light'"
                fill="clear"
                slot="end"
                class="add-button"
                [disabled]="
                  (deliver[item.pinc_id] != undefined &&
                    deliver[item.pinc_id].quantity == 1) ||
                  (deliver[item.pinc_id] == undefined && item.avail == 1)
                "
                (click)="
                  updatedDeliver(item);
                  deliver[item.pinc_id].quantity =
                    deliver[item.pinc_id].quantity - 1;
                  update()
                "
              >
                <ion-icon
                  slot="icon-only"
                  name="remove-circle-outline"
                ></ion-icon>
              </ion-button>
              <ion-label class="counter_item" slot="end"
                >{{
                  deliver[item.pinc_id] == undefined
                    ? item.avail
                    : deliver[item.pinc_id].quantity
                }}
                / {{ item.avail }}</ion-label
              >

              <ion-button
                [color]="item.checkin ? 'primary' : 'light'"
                fill="clear"
                slot="end"
                class="remove-button"
                [disabled]="
                  (deliver[item.pinc_id] != undefined &&
                    deliver[item.pinc_id].quantity == item.avail) ||
                  deliver[item.pinc_id] == undefined
                "
                (click)="
                  updatedDeliver(item);
                  deliver[item.pinc_id].quantity =
                    deliver[item.pinc_id].quantity + 1;
                  update()
                "
              >
                <ion-icon slot="icon-only" name="add-circle-outline"></ion-icon>
              </ion-button>
            </ng-container>
            <ion-chip
              slot="end"
              color="primary"
              *ngIf="
                item.avail <= 0 &&
                (item.mi_id != undefined || item.item_options != undefined)
              "
              ><ion-label>All Requested</ion-label></ion-chip
            >

            <ion-chip
              slot="end"
              color="primary"
              *ngIf="
                item.avail > 0 &&
                (item.mi_id != undefined || item.item_options != undefined) &&
                status == 'finished'
              "
              ><ion-label>{{ item.avail }} Left</ion-label></ion-chip
            >

            <ion-chip
              slot="end"
              color="primary"
              *ngIf="item.mi_id == undefined && item.item_options == undefined"
              ><ion-label>Decorative</ion-label></ion-chip
            >
          </ng-container>
        </ion-item>
        <ion-item-options side="end">
          <ion-item-option
            *ngIf="
              status != 'new' && status != 'finished' && status != 'seated'
            "
            color="danger"
            (click)="removeItem(item, package)"
          >
            <ion-icon slot="icon-only" name="trash"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
      <div
        class="modlist"
        *ngIf="
          item.item_options != undefined &&
          item.item_options.length > 0 &&
          status == 'seated' &&
          deliver[item.pinc_id] != undefined &&
          deliver[item.pinc_id].active &&
          deliver[item.pinc_id].quantity > 0
        "
      >
        <ng-container
          *ngFor="
            let dwa of deliver[item.pinc_id].options;
            trackBy: trackByID;
            let i = index
          "
        >
          <ion-item
            [style.display]="
              i >= deliver[item.pinc_id].quantity ? 'none' : 'block'
            "
          >
            <ion-label position="stacked"> Item {{ i + 1 }} </ion-label>

            <ion-label class="endtop" position="stacked">
              Select Option</ion-label
            >
            <div class="scrollmod">
              <ion-button
                size="default"
                *ngFor="let option of item.item_options"
                [fill]="
                  deliver[item.pinc_id].options[i] == option
                    ? 'solid'
                    : 'outline'
                "
                (click)="deliver[item.pinc_id].options[i] = option; update()"
                [style.font-size.px]="14"
              >
                {{ (menu_item$(option) | async)?.name }}
              </ion-button>
            </div>
          </ion-item>
        </ng-container>
      </div>
    </ng-container>
  </ion-list>
</ng-container>
