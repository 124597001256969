<div class="background">
  <div class="wrapper">
    <div>
      <div class="card">
        <img src="../../../assets/Artboard 2 copy 3.png" />

        <h2>Basic</h2>
        <div class="features">
          <h6>- Staff Ordering</h6>
          <h6>- POS Integration</h6>
          <h6>- Assisted Onboarding</h6>
          <h6>- Reporting and Analytics</h6>
          <h6>- Contactless Ordering</h6>
        </div>
        <h2>$100/mth</h2>
      </div>
      <ion-button expand="block" (click)="signup('basicmth')">Setup</ion-button>
    </div>
    <div>
      <div class="card main">
        <img src="../../../assets/Artboard 2 copy 2.png" />
        <h2>Advanced</h2>
        <div class="features">
          <h6>- 0% Managed Payment Fee</h6>
          <h6>- In-Person Staff Training</h6>
          <h6>- Map & Menu Build</h6>
          <h6>- 24/7 Support</h6>
          <h6>- Included Dispense Tablet</h6>
        </div>
        <h2>$500/mth</h2>
      </div>
      <ion-button expand="block" (click)="signup('advancmth')"
        >Setup</ion-button
      >
      <!-- link to pricing page getdqd.com -->
    </div>
    <div>
      <div class="card">
        <h2>Enterprise</h2>
        <div class="features">
          <h6>- API Access</h6>
          <h6>- Custom Integrations</h6>
          <h6>- Company Wide Analytics</h6>
          <h6>- Auditable Activity Log</h6>
        </div>
        <h2>Contact Us</h2>
      </div>
      <ion-button expand="block">Contact Us</ion-button>
    </div>
  </div>
</div>
