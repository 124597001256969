import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
@Component({
  selector: "app-empty",
  templateUrl: "./empty.component.html",
  styleUrls: ["./empty.component.scss"],
})
export class EmptyComponent implements OnInit {
  @Input() icon: string;
  @Input() title?: string;
  @Input() label: string;
  @Input() tip?: string;

  @Input() height?: "top" | "bottom" | "thin" | "full-height";

  @Output() tipClicked = new EventEmitter();

  

  constructor(

  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

}
