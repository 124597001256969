import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { filter, map, switchMap, take } from "rxjs/operators";
import { MapObject, Map as MEP } from "src/app/models/map_editor_models";
import { VenuesFacadeService } from "src/app/facades/venues-facade/venues-facade.service";
import { RegionsService } from "../regions/regions.service";

@Injectable({
  providedIn: "root",
})
export class MapService {
  constructor(
    private http: HttpClient,
    private venueFacade: VenuesFacadeService,
    private reg: RegionsService
  ) {}

  getMap() {
    const path = "/v4/venue/map";

    return this.http.get<MEP>(`${this.reg.ServerURL()}${path}`, {});
  }

  async saveMap(map) {
    const path = "/v4/venue/map";

    return this.http.post<MEP>(`${this.reg.ServerURL()}${path}`, map);
  }
}
