import { EventEmitter, Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import Pusher from "pusher-js";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { RegionsService } from "../regions/regions.service";
import StripeNative from "src/app/services/stripe-native/stripe-native.service";
import { Platform, ToastController } from "@ionic/angular";
import { AnalyticsService } from "../analytics/analytics.service";
import { select, Store } from "@ngrx/store";
import {
  State,
  venuesTerminalId,
  venuesSelectHaveBeenLoaded,
  venuesSelectAll,
  venuesSelectSelectedTableId,
} from "src/app/reducers";
import { combineLatest, filter, map, take, withLatestFrom } from "rxjs";
import { selectSelectedVenue } from "src/app/reducers/venues/venues.reducer";
import { Capacitor } from "@capacitor/core";
import { SettingsState } from "../sync-engine/settings-state/settings-state.service";
import { NativeStorage } from "@awesome-cordova-plugins/native-storage/ngx";
import { Hub, Logger } from "aws-amplify";
import {
  StripeTerminalPlugin,
  DiscoveryMethod,
  ConnectionStatus,
  ReaderNetworkStatus,
} from "dq-capacitor-stripe-terminal";
import { HttpClient } from "@angular/common/http";
import { RxState } from "@rx-angular/state";

@Injectable({
  providedIn: "root",
})
export class MobilePayService {
  terminal: StripeTerminalPlugin;

  state$: RxState<any> = new RxState<{
    available: boolean; //wether or not we should even bother trying to connect and show it on the fronternd
    ready: boolean; //we good to go and take payments
    error_state: string; //an error state to display to the user
    connected_location: string; //the location we are connected to
  }>();
  status_text$ = this.state$.select().pipe(
    map((x) => {
      if (x.available == false) {
        return "Not Available";
      }

      if (x.ready == false) {
        if (x.error_state != undefined && x.error_state != "") {
          return x.error_state;
        }

        return "preparing";
      }

      return "ready";
    })
  );

  available$ = this.state$.select("available");

  ready$ = this.state$.select("ready");
  //currently able to take payments ""status"""

  constructor(
    private http: HttpClient,

    private auth: AuthClass,
    private reg: RegionsService,
    private toast: ToastController,
    private analy: AnalyticsService,
    private platform: Platform,
    private settingSt: SettingsState,
    private nativeStorage: NativeStorage
  ) {
    this.state$.set({
      available: false,
      ready: false,
    });
    this.InitSubscribers();

    this.platform.resume.subscribe(async (x) => {
      console.log("Resuming Terminal, reinitilising");
      if (this.terminal == undefined || this.terminal.isInitialized == false) {
        //reconect to reader
        this.state$.set({
          ready: false,
        });
        console.log("need to re init whole terminal");
        this.InitSubscribers();
      } else {
        console.log(
          "terminal is already initilised, just need to reconnect to reader"
        );

        let reader = await this.terminal.getConnectedReader();
        let connectionStatus = await this.terminal.getConnectionStatus();

        if (
          reader == undefined &&
          connectionStatus == ConnectionStatus.NotConnected
        ) {
          console.log("reader is not ready", reader);

          console.log(
            "we could trigger a reconnect here, but we are not",
            reader
          );
        } else {
          console.log("reader was already ready");
        }
      }
    });
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signOut") {
        //need to disable stripe terminals
        if (this.terminal != undefined) {
          this.terminal.disconnectReader();
          this.terminal.clearCachedCredentials();
        }
      }
    });
  }
  //this should handle creating and mainting an accurate base terminal
  //setup and connection, it should handle permissions, and avialablity
  //if a user changes venue ect it should handle this
  //if a change occurs to the terminal, it should trigger a reconnect
  async InitSubscribers() {
    console.log("Init terminal");

    if (
      this.platform.is("capacitor") &&
      StripeTerminalPlugin.tapToPaySupported
    ) {
      this.state$.set({
        available: true,
      });
    } else {
      this.state$.set({
        available: false,
      });
      return;
    }
    //check we are not on ios
    if (!this.platform.is("ios")) {
      let perms = await StripeTerminalPlugin.checkPermissions();

      if (perms.location == "denied") {
        console.log("Permissions denied");
        this.state$.set({
          error_state: "Locations permissions denied",
        });
        return;
      } else if (perms.location == "granted") {
        console.log("Permissions location granted");
      } else {
        await StripeTerminalPlugin.requestPermissions();
        this.InitSubscribers();
        return;
      }
    }
    console.log("Passed permissions and platform checks");

    if (this.platform.is("capacitor")) {
      combineLatest([
        this.settingSt.currentUser$,
        this.settingSt.venueId$,
        this.settingSt.venue$,
      ]).subscribe(async ([user, venueId, venue]) => {
        console.log("terminal id", venue.stripe_location_id);
        console.log("starting to init");

        if (
          venue.stripe_location_id != undefined &&
          venue.stripe_location_id != null &&
          venue.stripe_location_id != "" &&
          StripeTerminalPlugin.tapToPaySupported
        ) {
          this.state$.set({
            available: true,
            error_state: "",
          });
          let last_location = this.state$.get("connected_location");
          if (
            last_location != venue.stripe_location_id &&
            this.terminal != undefined
          ) {
            console.log("need to connect to new location, deleting old one");

            await this.terminal.disconnectReader();
            await this.terminal.clearCachedCredentials;
            this.terminal = undefined;
            this.state$.set({
              ready: false,
            });
          }

          if (this.terminal == undefined) {
            console.log("Creating new terminal");

            this.terminal = await StripeTerminalPlugin.create({
              fetchConnectionToken: async () => {
                const resp = await this.http
                  .post<{ secret: string }>(
                    `${this.reg.ServerURL()}/v3/connection_token`,
                    {}
                  )
                  .toPromise();

                return resp.secret;
              },
              onUnexpectedReaderDisconnect: () => {
                // handle reader disconnect
                this.state$.set({
                  ready: false,
                });
                this.connect();
              },
            });

            this.terminal.didSucceedReaderReconnect.bind((x) => {
              console.log("Reader reconnected", x);
              this.state$.set({
                ready: true,
              });
            });
            this.state$.set({
              connected_location: venue.stripe_location_id,
            });
            this.connect();
          }

          console.log("Terminal created");
        } else {
          this.state$.set({
            available: false,
          });
        }
      });

      this.settingSt.InitStore();
    }
  }
  discovering = false;
  async connect() {
    console.log("Connecting readers start");

    if (this.terminal == undefined) {
      console.log("No terminal to use to connect a reader to");

      return;
    }

    if (this.terminal.isInitialized == false) {
      console.log("Terminal not initilised yet, unsure what to do here");

      return;
    }
    let state = await this.terminal.getConnectionStatus();
    if (state == ConnectionStatus.NotConnected) {
      console.log("Not connected to a reader, lets try to connect", state);
    } else {
      console.log(
        "Already connected to a reader, unsure what to do here",
        state
      );
      this.state$.set({
        ready: true,
      });
      return;
    }

    if ((await this.terminal.getConnectedReader()) != undefined) {
      console.log("Already connected to a reader, unsure what to do here");
      this.state$.set({
        ready: true,
      });
      return;
    } else {
      console.log("Not connected to a readerm2, lets try to connect");
    }
    try {
      console.log("Discovering readers");
      if (this.discovering) {
        console.log("Already discovering readers");
        return;
      }
      this.discovering = true;

      this.terminal
        .discoverReaders({
          discoveryMethod: DiscoveryMethod.LocalMobile,
          simulated: false,
        })
        .subscribe(async (readers) => {
          //user type
          let can_setup = await this.settingSt.hasClaim$('manage_venue')
            .pipe(take(1))
            .toPromise();

          if (readers.length) {
            let location = this.state$.get("connected_location");
            let reader = await this.terminal.connectLocalMobileReader(
              readers[0],
              {
                locationId: location,
                tosAcceptancePermitted: can_setup,
              }
            );

            this.state$.set({
              ready: true,
            });
            await this.terminal.cancelDiscoverReaders();
            this.discovering = false;
          }
        });
    } catch (e) {
      console.log("Failed to start discovering reader", e);
    }
  }
  /*
  async StripeSetup(terminalId) {
    if (this.initilised != true) {
      this.initilised = true;
      this.broadcastStatusUpdate();
      console.log("Setting up Plugin Listner");
      StripeNative.addListener("STRIPE_TERMINAL_MESSAGE", async (x) => {
        console.log("Stripe message", x);

        let message = x as any;
        //add a check to see this is the right message XD

        console.log("Web knows we are enabled!");
        //switch for x.type
        switch (message.type) {
          case "setup":
            if (message.status == "success") {
              if (this.connected == false && this.unusable == false) {
                await StripeNative.connectMobileReader({
                  locationId: terminalId,
                });
                this.connectedLocation = terminalId;
              }
            }
            if (message.status == "failed") {
              console.log("Failure toast");
              /*  await this.toast
                .create({
                  message: message.message,
                  duration: 3000,
                  position: "top",
                })
                .then(async (x) => await x.present());
                
              this.unusable = true;
              this.initilised = false;
            }
            break;
          case "connection":
            if (message.status == "success") {
              this.connected = true;
              this.broadcastStatusUpdate();
            } else if (message.status == "failed") {
              if (message.message.includes("=9020")) {
                console.log("Reader in wrong region");
              }
              if (message.message.includes("=5000")) {
                console.log("Unkown Stripe Specific Error");
              }
              this.unusable = true;
              this.broadcastStatusUpdate();

              /*await this.toast
          .create({
            message: message.message,
            duration: 3000,
            position: "top",
          })
          .then(async (x) => await x.present()); 
            }

            break;

          case "charge":
            if (message.secret != this.activeCharge) {
              console.log("fucked if i know what went wrong but this is bad");
            } else {
              if (message.status == "success") {
                //need to report this to payment page?
                this.PaymentUpdate.emit(message);
              }

              if (message.status == "failure") {
                //need to report this to payment page and perform action to cancell payment.
                this.PaymentUpdate.emit(message);
              }
            }

            break;
          case "reader":
            if (message.status == "disabled") {
              console.log("Temporarily disabling this");
              //need to report this to payment page?
              this.readerStatus = false;
              this.broadcastStatusUpdate();
            }

            if (message.status == "enabled") {
              console.log("enabling");
              //need to report this to payment page and perform action to cancell payment.
              this.readerStatus = true;
              this.broadcastStatusUpdate();
            }

            break;
        }
      });

      await StripeNative.checkMobileReaderSupport({});
    }
  }

  broadcastStatusUpdate() {
    if (this.laststate != this.status) {
      this.laststate = this.status;
      console.log("Broadcasting status update", this.status);
      this.ReaderStatus.emit(this.status);
    }
  }
  */
  async StartPayment(secret) {
    if (this.terminal != undefined) {
      try {
        await this.terminal.retrievePaymentIntent(secret);
      } catch (e) {
        console.log(e);
        console.log("Failed to retrieve payment intent");
        return "failure";
      }

      try {
        await this.terminal.collectPaymentMethod({
          updatePaymentIntent: true,
        });
      } catch (e) {
        console.log(e);
        console.log("Failed to collect payment method");

        return "failure";
      }

      try {
        await this.terminal.processPayment();
      } catch (e) {
        console.log(e);
        console.log("Failed to process payment");

        return "failure";
      }
      return "success";
    }
    console.log("Terminal has not been setup you dipshit");

    return "failure";
  }
}
