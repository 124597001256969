<ion-tabs>
  <ion-tab-bar slot="bottom" color="medium" class="tabbar-tab">
    <ion-tab-button tab="promoter">
      <ion-icon name="reorder-four-outline"></ion-icon>
      <ion-label>Events</ion-label>
    </ion-tab-button>

    <!-- END -->
    <ion-tab-button tab="notifications">
      <ion-icon name="chatbox-outline"></ion-icon>
      <ion-label>Notif</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="bookings">
      <ion-icon name="calendar-outline"></ion-icon>
      <ion-label>Bookings</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
