import { createAction, props } from "@ngrx/store";
import { DqCartItem } from "../models/cart.model";
import { DqConsumerTabCredit } from "../pages/consumer-event/services/consumer-event/consumer-event.service";
import { DqGratuityType } from "../services/order/order.service";

const ACTION_PREFIX = "[Cart]";

export const addToCart = createAction(
  `${ACTION_PREFIX} Add Item to Cart`,
  props<{ item: DqCartItem }>()
);

export const incrementItemQuantity = createAction(
  `${ACTION_PREFIX} Increment Item Quantity`,
  props<{ itemId: string; tableId: string }>()
);

export const decrementItemQuantity = createAction(
  `${ACTION_PREFIX} Decrement Item Quantity`,
  props<{ itemId: string; tableId: string }>()
);

export const removeCartItem = createAction(
  `${ACTION_PREFIX} Remove Cart Item`,
  props<{ itemId: string; tableId: string }>()
);

export const updateItemComment = createAction(
  `${ACTION_PREFIX} Update Item Comment`,
  props<{ itemId: string; comment: string; tableId: string }>()
);

export const loadSavedCartAtStartup = createAction(
  `${ACTION_PREFIX} Load Cart At Startup`,
  props<{
    items: DqCartItem[];
    gratuity?: {
      value: number;
      gratuityType: DqGratuityType;
    };
    orderUuid: string;
    couponCodes: DqConsumerTabCredit[];
  }>()
);

export const orderPlacedSuccessfully = createAction(
  `${ACTION_PREFIX} Order Successfully Placed`,
  props<{ tableId: string }>()
);

export const clearCart = createAction(`${ACTION_PREFIX} Clear Cart`);

export const changeCartToTable = createAction(
  `${ACTION_PREFIX} Change Cart Table`,
  props<{ newTableId: string; itemIdsNotAvailable: string[] }>()
);


export const monitorTreatStatus = createAction(
  `${ACTION_PREFIX} Monitoring Treat Status`,
  props<{ treatId: string }>()
);

export const monitorTreatStatusComplete = createAction(
  `${ACTION_PREFIX} Monitoring Treat Status Complete, Treat Expired`
);

