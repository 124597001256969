import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
} from "@angular/core";
import { int } from "@zxing/library/esm/customTypings";

import {
  HttpRequest,
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import { RegionsService } from "src/app/services/regions/regions.service";
import { IonInput } from "@ionic/angular";
import { captureRejections } from "events";

@Component({
  selector: "app-imageuploader",
  templateUrl: "./imageuploader.component.html",
  styleUrls: ["./imageuploader.component.scss"],
})
export class ImageuploaderComponent {
  //Current suuplied image (should always be displayed if not null)
  @Input() currentimage?: string = "";
  @Input() size?: string = "";
  //Current Search Term
  //output if a new image is selected
  @Output() UploadedEvent = new EventEmitter<String>();

  @ViewChildren("file") input: ElementRef;
  Loading = false;
  //the actual current image
  LocalUrl = "";
  showUrl = "";
  lastsearch = "";
  saveditem;
  constructor(public http: HttpClient, private reg: RegionsService) {}

  async ngOnChanges() {
    //called on an input change, and on init
  }

  public clear() {
    this.currentimage = undefined;
    this.showUrl = "";
  }

  openFile() {
    this.input.nativeElement.click;
  }
  handle(e) {}

  async uploaded(file: File) {
    //make http call to server to get the pre signed url

    this.Loading = true;
    //wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (file != undefined) {
      let url = (await this.http
        .get(`${this.reg.ServerURL()}/cms_v2/venue/upload`)
        .toPromise()) as any;

      const formData = new FormData();
      formData.append('file', file);
      const upload = await this.http.post(url.target, formData, {responseType: 'text'}).toPromise();
      this.currentimage = 'https://imagedelivery.net/UGOsP3nuwKcdFinTYNdcGg/'+url.object;
      console.log(upload);
      this.UploadedEvent.emit('https://imagedelivery.net/UGOsP3nuwKcdFinTYNdcGg/'+url.object);

      this.Loading = false;
    }
  }
  //AIzaSyA8xmPBmfI_WeYaZFykimSHiks9kTdAXJM

  //GET https://customsearch.googleapis.com/customsearch/v1?imgDominantColor=white&q=test&rights=c_publicdomain%2Ccc_nonderived%2Ccc_sharealike%2Ccc_attribute&safe=high&searchType=image&key=[YOUR_API_KEY] HTTP/1.1

  //Accept: application/json
}
