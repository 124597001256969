<ion-item
  [style.--inner-padding-top.px]="5"
  [style.--inner-padding-bottom.px]="5"
  [style.--padding-start.px]="reduced ? 8 : 20"
  lines="none"
  class="manual"
  [color]="color"
>
  <ion-label class="ion-no-margin" [style.width.%]="100">
    <ion-row [style.width.%]="100" class="ion-justify-content-between">
      <ion-label position="stacked" class="ion-text-capitalize">
        {{ title }}</ion-label
      >
      <ion-note
        [style.font-size.px]="14"
        position="stacked"
        *ngIf="subtitle != undefined"
        >{{ subtitle }}</ion-note
      >
    </ion-row>
    <div class="tags" [class.wrap]="wrap">
      <app-tag
        *ngFor="let tag of showableTags; trackBy: trackByTag"
        [tag]="tag"
        [edit]="edit"
        (clicked)="tagClicked.emit(tag)"
        (removed)="edit ? removeTag(tag) : tagClicked.emit(tag)"
      >
      </app-tag>
      <ion-chip
        (click)="editTags()"
        *ngIf="edit"
        [outline]="true"
        color="light"
      >
        <ion-icon [style.margin-left.px]="-4" name="create-outline"></ion-icon>
      </ion-chip>
    </div>
  </ion-label>
</ion-item>
