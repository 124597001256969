import { createAction, props } from "@ngrx/store";

const ACTION_PREFIX = "[Consumer Cart Page Step 2]";

export const didEnter = createAction(
  `${ACTION_PREFIX} Did Enter`,
  props<{ tableId: string }>()
);

export const didGetBindingQuote = createAction(
  `${ACTION_PREFIX} Did Get Binding Quote`,
  props<{ orderUuid: string }>()
);
