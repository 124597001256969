<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-nav-link router-direction="back">
        <ion-button expand="full" color="primary">
          Back
          <ion-icon name="arrow-back" slot="start"></ion-icon>
        </ion-button>
      </ion-nav-link>
    </ion-buttons>
    <ion-title>Data Store</ion-title>
    <ion-buttons slot="end">
      <ion-button expand="full" color="primary" (click)="clearAll()">
        Clear Cache
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-accordion-group>
      <ion-accordion
        [value]="service.data_icon"
        *ngFor="let service of sync.services"
      >
        <ion-item slot="header" color="secondary">
          <ion-icon slot="start" [name]="service.service_icon"></ion-icon>
          <ion-label>{{service.service_title}}</ion-label>
          <ion-note slot="end"
            >{{(service.loaded$ | async) ? (service.last_synced$ | async | date:
            'h:mm:ss') : 'Unloaded'}}</ion-note
          >
        </ion-item>
        <div class="" slot="content">
          <app-dq-infinite-scroll
            [data]="service.getItems$ | async"
            (chunkedData)="display[service.service_id] = $event"
          >
            <ion-item
              lines="full"
              *ngFor="let item of display[service.service_id]"
              (click)="selected = item; DD.present()"
            >
              <ion-label
                ><ion-label>{{item[service.data_detail]}}</ion-label>
                <ion-note>{{item[service.data_id]}}</ion-note></ion-label
              >
              <ion-note slot="end">{{item.sort_key || "No V Key"}}</ion-note>
            </ion-item>
          </app-dq-infinite-scroll>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-list>
</ion-content>

<ion-modal
  #DD
  id="viewdataofitem"
  cssClass="bottomonly"
  [presentingElement]="presenting"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>JSON Object</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="DD.dismiss()">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item *ngFor="let item of selected | keyvalue">
          <ion-label>{{ item.key }}</ion-label>
          <ion-note slot="end">{{ item.value }}</ion-note>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>
