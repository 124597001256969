/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_content_delivery_bucket": "dq-consumer-app-preview",
  "aws_content_delivery_bucket_region": "ap-southeast-2",
  "aws_content_delivery_url": "https://d1uca3w587bis9.cloudfront.net",
  "aws_appsync_graphqlEndpoint": "https://tkfih355xfh7tg4z352ha37mgi.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-2",
  "aws_appsync_authenticationType": "AWS_IAM",
  "aws_cognito_identity_pool_id": "ap-southeast-2:1f353dcd-1ae9-492f-96f1-22c53c017c9a",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_8UnjB1D9t",
  "aws_user_pools_web_client_id": "1hpga2e02taklgfcpphps2ts2l",
  "oauth": {}
};

export default awsmobile;