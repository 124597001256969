import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, mapTo, switchMap, take } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { SyncV1Notification } from "src/app/models/sync_models";
import { CognitoUser } from "@aws-amplify/auth";
import { SettingsState } from "../settings-state/settings-state.service";

@Injectable({
  providedIn: "root",
})
export class NotificationState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Notification }>();

  //fields specific to the service
  service_icon: string = "chatbox-outline";
  service_title: string = "Notif Store";
  service_id: string = "Notification"; //id of the service
  service_url: string = "notifications";

  //fields specific to the data
  data_id: string = "note_id";
  data_detail: string = "sender";
  data_expiry: number = 200; //in minutes, the amount of time before the data is considered unusable

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService,
    private settingSt: SettingsState
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));

  //get table using id
  reciept$ = (id) => this.state.select(id);

  notifications$ = this.state.select(map((x) => Object.values(x)));

  relevant$ = (table_id: string, timeout_success_length_minutes: number) =>
    this.state.select(
      map((x) => {
        if (x == undefined) {
          return undefined;
        }
        //filter out notifications that are not for this table and that have been accepted more than the success timeout ago
        let d = Object.values(x).filter((y) => {
          //debug when the time we dont care abnout a notification is

          return (
            (y.notification_class == "manager_request" ||
              y.notification_class == "security_request") &&
            (y.target_table_id == table_id ||
              (y.target_table_id == undefined && table_id == "bookings")) &&
            ((y.can_be_accepted && new Date().getTime() - new Date(y.created_utc).getTime() <
            24 * 60 * 60 * 1000) ||
              (y.can_be_accepted == false &&
                new Date().getTime() - new Date(y.created_utc).getTime() <
                  timeout_success_length_minutes * 60 * 1000))
            //c
          );
        });
        if (d.length > 0) {
          //sort by created date, latest first
          d.sort((a, b) => {
            return (
              new Date(b.created_utc).getTime() -
              new Date(a.created_utc).getTime()
            );
          });
          return d[0];
        } else {
          return undefined;
        }
      })
    );

  shouldBeSeen(notification, user: CognitoUser) {
    if (user["username"] == notification.sender) {
      return true;
    }
    //notification.for_user_claims

    //check if the user has any of the claims on the list of relevant claims provided on the notification
    //using settingSt.hasClaim
    //this.settingSt.hasClaim$
    if (notification.for_user_claims && notification.for_user_claims.length > 0) {
      return this.settingSt.currentClaims$.pipe(
        take(1),
        map(claims => {
          return notification.for_user_claims.some(claim => claims.includes(claim));
        })
      );

    }


 

    /*
    if (
      (user["attributes"]["custom:user_type"] == "1" ||
        user["attributes"]["custom:user_type"] == "4") &&
      notification.for_user_types.includes("foh_admin")
    ) {
      return true;
    }

    if (
      user["attributes"]["custom:user_type"] == "13" &&
      notification.for_user_types.includes("promoter_user")
    ) {
      return true;
    }

    if (
      notification.for_user_types.includes("foh_user") &&
      user["attributes"]["custom:user_type"] == "2"
    ) {
      return true;
    }
    if (
      notification.for_user_types.includes("boh_user") &&
      user["attributes"]["custom:user_type"] == "3"
    ) {
      return true;
    }

    if (notification.notification_class == "manager_announce") {
      if (
        user["attributes"]["custom:user_type"] == "2" &&
        notification.for_user_types.includes("foh_user")
      ) {
        return true;
      }
      if (
        user["attributes"]["custom:user_type"] == "3" &&
        notification.for_user_types.includes("boh_user")
      ) {
        return true;
      }
    }*/

    return false;

    //if your a staff member check if
  }
}
