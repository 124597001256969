import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { from, Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { StorageAdapter } from "../services/storage-adapter/storage-adapter.service";
import { RegionsService } from "../services/regions/regions.service";
import { ToastController } from "@ionic/angular";
import { SyncService } from "../services/sync-engine/sync/sync.service";
const STORAGE_KEY_USER_ID = "queuebar:user_id";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthClass,
    private storage: StorageAdapter,
    private reg: RegionsService,
    public toast: ToastController,
    private sync: SyncService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          console.log("ErrorInterceptor", err);
          if (err.status >= 400 && err.status < 500) {
            console.log("error showing starting", err.url);
            // auto logout if 401 response returned from api
            if (
              (err.error != undefined && err.error.message != undefined) ||
              ""
            ) {
              const toast = this.toast
                .create({
                  message: err.error.message,
                  duration: 2000,
                  color: "danger",
                  position: "top",
                  icon: "warning-outline",
                })
                .then((toast) => toast.present());
              Haptics.vibrate();
            } else {
              console.log("Error Returned with no message");
            }

            return throwError(err);
          } else {
            if (
              err.status == 0 &&
              !err.url.includes("/sync/") &&
              this.sync.error_toast == undefined
            ) {
              //double check if we are offline
              console.log("we are online", navigator.onLine);

              console.log("Present toast");
              const toast = this.toast
                .create({
                  header: "Connectivity Issues",
                  message: "Please check your network connection",
                  duration: 3000,
                  color: "danger",
                  position: "top",
                  icon: "warning-outline",
                })
                .then((toast) => toast.present());
            }

            return throwError(err);
          }
        }
      })
    );
  }
}
