<ion-row class="ion-align-items-center" all-items-stretch>
  <!-- GET MENU ID DYNAMICALLY -->
  <ion-col class="item_label">{{ item.i_name }}</ion-col>
  <ion-col class="price">
    {{ item.i_price | currency: (currency$ | async) }}</ion-col
  >
  <ion-col class="btn-add-item btn-item-action ion-align-self-stretch">
    <ion-button expand="full" color="pink" (click)="addItemToCart($event)">
      <ion-icon name="add" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
