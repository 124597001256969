import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { PaymentUserActionRequest } from 'src/app/models/payment_models';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { RegionsService } from 'src/app/services/regions/regions.service';
import { SettingsState } from 'src/app/services/sync-engine/settings-state/settings-state.service';

@Component({
  selector: 'app-timeline-reciept',
  templateUrl: './timeline-reciept.component.html',
  styleUrls: ['./timeline-reciept.component.scss'],
})
export class TimelineRecieptComponent  implements OnInit {
  @Input() payments: any[]
  @Input() recieptId
  cancelling = false;

  
  currency$ = this.settingSt.currencyCode$;

  constructor(private settingSt: SettingsState, private paymentSvc: PaymentService, private alertCtrl: AlertController, private router: Router, private reg: RegionsService, private toastCtrl: ToastController) { }

  ngOnInit() {
    console.log(this.payments);
  }


  async refundPayment(payment) {
    this.cancelling = true;
    let action: PaymentUserActionRequest = {
      old_state: payment.refundable.old_state,
      new_state: payment.refundable.new_state,
    };

    let act = await this.paymentSvc
      .postPaymentAction(this.recieptId, payment.pi_uuid, action)
      .toPromise();
    console.log("refundPayment: ", act);
    if (payment.view_process != undefined && payment.view_process == true) {
      this.router.navigate([this.reg.getRegion(), "pay", this.recieptId], {
        queryParams: { payment: payment.pi_uuid },
      });
    }

    this.cancelling = false;
  }

  async recieptSend(payment) {
    //show alert and ask to enter email or phone number
    //send email or phone number to reciept

    let al = await this.alertCtrl.create({
      header: "Send Receipt",
      message: "Enter Email or Phone Number",
      inputs: [
        {
          name: "email",
          type: "email",
          placeholder: "Email or Phone",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Send",
          handler: async (data) => {
            console.log("send reciept", data);

            let act = await this.paymentSvc
              .sendPaymentReceipt(payment.pi_uuid, data.email)
              .toPromise();
            console.log("refundPayment: ", act);
            //show toast
            let toast = await this.toastCtrl.create({
              message: "Receipt Sent to customer",
              duration: 3000,
              position: "top",
            });
            await toast.present();
          },
        },
      ],
    });
    await al.present();
  }

  async CancellPayment(payment) {
    this.cancelling = true;
    let action: PaymentUserActionRequest = {
      old_state: payment.status,
      new_state: "cancelled",
    };

    let act = await this.paymentSvc
      .postPaymentAction(this.recieptId, payment.pi_uuid, action)
      .toPromise();
    console.log("refundPayment: ", act);
    this.cancelling = false;
  }

}
