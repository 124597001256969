import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, Input } from "@angular/core";
import { tada } from "src/app/utils/animations";

@Component({
  selector: "app-header-cart",
  templateUrl: "./header-cart.component.html",
  styleUrls: ["./header-cart.component.scss"],
  animations: [
    trigger("cartUpdate", [transition(":increment", useAnimation(tada))]),
  ],
})
export class HeaderCartComponent {
  @Input() cartTotal: any;
  @Input() tableId: string;
  @Input() rootPath: string = "/table";

  constructor() {}
}
