/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export type ServiceMethod = "delivery" | "pickup" | "delivery_agent";
export type TableEntryAction = "menu_only" | "checkin_only" | "menu_and_checkin" | "treat_only" | "menu_and_treat";
/**
 * This is just an enum for the convenience of validation.
 */
export type PackageType = "advanced" | "upfront" | "minimum" | "minimumexc" | "package";
export type TagType = "taste" | "music" | "brand" | "general" | "highlight" | "system";
/**
 * If you update this, make sure you add it in to `getLogTypes()` in cms-settings.service.ts and re-export the models
 */
export type AuditEvent =
  | "order.created"
  | "order.accepted"
  | "order.declined"
  | "order.expired"
  | "order.preparing"
  | "order.ready"
  | "order.delivered"
  | "order.cancelled"
  | "payment.created"
  | "payment.completed"
  | "payment.updated"
  | "table.opened"
  | "table.closed"
  | "guest.moved"
  | "treat.created"
  | "treat.viewed"
  | "test.created"
  | "notification.created"
  | "notification.accepted"
  | "notification.deleted"
  | "booking.created"
  | "booking.accepted"
  | "booking.declined"
  | "booking.seated"
  | "booking.cancelled"
  | "booking.updated"
  | "package.created"
  | "package.updated"
  | "package.deleted"
  | "item.created"
  | "item.updated"
  | "item.deleted"
  | "table.created"
  | "table.updated"
  | "table.deleted"
  | "map.updated"
  | "settings.updated"
  | "pos.menu"
  | "pos.submission";
export type AuditLevel = "staff" | "manager" | "info" | "error";
export type CollectionRequirement = "not_shown" | "optional" | "required";

export interface CMSPOSImport {
  mappable_categories: CMSPOSMenuCategory[];
  new_items: CMSPOSMenuItemNew[];
  updated_items: CMSPOSMenuItemUpdate[];
  removed_items: CMSPosMenuItemDeleted[];
  notes: string[];
}
export interface CMSPOSMenuCategory {
  pos_cat_id: string;
  pos_name: string;
  menu_cat_id?: number;
}
export interface CMSPOSMenuItemNew {
  pos_sku: string;
  pos_name: string;
  pos_cat_id: string;
  pos_price: number;
  pos_description: string;
  to_import: boolean;
}
export interface CMSPOSMenuItemUpdate {
  pos_sku: string;
  i_id: number;
  pos_name: string;
  our_name: string;
  pos_cat_id: string;
  our_price: number;
  pos_price: number;
  to_import: boolean;
}
export interface CMSPosMenuItemDeleted {
  pos_sku: string;
  i_id: number;
  our_name: string;
  to_delete: boolean;
}
export interface CMSPaymentReconRequest {
  start: string;
  end: string;
  method_ids: string[];
  payment_status: "All" | "Paid";
}
export interface CMSRevenueTimeBins {
  rev_booking: Statistic[];
  rev_upsell: Statistic[];
  rev: Statistic[];
  tip: Statistic[];
  commission: Statistic[];
  rev_upsell_avrg?: Statistic[];
  rev_total_avrg?: Statistic[];
}
export interface Statistic {
  /**
   * Name of the statistic
   */
  name?: string;
  /**
   * Value of the statistic
   */
  value?: number;
}
export interface CMS_Event {
  /**
   * ID of the package
   */
  id: number;
  /**
   * Name of the package
   */
  name?: string;
  /**
   * Description of the package
   */
  description?: string;
  /**
   * Image of the package
   */
  image?: string;
  /**
   * Start time of the package in UTC
   */
  date?: string;
  /**
   * List of packages
   */
  packages?: CMS_Package[];
  /**
   * Deposit of the package
   */
  deposit?: number;
  /**
   * Calculate deposit of the package
   */
  deposit_calculate?: boolean;
  /**
   * Whether the event can be booked
   */
  can_book?: boolean;
  recurring?: boolean;
  sub_events?: CMS_Event[];
  availablity?: string;
  recurring_event_id?: number;
}
export interface CMS_Package {
  /**
   * ID of the package
   */
  id: number;
  /**
   * Name of the package
   */
  name?: string;
  /**
   * Number of guests
   */
  guests?: number;
  /**
   * Currency code of the package
   */
  currency_code?: string;
  /**
   * Price of the package
   */
  price?: number;
  /**
   * List of inclusions
   */
  inclusions?: CMS_PackageInclusion[];
  /**
   * List of tables
   */
  tables?: CMSv2Table[];
  /**
   * Deposit of the package
   */
  deposit?: number;
  /**
   * Minimum spend of the package
   */
  minimum_spend?: number;
  /**
   * Minimum spend of the package
   */
  included_spend?: number;
  chip?: string;
  subtitle?: string;
  type: PackageType;
}
export interface CMS_PackageInclusion {
  /**
   * ID of the inclusion
   */
  id: number;
  /**
   * Name of the inclusion
   */
  name?: string;
  /**
   * Quantity of the inclusion
   */
  quantity?: number;
  /**
   * ID of the menu item
   */
  menu_item_id: number;
  avail?: number;
  modifiers?: ConsumerModifierGroup[];
}
/**
 * "mg_id": self.mg_id,
 * "mg_name": self.mg_name,
 * "mg_description": self.mg_description,
 * "mg_min_selections": self.mg_min_selections,
 * "mg_max_selections": self.mg_max_selections,
 * "modifiers": mod_contents,
 */
export interface ConsumerModifierGroup {
  mg_id: number;
  mg_name?: string;
  /**
   * Description of the modifier group
   */
  mg_description?: string;
  /**
   * Minimum number of selections
   */
  mg_min_selections?: number;
  /**
   * Maximum number of selections
   */
  mg_max_selections?: number;
  modifiers?: ConsumerModifier[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface ConsumerModifier {
  mod_id: number;
  mod_name?: string;
  mod_price?: string;
}
export interface CMSv2Table {
  table_id?: number;
  table_name: string;
  vc_id: number;
  venue_id: number;
  table_status: number;
  doshii_table_id?: string;
  table_service: ServiceMethod;
  table_sort_order: number;
  table_entry_action: TableEntryAction;
  doshii_override_name?: string;
  table_capacity?: string;
}
export interface CMS_Event_List {
  /**
   * List of events
   */
  events?: CMS_Event[];
}
export interface CMS_Order_Stats {
  /**
   * Longest wait time
   */
  longest_wait?: number;
  /**
   * Shortest wait time
   */
  shortest_wait?: number;
  turnaround: Statistic[];
  /**
   * Average turnaround time
   */
  turnaroundAvrg?: number;
  tag_revenue: TagInstance[];
  orders: number;
  biggestItems: number;
}
export interface TagInstance {
  tag_id: string;
  tag_type: TagType;
  strength: number;
  strength_rendered: string;
  explanation?: string;
  selected?: boolean;
}
export interface CMS_Package_List {
  /**
   * List of packages
   */
  packages?: CMS_Package[];
}
export interface CMS_Rev_Stats {
  avrg: Statistic[];
  rev: Statistic[];
  count: Statistic[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2AailabilityInterval {
  id: number;
  start: string;
  end: string;
  day: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2AuditLog {
  event: AuditEvent;
  id: string;
  timestamp: number;
  level: AuditLevel;
  venue: string;
  owner: NestedLogData;
  message: string;
  price: string;
  order: NestedLogData;
  order_id?: string;
  guest: NestedLogData;
  guest_id?: string;
  table: NestedLogData;
  table_id?: string;
  receipt?: NestedLogData;
  receipt_state?: string;
  payment?: NestedLogData;
  payment_state?: string;
  data: string;
}
/**
 * Nested model for LogData
 */
export interface NestedLogData {
  id: string;
  Name: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2AvailabilityGroup {
  id: number;
  name: string;
  intervals: CMSv2AailabilityInterval[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2AvailabilityGroupList {
  avails: CMSv2AvailabilityGroup[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2CollectionList {
  collection_points: CMSv2CollectionPoint[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2CollectionPoint {
  vc_id: number;
  vc_name: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2CreateVenue {
  venue_name: string;
  email: string;
  password: string;
  booths: boolean;
  contactless: boolean;
  treat: boolean;
  venue_tables: string;
  agreements: boolean;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2CreateVenueResult {
  v_id: number;
  clientSecret?: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2Logs {
  logs: CMSv2AuditLog[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2ManagementModelOutput {
  phone_num: string;
  full_name: string;
  is_enabled: boolean;
  cog_user_name: string;
  user_type: string;
  role_id?: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2Menu {
  CMSv2MenuSuperCategory: CMSv2MenuSuperCategory[];
  pos_integrated?: boolean;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuSuperCategory {
  menu_cats: CMSv2MenuCategory[];
  name: string;
  sort_order: number;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuCategory {
  is_mixer: boolean;
  menu_cat_id: number;
  name: string;
  priority: number;
  CMSv2MenuItem?: CMSv2MenuItem[];
  availability?: CMSv2AvailabilityGroup;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuItem {
  is_pos_item: boolean;
  pos_id?: string;
  is_featured: boolean;
  is_mixer: boolean;
  is_active: boolean;
  is_treatable: boolean;
  is_staff: boolean;
  is_consumer: boolean;
  item_id: number;
  media: string;
  menu_cat_id: number;
  desc: string;
  name: string;
  price: string;
  ModifierGroups?: CMSv2MenuModifierGroup[];
  price_overrides?: CMSv2PriceOverride[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuModifierGroup {
  id: number;
  name: string;
  desc?: string;
  min_selections?: number;
  max_selections?: number;
  modifiers: CMSv2MenuModifier[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuModifier {
  id: number;
  name: string;
  price: string;
}
export interface CMSv2PriceOverride {
  id: number;
  avail_id: number;
  price_override: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuItemPriceOverrides {
  price_overrides: CMSv2PriceOverride[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2MenuModifierGroupList {
  modifier_groups: CMSv2MenuModifierGroup[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2TableList {
  tables: CMSv2Table[];
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2UserEnabledInput {
  enabled: boolean;
  role_id?: string;
  full_name?: string;
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface CMSv2UserList {
  users: CMSv2ManagementModelOutput[];
}
export interface CMSv2VenueSettings {
  v_name: string;
  v_desc?: string;
  v_thumbnail_image?: string;
  v_LargeImage?: string;
  v_logo?: string;
  v_address?: string;
  v_start_time?: string;
  v_end_time?: string;
  v_timezone?: string;
  v_stripe_account_id?: string;
  V_stripe_state?: string;
  v_feature_color: string;
  v_contact_email?: string;
  v_contact_phone?: string;
  v_customer_id?: string;
  v_id: number;
  lightspeed?: boolean;
  automatic_communication?: boolean;
  payments: {
    [k: string]: boolean;
  };
  tip_1: number;
  tip_2: number;
  tip_3: number;
  customer_arrival_times: boolean;
  enable_tips: boolean;
  booking_require_email: boolean;
  booking_customer_table_preference_allowed: boolean;
  booking_birthday_collection: CollectionRequirement;
  auto_send_receipt_table_close: boolean;
  exclude_deposit_rev_dashboard: boolean;
  surcharge_target: string;
  surcharge_ratio: number;
  surcharge_fixed: number;
requests_list: string[]
}
/**
 * Checks that the claimed venue id can be used by this user.
 */
export interface OGData {
  name: number;
  image: string;
  description: string;
}
export interface PydanticBaseEnumToString {}
