import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { combineLatest } from "rxjs";
import {
  concatMap,
  filter,
  map,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { closeTableSuccess, openTable } from "src/app/actions/table.actions";
import { OpenTableDidCloseData } from "src/app/pages/open-table-modal/open-table-modal.page";
import {
  collectionPointsSelectAll,
  State,
  tablesSelectAll,
  tablesSelectEntities,
} from "src/app/reducers";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import {
  DqOrderDetailsItem,
  DqPaymentMethod,
} from "src/app/services/order/order.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { SquarePaymentsService } from "src/app/services/square-payments/square-payments.service";
import {
  DqTableDetails,
  TableService,
} from "src/app/services/table/table.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TablesFacadeService {
  collectionPointsAll$ = this.store$.pipe(select(collectionPointsSelectAll));
  tablesAll$ = this.store$.pipe(select(tablesSelectAll));

  constructor(
    private currencyPipe: CurrencyPipe,
    private orderV2Svc: OrderV2Service,
    private squarePayments: SquarePaymentsService,
    private store$: Store<State>,
    private tableSvc: TableService,
    private regions: RegionsService
  ) {
    this.tablesAll$.subscribe((data) => {});
  }

  tableNeedsToBeClosed(table: DqTableDetails) {
    return table.must_close_session;
  }

  openTable(details: OpenTableDidCloseData, table: DqTableDetails) {
    this.store$.dispatch(
      openTable({
        details,
        tableId: table.table_id,
        collectionPointId: table.collection_id,
      })
    );
  }

  closeTableAndPay({
    tableId,
    subTotal,
    gratuity,
    paymentMethod,
    orderDetails,
  }: {
    tableId: string;
    subTotal: number;
    gratuity?: number;
    paymentMethod: DqPaymentMethod;
    orderDetails: DqOrderDetailsItem[];
  }) {
    if (paymentMethod === "square") {
    }
    return this.orderV2Svc
      .payForTable({
        tableId,
        total: subTotal,
        gratuity,
        paymentMethod,
      })
      .pipe(
        concatMap(() => this.orderV2Svc.closeTable({ tableId })),
        withLatestFrom(this.selectTableById(tableId)),
        tap(([result, table]) => {
          this.store$.dispatch(closeTableSuccess({ table }));
        })
      );
    // );
  }

  selectTableById(id: string) {
    return this.store$.pipe(
      select(tablesSelectEntities),
      map((tableEntities) => tableEntities[id]),
      filter((table) => !!table)
    );
  }

  getCollectionPointIdFromTableId(id: string) {
    return this.selectTableById(id).pipe(map((table) => table.collection_id));
  }

  getGuestForTable(tableId: string) {
    return this.selectTableById(tableId).pipe(
      map((table) => table.guest && table.guest.length > 0 && table.guest[0]),
      filter((guest) => !!guest)
    );
  }

  getExistingTableOrderId(tableId: string) {
    return this.selectTableById(tableId).pipe(
      map((table) => {
        const tableGuest = table && table.guest && table.guest[0];
        const orderId = tableGuest && tableGuest.order_id;
        return orderId && orderId !== "0" && orderId;
      })
    );
  }

  private getSquareNoteForOrder(
    items: DqOrderDetailsItem[],
    currencyCode: string
  ) {
    const orderIds = items.reduce<Set<string>>(
      (ids, item) => ids.add(item.od_order_id),
      new Set()
    );
    const itemsString = items
      .map(
        (item) =>
          `${item.od_item_name} - ${
            item.od_quantity
          } @ ${this.currencyPipe.transform(
            item.od_price,
            currencyCode
          )} = ${this.currencyPipe.transform(
            +item.od_quantity * +item.od_price,
            currencyCode
          )}`
      )
      .join("\n");

    return `${itemsString}

Queuebar Order Ids ${Array.from(orderIds)
      .map((id) => `#${id}`)
      .join(", ")}`;
  }
}
