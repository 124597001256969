<ion-header>
  <ion-toolbar color="">
    <ion-title>Select Option</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="guestRaw"
      placeholder="Search Options"
      [debounce]="500"
      (ngModelChange)="guestSearch = guestRaw.toLowerCase()"
      #searchbar
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content class="thinmodal transparentmodal" color="" class="">
  <ion-list class="ion-margin-start ion-margin-end">
    <ng-container *ngFor="let event of closeEvents$ | async">
      <ion-item-divider lines="none" color="black">
        <ion-note class="" >
          {{event.name}}
        </ion-note>
      </ion-item-divider>
      <ion-item-group class="rounded-group">

        <ng-container *ngFor="let package_id of event.package_ids">
          <ng-container *ngrxLet="package$(package_id) as package">
            
            <app-package-card
            class=""
            *ngIf="
            guestSearch == '' || package.name.toLowerCase().includes(guestSearch)
            "
          [package]="package"
          (click)="selectPackage(package)"
          >
        </app-package-card>
      </ng-container>
    </ng-container>
  </ion-item-group>

      </ng-container>
    

    <ion-item-divider color="black" class="ion-margin-top">
      <ion-note class="" >
        All Packages
      </ion-note>
    </ion-item-divider>
    <ion-item-group class="rounded-group ion-margin-bottom">

    <ng-container *ngFor="let package of packages$ | async">
     
      <app-package-card
        class=""
        *ngIf="
          guestSearch == '' || package.name.toLowerCase().includes(guestSearch)
        "
        [package]="package"
        (click)="selectPackage(package)"
      >
      </app-package-card>
      
    </ng-container>
    </ion-item-group>
  </ion-list>
</ion-content>
