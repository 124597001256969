import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Observable, Subscription, combineLatest, map } from "rxjs";
import { SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { TagInstance } from "src/app/models/live_tags";

export interface SyncV1TagInstance extends SyncV1TagDefinition, TagInstance {}

@Component({
  selector: "app-tag-groups",
  templateUrl: "./tag-groups.component.html",
  styleUrls: ["./tag-groups.component.scss"],
})
export class TagGroupsComponent implements OnInit, OnChanges {
  @Input() tags$: Observable<any>;
  @Input() show_manual = false;

  tag_groups$: Observable<any>;

  constructor(private region: RegionsService, private tagSt: TagState) {}

  ngOnInit() {
    //log output of tableDetail
    console.log("taggroupsforcomponent", this.tags$);
  }

  ngOnChanges() {
    if (this.tags$ != undefined) {
      this.tag_groups$ = combineLatest([this.tags$, this.tagSt.tag_maps$]).pipe(
        map(([tags, tagDefs]) => {
          let tag_groups: any = {};
          console.log("TEstdwad", tags, tagDefs);

          for (let tag of tags) {
            if (tagDefs[tag.tag_id] == undefined) {
              continue;
            }

            let tagDef = tagDefs[tag.tag_id];
            var tagType = tagDef.tag_type;

            if (["system", "general", "highlight"].includes(tagType)) {
              (tagType as any) = "other";
            }

            if (tag_groups[tagType] == undefined) {
              tag_groups[tagType] = {
                type: tagType,
                tags: [],
              };
            }

            tag_groups[tagType].tags.push(Object.assign({}, tag, tagDef));
          }

          console.log("taggroupsforcomponent", tag_groups);

          let val = Object.values(tag_groups);

          //order groups like this.
          //music taste brand other

          let order = ["music", "taste", "brand", "other"];

          val.sort((a: any, b: any) => {
            return order.indexOf(a.type) - order.indexOf(b.type);
          });

          return val;
        })
      );
    }
  }

  trackByType(index, item) {
    return item.type;
  }
}
