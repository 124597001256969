import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { CartFacadeService } from "src/app/facades/cart-facade/cart-facade.service";
import {
  DqGarnish,
  DqMixture,
  DqGarnishBasic,
  DqMixtureBasic,
} from "src/app/services/mixture/mixture.service";
import { DqOrderStatus } from "src/app/services/order/order.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";
import { DqModifierGroup } from "src/app/models/cart.model";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  AlertController,
  IonSearchbar,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { BookingService } from "src/app/services/booking/booking.service";
import { BookingGuest } from "src/app/models/bookings";
import { Router } from "@angular/router";
import { RegionsService } from "src/app/services/regions/regions.service";
import { PackageState } from "src/app/services/sync-engine/package-state/package.service";
import { combineLatest, map } from "rxjs";
import { EventState } from "src/app/services/sync-engine/event-state/event.service";

@Component({
  selector: "app-select-package",
  templateUrl: "./select-package.component.html",
  styleUrls: ["./select-package.component.scss"],
})
export class SelectPackageComponent implements OnInit, OnChanges {
  @Output() selectedPackage: EventEmitter<BookingGuest> = new EventEmitter();

  @Input() event_id: string;

  packages$ = this.packageSt.packagesPublic$;
  package$ = this.packageSt.package$

  closeEvents$ = this.eventSt.events$.pipe(
    map(events => events
      .filter(event => {
        console.log(this.event_id);
        if (this.event_id) {
          return event.event_id == this.event_id;
        }
        const now = new Date();
        const eventDate = new Date(event.e_date_local);
        const timeDiff = Math.abs(eventDate.getTime() - now.getTime());
        const hoursDiff = timeDiff / (1000 * 3600);
        return hoursDiff <= 48;
      })
      .sort((a, b) => {
        const now = new Date();
        const dateA = new Date(a.e_date_local);
        const dateB = new Date(b.e_date_local);
        return Math.abs(dateA.getTime() - now.getTime()) - Math.abs(dateB.getTime() - now.getTime());
      })
    )
  )

  @ViewChild("searchbar") searchbar: IonSearchbar;
  guestRaw = "";
  guestSearch = "";
  saving = false;
  constructor(
    private modal: ModalController,
    private packageSt: PackageState,
    private eventSt: EventState
  ) {}
  //guestsearching = false; booking.card.name = guest.guest_name; booking.card.contact = guest.guest_mobile_num

  async ngOnInit() {
  
    setTimeout(() => {
      if (this.searchbar) {
        this.searchbar.setFocus();
      }
    }, 300);

  }

  ngOnChanges(changes: SimpleChanges) {}

  dismiss() {
    this.modal.dismiss();
  }

  selectPackage(packageobnj) {
    Haptics.selectionStart()
    Haptics.selectionChanged()
    Haptics.selectionEnd()
    this.selectedPackage.emit(packageobnj);
    this.modal.dismiss(packageobnj);
  }
}
