import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  IonSlides,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { State } from "src/app/reducers";
import { table } from "console";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { DqTableDetails } from "src/app/services/table/table.service";
import { closeTable } from "src/app/actions/table.actions";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { RegionsService } from "src/app/services/regions/regions.service";

@Component({
  selector: "app-event-selector",
  templateUrl: "./event-selector.component.html",
  styleUrls: ["./event-selector.component.scss"],
})
export class EventSelectorComponent implements OnInit {
  @Input() month: number;
  @ViewChild("slider") slider: IonSlides;

  Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  CurrentMonth = new Date().getMonth();
  @Output() updatedMonth = new EventEmitter();
  start_month;
  end_month;
  constructor(
    private loaderCtrl: LoadingController,
    private router: Router,
    public modalController: ModalController,
    private tableFacade: TablesFacadeService,
    private activatedRoute: ActivatedRoute,
    public alertController: AlertController,
    private ordersV2Svc: OrderV2Service,
    private store$: Store<State>,
    private changeDetector: ChangeDetectorRef,
    private analy: AnalyticsService,
    private platform: Platform,
    private region: RegionsService
  ) {}

  async ngOnInit() {
    //wait for slider to not be null
    while (!this.slider) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    console.log("slider", this.slider);
    await this.slider.slideTo(this.CurrentMonth + 1);
    this.updatedMonth.emit(this.CurrentMonth);
    await this.checkMonth();
  }

  async checkMonth() {
    let sliderIndex = await this.slider.getActiveIndex();
    // Convert slider index (2-13) to month index (0-11)
    this.CurrentMonth = sliderIndex - 1;
  
    if (this.CurrentMonth < 0) {
      this.CurrentMonth = 12 + this.CurrentMonth;
    }
    if (this.CurrentMonth > 11) {
      this.CurrentMonth = this.CurrentMonth - 12;
    }
    
    console.log("checkMonth", this.CurrentMonth);
    
    //create a date object from the start of the month
    var start = new Date(new Date().getFullYear(), this.CurrentMonth, 1);
    //create a date object from the end of the month 
    var end = new Date(new Date().getFullYear(), this.CurrentMonth + 1, 0);

    //set the start and end month
    this.start_month = start;
    this.end_month = end;
    this.updatedMonth.emit(this.CurrentMonth);
  }
}
