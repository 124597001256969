<ion-item color="secondary" [style.pointer-events]="'all'" (click)="inputsel.setFocus()" class="big-item">
    <div class="country-click" (click)="this.filterEmpty(); isDesktop ? countryPopover.present($event) : countryModal.present()">
        </div>
    <ion-label [style.width]="'100%'">

        <ion-label position="stacked" [style.margin-bottom.px]="2" [style.text-wrap]="'nowrap'">Mobile Number</ion-label>
        
        <ion-row class="ion-align-items-baseline" [style.height.px]="23" [style.margin-top.px]="7">
            <div class="country-label" (click)="this.filterEmpty(); isDesktop ? countryPopover.present($event) : countryModal.present(); $event.stopPropagation()">
            
                <ion-label >
                    {{selectedCountry?.flag}} 

                </ion-label>
                
            </div>
        
            <ion-input #inputsel [style.pointer-events]="'all'" [style.height.px]="23" type="tel" placeholder="0431123456" [(ngModel)]="phone" (ngModelChange)="phoneChange.emit($event)" [pattern]="'^[0-9]*$'" (keypress)="$event.charCode >= 48 && $event.charCode <= 57">
                
            </ion-input>
        </ion-row>
    </ion-label>
    <ng-content></ng-content>

       

</ion-item>

    
    <ion-modal #countryModal>
      <ng-template>
        <ion-header>
          <ion-toolbar>
            <ion-title>Select Country</ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="countryModal.dismiss()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-toolbar>
            <ion-searchbar
              placeholder="Search countries"
              (ionInput)="filterCountries($event)"
            ></ion-searchbar>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list>
            <ion-item-group class="rounded-group ion-margin">

              <ion-item color="secondary" *ngFor="let country of filteredCountries" (click)="selectCountry(country); countryModal.dismiss()">
                
                <ion-label slot="start" [style.max-width.px]="22">
                  {{country.flag}}
                </ion-label>
                
                <ion-label>
                  {{country.name}}
                  
                </ion-label>
                <ion-note>
                  
                  {{country.dialCode}}
            </ion-note>    
            
          </ion-item>
        </ion-item-group>
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-modal>


    <ion-popover [style.--min-width.px]="350"  #countryPopover [isOpen]="false" size="auto" side="bottom" alignment="start" >
        <ng-template>
          <ion-content>
            <ion-toolbar color="secondary">
              <ion-searchbar [style.padding-left.px]="0" [style.padding-right.px]="0" [style.padding-top.px]="0" [style.padding-bottom.px]="0"
              color="secondary"
              class="ion-text-center"
                placeholder="Search countries"
                (ionInput)="filterCountries($event)"
                [debounce]="300"
              ></ion-searchbar>
            </ion-toolbar>
            <ion-list>
              <ion-item color="secondary" *ngFor="let country of filteredCountries" (click)="selectCountry(country); countryPopover.dismiss()">
                <ion-label slot="start" [style.max-width.px]="22">
                  {{country.flag}}
                </ion-label>
                <ion-label>
                  {{country.name}}
                </ion-label>
                <ion-note>
                  {{country.dialCode}}
                </ion-note>    
              </ion-item>
            </ion-list>
          </ion-content>
        </ng-template>
    </ion-popover>