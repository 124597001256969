import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-menu-logged-out",
  templateUrl: "./menu-logged-out.component.html",
  styleUrls: ["./menu-logged-out.component.scss"],
})
export class MenuLoggedOutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
