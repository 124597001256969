import { Pipe, PipeTransform } from "@angular/core";

// this pipe adds a random 'get' param to image urls to help prevent browser caching.
@Pipe({
  name: "imagePipe",
})
export class imageTransformPipe implements PipeTransform {
  
  transform(imageUrl: string, varation: 'thumbnail'| 'small' |'medium' | 'large' | 'full' = 'full'): any {
    //thumbnail 100x100x
    //small 240x240
    //medium 460x460
    //large 800x800
    console.log('image url', imageUrl)


    if (imageUrl === null || imageUrl === undefined) {
      return '';
    }

    //check if the start of the url includes  image.getdqd.com
    if (imageUrl.includes("imagedelivery.net")) {
      //if asking for full  then return the image url as is
      if (varation === 'full' ) {
        return `${imageUrl}/public`;
      } else {
        //if asking for a variation then add the variation as a get param
        return `${imageUrl}/${varation}`;
      }

    } else {
      if (imageUrl.includes('dq-static-media.s3.amazonaws.com')) {
        return imageUrl;
      } else {
      //this image doesnt have support for dynamic image sizes
      return `https://dq-static-media.s3.amazonaws.com/${imageUrl}`;
      }
    }
    

  }
}

