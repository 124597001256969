import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import {
  combineLatestWith,
  filter,
  map,
  mapTo,
  switchMap,
  take,
  tap,
} from "rxjs/operators";

import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { Platform, ToastController } from "@ionic/angular";
import { BaseSynced } from "../base-synced/base.service";
import { MenuState } from "../menu-state/menu.service";
import { PusherService } from "../../pusher/pusher.service";
import { SettingsState } from "../settings-state/settings-state.service";
import { TableState } from "../table-state/table.service";
import { SessionState } from "../sessions-state/session.service";
import { AssignmentState } from "../assignment-state/assignment.service";
import { GuestState } from "../guest-state/guest.service";
import { EventState } from "../event-state/event.service";
import { BookingState } from "../booking-state/booking.service";
import { EntryState } from "../guest-entry/entry.service";
import { PackageState } from "../package-state/package.service";
import { RecieptState } from "../reciept-state/reciept.service";
import { OrderState } from "../order-state/order.service";
import { NotificationState } from "../notification-state/notification.service";

import { Storage } from "@ionic/storage-angular";
import { TagState } from "../tag-state/assignment.service";
import { MetricState } from "../metric-state/metric.service";
import { CampaignState } from "../campaign-state/campaign.service";
import { ManualState } from "../manual-state/manual.service";
import { RolesState } from "../roles-state/roles.service";
import { UsersState } from "../users-state/users.service";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";

//export enum

export interface SyncSEvent {
  sync_ver: 1;
  obj_type:
    | "menu"
    | "order"
    | "ModifierGroup"
    | "Modifier"
    | "MenuSuperCategory"
    | "MenuItem"
    | "MenuCategory";
  sync_id: string;
  event_type: "CREATE" | "UPDATE" | "DELETE";
  sort_key: number;
  payload: any;
  v_id: number;
}

@Injectable({
  providedIn: "root",
})
export class SyncService {
  services = [
    this.settingsSt,
    this.menuSt,
    this.eventSt,
    this.tableSt,
    this.sessionSt,
    this.assignmentSt,
    this.guestSt,
    this.bookingSt,
    this.entrySt,
    this.packageSt,
    this.recieptSt,
    this.orderSt,
    this.notificationSt,
    this.tagSt,
    this.metricSt,
    this.campaignSt,
    this.manualSt,
    this.rolesSt,
    this.userSt,
  ];
  serviceMap = {};
  in_error$;

  error_toast;
  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private palt: Platform,
    private pusher: PusherService,

    //State Services
    private menuSt: MenuState,
    private settingsSt: SettingsState,
    private metricSt: MetricState,
    private tableSt: TableState,
    private sessionSt: SessionState,
    private assignmentSt: AssignmentState,
    private guestSt: GuestState,
    private eventSt: EventState,
    private bookingSt: BookingState,
    private entrySt: EntryState,
    private packageSt: PackageState,
    private recieptSt: RecieptState,
    private campaignSt: CampaignState,
    private orderSt: OrderState,
    private notificationSt: NotificationState,
    private manualSt: ManualState,
    private tagSt: TagState,
    public storage: Storage,
    private toast: ToastController,
    private rolesSt: RolesState,
    private userSt: UsersState,
    private auth: AuthClass
  ) {
    this.storage.create().then((storage) => {
      let c = combineLatest([
        ...this.services.map((x) => {
          return x.in_error$;
        }),
        this.pusher.in_error$,
      ]).pipe(
        map(async (x) => {
          //if any of the services are in error, set the sync service to error
          let error = x.some((y) => y == true);
          if (error) {
            if (this.error_toast == undefined) {
              this.error_toast = "load";
              this.error_toast = await this.toast.create({
                message: "Connection Error, Retrying...",
                //wifi icon
                icon: "wifi-outline",
                //show a loading spinner
                duration: 20000000,
                position: "top",
                color: "danger",
              });
              this.error_toast.present();
            }
          } else {
            if (this.error_toast != undefined) {
              this.error_toast.dismiss();
              //create a short reconnected toast
              const toast = await this.toast.create({
                message: "Successfully Reconnected",
                //success icon
                icon: "checkmark-outline",
                duration: 2000,
                position: "top",
              });
              toast.present();
              this.error_toast = undefined;
            }
          }

          return error;
        })
      );
      this.in_error$ = c;
      let loop;
      this.in_error$.subscribe((x) => {
        //run loop if x is true otherwise dont run (and cancel any running loops)

        if (x) {
          if (loop == undefined) {
            loop = setInterval(() => {
              this.services.forEach((x) => {
                if (x.serviceState.get().in_error) {
                  x.serviceState.set({ refreshing: false });
                  x.SyncData();
                }
              });
            }, 5000);
          }
        } else {
          clearInterval(loop);
          loop = undefined;
        }
      });

      this.services.map((x) => {
        //if service_id is typeof string

        if (typeof x.service_id === "string") {
          this.serviceMap[x.service_id] = x;
        } else {
          x.service_id.forEach((string) => {
            this.serviceMap[string] = x;
          });
        }
      });
      console.log(
        " %c Init Sync Service",
        "background: #00000; color: #FF0000"
      );


        let waitingState = new RxState<{
          falling: boolean;
        }>();
        waitingState.set({falling: false})
      combineLatest([
        ...this.services.map((x) => {
          return x.in_error$;
        }),
        ...this.services.map((x) => {
          return x.refreshing$;
        }),
        waitingState.select('falling')
     
      ]).pipe(

        filter((value: boolean[]) => {
          
          // check if any services are refreshing or any are in error 
          // if none are, check if there are any services not loaded and load them one by one
          if (value.some((y) => y == true)) {
            return false;
          }
          
          // this should now theoretically stop this from running again until the next service is loaded
          return true;
        }),
      combineLatestWith(
        ...this.services.map((x) => {
          return x.loaded$;
        }),
      ), 
      
      ).subscribe(async (x) => {
         //check at least four service is loaded
        if (x.filter((y) => y == true).length < 4) {
          return;

        }

        //not if your a promoter
        if (window.location.href.includes("/prombar/")) {
          return
        }
    

        let not_loaded = this.services.filter((y) => y.serviceState.get('initiliased') == false && y.serviceState.get('refreshing') == false);
        not_loaded = not_loaded.filter((y) =>  {
          if(y.permissions_required != undefined && y.permissions_required.length > 0) {
          this.settingsSt.hasClaim$(y.permissions_required)
          } else {
            return true
          }
          
        });
        if (not_loaded.length == 0) {
          return;
        }

          //check were not already falling
          if (waitingState.get().falling) {
            return
          }

        console.log(
          " %c Waterfall Init " + not_loaded[0].service_title,
          "background: #00000; color: #FF0000"
        );

        waitingState.set({falling: true})
        try {

        
        await not_loaded[0].InitStore();
        } catch (error) {

        console.error("Failed to initiate service as part of waterfall due to error:", error);
        }
        waitingState.set({falling: false})


        //wait 1 second
      })
      //wait for pusher service
      //wait for the settings service venue_id to be set

      this.palt.resume.subscribe(() => {
        this.services.forEach((x) => {
          if (
            x.serviceState.get().initiliased ||
            x.serviceState.get().in_error ||
            x.serviceState.get().restored
          ) {
            x.serviceState.set({ refreshing: false });
            x.SyncData();
          }
        });
      });
      //wait for pusher to be ready
      this.pusher.pusher$
        .select("pusher")
        .pipe(
          combineLatestWith(this.settingsSt.venueId$),
          take(1),
          tap(async (x) => {
            console.log(
              " %c Pusher Initiliased",
              "background: #00000; color: #FF0000",
              x
            );

            let test = await x[0]
              .subscribe(`${x[1]}-sync`)
              



            let res = await x[0]
              .subscribe(`private-${x[1]}-sync`)
             
             await res.bind("update", (data) => {
                console.log("sync data", data);
                data.events.forEach((event) => {
                  this.RecivedEvent(event);
                });
              });

              
            console.log(
              " %c Pusher Subscribed",
              "background: #00000; color: #FF0000", res
            );

            x[0].connection.bind("disconnected", (data) => {});
          })
        )
        .toPromise();

      //check if there data in storage for any state services, if there is, load it!

      this.services.forEach((x) => {
        x.allWithService$.subscribe((data) => {
          if (data != undefined) {
            console.log(" %c Saved Data " + x.service_title, "background: #00000; color: #FF0000");
            storage.set("synced_" + x.storageId, data);
          }
        });
      });
      storage.keys().then((keys) => {
        this.services.forEach((x) => {
          if (keys.includes("synced_" + x.storageId)) {
            storage.get("synced_" + x.storageId).then((result) => {
              let data = result;
              if (data != null && data != undefined) {
                let biggerdata = data.last_synced as Date;
                if ((data.last_updated as Date) > (data.last_synced as Date)) {
                  biggerdata = data.last_updated as Date;
                }

                //check the data is not older than x.data_expiry (minutes)
                if (
                  new Date().getTime() - biggerdata.getTime() <
                  x.data_expiry * 60 * 1000
                ) {
                  console.log(
                    " %c Restored State For " + x.service_title,
                    "background: #00000; color: #FF0000"
                  );
                  x.serviceState.set({
                    restored: true,
                    last_synced: new Date(),
                    last_updated: new Date(),
                  });
                  x.UpdateState(data.data);
                }
              }
            });
          }
        });
      });
    });
  }

  RecivedEvent(event: SyncSEvent) {
    //
    console.log(
      " %c Recieved Event",
      "background: #00000; color: #FF0000",
      event
    );


      if ((event as any)?.signal == 'users_need_token_refresh') {
        let username = this.settingsSt.state.get('user').user_name
        console.log("Is this refresh relevant to us?", username)
        if ((event as any)?.user_names.includes(username)) {

          console.log(" %c User Token Refresh", "background: #00000; color: #FF0000");

          this.settingsSt.currentUser$.pipe().subscribe(async (user) => {
          try {
            user.getSession((err, session) => {});
          } catch (error) {
            console.log(error);
             user = await this.auth.currentAuthenticatedUser();
          }
          user.getSession((err, session) => {
            user.refreshSession(session.refreshToken, (err, session) => {
              this.settingsSt.state.set({
                cognitoUser: user
              })
          })
          })
          
        })
          
        
      }
      return
    }


    if (this.serviceMap[event.obj_type].loaded) {
      event.payload.sort_key = event.sort_key;
      this.serviceMap[event.obj_type].HandleEvent(event);
      this.serviceMap[event.obj_type].serviceState.set({
        last_updated: new Date(),
      });
    } else {
      console.log(
        " %c Event Discarded - Not Loaded",
        "background: #00000; color: #FF0000",
        event
      );
    }
  }
}
