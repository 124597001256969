import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-treat-me-how-works",
  templateUrl: "./treat-me-how-works.page.html",
  styleUrls: ["./treat-me-how-works.page.scss"],
})
export class TreatMeHowWorksPage implements OnInit {
  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {}

  async close() {
    await this.modalCtrl.dismiss();
  }
}
