/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export type FilterableType = "tag" | "metric";
export type FilterableValueClass = "raw_count" | "strength" | "currency";
export type FilterComparator = "less_than_or_eq" | "greater_than_or_eq";
export type Ordering = "high_to_low" | "low_to_high";
/**
 * Say someone was a regular booker, then they stopped coming for a year,
 * but a couple of months ago they attended on someone's guestlist. What are
 * they? A churned booker? A returning guestlist? The order decides that
 * when more than one status could apply. First is highest priority.
 */
export type GuestStatus =
  | "regular_booker"
  | "regular_guestlist"
  | "returning_booker"
  | "returning_guestlist"
  | "churned_booker"
  | "churned_guestlist"
  | "first_time_guest"
  | "first_time_booker";
export type TagType = "taste" | "music" | "brand" | "general" | "highlight" | "system";

export interface AllFilterableOptions {
  filter_options: FilterableOption[];
}
export interface FilterableOption {
  filter_type: FilterableType;
  metric_or_tag_name: string;
  nice_name: string;
  has_raw_count: boolean;
  has_strength: boolean;
  has_currency: boolean;
  raw_count_descrip?: string;
  strength_descrip?: string;
  currency_descrip?: string;
}
export interface AllGuestFilterResponses {
  guests_and_filters: GuestFilterResponses[];
  warning_text?: string;
}
export interface GuestFilterResponses {
  guest: BaseModel;
  filter_clauses: FilterResponse[];
}
export interface BaseModel {}
/**
 * The value used in the comparison
 */
export interface FilterResponse {
  filter_type: FilterableType;
  metric_or_tag_name: string;
  compare_to_type: FilterableValueClass;
  value: number;
  rendered_value: string;
}
export interface FilterClause {
  metric_or_tag_name: string;
  comparator: FilterComparator;
  compare_to_type: FilterableValueClass;
  compare_to_value: number;
}
export interface FilterClauses {
  clauses: FilterClause[];
  order_by?: OrderBy;
  limit_to_guest_statuses?: GuestStatus[];
}
export interface OrderBy {
  metric_or_tag_name: string;
  ordering: Ordering;
  compare_to_type: FilterableValueClass;
}
export interface MetricTagInfer {}
export interface TagInstance {
  tag_id: string;
  tag_type: TagType;
  strength: number;
  strength_rendered: string;
  explanation?: string;
  selected?: boolean;
}
export interface TagInstances {
  tags: TagInstance[];
}
