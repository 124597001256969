import { Injectable } from "@angular/core";
import * as Honeybadger from "honeybadger-js";

import { Preferences } from "@capacitor/preferences";

@Injectable({
  providedIn: "root",
})
export class StorageAdapter {
  constructor() {}

  async getObject<T = any>(key: string) {
    if (key != undefined) {
      const { value } = await Preferences.get({ key });
      try {
        return JSON.parse(value) as T;
      } catch (error) {
        return;
      }
    } else {
    }
  }

  async setObject(key: string, value: any) {
    return this.set(key, JSON.stringify(value));
  }

  clear() {
    return Preferences.clear();
  }

  get(key: string) {
    return Preferences.get({ key });
  }

  set(key: string, value: string) {
    return Preferences.set({ key, value });
  }

  remove(key: string) {
    return Preferences.remove({ key });
  }

  keys() {
    return Preferences.keys();
  }

  async forEach(cb: (key: string, value: any) => void) {
    const { keys } = await this.keys();
    for (const key of keys) {
      const value = await this.getObject(key);
      cb(key, value);
    }
  }
}
