import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Platform, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { defer, EMPTY, of, timer } from "rxjs";
import {
  catchError,
  concatMap,
  concatMapTo,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import {
  authCheckAtStartupSavedDetails,
  userLoginSuccess,
} from "src/app/actions/auth.actions";
import {
  checkIn,
  loadCollectionPointsSuccess,
} from "src/app/actions/table.actions";
import {
  loadVenuesError,
  loadVenuesNoVenues,
  loadVenuesSuccess,
  selectVenue,
  selectVenueAtStartupNoDetails,
  selectVenueAtStartupSucess,
  selectVenueSucess,
  venueCheckInError,
  venueCheckInSuccess,
} from "src/app/actions/venue.actions";
import { VenuesFacadeService } from "src/app/facades/venues-facade/venues-facade.service";
import { notificationsRegistrationComplete, State } from "src/app/reducers";
import { OrderService } from "src/app/services/order/order.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { StorageAdapter } from "src/app/services/storage-adapter/storage-adapter.service";
import { DqVenue, VenueService } from "src/app/services/venue/venue.service";
import { environment } from "src/environments/environment";

export const STORAGE_KEY_VENUE_ID = "queuebar:venue_id";

@Injectable()
export class VenueEffects {
  onReady$ = defer(() => this.platform.ready());

  checkVenueAtStartOnApp$ = createEffect(() =>
    this.onReady$.pipe(
      switchMap(async () => {
        try {
          const venue_id = await this.storage.getObject<{
            venue_id;
          }>(STORAGE_KEY_VENUE_ID);

          return selectVenueAtStartupSucess({
            selectedVenueId: venue_id.toString(),
          });
        } catch (error) {
          return selectVenueAtStartupNoDetails();
        }
      })
    )
  );

  noVenues$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadVenuesNoVenues),
        concatMap(async () => {
          const reg = this.regionService.getRegion();
          this.router.navigate([`${reg}/no-venues`]);
          const toast = await this.toastCtrl.create({
            header: "No Venues",
            duration: environment.defaultToastDuration,
            position: "top",
            message: "You are not currently associated with any venues",
            buttons: [
              {
                role: "cancel",
                icon: "close",
                cssClass: "toast-close-button",
              },
            ],
          });
          await toast.present();
        })
      ),
    { dispatch: false }
  );

  loadVenuesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadVenuesError),
        concatMap(async () => {})
      ),
    { dispatch: false }
  );

  venueSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectVenue),
      map((payload: any) => {
        return selectVenueSucess(payload.selectedVenueId);
      })
    )
  );

  doCheckInAfterVenueSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCollectionPointsSuccess, checkIn)
      // wait till push notification registration is complete so that we can
      // ensure there is a push device token if it exists
    )
  );

  constructor(
    private actions$: Actions,
    private platform: Platform,
    private router: Router,
    private venueService: VenueService,
    private venueFacade: VenuesFacadeService,
    private regionService: RegionsService,
    private orderSvc: OrderService,
    private store$: Store<State>,
    private storage: StorageAdapter,
    private toastCtrl: ToastController
  ) {}
}
