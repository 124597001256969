import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  name: "lengthDate",
})
export class lengthDatePipe implements PipeTransform {
  transform(days: number): string {
    if (days <= 14) {
      return Math.round(days) + " Days";
    }
    if (days <= 56) {
      return Math.round(days / 7) + " Wk";
    }

    return Math.round(days / 28) + " Mth";
  }
}
