import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DqPaymentMethod } from "src/app/services/order/order.service";

@Component({
  selector: "app-payment-form-method",
  templateUrl: "./payment-form-method.component.html",
  styleUrls: ["./payment-form-method.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PaymentFormMethodComponent,
      multi: true,
    },
  ],
})
export class PaymentFormMethodComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() allowPayLater = true;
  @Input() allowSquare = false;

  paymentMethod: DqPaymentMethod;
  disabled = false;
  onChangeCb: (paymentMethod: DqPaymentMethod) => void;
  onTouchedCb: () => void;

  allPaymentMethods: { value: DqPaymentMethod; label: string }[] = [
    { value: "paylater", label: "Pay Later" },
    { value: "cash", label: "Cash" },
    { value: "eftpos", label: "EFTPOS" },
    { value: "square", label: "Credit Card" },
  ];

  enabledPaymentMethods = this.setPaymentMethods();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowPayLater || changes.allowSquare) {
      this.enabledPaymentMethods = this.setPaymentMethods();
    }
  }

  private setPaymentMethods() {
    return this.allPaymentMethods.filter(method => {
      return (
        ["cash", "eftpos"].includes(method.value) ||
        (method.value === "paylater" && this.allowPayLater) ||
        (method.value === "square" && this.allowSquare)
      );
    });
  }

  writeValue(paymentMethod: DqPaymentMethod) {
    if (
      paymentMethod != "paylater" ||
      (this.allowPayLater && paymentMethod === "paylater")
    ) {
      this.paymentMethod = paymentMethod;
    }
  }

  registerOnChange(onChangeCb: (paymentMethod: DqPaymentMethod) => void) {
    this.onChangeCb = onChangeCb;
  }

  registerOnTouched(onTouchedCb: () => void) {
    this.onTouchedCb = onTouchedCb;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  paymentMethodDidChange(ev: CustomEvent) {
    this.paymentMethod = ev.detail.value;
    if (this.onChangeCb) {
      this.onChangeCb(this.paymentMethod);
    }
    if (this.onTouchedCb) {
      this.onTouchedCb();
    }
  }
}
