import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { StorageAdapter } from "../services/storage-adapter/storage-adapter.service";
import { RegionsService } from "../services/regions/regions.service";
const STORAGE_KEY_USER_ID = "queuebar:user_id";

@Injectable()
export class AnonymousUserIdInterceptor implements HttpInterceptor {
  constructor(private auth: AuthClass, private storage: StorageAdapter, private reg: RegionsService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.auth.currentSession().catch(() => undefined)).pipe(
      mergeMap((session) => {
        if (session || !this.isKnownDomain(req, [`${this.reg.ServerURL()}`])) {
          return next.handle(req);
        }
        if (!session) {
          return this.addUserIdHeaderToRequest(req, next);
        }
      })
    );
  }

  private isKnownDomain(request: HttpRequest<any>, domains: string[]): boolean {
    return domains.some((d) => request.url.includes(d));
  }

  private addUserIdHeaderToRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.storage.get(STORAGE_KEY_USER_ID)).pipe(
      mergeMap(async ({ value: userId }) => {
        if (!userId) {
          userId = uuidv4();
          await this.storage.set(STORAGE_KEY_USER_ID, userId);
        }
        return userId;
      }),
      mergeMap((userId) => {
        req = req.clone({
          setHeaders: {
            "X-Client-Uuid": userId,
          },
        });
        return next.handle(req);
      })
    );
  }
}
