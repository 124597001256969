import {
  MapObject,
  MapItem,
  Offset,
  Map as MEP,
} from "../../../models/map_editor_models";

export class MapItemTypes {
  static readonly Wall_Booth: MapItem = {
    svg: "Wall_Booth",
    type: "Wall_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Wall Booth",
  };
  static readonly Corner_Booth: MapItem = {
    svg: "Corner_Booth",
    type: "Corner_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Corner Booth",
  };
  static readonly Semi_Circle: MapItem = {
    svg: "Semi_Circle_Booth",
    type: "Semi_Circle",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Semi Circle",
  };
  static readonly Double_Booth: MapItem = {
    svg: "Double_Booth",
    type: "Double_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Double Booth",
  };
  static readonly Quarter_Booth: MapItem = {
    svg: "3_Qaurter_Booth",
    type: "Quarter_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Quarter Booth",
  };
  static readonly Table_Booth: MapItem = {
    svg: "Table_Booth",
    type: "Table_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Table Booth",
  };
  static readonly Small_Corner_Booth: MapItem = {
    svg: "Small_Corner_Booth",
    type: "Small_Corner_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Corner Booth",
  };
  static readonly Full_Semi_Booth: MapItem = {
    svg: "FullSemiBooth",
    type: "Full_Semi_Booth",
    toolbar: { offset_x: 140, offset_y: -60 },
    reference: "Semi Booth",
  };

  static readonly list = [
    MapItemTypes.Wall_Booth,
    MapItemTypes.Corner_Booth,
    MapItemTypes.Semi_Circle,
    MapItemTypes.Double_Booth,
    MapItemTypes.Quarter_Booth,
    MapItemTypes.Table_Booth,
    MapItemTypes.Small_Corner_Booth,
    MapItemTypes.Full_Semi_Booth,
  ];
}
