import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DqConsumerTabCredit } from "src/app/pages/consumer-event/services/consumer-event/consumer-event.service";
import { environment } from "src/environments/environment";
import { DqConsumerVenueInfo } from "../consumer-table/consumer-table.service";
import { DqCartField } from "src/app/pages/consumer-event/services/consumer-event/consumer-event.service";
import { paramsToFormData } from "src/app/utils/object-to-form-data";
import { DateTime } from "luxon";
import { RegionsService } from "../regions/regions.service";
import { GiftInviteOutboundList } from "src/app/models/gift_code";

export interface SocialAccountModel {
  sa_handle: string;
  sa_image_url: string;
  sa_name: string;
  sa_provider?: "instagram" | "facebook" | "no_provider";
}

export interface DqTreatMeCurrentGiftInviteResponse {
  gift_invites: DqTreatMeDetails[];
}

export interface DqTreatMeSessionValidity {
  expiry_utc: string;
  is_valid: boolean;
}

export interface DqPaymentBranch {
  fields: DqCartField[];
  pb_key: string; // "pb_gift";
  pb_name: string; // "Pay on Card";
}

export interface DqTreatMeCartDetails {
  branches: DqPaymentBranch[];
  tab_credits: DqConsumerTabCredit[];
}

export interface DqTreatMeRequestDetails {
  table_id: number;
  phone_num: string;
  social_account?: SocialAccountModel;
  expires_in_minutes: number;
  gift_for: string;
  caption_text: string;
  hide_full_name: boolean;
  public_listing: boolean;
}

export interface DqTreatMeDetails {
  gift_invite_public_id: string;
  gift_invite_url: string;
  gift_for: string;
  validity: DqTreatMeSessionValidity;
  has_been_used: boolean;
  gift_header: string;
  gift_description: string;
  location: DqTreatLocation;
  show_how_does_treat_work: boolean;
  thanks_for_using_text: string;
  social_account_consumer: SocialAccountModel;
  hide_full_name?: boolean;
  gift_card_status?: DqGiftCardStatus;
  o_uuid?: null; //deprecated
  o_uuids_approved?: string[];
  expires_in_minutes?: number;
  caption_text?: string;
  table_id?: number;
  phone_num?: string;
}

export interface DqTreatMeGiftCancel {
  gift_invite: DqTreatMeDetails;
  message: string;
}

export interface DqGiftCardStatus {
  display: boolean;
  show_cancel_x: boolean;
  lines: DqTreatLine[];
}

export interface DqTreatLine {
  line_type: string;
  text?: string;
  weight?: number;
  font_size?: string;
  buttons?: DqTreatButton[];
}

export interface DqTreatButton {
  fill: string;
  expand: null | string;
  text: string;
  action: string; //"share_link" | "cancel_link" | "copy_link" | "preview_link" | "close" | "create_new"
}

export interface DqTreatLocation {
  table_name: string;
  venue_name: string;
}

export interface DqAvatar {
  image_uri: string;
}

export interface DqAvatarResponse {
  status: string;
  avatar_url: string;
}

export interface DqCanCreateTreatLinksResponse {
  can_create: boolean;
  error_message: string;
}

export interface DqTreatMeAddtionalInfo {
  hideLocation?: boolean;
  hideName?: boolean;
  treatType?: string;
  extraInfo?: string;
  activate: boolean;
  listPublicly: boolean;
}
@Injectable({ providedIn: "root" })
export class TreatMeDataService {
  constructor(private httpClient: HttpClient, private reg: RegionsService) {}

  getVenueInfo(sessionId: string) {
    return this.httpClient.get<DqConsumerVenueInfo>(
      `${this.reg.ServerURL()}/v4/gift_invite/${sessionId}/venue`
    );
  }

  getVenueInfoDirect(venue_id: string) {
    return this.httpClient.get<DqConsumerVenueInfo>(
      `${this.reg.ServerURL()}/v4/venue/${venue_id}/branding`
    );
  }

  getDetails(sessionId: string) {
    return this.httpClient.get<DqTreatMeDetails>(
      `${this.reg.ServerURL()}/v4/gift_invite/${sessionId}`
    );
  }

  getMenu(sessionId: string) {
    return this.httpClient.get<any>(
      `${this.reg.ServerURL()}/v4/gift_invite/${sessionId}/menu`
    );
  }

  getCartFields(sessionId: string) {
    return this.httpClient.get<DqTreatMeCartDetails>(
      `${this.reg.ServerURL()}/v4/gift_invite/${sessionId}/guest_details_branches`
    );
  }

  getStatus(orderId: string) {
    return this.httpClient.get<DqTreatMeDetails>(
      `${this.reg.ServerURL()}/v4/gift_invite/${orderId}`
    );
  }

  checkForCurrentGiftInvite() {
    return this.httpClient.get<DqTreatMeCurrentGiftInviteResponse>(
      `${this.reg.ServerURL()}/v4/gift_invite`
    );
  }

  createGiftInvite(treatRequest: DqTreatMeRequestDetails) {
    return this.httpClient.post<DqTreatMeDetails>(
      `${this.reg.ServerURL()}/v4/gift_invite`,
      treatRequest
    );
  }

  can_create_invite_link() {
    return this.httpClient.get<DqCanCreateTreatLinksResponse>(
      `${this.reg.ServerURL()}/users/can_create_invite_link`
    );
  }

  cancelGiftInvite(giftId: string) {
    return this.httpClient.delete<DqTreatMeGiftCancel>(
      `${this.reg.ServerURL()}/v4/gift_invite/${giftId}`
    );
  }

  uploadAvatar(avatar: DqAvatar) {
    return this.httpClient.post<DqAvatarResponse>(
      `${this.reg.ServerURL()}/users/avatar`,
      avatar
    );
  }

  createCheckout(data) {
    return this.httpClient.post(
      `${this.reg.ServerURL()}/v4/treat/checkout`,
      data
    );
  }
}
