import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonInput, ModalController, NavController, Platform } from "@ionic/angular";
import { map } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";
import { BookingsService } from "src/app/services/cms/bookings/bookings.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { BookingState } from "src/app/services/sync-engine/booking-state/booking.service";
import { EntryState } from "src/app/services/sync-engine/guest-entry/entry.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { TableService } from "src/app/services/table/table.service";

export interface OpenTableDidCloseData {
  guestName: string;
}

@Component({
  selector: "app-open-table-modal",
  templateUrl: "./open-table-modal.page.html",
  styleUrls: ["./open-table-modal.page.scss"],
})
export class OpenTableModalPage implements OnInit {
  guestName: string;
  table_id: string;
  bookings$ = this.bookingSt.bookings$.pipe(
    map((x) => {
      let book = x.filter(
        (y) => y.table_id == this.table_id && y.status == "confirmed"
      );

      //sort with order_key
      return book.sort((a, b) => {
        return a.sort_order - b.sort_order;
      });
    })
  );
  @ViewChild('nameinput') nameinput: IonInput
  constructor(
    private modalController: ModalController,
    private tableSvc: TableService,
    private navCtrl: NavController,
    private reg: RegionsService,
    private platform: Platform,
    private bookingSt: BookingState,
    private receiptSt: RecieptState,
    private entrySt: EntryState
  ) {
    this.bookingSt.InitStore();
    this.entrySt.InitStore();
    this.receiptSt.InitStore();
  }

  openTable() {
    this.modalController.dismiss({
      guestName: this.guestName,
    } as OpenTableDidCloseData);
  }

  cancel() {
    this.modalController.dismiss();
  }

  async viewBooking(booking) {
    if (this.platform.is("desktop") || this.platform.is("tablet")) {
      await this.navCtrl.navigateForward([
        this.reg.getRegion(),
        "tab",
        "bookings",
        "b",
        booking.booking_id,
      ]);
    } else {
      await this.navCtrl.navigateForward([
        this.reg.getRegion(),
        "bookings",
        booking.booking_id,
      ]);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.nameinput) {
        this.nameinput.setFocus();
      }
    }, 300);

  }
}
