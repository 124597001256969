<ion-col class="list" *ngIf="Items != undefined">
  <ng-container *ngFor="let item of Items">
    <ion-chip (click)="choose(item)">{{ item[Label] }}</ion-chip>
  </ng-container>
</ion-col>
<ion-col class="list" *ngIf="Placeholder != undefined">
  <ion-item>
    <ion-label>{{ Label }}</ion-label>
    <ion-input
      [type]="Type"
      [(ngModel)]="Data"
      (keyup.enter)="save()"
      [placeholder]="Placeholder"
    ></ion-input>
    <ion-button (click)="save()">Save</ion-button>
  </ion-item>
</ion-col>
