<ion-chip
*ngIf="guest != undefined"
  class="background"
  [class.trans]="transparent"
  [color]="statColor(guest.status)"
  [outline]="true"
  [class.shrink]="shrink"
>
  <ion-icon
    [color]="transparent ? 'default' : ''"
    [name]="statIcon(guest.status)"
  >
  </ion-icon>
  <ion-label *ngIf="!transparent">
    {{ statText(guest.status) }}
  </ion-label>
  <ion-note *ngIf="transparent">
    {{ statText(guest.status) }}
  </ion-note>
</ion-chip>
