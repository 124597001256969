import { CognitoUser } from "@aws-amplify/auth";
import { createAction, props } from "@ngrx/store";
import {
  DqLoginResponseSuccess,
  DqVenueDetails,
} from "../services/auth/auth.service";

const ACTION_PREFIX = "[Auth]";

export const userLoginSuccess = createAction(
  `${ACTION_PREFIX} User Logged In Successfully`,
  props<{
    user: CognitoUser;
  }>()
);

export const treatUserLoginSuccess = createAction(
  `${ACTION_PREFIX} You Have Logged In Successfully`,
  props<{
    user: CognitoUser;
  }>()
);

export const userLogOut = createAction(`${ACTION_PREFIX} User Log Out`);

export const authCheckAtStartupSavedDetails = createAction(
  `${ACTION_PREFIX} Loaded save login details`,
  props<{ user: CognitoUser }>()
);

export const authTreatUserCheckAtStartupSavedDetails = createAction(
  `${ACTION_PREFIX} Loaded save login details (Treat User)`,
  props<{ user: CognitoUser }>()
);

export const authCheckAtStartupNoDetails = createAction(
  `${ACTION_PREFIX} No saved login details`
);

export const authCheckInSuccess = createAction(
  `${ACTION_PREFIX} Check In Success`,
  props<{ loginResponse: DqLoginResponseSuccess }>()
);
export const authCheckInError = createAction(
  `${ACTION_PREFIX} Check In Error`,
  props<{ error: any }>()
);

export const authSelectVenue = createAction(
  `${ACTION_PREFIX} Venue Selected Into Auth State`,
  props<{
    venue: DqVenueDetails;
  }>()
);

export const updateUserProfile = createAction(
  `${ACTION_PREFIX} Update User Profile`,
  props<{
    user: CognitoUser;
  }>()
);
