import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-statistic-dates",
  templateUrl: "./statistic-dates.component.html",
  styleUrls: ["./statistic-dates.component.scss"],
})
export class StatisticDateComponent implements OnInit {
  @Input() Live: boolean = true;
  @Input() Selectable: boolean = true;

  //event emmitter output
  @Output() SelectChange = new EventEmitter<any>();

  CurrentSelected: string = "Live";
  Start_Date: Date = new Date();
  Stop_Date: Date = new Date();
  Interval = "minute";
  StopDateText = new Date().toISOString();
  StartDateText = new Date().toISOString();
  selecting = ""

  initilised = false;

  constructor(public popover: PopoverController) {}

  ngOnInit() {
    if (this.Live) {
      this.CurrentSelected = "Live";
      this.select("Live");

    } else {
      //works proper;ly
      this.select("Month");

    }
    this.initilised = true
  }

  select(value) {
    switch (value) {
      case "Year":
        //get current date
        let date = new Date();
        //stop date is today
        this.Stop_Date = date;
        //start date is 1 year ago
        this.Start_Date = new Date(
          date.getFullYear() - 1,
          date.getMonth(),
          date.getDate()
        );
        this.Interval = "week";

        this.CurrentSelected = date.getFullYear().toString();
        break;

      case "Month":
        //get current date
        let date2 = new Date();
        this.Interval = "day";
        //stop date is today
        this.Stop_Date = date2;

        //start date is 1 month ago
        this.Start_Date = new Date(
          date2.getFullYear(),
          date2.getMonth() - 1,
          date2.getDate()
        );

        this.CurrentSelected = date2.toLocaleString("default", {
          month: "long",
        });
        break;
      case "date":
        //if there is no StopDateText then set it to today
        if (!this.StopDateText && !this.StartDateText) {
          //if its past 5pm today then set the stop date to midday tommorow
          if (new Date().getHours() > 17) {
            this.StopDateText = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + 1,
              12
            ).toISOString();
          } else {
            this.StopDateText = new Date().toISOString();
          }
        } 




        this.Interval = "minute";
        //parse date from text date, then set the stop to be 12pm that day,
        //and the start to be 12pm the day before
        //if its after midday then set the stop date to be 12pm tommorow
       
        let dateStop = new Date(this.StopDateText);
        let dateStart = new Date(this.StartDateText);
        this.Stop_Date = new Date(
          dateStop.getFullYear(),
          dateStop.getMonth(),
          dateStop.getDate(),
          12
        );  


        this.Start_Date = new Date(
          dateStart.getFullYear(),
          dateStart.getMonth(),
          dateStart.getDate(),
          12
        );
        if (dateStart.toDateString() === dateStop.toDateString()) {
          this.Start_Date.setDate(this.Start_Date.getDate() - 1);
        }

        if (new Date(this.StopDateText).getHours() <= 18 && this.initilised == false) {
          //its before midday when we opened this so select from midday to middary yesterday instead
          this.Stop_Date = new Date(
            dateStop.getFullYear(),
            dateStop.getMonth(),
            dateStop.getDate(),
            12
          );
          this.Start_Date = new Date(
            dateStart.getFullYear(),
            dateStart.getMonth(),
            dateStart.getDate() - 1,
            12
          );
        }
        this.CurrentSelected = 'date';
        

        break;
      case "Live":
        this.Interval = "day";
        this.CurrentSelected = "Live";
        this.Live = true;

        const now = new Date();
        this.Stop_Date = now;
        this.Start_Date = new Date(now.getTime() - 18 * 60 * 60 * 1000);

        break;
    }

    //return the start and stop date
    this.SelectChange.emit({
      start: this.Start_Date.toJSON(),
      stop: this.Stop_Date.toJSON(),
      interval: this.Interval,
    });
  }

  Datetext() {
    const diffInHours = (this.Stop_Date.getTime() - this.Start_Date.getTime()) / (1000 * 3600);
    
    if (diffInHours <= 36) {
      console.log("Re turning date")
      return this.Start_Date.toLocaleDateString('en-GB'); // dd/mm/yyyy format
    }
    
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
    }
    
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''}`;
    }
    
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''}`;
    }
    
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} year${diffInYears > 1 ? 's' : ''}`;
  }

  choose(item) {
    this.popover.dismiss(item);
  }
}
