import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { select, Store } from "@ngrx/store";
import { filter, map, take, withLatestFrom } from "rxjs/operators";
import {
  State,
  venuesSelectHaveBeenLoaded,
  venuesSelectSelectedTableId,
} from "src/app/reducers";
import { AuthService } from "src/app/services/auth/auth.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

@Injectable({
  providedIn: "root",
})
export class VenueSelectedGuard implements CanActivate {
  constructor(
    private auth: AuthClass,
    private authSvc: AuthService,
    private router: Router,
    private settingsSt: SettingsState,
    private regionService: RegionsService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.settingsSt.venue_checked$
      .pipe(
        filter((X) => X == true),
        take(1)
      )
      .toPromise();

    if (this.settingsSt.state.get("currentVenue") != undefined) {
      return true;
    } else {
      var reg = this.regionService.getRegion();
      await this.authSvc.switchVenue();
      return true;
    }
  }
}
