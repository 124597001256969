import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, createSelector, on } from "@ngrx/store";
import { DqCollectionPointTableDetails } from "src/app/services/table/table.service";
import * as TableActions from "../../actions/table.actions";

export interface State extends EntityState<DqCollectionPointTableDetails> {
  // additional entities state properties
  selectedId: string;
  AssignmentViewed: boolean;
  AssignedTables: assignedTables;
}

export const adapter = createEntityAdapter<DqCollectionPointTableDetails>({
  selectId: (table) => table.vc_id,
});

export interface assignedTables {
  [key: string]: boolean;
}

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  AssignmentViewed: true,
  AssignedTables: {} as assignedTables,
});

const tableReducer = createReducer(
  initialState,
  on(
    TableActions.loadCollectionPointsSuccess,
    (state, { collectionPoints: tables }) => adapter.addMany(tables, state)
  ),
  //this provides a tables argument that includes a map. the keys are table ids and the values are booleans
  //add these to the state as their own property
  on(TableActions.assignmentLoaded, (state, { tables }) => ({
    ...state,
    AssignedTables: tables,
  })),
  //add the table id to the map with the value true or change the value to true
  on(TableActions.assign, (state, { table_id }) => ({
    ...state,
    AssignedTables: { ...state.AssignedTables, [table_id]: true },
  })),
  //remove the table id from the map
  on(TableActions.unAssign, (state, { table_id }) => ({
    ...state,
    AssignedTables: { ...state.AssignedTables, [table_id]: false },
  })),

  on(TableActions.assignmentViewed, (state) => ({
    ...state,
    AssignmentViewed: true,
  })),
  on(TableActions.assignmentUpdated, (state) => ({
    ...state,
    AssignmentViewed: false,
  })),

  on(TableActions.clearCollectionPoints, (state) => adapter.removeAll(state)),
  on(TableActions.selectTable, (state, { selectedTableId }) => ({
    ...state,
    selectedTableId,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectSelectedTableId = (state: State) => state.selectedId;

export const selectSelectedCp = createSelector(
  selectEntities,
  selectSelectedTableId,
  (tables, selectedId) => tables[selectedId]
);

export const selectAssignedTables = (state: State) => state.AssignedTables;

//this selector returns wether or not a table is assigned
export const selectIsAssigned = createSelector(
  selectEntities,
  selectAssignedTables,
  (tables, assignedTables) => (table_id: string) =>
    assignedTables[table_id] === true
);

export const selectAssignmentViewed = (state: State) => state.AssignmentViewed;

//create a selector to get the assignment viewed
export const selectAssignmentViewedSelector = createSelector(
  selectEntities,
  selectAssignmentViewed,
  (tables, AssignmentViewed) => AssignmentViewed
);
