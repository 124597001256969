import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RegionsService } from "../regions/regions.service";
import Tracker from "@openreplay/tracker";
import { Capacitor } from "@capacitor/core";
import mixpanel from "mixpanel-browser";
import * as configcat from "configcat-js";
import { SettingsState } from "../sync-engine/settings-state/settings-state.service";

@Injectable({ providedIn: "root" })
export class AnalyticsService {
  tracker: Tracker;
  client;
  userValue;
  constructor(private reg: RegionsService, private settingSt: SettingsState) {
    if (environment.production) {
      setTimeout(() => {
        let settings;
        if (Capacitor.isNativePlatform()) {
          settings = {
            projectKey: "cxZ5uNgyWp32uKIDc3rt",
            resourceBaseHref: "https://order.getdqd.com/",
            __DISABLE_SECURE_MODE: true,
          };
        } else {
          settings = {
            projectKey: "cxZ5uNgyWp32uKIDc3rt",
          };
        }
        const tracker = new Tracker(settings);
        tracker.start();
        this.tracker = tracker;
        //subscribe to congito user updates
        this.settingSt.currentUser$.subscribe((user) => {
          if (user != null) {
            tracker.setUserID((user as any).attributes.name);
            tracker.setMetadata("email", (user as any).attributes.email);
            tracker.setMetadata("sub", (user as any).attributes.sub);
            tracker.setMetadata(
              "region",
              (user as any).attributes["custom:user_region"]
            );
            tracker.setMetadata(
              "user_type",
              (user as any).attributes["custom:user_type"]
            );
            tracker.setMetadata(
              "venue",
              (user as any).attributes["custom:venue_id"]
            );
          }
          mixpanel.init("d6a7828b1e592814e6447194b89b0c7e", { debug: false });
          this.identifyUser(user);
        });
      }, 750);
    }

    let logger = configcat.createConsoleLogger(3); // Set the log level to INFO to track how your feature flags were evaluated. When moving to production, you can remove this line to avoid too detailed logging.
    this.settingSt.currentUser$.subscribe((user) => {
      if (user != null) {
        this.userValue = {
          identifier: (user as any).attributes.name,
          email: (user as any).attributes.email,
          country: this.reg.getRegion(),
          custom: {
            venue: this.settingSt.state.get('currentVenue'),
          
          },
        };
      }
    });
    this.client = configcat.createClientWithAutoPoll(environment.configCat, {
      // <-- This is the actual SDK Key for your Test Environment environment
      pollIntervalSeconds: 160,
      logger: logger,
    });
  }

  async getFeatureFlag(key: string, defualt: any) {
    const value = await this.client.getValueAsync(
      key, // Setting Key
      defualt, // Default value
      this.userValue // Optional User Object
    );
    return value;
  }

  identifyUser(user) {
    let groups = (user as any).signInUserSession.accessToken.payload[
      "cognito:groups"
    ];
    let venueId = this.settingSt.state.get('currentVenue')
    let claims = []
    try {
     claims =  (user as any)["signInUserSession"]["idToken"]["payload"]["venue:" + venueId].split(' ')
    } catch (e) {
      console.log(e)
    }
    //remove any values with "ever" in them
    groups = groups.filter((group) => !group.includes("ever"));
    mixpanel.identify((user as any)["attributes"]["sub"]);
    mixpanel.set_group("venue", groups); // an array of IDs
    mixpanel.set_group("role", (user as any)["attributes"]["custom:user_type"]);
    mixpanel.people.set({
      region: (user as any)["attributes"]["custom:user_region"],
      claims: claims,
      venue: venueId,
      Email: (user as any)["attributes"].email,
      name: user["username"],
    });
  }

  //addEvent
  addEvent(event: string, value: any) {
    try {
      if (environment.production) {
        this.tracker.event(event, "completed");
        //run with 100ms timeout and in try statement
        try {
          setTimeout(() => {
            if (value != undefined && value != null) {
              //check vlue is json, cant just be string
              if (typeof value === "string") {
                value = { value: value };
              }

              mixpanel.track(event, value);
            } else {
              mixpanel.track(event);
            }
          }, 100);
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
    //add blue log for dev mode
    try {
      console.log(
        `%c ${event} %c : ${JSON.stringify(value)}`,
        "background: #222; color: #e36936",
        "background: #222; color: #e36936"
      );
    } catch (error) {
      console.log(error);
    }
  }
}
