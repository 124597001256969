import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { CartFacadeService } from "src/app/facades/cart-facade/cart-facade.service";
import {
  DqGarnish,
  DqMixture,
  DqGarnishBasic,
  DqMixtureBasic,
} from "src/app/services/mixture/mixture.service";
import { DqOrderStatus } from "src/app/services/order/order.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";
import { DqModifierGroup } from "src/app/models/cart.model";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { ConsumerCartFacadeService } from "src/app/facades/consumer-cart-facade/consumer-cart-facade.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

@Component({
  selector: "app-perm-bl",
  templateUrl: "./permissions-block.component.html",
  styleUrls: ["./permissions-block.component.scss"],
})
export class PermissionsBlockComponent implements OnInit, OnChanges {
  @Input() claims = [];

  shouldDisplay$ = this.settingSt.hasClaim$;

  constructor(private settingSt: SettingsState) {}

  ngOnInit() {
    console.log("PermissionsBlockComponent ngOnInit", this.claims);
  }

  ngOnChanges(changes: SimpleChanges) {}
}
