<ion-header>
  <ion-toolbar>
    <ion-title>Seat Walkin</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>w

  <ion-item-group class="ion-margin rounded-group" color="secondary">
    <ion-item *ngIf="event == undefined" color="secondary"  lines="full"  [detail]="false" button>
      <ion-thumbnail [style.margin-right.px]="0" slot="start">
       <ion-icon class="fillicon" size="large" name="enter-outline"></ion-icon>
      </ion-thumbnail>  
      <ion-label  class="extramargin">
        <ion-label>Walkin Booking</ion-label>
        <ion-note> No Event Selected </ion-note>
       
      </ion-label>
     
      <ng-content></ng-content>
    </ion-item>
    
   <app-event-card [clickable]="false" [style.--ion-background-color]="'var(--ion-color-secondary)'" [event]="event"*ngIf="event != undefined">
   </app-event-card>
  </ion-item-group>

  <form #openTableForm="ngForm">
    <ion-item-group class="ion-margin rounded-group" color="secondary">
    <ion-item color="secondary" [style.--padding-start.px]="10">

      <ion-input
      type="text"
      autocapitalize="words"
      placeholder="Guest Name"
      required
      #nameinput
      name="guestName"
      ></ion-input>
    </ion-item>
    </ion-item-group>
  </form>
 

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button
      size="large"
      expand="block"
      [disabled]="openTableForm.invalid"
    >
      <ion-label color="light">Seat Walkin</ion-label></ion-button
    >
  </ion-toolbar>
</ion-footer>
