import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Directive({ selector: "[appPageTitle]" })
export class PageTitleDirective implements OnChanges {
  @Input("appPageTitle") title: string;
  @Input("titleSuffix") titleSuffix = ""; // " - Queuebar";

  constructor(private titleSvc: Title) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.title?.currentValue && this.title) {
      this.titleSvc.setTitle(`${this.title}${this.titleSuffix}`);
    }
  }
}
