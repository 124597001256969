import { Injectable } from "@angular/core";
import Cache from "@aws-amplify/cache";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Platform } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { defer, EMPTY, timer } from "rxjs";
import {
  catchError,
  concatMapTo,
  filter,
  finalize,
  map,
  share,
  switchMap,
  switchMapTo,
  takeUntil,
  tap,
} from "rxjs/operators";
import { DqCartItem } from "src/app/models/cart.model";
import { DqConsumerTabCredit } from "src/app/pages/consumer-event/services/consumer-event/consumer-event.service";
import { cartSelectState, State } from "src/app/reducers";
import {
  selectAll,
  selectCouponCodes,
  selectGratuity,
  selectOrderUuid,
} from "src/app/reducers/cart/cart.reducer";
import { DqGratuityType } from "src/app/services/order/order.service";
import { StorageAdapter } from "src/app/services/storage-adapter/storage-adapter.service";
import { TreatMeDataService } from "src/app/services/treat-me-data/treat-me-data.service";
import { environment } from "src/environments/environment";
import * as CartActions from "../../actions/cart.actions";
import { monitorTreatStatusComplete } from "../../actions/cart.actions";

export const STORAGE_KEY_CART = "queuebar:cart";
export const STORAGE_KEY_GRATUITY = "queuebar:gratuity";
export const STORAGE_KEY_ORDER_UUID = "queuebar:order_uuid";
export const STORAGE_KEY_COUPON_CODES = "codes";

const MAX_CACHE_HOURS = 12;

@Injectable()
export class CartEffects {
  cartCache = Cache.createInstance({
    defaultTTL: MAX_CACHE_HOURS * 60 * 60 * 1000,
    keyPrefix: "dq:cart:",
  });
  loadCartOnStartup$ = createEffect(() =>
    defer(() => this.platform.ready()).pipe(
      switchMap(async () => {
        const items = await this.storage.getObject<DqCartItem[]>(
          STORAGE_KEY_CART
        );
        const gratuity = await this.storage.getObject<{
          value: number;
          gratuityType: DqGratuityType;
        }>(STORAGE_KEY_GRATUITY);
        const orderUuid = await this.storage.getObject<string>(
          STORAGE_KEY_ORDER_UUID
        );
        let couponCodes: DqConsumerTabCredit[] = [];
        try {
          const savedValue = this.cartCache.getItem(STORAGE_KEY_COUPON_CODES);
          if (savedValue) {
            couponCodes = JSON.parse(savedValue);
          }
        } catch (error) {}

        return { items, gratuity, orderUuid, couponCodes };
      }),
      map(({ items, gratuity, orderUuid, couponCodes }) =>
        CartActions.loadSavedCartAtStartup({
          items: items || [],
          gratuity,
          orderUuid,
          couponCodes,
        })
      )
    )
  );

  saveCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.loadSavedCartAtStartup),
        switchMapTo(this.store$.pipe(select(cartSelectState))),
        tap((cartState) => {
          const cart = selectAll(cartState);
          const gratuity = selectGratuity(cartState);
          const orderUuid = selectOrderUuid(cartState);
          const coupons = selectCouponCodes(cartState);
          this.storage.setObject(STORAGE_KEY_CART, cart);
          this.storage.setObject(STORAGE_KEY_GRATUITY, gratuity);
          this.storage.setObject(STORAGE_KEY_ORDER_UUID, orderUuid);
          this.cartCache.setItem(
            STORAGE_KEY_COUPON_CODES,
            JSON.stringify(coupons)
          );
        })
      ),
    { dispatch: false }
  );

  vibrateOnAddToCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.addToCart),
        tap(() => {
          if (this.platform.is("capacitor")) {
            Haptics.vibrate();
          } else if ("vibrate" in navigator) {
            // Success = tap, tap, tap
            navigator.vibrate([20, 20, 20, 20]);
          }
        })
      ),
    { dispatch: false }
  );

  monitorTreatStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.monitorTreatStatus),
        tap(({ treatId }) => {
          const currentTreat$ = this.treatMeSvc.getStatus(treatId).pipe(
            catchError(() => EMPTY),
            finalize(() => {}),
            share()
          );

          const timer$ = timer(0, environment.orderAutoRefreshInterval)
            .pipe(
              takeUntil(
                currentTreat$.pipe(
                  filter((currentTreatStatus) => {
                    return currentTreatStatus.validity.is_valid === false;
                  })
                )
              ),
              // concatmap is important, so that the output remains in order, guarding
              // against slow stale date overriding a quicker later request
              concatMapTo(currentTreat$)
            )
            .subscribe((currentTreatStatus) => {
              if (currentTreatStatus.validity.is_valid === false) {
                timer$.unsubscribe();
                this.store$.dispatch(monitorTreatStatusComplete());
              }
            });

          return timer$;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private platform: Platform,
    private storage: StorageAdapter,
    private store$: Store<State>,
    private treatMeSvc: TreatMeDataService
  ) {}
}
