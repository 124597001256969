import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Package,
  SyncV1Reciept,
  SyncV1Table,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class RolesState extends BaseSynced {
  state = new RxState<{ [key: string]: any }>();

  //fields specific to the service
  service_icon: string = "user-pen-outline";
  service_title: string = "Roles Store";
  service_id: string = "Roles"; //id of the service
  service_url: string = "roles";

  //fields specific to the data
  data_id: string = "receipt_id";
  data_detail: string = "total";
  data_expiry: number = 2000; //in minutes, the amount of time before the data is considered unusable

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService
  ) {
    super(syncapis);
  }

  perms$ = this.state.select("available_permissions");
  roles$ = this.state.select("roles");

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));

  override async Sync() {
    if (this.serviceState.get("refreshing")) {
      return "uness";
    }
    this.serviceState.set({
      refreshing: true,
    });
    ///throw error

    let data: any = await this.syncapis
      .getCompleteSync(this.service_url)
      .toPromise();

    console.log("got new data", data)

    let map = data;

    this.state.set(map);

    this.serviceState.set({
      refreshing: false,
    });

    return "success";
  }

  //get table using id
  receipt$ = (id) => this.state.select(id);
  getDirect$ = (id) => this.state.select(id);
}
