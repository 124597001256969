<div class="tapaway"  *ngIf="selecting != ''" (click)="selecting = ''">

</div>
<div class="box space">
  <div class="datepicker" [class.expanded]="selecting != 'all' && selecting != 'selecting'" *ngIf="selecting != ''">


<ion-list  class="" >
  <ion-item color="medium" (click)="selecting = 'start'">
    <ion-label>
      Start Date
    </ion-label>
    <ion-datetime-button   datetime="datetime"></ion-datetime-button>
    
  </ion-item>
  
  <ion-item [style.display]="selecting ==='start'? 'block' : 'none'">
    <ion-datetime presentation="date"  [(ngModel)]="StartDateText"  (ngModelChange)="select('date')"  slot="end"  id="datetime"></ion-datetime>
    
  </ion-item>
  
  
  <ion-item color="medium" (click)="selecting = 'end'">
    <ion-label>
      End Date  
    </ion-label>
    <ion-datetime-button  datetime="datetime2"></ion-datetime-button>
    
  </ion-item>
  
  <ion-item [style.display]="selecting ==='end'? 'block' : 'none'">
    <ion-datetime presentation="date"  slot="end" [(ngModel)]="StopDateText" [min]="StartDateText" (ngModelChange)="select('date')"  id="datetime2"></ion-datetime>
    
  </ion-item>
</ion-list>



  </div>
  <div class="options space" *ngIf="Selectable">
    <div class="option" (click)="select('Year')">Yr</div>
    <div class="option" (click)="select('Month')">Mth</div>
    <div id="click-trigger" class="option" (click)="selecting = 'all'">
      Date
    </div>

    <div
      *ngIf="Live && CurrentSelected != 'Live'"
      class="option"
      (click)="select('Live')"
    >
      Live
    </div>
  </div>
  <div class="selected space" [class.alone]="!Selectable">
    {{ CurrentSelected == 'date' ? Datetext() : CurrentSelected }}
    <ion-icon *ngIf="CurrentSelected == 'Live'" name="pulse-outline"></ion-icon>
  </div>
</div>
