import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { IonContent } from "@ionic/angular";

@Directive({ selector: "[appKeyboardScroll]" })
export class ScrollWhenKeyboardDidShowDirective implements OnInit, OnDestroy {
  constructor(
    private ionContent: IonContent,
    private element: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    this.ionContent.getScrollElement().then((scroll) => {
      scroll.style.setProperty("transition", "transform ease-in-out");
      scroll.style.setProperty("transition-duration", "0.3s");
    });

    window.addEventListener(
      "ionKeyboardDidShow",
      this.keyboardDidShow.bind(this)
    );
    window.addEventListener(
      "ionKeyboardDidHide",
      this.keyboardDidHide.bind(this)
    );
  }

  ngOnDestroy() {
    this.ionContent.getScrollElement().then((scroll) => {
      scroll.style.removeProperty("transition");
      scroll.style.removeProperty("transition-duration");
    });
    window.removeEventListener("ionKeyboardDidShow", this.keyboardDidShow);
    window.removeEventListener("ionKeyboardDidHide", this.keyboardDidHide);
  }

  async keyboardDidShow(ev: CustomEvent) {
    const { keyboardHeight } = ev.detail;

    const clientRects = this.element.nativeElement.getClientRects();
    const currentY = clientRects.item(0)?.y;
    if (currentY) {
      const scroll = await this.ionContent.getScrollElement();

      const scrollHeight = scroll.offsetHeight;

      // calculate how much we need to scroll the view to show the input

      const pxToScroll = currentY - (scrollHeight - keyboardHeight);

      if (pxToScroll > 0) {
        scroll.style.setProperty(
          "transform",
          `translate3d(0, ${-1 * pxToScroll}px, 0)`
        );
      }
    }
  }

  async keyboardDidHide(event: any) {
    const scroll = await this.ionContent.getScrollElement();

    scroll.style.removeProperty("transform");
  }
}
