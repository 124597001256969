import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  IonItemSliding,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import {
  DqTableDetails,
  TableService,
} from "src/app/services/table/table.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

import { VenueService } from "src/app/services/venue/venue.service";
import { SessionState } from "src/app/services/sync-engine/sessions-state/session.service";

import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import { BookingState } from "src/app/services/sync-engine/booking-state/booking.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { AssignmentState } from "src/app/services/sync-engine/assignment-state/assignment.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { BookingService } from "src/app/services/booking/booking.service";
import { EventState } from "src/app/services/sync-engine/event-state/event.service";
import { take } from "rxjs";
import { SyncV1Event } from "src/app/models/sync_models";
import { EventSelectorComponent } from "../event-selector/event-selector.component";


@Component({
  selector: "app-start-seating",
  templateUrl: "./start-seating.component.html",
  styleUrls: ["./start-seating.component.scss"],
  animations: [],
})
export class StartSeatingComponent implements OnInit {
  
  constructor(
    private platform: Platform,
    public router: Router,
    public alertController: AlertController,
    public modalController: ModalController,
    private venue: VenueService,
    private analy: AnalyticsService,
    private sessionSt: SessionState,
    private guestSt: GuestState,
    private bookingSt: BookingState,
    private tableSt: TableState,
    private assignmentSt: AssignmentState,
    private settingSt: SettingsState,
    private bookingSvc: BookingService,
    private eventSt: EventState
  ) {}

  event: SyncV1Event;

  async ngOnInit() {

    //find closest event //or set to walking event

    let events = await this.eventSt.events$.pipe(take(1)).toPromise();
    let closestEvent = events.sort((a, b) => new Date(a.e_date_local).getTime() - new Date(b.e_date_local).getTime())[0];
    //time diff under 12 hours and closest event not undefined 
    if (closestEvent != undefined) {
      let timeDiff = Math.abs(new Date(closestEvent.e_date_local).getTime() - Date.now());
      if (timeDiff < 1000 * 60 * 60 * 12) {
        this.event = closestEvent;
      }
    } else {
      
    }


    //


  }

  dismiss() {
    this.modalController.dismiss();
  }



  async selectEvent() {

   
  }
 
}
