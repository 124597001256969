import { Component, Input, OnInit } from "@angular/core";
import { Observable, combineLatest, map } from "rxjs";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";

@Component({
  selector: "app-wrapper",
  templateUrl: "./wrapper.component.html",
  styleUrls: ["./wrapper.component.scss"],
})
export class WrapperComponent implements OnInit {
  @Input() shinkable: boolean = false;
  @Input() open: boolean = false;
  @Input() border: boolean = true;

  constructor() {}

  ngOnInit() {}
}
