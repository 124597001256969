import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  IonItemSliding,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { relative } from "path";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { dashboardRefreshTables } from "src/app/pages/dashboard/actions/dashboard.actions";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import {
  DqTableDetails,
  TableService,
} from "src/app/services/table/table.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  collapseOnLeaveAnimation,
  expandOnEnterAnimation,
  fadeInUpOnEnterAnimation,
} from "angular-animations";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { VenueService } from "src/app/services/venue/venue.service";
import { SessionState } from "src/app/services/sync-engine/sessions-state/session.service";
import {
  Observable,
  combineLatest,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
  zip,
} from "rxjs";
import { SyncV1Table } from "src/app/models/sync_models";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import { BookingState } from "src/app/services/sync-engine/booking-state/booking.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { AssignmentState } from "src/app/services/sync-engine/assignment-state/assignment.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { BookingService } from "src/app/services/booking/booking.service";

interface DqTableDetailsWithSelected extends DqTableDetails {
  selected?: boolean;
}
@Component({
  selector: "app-table-item",
  templateUrl: "./table-item.component.html",
  styleUrls: ["./table-item.component.scss"],
  animations: [],
})
export class TableItemComponent implements OnInit {
  @Input() table?: any;
  @Input() fav?: boolean;
  @Input() interactions: boolean = true;
  @Input() transparent = false;
  @Output() closeTable = new EventEmitter<DqTableDetails>();
  @Output() openTable = new EventEmitter<DqTableDetails>();
  @Output() selectedTable = new EventEmitter<DqTableDetails>();
  @Input() selected = false;
  @ViewChild("item") slider: IonItemSliding;
  isTablet: boolean = this.platform.is("tablet");
  is_upcoming$ = this.bookingSvc.is_upcoming$;
  url;
  //is manager
  isManager: boolean = false;
  session$ = this.sessionSt.session$;
  booking$ = this.bookingSt.booking$;
  table$ = this.tableSt.table$;
  guest$ = (id) => this.guestSt.guest$(id);
  free_access$ = this.settingSt.hasClaim$("manage_assignments");
  constructor(
    private platform: Platform,
    public router: Router,
    public alertController: AlertController,
    public modalController: ModalController,
    private venue: VenueService,
    private analy: AnalyticsService,
    private sessionSt: SessionState,
    private guestSt: GuestState,
    private bookingSt: BookingState,
    private tableSt: TableState,
    private assignmentSt: AssignmentState,
    private settingSt: SettingsState,
    private bookingSvc: BookingService
  ) {}

  ngOnInit() {}

  async favTable() {
    // this.store.dispatch(assign({ table_id: this.table.table_id }));
    Haptics.impact();
    await this.venue.FavTable(this.table.table_id, true).toPromise();
    this.table.is_assigned = true;
    //set table loading
    this.settingSt.readAssignments();
  }

  async unfavTable() {
    //  this.store.dispatch(unAssign({ table_id: this.table.table_id }));
    await this.venue.FavTable(this.table.table_id, false).toPromise();
    Haptics.impact();

    this.table.is_assigned = false;
    this.settingSt.readAssignments();
  }
  secondlasttap = 0;
  lasttap = 0;
  async checkAnger() {
    if (this.table.seat_id != undefined) {
      return;
    }
    //opens after multple clicks
    let now = Date.now();
    if (now - this.lasttap < 1000 && now - this.secondlasttap < 2000) {
      this.slider.open("end");
      Haptics.impact();
    }

    this.secondlasttap = this.lasttap;
    this.lasttap = now;
  }
  runningaction = false;
  async dragging(event, list) {
    console.log(event);

    if (this.runningaction) {
      return;
    }
    //if value is positive we are fragging right to left (close open table)
    if (event.detail.ratio > 1.5) {
      this.runningaction = true;
      Haptics.impact();
      await list.close();
      if (this.table.seat_id != null) {
        await this.closeTable.emit(this.table);
      } else {
        await this.openTable.emit(this.table);
      }

      this.runningaction = false;
    }
    if (event.detail.ratio < -1.5) {
      this.runningaction = true;
      Haptics.impact();
      await list.close();
      if (!this.fav) {
        try {
          await this.favTable();
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          await this.unfavTable();
        } catch (e) {
          console.log(e);
        }
      }

      this.runningaction = false;
    }
    return;
    //if value is negative we are dragging left to right (favourite unfavorite)
    //either way if we get over half way, run relevant action and vibrate the device
  }

  log(event) {
    console.log(event);
  }

  getTables(table, bookings) {
    let arr = [table.table_id];
    if (bookings != undefined && bookings.length > 0) {
      bookings.forEach((x) => {
        arr.push(x);
      });
    }
    return arr;
  }
  //track by
  trackByFn(index, item) {
    return item.table_id;
  }

  isHalfway(i, length) {
    //return true if i is halfway through the array (taking into account length can be odd ect)
    if (length % 2 == 0) {
      //even
      if (i == 0) {
        return true;
      }
    } else {
      //odd
      if (i == Math.floor(length / 2)) {
        return true;
      }
    }
  }
}
