<ion-row color="none">
  <ion-button
    size="default"
    color="primary"
    class="small"
    [class.smallr]="!(selected === 0)"
    [fill]="selected == 0 ? 'solid' : 'outline'"
    (click)="gratuityChange(0)"
    ><ion-col
      ><ion-label>0%</ion-label>
      <ion-note [color]="selected === 0 ? 'light' : ''">{{
        0 | currency: (currency$ | async)
      }}</ion-note></ion-col
    ></ion-button
  >
  <ion-button
    size="default"
    *ngFor="let option of options"
    class="small"
    color="primary"
    [class.smallr]="!(selected === option)"
    [fill]="selected == option ? 'solid' : 'outline'"
    (click)="gratuityChange(option)"
  >
    <ion-col
      ><ion-label>{{ option }}%</ion-label>
      <ion-note [color]="selected === option ? 'light' : ''">{{
        total * (option / 100) | currency: (currency$ | async)
      }}</ion-note></ion-col
    ></ion-button
  >

  <ion-button
    size="default"
    color="primary"
    class="small"
    [class.smallr]="!(selected === 'amount')"
    (click)="gratuityChange('amount')"
    [fill]="selected === 'amount' ? 'solid' : 'outline'"
  >
    <ion-icon
      *ngIf="selected != 'amount'"
      slot="icon-only"
      name="create-outline"
    ></ion-icon>
    <ion-col *ngIf="selected === 'amount'"
      ><ion-icon class="icon-only" name="create-outline"></ion-icon>
      <ion-note [color]="selected === 'amount' ? 'light' : ''">{{
        customvalue | currency: (currency$ | async)
      }}</ion-note></ion-col
    >
  </ion-button>
</ion-row>
