import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { relative } from "path";
import { closeTable } from "src/app/actions/table.actions";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { DqNotificationSubscription } from "src/app/models/notification-subscription";
import { dashboardRefreshTables } from "src/app/pages/dashboard/actions/dashboard.actions";
import { OpenTableModalPage } from "src/app/pages/open-table-modal/open-table-modal.page";
import { State } from "src/app/reducers";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { OrderV2Service } from "src/app/services/order-v2/order-v2.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import {
  DqTableDetails,
  TableService,
} from "src/app/services/table/table.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  collapseOnLeaveAnimation,
  expandOnEnterAnimation,
  fadeInUpOnEnterAnimation,
} from "angular-animations";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { switchMap, take, tap } from "rxjs/operators";
import { VenueService } from "src/app/services/venue/venue.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { RolesState } from "src/app/services/sync-engine/roles-state/roles.service";
import { UsersState } from "src/app/services/sync-engine/users-state/users.service";
import { DateTime } from "luxon";

interface DqTableDetailsWithSelected extends DqTableDetails {
  selected?: boolean;
}
@Component({
  selector: "app-staff-item",
  templateUrl: "./staff-item.component.html",
  styleUrls: ["./staff-item.component.scss"],
  animations: [],
})
export class StaffItemComponent implements OnInit {
  @Input() staffMember;
  @Input() editing = true;
  @Input() hideButtons = false;

  @Output() refresh = new EventEmitter();
  isDesktop = window.location.href.includes("/cms/");
  loading = false;
  tables$ = this.tableSt.tables$;
  @ViewChild("select") select;

  constructor(
    private platform: Platform,
    public router: Router,
    public alertController: AlertController,
    public modalController: ModalController,
    private analy: AnalyticsService,
    private venueService: VenueService,
    private loadingCtrl: LoadingController,
    private settingSt: SettingsState,
    private action: ActionSheetController,
    private tableSt: TableState,
    private roleSt: RolesState,
    private userSt: UsersState
  ) {}

  ngOnInit() {
    if (this.staffMember.last_active_at) {
      this.longInactive = this.staffMember.last_active_at < DateTime.now().minus({ days: 100 }).toISO();
    }
  }
  longInactive = false
  runningaction = false;
  async dragging(event, list) {
    console.log(event);

    if (this.runningaction) {
      return;
    }
    //if value is positive we are fragging right to left (close open table)
    if (event.detail.ratio > 1.5) {
      this.runningaction = true;
      Haptics.impact();
      this.presentOptions();

      await list.close();

      this.runningaction = false;
    }
    if (event.detail.ratio < -1.5) {
      this.runningaction = true;

      await list.close();

      this.runningaction = false;
    }
    return;
    //if value is negative we are dragging left to right (favourite unfavorite)
    //either way if we get over half way, run relevant action and vibrate the device
  }

  async presentOptions() {
    //show action sheet with assign user, delete user, and reset password
    let buttons = [
      {
        text: "Change Roles",
        icon: "person-outline",
        handler: () => {
          this.changeRoles();
        },
      },
      {
        text: "Rename User",
        icon: "create-outline",
        handler: () => {
          this.renameUser();
        },
      },
      {
        text: "Reset Password",
        icon: "key-outline",
        handler: () => {
          this.restUser();
        },
      },
      {
        text: "Delete User",
        icon: "trash-outline",
        handler: async () => {
          //show confirmation alert
          this.deleteUser();
        },
      },
      {
        text: "Cancel",
        icon: "close",
        role: "cancel",
      },
    ];

    let acti = await this.action.create({
      header: "Manage Users",

      buttons: buttons,
    });
    await acti.present();
  }

  async deleteUser() {
    //show confirmation alert
    let alert = await this.alertController.create({
      header: "Delete User",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Delete",
          handler: () => {
            this.removeUser();

          },
        },
      ],
    });
    alert.present();
  }

  async renameUser() {

    //show an alert with an input field. 
    //pre fill the input with the current name
    //on submit, run the rename user function

  let alert = await this.alertController.create({
    header: 'Rename User',
    inputs: [
      {
        name: 'name',
        type: 'text',
        placeholder: 'Enter new name',
        value: this.staffMember?.full_name // Assuming staffMember is the object that holds the user's current name
      }
    ],
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Save',
        handler: async (data) => {
          this.staffMember.full_name = data.name

          this.staffMember.enabled  = this.staffMember.is_enabled
          await this.venueService.updateUser(this.staffMember).toPromise()
          this.refresh.emit()
          this.userSt.SyncData()
        }
      }
    ]
  });

  await alert.present();
  }

  async changeRoles() {
    console.log(this.staffMember)


    //show an alert with a list of roles from the role state
    let roles = await this.roleSt.roles$.pipe(take(1)).toPromise();
  let alert = await this.alertController.create({
    header: 'Change Role',
    inputs: roles.map(role => ({
      name: role.name,
      type: 'radio',
      label: role.name,
      value: role.r_id,
      checked: this.staffMember.role_id === role.r_id
    })),
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Ok',
        handler: async (selectedRoleId) => {
          try {
          this.staffMember.role_id = selectedRoleId;
          await this.venueService.updateUser(this.staffMember).toPromise()
          this.refresh.emit()
          this.userSt.SyncData()
          } catch (error) {
            console.log(error)
          
          }
        }
      }
    ]
  });
  await alert.present();

  }

  async assignUser(event) {
    event = event.detail.value;
    //create a ion select
    //compare the tables on the output of the ion select
    //if a new table is in there, run the "addit function"
    //if a table is not in there, run the "removeit function"
    //if a table is in both, do nothing
    //if a table is not in both, do nothing
    this.loading = true;

    let newones = [];
    let oldones = [];

    console.log("incoming", event, this.staffMember.tables);

    //get the new tables
    event.forEach((table) => {
      if (this.staffMember.tables != undefined) {
        if (
          !this.staffMember.tables.find((t) => t.table_id === table.table_id)
        ) {
          newones.push(table);
        }
      } else {
        newones.push(table);
      }
    });

    console.log("NEw ones", newones);

    //get the old tables
    if (this.staffMember.tables) {
      this.staffMember.tables.forEach((table) => {
        if (!event.find((t) => t.table_id === table.table_id)) {
          oldones.push(table);
        }
      });
    }

    console.log("Old Ones", oldones);

    //remove the old ones
    await oldones.forEach(async (table) => {
      let tempstaff = this.staffMember;
      tempstaff.is_assigned = false;
      await this.venueService
        .AssignUsers(table.table_id, this.staffMember)
        .toPromise();
    });

    //add the new ones
    await newones.forEach(async (table) => {
      let tempstaff = this.staffMember;
      tempstaff.is_assigned = true;
      await this.venueService
        .AssignUsers(table.table_id, this.staffMember)
        .toPromise();
    });

    
    //finish off by setting the tables to the new tables
    this.staffMember.tables = event;
    this.loading = false;
  }

  async toggleEnabled(staffMember: any) {
    if (staffMember.is_enabled) {
      this.analy.addEvent("Enable User", staffMember);
    } else {
      this.analy.addEvent("Disable User", staffMember);
    }

    this.loading = true;
    this.settingSt.venueId$
      .pipe(
        take(1),
        tap(),
        switchMap((venue_id) => {
          staffMember.v_id = venue_id;
          return this.venueService.addEditVenueUser(staffMember);
        })
      )
      .subscribe(() => {
        this.userSt.SyncData()
        this.loading = false;
      });
  }

  async removeUser() {
    this.settingSt.venueId$
      .pipe(
        take(1),
        switchMap((venue_id) => {
          this.staffMember.v_id = venue_id;

          return this.venueService.removeVenueUser(this.staffMember);
        })
      )
      .subscribe(() => this.userSt.SyncData());
    this.analy.addEvent("Remove User", this.staffMember);
  }

  async restUser() {
    this.settingSt.venueId$
      .pipe(
        take(1),
        switchMap((venue_id) => {
          this.staffMember.v_id = venue_id;
          return this.venueService.resetVenueUser(this.staffMember);
        })
      )
      .subscribe(() => this.userSt.SyncData());
    this.analy.addEvent("Remove User", this.staffMember);
  }
}
