import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Platform, PopoverController } from "@ionic/angular";
import { getCountries } from "libphonenumber-js/min";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import {countries, 
CountryInterface,
} from "country-codes-flags-phone-codes";
import { RegionsService } from "src/app/services/regions/regions.service";
@Component({
  selector: "app-phoneInput",
  templateUrl: "./phoneInput.component.html",
  styleUrls: ["./phoneInput.component.scss"],
})
export class PhoneInputComponent implements OnInit, OnChanges {
  @Input() phone: string;
  @Input() code: string
  selectedCountry: CountryInterface;
  @Output() phoneChange = new EventEmitter<string>();
  @Output() codeChange = new EventEmitter<string>();

  isDesktop = this.platform.is("desktop")
  filteredCountries = countries
  constructor(
    private region: RegionsService,
    private platform: Platform
  ) {}

  ngOnInit() {
    //log output of tableDetail
    this.filterEmpty();
    if (this.phone == undefined || this.phone == '') {
      this.phone = '';
    }
    this.phoneChange.emit(this.phone);
  }

  filterEmpty() {
    this.filteredCountries = countries.filter((country: CountryInterface) => country.dialCode !== '');
  }

  logChangeEvent(event) {
    console.log("Event", event)
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    //loop through countries and find the country that matches the code
    this.selectedCountry = countries.find((country: CountryInterface) => country.dialCode === this.code);
  }

  filterCountries(event: any) {
    const searchValue = event.target.value;

    // Move priority countries to top
    const priorityCountries = ['AU', 'US', 'GB', 'SG', 'FR', 'ES', 'CH', 'DE'];
    

    // Sort countries to put priority countries at the top
    const sortedCountries = [...countries].sort((a, b) => {
      const aIsPriority = priorityCountries.includes(a.code);
      const bIsPriority = priorityCountries.includes(b.code);
      
      if (aIsPriority && !bIsPriority) return -1;
      if (!aIsPriority && bIsPriority) return 1;
      return 0;
    });
    this.filteredCountries = sortedCountries.filter((country: CountryInterface) => {
      const searchLower = searchValue.toLowerCase();
      return country.name.toLowerCase().includes(searchLower) || 
             country.dialCode.toLowerCase().includes(searchLower) ||
             country.code.toLowerCase().includes(searchLower) ||
             country.flag.toLowerCase().includes(searchLower);
    });
  }

  selectCountry(country: CountryInterface) {
    this.codeChange.emit(country.dialCode);
    this.code = country.dialCode;
    this.selectedCountry = country;
  }

}
