import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRouteSnapshot, Route, Router } from "@angular/router";
import { ModalController, PopoverController } from "@ionic/angular";
import { ROUTE_PARAM_LOCATION_ID } from "src/app/pages/consumer-menu/consumer-menu-route-params";
import { RegionsService } from "src/app/services/regions/regions.service";
import { environment } from "src/environments/environment";
import { MapComponent } from "../../map/map.component";
import { QRPaymentModalComponent } from "../qrpayment-modal/qrpayment-modal.component";

@Component({
  selector: "app-map-actions",
  templateUrl: "./map-actions.component.html",
  styleUrls: ["./map-actions.component.scss"],
})
export class MapActionsComponent implements OnInit {
  @Input() view: MapComponent;

  constructor(
    public popover: PopoverController,
    private router: Router,
    private region: RegionsService
  ) {}

  ngOnInit() {}

  AddObject(thing: string) {
    switch (thing) {
      case "Table":
        this.view.createObject();
        break;
      case "Object":
        this.view.createObject();
        break;
      case "Text":
        this.view.createText();
        break;
      case "Wall":
        this.view.createWall();
        break;
    }
    this.dismissPopover();
  }

  dismissPopover() {
    this.popover.dismiss();
  }
}
