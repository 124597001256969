import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CMSv2CollectionList,
  CMSv2CollectionPoint,
  CMSv2Table,
  CMSv2TableList,
} from "src/app/models/cms_db_equiv";
import { TableSummaryResponse } from "src/app/models/table_summary_models";
import { RegionsService } from "../../regions/regions.service";
import { SyncV1Table } from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class CmsTablesService {
  constructor(public reg: RegionsService, public http: HttpClient) {}

  getTables() {
    return this.http.get<CMSv2TableList>(
      `${this.reg.ServerURL()}/cms_v2/table`
    );
  }

  getMethods() {
    return this.http.get<any>(`${this.reg.ServerURL()}/cms_v2/payment_methods`);
  }
  GetPayments(data) {
    return this.http.post(`${this.reg.ServerURL()}/cms_v2/payment_recon`, data);
  }

  saveTable(table: SyncV1Table) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/table/${table.table_id}`,
      table
    );
  }

  getSeatingStats() {
    return this.http.get(
      `${this.reg.ServerURL()}/cms_v2/current_seating_stats`
    );
  }

  createTable(table) {
    return this.http.post(`${this.reg.ServerURL()}/cms_v2/table`, table);
  }

  deleteTable(table) {
    return this.http.delete(
      `${this.reg.ServerURL()}/cms_v2/table/${table.table_id}`
    );
  }

  getCollections() {
    return this.http.get<CMSv2CollectionList>(
      `${this.reg.ServerURL()}/cms_v2/collection`
    );
  }

  orderCollection(neworder, collection) {
    return this.http.post(
      `${this.reg.ServerURL()}/cms_v2/collection/${collection}/order`,
      neworder
    );
  }

  getData(start?, end?, operational?) {
    let params = {};
    if (start && end) {
      params = {
        start: start,
        end: end,
      };
    }
    if (operational) {
      params = {
        operational: operational,
      };
    }
    return this.http.get<TableSummaryResponse>(
      `${this.reg.ServerURL()}/cms_v2/table_summary`,
      {
        params: params,
      }
    );
  }

  getStock(start?, end?, operational?) {
    let params = {};
    if (start && end) {
      params = {
        start: start,
        end: end,
      };
    }
    if (operational) {
      params = {
        operational: operational,
      };
    }
    return this.http.get<Object>(
      `${this.reg.ServerURL()}/cms_v2/stock_report`,
      {
        params: params,
      }
    );
  }
}
