import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable, take } from "rxjs";
import { Injectable } from "@angular/core";
import { SettingsState } from "../services/sync-engine/settings-state/settings-state.service";
import { Router } from "@angular/router";
import { BookingState } from "../services/sync-engine/booking-state/booking.service";

@Injectable()
export class VenueHeaderInterceptor implements HttpInterceptor {
  constructor(private settingSt: SettingsState, private router: Router, private bookingSt: BookingState) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let venue_id = this.settingSt.state.get("currentVenue");
    // get latest from this.authFacade.currentVenueId$


    if (window.location.href.includes('/prombar/')) {

    

    // check if were on a bookings page and if so, get 
    //get current route params

    //loop through the children and get all the params
    //this.router.routerState.snapshot.root.children[0].children[0].params;
    function collectParams(node) {
      let params = {};
      if (node.children.length > 0) {
        node.children.forEach((child) => {
          params = { ...params, ...child.params, ...collectParams(child) };
        });
      }
      return params;
    }

    let params = collectParams(this.router.routerState.snapshot.root);

    console.log("Were a promoter, we need to be careful", params);

    
    if (params["booking_id"] != undefined) {
      console.log("Booking id exists, gonna use that");
      // get booking id from route params
      let booking_id = params["booking_id"]
      let booking = this.bookingSt.state.get(booking_id);
        if (booking!= undefined && booking.v_id != undefined) {

        
        // get booking from booking
        let cl = { headers: req.headers.append("venue_id", booking.v_id + ""),}
        const clonedRequest = req.clone(cl);
        return next.handle(clonedRequest);
        }
      
    }
  }

    if (venue_id == undefined) {
      console.log("VenueHeaderInterceptor: current_venue_id is undefined");



      const clonedRequest = req.clone();
      return next.handle(clonedRequest);
    } else {
      const clonedRequest = req.clone({
        headers: req.headers.append("venue_id", venue_id + ""),
      });
      return next.handle(clonedRequest);
    }

    // Pass the cloned request instead of the original request to the next handle
  }
}
