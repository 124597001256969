import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
@Component({
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
})
export class TagComponent implements OnInit {
  @Input() tag: SyncV1TagInstance;
  @Input() edit = false;

  @Output() removed = new EventEmitter();
  @Output() clicked = new EventEmitter();

  constructor(
    private region: RegionsService,
    private tagSt: TagState,
    private popover: PopoverController
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  async viewDetail(detail, event) {
    if (this.tag.explanation == undefined) {
      return;
    }

    let popover = await this.popover.create({
      component: detail,
      showBackdrop: false,
      event: event,
    });

    popover.present();
  }

  tagStrength(tagDef, type) {
    if (["music", "taste", "brand"].includes(type)) {
      return Math.round(tagDef * 100) + "%";
    } else if (type == undefined || type == "") {
      return tagDef;
    } else {
      return "x" + tagDef;
    }
  }
}
