import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController, PopoverController, ToastController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import { SyncV1TagDefinition } from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
import { IonRouterOutlet } from "@ionic/angular/common";
import { BookingState } from "src/app/services/sync-engine/booking-state/booking.service";
import { TagSearchComponent } from "../tag-search/tag-search.component";
import { BookingService } from "src/app/services/booking/booking.service";
import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";
@Component({
  selector: "app-booking-hints",
  templateUrl: "./booking-hints.component.html",
  styleUrls: ["./booking-hints.component.scss"],
})
export class BookingHintsComponent implements OnInit {
  @Input() booking_id: string
  @Input() presentingElement: any;

  notes = ""

  constructor(
    private bookingSt: BookingState,
    private bookingSvc: BookingService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }

  async addTag() {
    this.impact()
    console.log("addTag", this.booking_id)
    let booking = this.bookingSt.state.get(this.booking_id)
    let tags = booking.tags
    console.log("tags", tags)
    const modal = await this.modalCtrl.create({
      component: TagSearchComponent,
      componentProps: {
        tags: booking.tags,
      },
      presentingElement: this.presentingElement
    });

    await modal.present()

    modal.onDidDismiss().then(async (data) => {
      if (data.data) {
        let booking = this.bookingSt.state.get(this.booking_id)
        booking.tags = data.data
        await this.bookingSvc.updateBooking(booking).toPromise()
        Haptics.notification({ type: NotificationType.Success })
        // Show success toast
        this.toastCtrl.create({
          message: 'Tags updated successfully',
          duration: 2000,
        
          swipeGesture: 'vertical',
          position: 'bottom'
        }).then(toast => toast.present());
      }
    })

  }

  async addNotes() {
    let booking = this.bookingSt.state.get(this.booking_id)

    if (this.notes.length == 0) {
 return;
    }
    booking.notes +=  "\n" + this.notes
    this.impact()
    await this.bookingSvc.updateBooking(booking).toPromise()
    this.notes = ""
    Haptics.notification({ type: NotificationType.Success })
    this.toastCtrl.create({
      message: 'Notes updated successfully',
      duration: 2000,
      position: 'bottom',
      swipeGesture: 'vertical',
    }).then(toast => toast.present());

  }

  impact() {
   Haptics.impact({ style: ImpactStyle.Light })
  }
}
