import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { TagInstance } from "src/app/models/live_tags";
import {
  SyncV1Notification,
  SyncV1TagDefinition,
} from "src/app/models/sync_models";

import { RegionsService } from "src/app/services/regions/regions.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SyncV1TagInstance } from "../tag-groups/tag-groups.component";
import { NotificationState } from "src/app/services/sync-engine/notification-state/notification.service";
@Component({
  selector: "app-waiting-item",
  templateUrl: "./waiting-item.component.html",
  styleUrls: ["./waiting-item.component.scss"],
})
export class WaitingComponent implements OnInit {
  @Input() table_id;
  @Input() success_timeout = 3;

  relevantNotification$ = this.notificationSt.relevant$;

  constructor(
    private region: RegionsService,
    private notificationSt: NotificationState
  ) {}

  ngOnInit() {
    //log output of tableDetail
  }
}
