import { Action, createReducer, on } from "@ngrx/store";
import {
  DqCurrencyDetails,
  DqLoginResponseSuccess,
  DqStaffDetails,
  DqVenueDetails,
} from "src/app/services/auth/auth.service";
import * as AuthActions from "../../actions/auth.actions";
import { CognitoUser } from "@aws-amplify/auth";
import { venueCheckInSuccess } from "src/app/actions/venue.actions";

export interface State {
  user: CognitoUser;
  checkedAtStartup: boolean;
  currentUser: DqStaffDetails;
  currentVenue: DqVenueDetails;
  currentVenueLocale: DqCurrencyDetails;
  squareLocationId: string;
  timeOfLastLogin: Date;
  terminal_location?: string;
}

export const initialState: State = {
  user: null,
  checkedAtStartup: false,
  currentUser: null,
  currentVenue: null,
  currentVenueLocale: null,
  squareLocationId: null,
  timeOfLastLogin: null,
  terminal_location: null,
};

const authReducer = createReducer(
  initialState,
  on(
    AuthActions.userLoginSuccess,
    (state, { user }): State => ({ ...state, user })
  ),
  on(
    AuthActions.treatUserLoginSuccess,
    (state, { user }): State => ({ ...state, user })
  ),
  on(
    AuthActions.updateUserProfile,
    (state, { user }): State => ({ ...state, user })
  ),
  on(
    AuthActions.authCheckAtStartupSavedDetails,
    (state, { user }): State => ({
      ...state,
      user,
      checkedAtStartup: true,
    })
  ),
  on(
    AuthActions.authTreatUserCheckAtStartupSavedDetails,
    (state, { user }): State => ({
      ...state,
      user,
      checkedAtStartup: true,
    })
  ),
  on(
    AuthActions.authCheckAtStartupNoDetails,
    (state): State => ({
      ...state,
      checkedAtStartup: true,
    })
  ),
  on(
    venueCheckInSuccess,
    (state, { loginResponse }): State =>
      updateStateWithLoginResponse(state, loginResponse)
  ),
  on(
    AuthActions.userLogOut,
    (): State => ({
      ...initialState,
      checkedAtStartup: true,
    })
  ),
  on(
    AuthActions.authSelectVenue,
    (state, venue): State => updateStateWithVenueResponse(state, venue)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

// removed from return below, prevent default venue and allow multi venue funciton
// currentVenue: marchantdetails[0],
function updateStateWithLoginResponse(
  state: State,
  {
    staffdetails,
    marchantdetails,
    currency_locale_details,
    square_location_id,
  }: DqLoginResponseSuccess
): State {
  return {
    ...state,
    timeOfLastLogin: new Date(),
    currentUser: staffdetails[0],
    currentVenueLocale: currency_locale_details[0],
    squareLocationId: square_location_id,
    terminal_location: marchantdetails[0].v_stripe_physical_location,
  };
}

function updateStateWithVenueResponse(state: State, venue: any): State {
  const tmpLocal: DqCurrencyDetails = {
    lc_currency_code_iso_4217: "AUD",
    lc_country_code_iso_3166_three_char: "AUS",
    lc_language_iso_639_1: "en",
    lc_tax_text: "GST",
    lc_tax_rate: "0.1",
  };

  const tmpVenue: DqVenueDetails = {
    v_api_key: "opt6ufl8",
    v_api_passphrase: "czo4lkoe",
    v_id: "1",
    v_merchant_uuid: "54a624c325151",
    v_name: "The Ivy",
  };

  return {
    ...state,
    currentVenue: tmpVenue,
    currentVenueLocale: tmpLocal,
  };
}

export const selectCognitoUser = (state: State) => state.user;
export const selectCheckedAtStartup = (state: State) => state.checkedAtStartup;
export const selectCurrentUser = (state: State) => state.currentUser;
export const selectCurrentUserId = (state: State) =>
  state.currentUser && state.currentUser.staff_mem_id;
export const selectCurrentVenue = (state: State) => state.currentVenue;
export const selectCurrentVenueId = (state: State) =>
  state.currentVenue && state.currentVenue.v_id;
export const selectCurrentVenueLocale = (state: State) =>
  state.currentVenueLocale;
export const selectTimeOfLastLogin = (state: State) => state.timeOfLastLogin;
export const selectSquareLocationId = (state: State) => state.squareLocationId;
