import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { CartFacadeService } from "src/app/facades/cart-facade/cart-facade.service";
import {
  DqGarnish,
  DqMixture,
  DqGarnishBasic,
  DqMixtureBasic,
} from "src/app/services/mixture/mixture.service";
import { DqOrderStatus } from "src/app/services/order/order.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";
import { DqModifierGroup } from "src/app/models/cart.model";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";

import dials from "../../../assets/dialing.json";

import { BookingService } from "src/app/services/booking/booking.service";
import { BookingGuest } from "src/app/models/bookings";
import { Router } from "@angular/router";
import { RegionsService } from "src/app/services/regions/regions.service";
import { SearchGuestComponent } from "../search-guests/search-guest.component";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";
import {
  combineLatest,
  map,
  pipe,
  pluck,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs";
import { RxState } from "@rx-angular/state";
import { SyncV1Guest } from "src/app/models/sync_models";
import { MetricState } from "src/app/services/sync-engine/metric-state/metric.service";
import { TagState } from "src/app/services/sync-engine/tag-state/assignment.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-edit-guest",
  templateUrl: "./edit-guest.component.html",
  styleUrls: ["./edit-guest.component.scss"],
})
export class EditGuestComponent implements OnInit, OnChanges {
  @Input() guest_id;
  @Input() change;
  @Input() header = true;
  dialingCodes = dials;
  max_slice = 20;
  isCMS = window.location.href.includes("/cms/");
  isFullCMS = window.location.href.includes("/cms/manage-guests");

  temporarySt = new RxState<{
    guest: SyncV1Guest;
    duplicates: {
      guests_phone: SyncV1Guest[];
      guests_email: SyncV1Guest[];
    };
  }>();

  duplicates$ = this.temporarySt.select("duplicates");
  guest$ = this.temporarySt.select("guest");
  newguest$ = this.guestSt.guest$;
  guest_tags$ = this.guest$.pipe(
    switchMap((guest) => this.metricSt.guest_tags$(guest.guest_id))
  );
  guest_history$ = this.guest$.pipe(
    switchMap((guest) => this.metricSt.guest_history$(guest.guest_id)),

    //null first
    startWith([])
  );
  guest_metrics$ = this.guest$.pipe(
    switchMap((guest) => this.metricSt.guest_metric$(guest.guest_id))
  );

    seeGuestDetails$ = combineLatest([
      this.settingSt.hasClaim$('view_guest_contacts_ops'),
      this.settingSt.hasClaim$('view_guest_contacts_cms'),


    ]).pipe(
      map(([ops, cms]) => {
        return ops || cms;
      })
    );

    

  shortened_tags$ = combineLatest([this.guest_tags$, this.guest$]).pipe(
    map(([auto_tags, dwa]) => {
      let manual_tags: any = dwa.tags;

      manual_tags = manual_tags.map((t) => {
        return this.tagSt.state.get(t);
      });

      manual_tags = manual_tags.map((t) => {
        return {
          ...t,
          importance: 2,
        };
      });

      var ids = manual_tags.map((t) => t.tag_id).join(",");
      let tag_options = auto_tags.filter((t) => !ids.includes(t + ","));

      tag_options = tag_options.concat(manual_tags);
      //now we have a list of tags that are in the guest, with no duplicates
      //manual tags have priority, folled by system tags, highlight tags
      //and then the highest tag from each category
      let combinedTags = tag_options.map((t: any) => {
        let obj = this.tagSt.state.get(t.tag_id);

        t = Object.assign({}, t, obj);

        if (t.importance == undefined) {
          if (t.tag_type == "system") {
            t.importance = 1.6;
          } else if (t.tag_type == "highlight") {
            t.importance = 1.4;
          } else if (t.tag_type == "general") {
            t.importance = 0.3;
          } else if (t.tag_type == "brand") {
            t.importance = t.strength / 2;
          } else if (t.strength != undefined) {
            t.importance = t.strength;
          } else {
            t.importance = 0;
          }
          t.strength = undefined;
          return t;
        } else {
          t.strength = undefined;

          return t;
        }
      });

      //order tags by importance value

      combinedTags = combinedTags.sort((a, b) => {
        return b.importance - a.importance;
      });

      //now we need to shorten the list of tags to fit on the screen
      //max tag amount is
      let max_char = 50;
      let current_char = 0;
      console.log("Combined TAG", combinedTags);
      combinedTags = combinedTags.filter((element) => {
        let cost = 8;
        if (element.importance == 2) {
          cost = 12;
        }

        cost += element.name.length;

        //check if we can add this tag
        if (current_char + cost < max_char) {
          current_char += cost;

          return true;
        } else {
          return false;
        }
      });

      //order combined tags by cost
      combinedTags = combinedTags.sort((a, b) => {
        let cost = 8;
        if (a.importance == 2) {
          cost = 12;
        }

        let cost2 = 8;
        if (b.importance == 2) {
          cost2 = 12;
        }

        cost += a.name.length;
        cost2 += b.name.length;

        return cost2 - cost;
      });

      return combinedTags;
    })
  );

  @Output() notableChange = new EventEmitter();

  page = "info";
  saving = false;
  is_desktop = this.platform.is("desktop");
  loadingStats = false;
  Stats: any = {};

  EditedTags = false;

  currency$ = this.settingSt.currencyCode$;

  constructor(
    private cartFacade: CartFacadeService,
    private analy: AnalyticsService,
    private modal: ModalController,
    private booking: BookingService,
    private alert: AlertController,
    private router: Router,
    private platform: Platform,
    private reg: RegionsService,
    private toastCtrl: ToastController,
    private guestSt: GuestState,
    private metricSt: MetricState,
    private tagSt: TagState,
    private settingSt: SettingsState,
    private bookingSvc: BookingService
  ) {}

  defSe = this.reg.getISO()

  async ngOnInit() {
    this.tagSt.InitStore();
    this.metricSt.InitStore();
    this.check_guest_ids();

    this.guest$.subscribe((guest) => {

   //   this.phoneForm.setValue({phone: guest.mobile_code+guest.mobile_num})
    
    })
   
    /*this.phoneForm.valueChanges.subscribe((value) => {
      console.log("Phone Form Changed", value)

      if (value.phone == undefined ||value.phone.internationalNumber == undefined) {
        return
      }
      //get the guest and update it 
      value.phone.dialCode

      let val = value.phone.internationalNumber.replace(value.phone.dialCode, "")
      if (val[0] == "0") {
        val = val.slice(1)
      }
      console.log("Val", val)
      let guest = this.temporarySt.get("guest")
      if (guest != undefined) {
        guest.mobile_num = val
        guest.mobile_code = value.phone.dialCode
        this.temporarySt.set({guest})
      }

    })
      */
  }


countryChange(event) {
 
}

  async viewing(oa) {
    let guest = await this.guestSt
      .guest$(this.guest_id)
      .pipe(take(1))
      .toPromise();
    if (oa == "history") {
      this.analy.addEvent("View Guest History", guest);
      return true;
    }
    if (oa == "metrics") {
      this.analy.addEvent("View Guest Metrics", guest);
      return true;
    }
  }

  async check_guest_ids() {
    let guestat = false;
    if (
      this.guest_id != undefined &&
      this.guest_id != "0" &&
      this.temporarySt.get("guest")?.guest_id != this.guest_id
    ) {
      guestat = true;
    }

    if (this.guest_id != undefined) {
      if (this.guest_id != "0") {
        let guest = await this.newguest$(this.guest_id)
          .pipe(take(1))
          .toPromise();
        this.temporarySt.set({ guest });
      } else {
        this.temporarySt.set({
          guest: {
            guest_id: "0",
            name: "",
            mobile_num: "",
            email: "",
            mobile_code: this.settingSt.getPrefix(),
            tags: [],
            walk_in_unassigned: false,
            status: "first_time_guest",
            status_text: "",
          },
        });
      }
    } else {
      //remove the guest
      this.temporarySt.set({ guest: undefined });
    }

    if (guestat) {
      this.fetchPersonalDetails(this.guest_id)
      this.UpdateGuestStats();
      if (this.isCMS) {
        this.temporarySt.set({
          duplicates: {
            guests_email: [],
            guests_phone: [],
          },
        });

        this.bookingSvc
          .getDuplicateGuests(this.guest_id)
          .subscribe((data: any) => {
            this.temporarySt.set({ duplicates: data });
          });
      }

    }
  }

  async fetchPersonalDetails(id) {
    let ops = await this.settingSt.hasClaim$('view_guest_contacts_ops').pipe(take(1)).toPromise()
    let cms = await this.settingSt.hasClaim$('view_guest_contacts_cms').pipe(take(1)).toPromise()

    if ((ops || cms) ) {
    let guest: any = await this.bookingSvc.GetSyncGuest(id).toPromise()
    console.log("Fetching Guest Details",     guest.items[0], guest.items[0].guest_id
    )
    let guest_id = guest.items[0].guest_id
    console.log({[guest_id]: guest.items[0]})
    this.guestSt.state.set({[guest_id]: guest.items[0]}
      )
      if (this.temporarySt.get("guest")?.guest_id == guest_id) {
    this.temporarySt.set({"guest":  guest.items[0]})
      }
    }
  }


  debugt(event?) {
    console.log("Debugt", this.temporarySt.get("guest"))
    if (event != undefined) {
      console.log("Event", event)
    }
  }
  async mergeGuests(guest_id) {
    //show loading toast
    let t = await this.toastCtrl.create({
      message: "Merging Guest",
      duration: 10000,
      position: "top",
    });
    await t.present();
    try {
      //merge the guests
      await this.booking.MergeGuests(guest_id, this.guest_id).toPromise();
    } catch (e) {
      console.log(e);
    }

    //remove the loading toast
    await t.dismiss();

    this.bookingSvc.getDuplicateGuests(this.guest_id).subscribe((data: any) => {
      this.temporarySt.set({ duplicates: data });
    });

    this.UpdateGuestStats();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.check_guest_ids();
  }

  openBooking(booking) {
    this.modal.dismiss();
    if (window.location.href.includes("/cms/")) {
      if (booking.venue != undefined) {
        this.toastCtrl
          .create({
            header: "This booking is for " + booking.venue,
            message:
              "You cannot view bookings for partner venues, please login to the correct venue",
            duration: 3000,
            position: "top",
          })
          .then((toast) => toast.present());
        return;
      }
      this.router.navigate([
        this.reg.getRegion(),
        "cms",
        "bookings",
        "b",
        booking.id,
      ]);
    } else {
      if (this.platform.is("tablet")) {
        this.router.navigate([
          this.reg.getRegion(),
          "tab",
          "bookings",
          "b",
          booking.id,
        ]);
      } else {
        this.router.navigate([
          this.reg.getRegion(),
          "tab",
          "bookings",
          booking.id,
        ]);
      }
    }
  }

  async UpdateGuestStats() {
    var gi = this.guest_id;

    //quickly add empty stats if they dont exist
    if (this.metricSt.state.get("tags_" + gi) == undefined) {
      this.metricSt.state.set({
        ["tags_" + gi]: [],
      });
    }
    if (this.metricSt.state.get("history_" + gi) == undefined) {
      this.metricSt.state.set({
        ["history_" + gi]: [],
      });
    }
    if (this.metricSt.state.get("metric_" + gi) == undefined) {
      this.metricSt.state.set({
        ["metric_" + gi]: [],
      });
    }

    this.booking.getGuestTags(this.guest_id).subscribe((stats) => {
      this.metricSt.state.set({
        ["tags_" + gi]: stats.tags,
      });
      console.log("Stats Out", stats);
    });
    this.booking.getGuestHistory(this.guest_id).subscribe((stats) => {
      this.metricSt.state.set({
        ["history_" + gi]: stats.events,
      });
      console.log("History Out", stats);
    });

    this.booking.getGuestMetrics(this.guest_id).subscribe((stats) => {
      this.metricSt.state.set({
        ["metric_" + gi]: stats.filter_clauses,
      });
      console.log("Metrics Out", stats);
    });
    this.timeoutexpand();
    //go get the guest history, metrics, and tags
  }

  timeoutexpand() {
    this.max_slice = 20;
  }

  openGuest(guest) {}

  RemoveTag(tag: string) {
    // this.guest.tags = this.guest.tags.filter((t) => t != tag);
  }

  async AddTag() {
    //show alert to add tag
    const alert = await this.alert.create({
      header: "Add Tag",
      inputs: [
        {
          name: "tag",
          type: "text",
          placeholder: "Tag",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {},
        },
        {
          text: "Ok",
          handler: async (data) => {
            //make sure this tag doesnt already exist
            let guest = await this.guest$.pipe(take(1)).toPromise();
            if (guest.tags.includes(data.tag)) {
              return;
            }

            guest.tags.push(data.tag);
            this.updateGuest(guest);
          },
        },
      ],
    });

    this.analy.addEvent("Add Guest Tag", { tag: "success" });

    await alert.present();
  }

  dismiss() {
    this.modal.dismiss();
  }

  changeGuest() {
    this.modal.dismiss("change");
  }

  async updateGuest(guest) {
    this.saving = true;
    await this.booking.updateGuest(guest).toPromise();
    this.EditedTags = false;
    this.analy.addEvent("Updated Guest", guest);
    this.notableChange.emit();
    this.modal.dismiss(guest);
    this.saving = false;
  }

  async CreateGuest(guest) {
    this.saving = true;
    let result_guest = (await this.booking
      .createGuest(guest)
      .toPromise()) as any;

    if (result_guest.save_type == "required") {
      let res = await this.alert.create({
        header: "Duplicate Guest Found",
        message:
          "Guest '" +
          result_guest.name +
          "' has the same phone. Would you like to merge the two guests?",
        buttons: [
          {
            text: "Ignore",
            handler: async () => {
              guest.save_type = "duplicate";
              //create a duplicate guest
              guest = await this.booking.createGuest(guest).toPromise();
              this.guestSt.state.set({ [guest.guest_id]: guest });
              this.analy.addEvent("Created Guest", guest);
              guest.id = guest.guest_id;
              this.modal.dismiss(guest);
              this.notableChange.emit();
              this.saving = false;
            },
          },
          {
            text: "Merge",
            handler: async () => {
              guest.save_type = "merge";

              //merge the two guests
              guest = await this.booking.createGuest(guest).toPromise();
              this.guestSt.state.set({ [guest.guest_id]: guest });

              this.analy.addEvent("Created Guest", guest);
              guest.id = guest.guest_id;

              this.modal.dismiss(guest);
              this.notableChange.emit();
              this.saving = false;
            },
          },
        ],
      });
      await res.present();
      this.saving = false;
    } else {
      this.analy.addEvent("Created Guest", result_guest);
      guest.id = guest.guest_id;

      this.modal.dismiss(result_guest);
      this.notableChange.emit();
      this.saving = false;
    }
    this.temporarySt.set({ guest: result_guest });

  }

  async mergeGuest() {
    let modal = await this.modal.create({
      component: SearchGuestComponent,

      componentProps: {
        createAllowed: false,
      },
    });
    await modal.present();

    let { data } = await modal.onWillDismiss();

    if (data == undefined) {
      return;
    }
    let guest = await this.guestSt
      .guest$(this.guest_id)
      .pipe(take(1))
      .toPromise();

    //merge with this gueest
    //create an alert
    const alert = await this.alert.create({
      header: "Merge Guest",
      message:
        "Are you sure you want to merge " +
        data.name +
        "'s Guest Profile into " +
        guest.name +
        "'s Profile?",
      subHeader:
        "Warning: This cannot be undone, do NOT merge guests during service",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {},
        },
        //make this button red
        {
          text: "Merge",
          cssClass: "danger",
          handler: async () => {
            this.saving = true;
            await this.booking.MergeGuests(data.id, guest.guest_id).toPromise();
            this.analy.addEvent("Merged Guest", guest);
            this.modal.dismiss(guest);
            this.saving = false;
            this.notableChange.emit();
          },
        },
      ],
    });
    await alert.present();
  }
  trackByevent(index: number, event: any) {
    return index;
  }
  trackByid(index: number, event: any) {
    return event.guest_id;
  }

  trackByTagID(index: number, tag: any) {
    return tag.tag_id;
  }
}
