import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AlertController, ToastController } from "@ionic/angular";
import { DqGratuityType } from "src/app/services/order/order.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";

export interface PaymentFormGratuityData {
  value: number;
  type: DqGratuityType;
}

@Component({
  selector: "app-gratuity",
  templateUrl: "./gratuity.component.html",
  styleUrls: ["./gratuity.component.scss"],
})
export class GratuityComponent implements OnInit, OnChanges {
  @Input() total: number;
  @Input() options: number[] = [0, 5, 10];
  selected: any = 0;
  customvalue;
  @Output() valueChange = new EventEmitter<number>();
  currency$ = this.settingSt.currencyCode$;
  constructor(
    private alertCtrl: AlertController,
    private toast: ToastController,
    private settingSt: SettingsState
  ) {}

  ngOnInit() {}

  gratuityChange(value) {
    if (value == "amount") {
      //show alert and request custom amount
      this.alertCtrl
        .create({
          header: "Custom Gratuity",
          message: "Please enter the custom gratuity amount",
          inputs: [
            {
              name: "customvalue",
              type: "number",
              placeholder: "Custom Gratuity",
            },
          ],
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {},
            },
            {
              text: "Ok",
              handler: (data) => {
                //get percatage of total that customvalue is
                //parse customvalue to number
                try {
                  data.customvalue = parseFloat(data.customvalue);
                  if (isNaN(data.customvalue)) {
                    this.toast
                      .create({
                        message: "Please enter a valid number",
                        duration: 3000,
                        position: "top",
                        color: "danger",
                      })
                      .then((toast) => toast.present());

                    return;
                  }

                  this.customvalue = data.customvalue;

                  this.selected = value;
                  this.valueChange.emit(data.customvalue);
                } catch (e) {
                  console.log(e);
                }
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    } else {
      this.selected = value;
      this.valueChange.emit(this.total * (value / 100));
    }
  }

  ngOnChanges(changes: SimpleChanges) {}
}
