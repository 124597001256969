import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import { SyncV1Assignment } from "src/app/models/sync_models";
import { SettingsState } from "../settings-state/settings-state.service";

@Injectable({
  providedIn: "root",
})
export class AssignmentState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Assignment }>();

  //fields specific to the service
  service_icon: string = "compass-outline";
  service_title: string = "Assignment Store";
  service_id: string = "UserTableAssigned"; //id of the service
  service_url: string = "table_assignments";

  //fields specific to the data
  data_id: string = "uta_id";
  data_detail: string = "table_id";
  data_expiry: number = 200; //in minutes, the amount of time before the data is considered unusable

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService,
    private settingSt: SettingsState
  ) {
    super(syncapis);

    //add data to the state
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(
    map((assignments) => Object.values(assignments))
  );
  //get the menu items as an array

  assignments$: Observable<SyncV1Assignment[]> = this.state.select(
    map((assignments) => Object.values(assignments))
  );

  visibleTables$: Observable<SyncV1Assignment[]> = combineLatest([
    this.state.select(),
    this.settingSt.currentUser$,
    this.settingSt.hasClaim$('manage_assignments'),
    this.settingSt.hasClaim$('manage_bookings'),
    this.settingSt.hasClaim$('manage_seatings'),
    this.settingSt.hasClaim$('manage_bookings_prom'),

  ]).pipe(
    map(([assignments, user, manage_assignments, manage_bookings, manage_seatings, manage_prom]) => {
      console.log("visibleTables$");
      
      if (!manage_assignments && !manage_bookings && !manage_seatings && !manage_prom) {
        return Object.values(assignments).filter((x) => {
          return x.user_id == user["username"];
        });
      } else {
        return undefined;
      }
    })
  );

  visibleTablesOrders$: Observable<SyncV1Assignment[]> = combineLatest([
    this.state.select(),
    this.settingSt.currentUser$,

  ]).pipe(
    map(([assignments, user]) => {
      console.log("visibleTables$");
      
        return Object.values(assignments).filter((x) => {
          return x.user_id == user["username"];
        });
   
    })
  );

  tableAssignments$ = (id) =>
    this.state.select(
      map((assignments) =>
        Object.values(assignments).filter((x) => {
          return x.table_id == id;
        })
      )
    );
}
