import { ToolBar } from "./toolbar.graphic";
import { MapObject } from "../../../models/map_editor_models";
import {
  Application,
  Container,
  Sprite,
  Graphics,
  Texture,
  DisplayObject,
} from "pixi.js";
import { GlowFilter } from "@pixi/filter-glow";
import { Subject } from "rxjs";
export abstract class Actor extends Graphics {

  public CurrentlySelected: boolean = false;

  update(delta: number) {}

  onKeydown(e: KeyboardEvent) {}

  onKeyup(e: KeyboardEvent) {}

  lerp(start: number, end: number, amt: number) {
    return (1 - amt) * start + amt * end;
  }

  public destroyobject() {
    this.destroy();
  }


}
