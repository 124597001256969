<ng-container
  *ngrxLet="relevantNotification$(table_id, success_timeout) as notif"
>
  <div
    class="seperate ion-padding-horizontal"
    *ngIf="notif != undefined"
    lines="none"
  >
    <ion-note
      color="warning"
      *ngIf="
        notif.notification_class == 'manager_request' &&
        notif.can_be_accepted == true
      "
      >Manager Requested</ion-note
    >
    <ion-note
      color="danger"
      *ngIf="
        notif.notification_class == 'security_request' &&
        notif.can_be_accepted == true
      "
      >Security Requested</ion-note
    >
    <ion-note color="success" *ngIf="notif.can_be_accepted == false"
      >{{ notif.accepted_by_user_names[0] }} Accepted
    </ion-note>

    <ion-spinner
      [color]="
        notif.notification_class == 'manager_request' ? 'warning' : 'danger'
      "
      name="circular"
      *ngIf="notif.can_be_accepted == true"
    ></ion-spinner>
    <ion-icon
      color="success"
      name="checkmark-done-outline"
      *ngIf="notif.can_be_accepted == false"
    ></ion-icon>
  </div>
</ng-container>
