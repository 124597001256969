import {
  Component,
  Injectable,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CMSv2AailabilityInterval,
  CMSv2MenuCategory,
  CMSv2MenuModifierGroup,
} from "src/app/models/cms_db_equiv";
import { MenuService } from "src/app/services/menu/menu.service";
import { CmsMenuService } from "src/app/services/cms/menu/cms-menu.service";
import { ModalController } from "@ionic/angular";
import { float } from "@zxing/library/esm/customTypings";
import { ConsumerItemDetailsPage } from "src/app/pages/consumer-item-details/consumer-item-details.page";
import { CmsSettingsService } from "src/app/services/cms/settings/cms-settings.service";

@Component({
  selector: "app-billing-setup",
  templateUrl: "./billing-setup.component.html",
  styleUrls: ["./billing-setup.component.scss"],
})
export class BillingSetupComponent implements OnInit {
  constructor(
    private menuSvc: CmsMenuService,
    private modal: ModalController,
    private settings: CmsSettingsService
  ) {}

  ngOnInit() {}

  async signup(plan: string) {
    let data = await this.settings.setupPlan(plan).toPromise();
    window.location.href = (data as any).url;
  }
}
