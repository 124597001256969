import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map, mapTo, share, shareReplay, switchMap } from "rxjs/operators";
import { RegionsService } from "../../regions/regions.service";
import { RxState } from "@rx-angular/state";
import { Observable, combineLatest } from "rxjs";
import { BaseSynced } from "../base-synced/base.service";
import { Platform } from "@ionic/angular";
import { SyncAPIService } from "../sync-api/syncapi.service";
import {
  SyncV1Booking,
  SyncV1Guest,
  SyncV1Table,
} from "src/app/models/sync_models";

@Injectable({
  providedIn: "root",
})
export class BookingState extends BaseSynced {
  state = new RxState<{ [key: string]: SyncV1Booking }>();

  //fields specific to the service
  service_icon: string = "book-outline";
  service_title: string = "Booking Store";
  service_id: string = "VenueBooking"; //id of the service
  service_url: string = "bookings";

  //fields specific to the data
  data_id: string = "booking_id";
  data_detail: string = "number";
  data_expiry: number = 200; //in minutes, the amount of time before the data is considered unusable

  constructor(
    private http: HttpClient,
    private reg: RegionsService,
    private syncapis: SyncAPIService,
  ) {
    super(syncapis);
  }

  allWithService$ = combineLatest([
    this.state.select(),
    this.serviceState.select(),
  ]).pipe(
    map(([items, stats]) => {
      if (stats.initiliased) {
        return {
          ...stats,
          data: this.GetState(),
        };
      } else {
        return undefined;
      }
    })
  );

  getItems$ = this.state.select(map((menuItems) => Object.values(menuItems)));
  //get the menu items as an array

  //get table using id
  booking$ = (id) => this.state.select(id);
  bookings$ = this.state.select(map((menuItems) => Object.values(menuItems)));

  link_booking_array$ = this.state.select(
    map((menuItems) => {
      let obj = Object.values(menuItems);
      let arr = [];
      obj.forEach((x) => {
        if (x.status == "seated") {
          if (x.linked_table_ids != undefined) {
            x.linked_table_ids.forEach((y) => {
              arr.push(y);
            });
          }
        }
      });
      return arr;
    })
  );



  booking_name = (booking, guest) => {
  
    return booking != undefined && booking.group_name != undefined && booking.group_name != ''?  booking.group_name: guest.name
  }

  
}
